<script setup lang="ts">
import {ref, onMounted, computed, watch} from "vue"
import {useI18n} from "vue-i18n"
import {storeToRefs} from "pinia"
import {useToast} from "vue-toastification"
import {useHelpers} from "@/composables/useHelpers"
import {useModalStore} from "@/stores/ModalStore"
import {useAddDriverAutoStore} from "@/stores/AddDriverAutoStore"
import {constants} from "@/constants/kdp"
import FFButton from "@/components/FFUI/FFButton.vue"
import WhiteCheckIcon from "@/components/icons/button-states/WhiteCheckIcon.vue"
import WhiteCrossIcon from "@/components/icons/button-states/WhiteCrossIcon.vue"
import CloseIcon from "@/components/icons/CloseIcon.vue"

const toast = useToast()
const {currentLocale, openLink} = useHelpers()
const {t} = useI18n()
const addDriverAutoStore = useAddDriverAutoStore()
const {notVerificatedUsers, intervalIds, agreementLoaderHide} = storeToRefs(addDriverAutoStore)
const modalStore = useModalStore()

const buttonStates = ref<Array<{id: number; status: string}>>([])
const timers = ref<number[]>([])

onMounted(async () => {
  buttonStates.value = notVerificatedUsers.value.map(() => ({
    id: constants.DEFAULT,
    status: ""
  }))
  timers.value = new Array(notVerificatedUsers.value.length).fill(constants.DEFAULT)
})

const handleIntervalKdpCheck = async (index: number) => {
  agreementLoaderHide.value = true
  const response = await addDriverAutoStore.sendCode(index)
  const responseData = response?.data?.data

  if (responseData?.id === 1) {
    clearInterval(intervalIds.value[index])
    delete intervalIds.value[index]
    agreementLoaderHide.value = false
    buttonStates.value[index].id = constants.AGREED
    buttonStates.value[index].status = responseData?.status
  } else if (responseData?.id === 2) {
    buttonStates.value[index].id = constants.PENDING
  } else if (responseData?.id === 3) {
    clearInterval(intervalIds.value[index])
    delete intervalIds.value[index]
    agreementLoaderHide.value = false
    buttonStates.value[index].id = constants.DENIED
    buttonStates.value[index].status = responseData?.status
  } else if (responseData?.id === 4) {
    clearInterval(intervalIds.value[index])
    delete intervalIds.value[index]
    agreementLoaderHide.value = false
    buttonStates.value[index].id = constants.ERROR
    buttonStates.value[index].status = responseData?.status
  }
}

const handleSendCode = async (index: number) => {
  buttonStates.value[index].id = constants.PENDING
  await handleIntervalKdpCheck(index)
  intervalIds.value[index] = setInterval(async () => {
    await handleIntervalKdpCheck(index)
  }, 5000)
}

const buttonTitle = (state: number, index: number) => {
  switch (state) {
    case constants.PENDING:
      if (currentLocale.value === "ru" || currentLocale.value === "en") {
        return `${t("app.buttons.sendCodeAgainThrough.text-1")} ${timers.value[index]} ${t("app.buttons.sendCodeAgainThrough.text-2")}`
      } else {
        return `${timers.value[index]} ${t("app.buttons.sendCodeAgainThrough.text-2")}`
      }
    case constants.DENIED:
      return t("app.buttons.sendCodeAgain")
    default:
      return t("app.buttons.sendCode")
  }
}

const closeKdp = () => {
  modalStore.closeModal()
  addDriverAutoStore.resetCalcStates()
  addDriverAutoStore.clearAllIntervals()
}

const allUsersAreVerified = computed(() => {
  return buttonStates.value.every((button) => button.id === 1)
})

watch(allUsersAreVerified, (newVal) => {
  if(newVal) {
    setTimeout(() => {
      modalStore.closeModal()
      addDriverAutoStore.clearAllIntervals()
    }, 1000)
  }
})
</script>
<template>
  <div class="add-driver">
    <CloseIcon @click="closeKdp" class="add-driver__close-icon" />
    <div class="add-driver__header">
      <p class="add-driver__header--title">
        <template v-if="currentLocale === 'kk'">
          {{ $t("app.addDriverAuto.firstDescription-1") }}
          <span>{{ $t("app.addDriverAuto.firstDescription-2") }}</span>
          {{ $t("app.addDriverAuto.firstDescription-3") }}
        </template>
        <template v-else>
          {{ $t("app.addDriverAuto.firstDescription-1") }}
          <span @click="openLink('https://ffins.kz/polodgenie-ob-obrabotke-personalnykh-dannykh', '_blank')">{{
            $t("app.addDriverAuto.firstDescription-2")
          }}</span>
        </template>
      </p>
      <p class="add-driver__header--description">
        {{ $t("app.addDriverAuto.secondDescription") }}
      </p>
    </div>
    <div v-if="notVerificatedUsers.length" class="add-driver__persons">
      <div v-for="(person, index) in notVerificatedUsers" :key="index">
        <div class="add-driver__person">
          <div class="add-driver__person-first-row">
            <div class="add-driver__person--info">
              <div class="add-driver__person--info__data">
                <div class="add-driver__person--info__data--name">
                  <p>{{ person.fullName }}</p>
                  <p v-if="person.benefits">{{ $t("app.addDriverAuto.beneficiary") }}</p>
                </div>
                <p class="add-driver__person--info__data--iin">{{ person.iin }}</p>
              </div>
            </div>
            <div class="add-driver__person--confirm">
              <div
                v-if="buttonStates[index]?.id === constants.AGREED || person.verifyClient === true"
                class="agreement-form__second-part-state-block">
                <p>{{ $t("app.buttons.confirmed") }}</p>
                <WhiteCheckIcon />
              </div>
              <div
                v-if="buttonStates[index]?.id === constants.PENDING || buttonStates[index]?.id === constants.ERROR"
                class="agreement-form__second-part-state-block">
                <p>{{ $t("app.buttons.waitingConfirmation") }}</p>
                <img class="loading-icon" src="/main-front/images/loading-gif.gif" height="20" width="20" alt="loading" />
              </div>
              <div v-if="buttonStates[index]?.id === constants.DENIED" class="agreement-form__second-part-state-block">
                <p>{{ buttonStates[index]?.status }}</p>
                <WhiteCrossIcon />
              </div>
              <FFButton
                v-if="
                  !person.verifyClient &&
                  buttonStates[index]?.id !== constants.AGREED &&
                  buttonStates[index]?.id !== constants.PENDING &&
                  buttonStates[index]?.id !== constants.ERROR
                "
                @click="handleSendCode(index)"
                :disabled="buttonStates[index]?.id === constants.PENDING"
                :title="buttonTitle(buttonStates[index]?.id, index)"
                width="100%"
                :uppercase="false" />
            </div>
          </div>
          <p
            class="add-driver__person-download-agreement"
            @click="addDriverAutoStore.openAddDriverKdpAgreementPdf(person.fullName, person.iin)">
            {{ $t("app.ogpoPage.forms.downloadConsent") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.add-driver {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 50px;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);
  .add-driver__close-icon {
    position: absolute;
    top: 12px;
    right: 12px;
  }
  .add-driver__header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: start;
    padding: 20px 16px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.2);
    .add-driver__header--title {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      max-width: 350px;
      span {
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
    .add-driver__header--description {
      color: #fff;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      max-width: 470px;
    }
  }
  .add-driver__persons {
    display: flex;
    flex-direction: column;
    gap: 30px;
    .add-driver__person {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: space-between;
      .add-driver__person-first-row {
        width: 100%;
        display: flex;
        justify-content: space-between;

        @media (max-width: $lg) {
          flex-direction: column;
          gap: 10px;
        }
        .add-driver__person--info {
          display: flex;
          align-items: center;
          gap: 20px;
          max-width: 300px;
          .add-driver__person--info__data {
            display: flex;
            flex-direction: column;
            .add-driver__person--info__data--name {
              display: flex;
              align-items: flex-start;
              gap: 10px;
              :nth-child(1) {
                color: #fff;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }
              :nth-child(2) {
                color: #fff;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                padding: 6px 8px;
                text-wrap: nowrap;
                border-radius: 10px;
                background: #383838;
              }
            }
            .add-driver__person--info__data--iin {
              color: rgba(255, 255, 255, 0.5);
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
        .add-driver__person--confirm {
          display: flex;
          gap: 10px;
          align-items: center;
          .agreement-form__second-part-state-block {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            width: 100%;
            p {
              color: #fff;
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: 100%;
            }
            img {
              width: 20px;
              height: 20px;
            }
          }
          .add-driver__person--confirm__download:hover {
            color: #fff;
          }
        }
      }
      .add-driver__person-download-agreement {
        color: rgba(255, 255, 255, 0.5);
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-decoration-line: underline;
        cursor: pointer;
        transition: all 0.3s ease;
      }
    }
  }
}

@media (max-width: $lg) {
  .add-driver {
    padding: 34px 20px 27px;
    margin: 0 15px;
    gap: 30px;
    .add-driver__person {
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
      .add-driver__person-first-row {
        .add-driver__person-first-row--info {
          width: 100%;
          .add-driver__person-first-row--info__data {
            width: 100%;
            max-width: 215px;

            .add-driver__person-first-row--info__data--name {
              justify-content: space-between;
            }
          }
          .add-driver__person--confirm {
            .agreement-form__second-part-state-block {
              display: flex;
              justify-content:flex-start;
            }
          }
        }
        .add-driver__person-first-row--confirm {
          width: 100%;
          .add-driver__person-first-row--confirm__download {
            font-size: 12px;
            display: block;
          }
          .add-driver__person-first-row--confirm__download:hover {
            color: rgba(255, 255, 255, 0.5);
          }
        }
      }
    }
    .add-driver__line {
      margin: 20px 0;
    }
  }
}
</style>
