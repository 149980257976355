<script setup lang="ts">
import {onBeforeMount} from "vue"
import {storeToRefs} from "pinia"
import {useRouter, onBeforeRouteLeave} from "vue-router"
import {vMaska} from "maska"
import {useWearAndMileageStore} from "@/stores/CalculatorStore"
import WearVehicleCalculatorResult from "@/components/WearVehicleCalculator/WearVehicleCalculatorResult.vue"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import Dropdown from "primevue/dropdown"

const router = useRouter()
const wearAndMileageStore = useWearAndMileageStore()
const {wearVehicleObject, isWearVehicleObjectComplete, categories, brands, wearVehicleCalculateResult} =
  storeToRefs(wearAndMileageStore)

onBeforeMount(() => {
  wearAndMileageStore.fetchCategories()
})

onBeforeRouteLeave(() => {
  wearAndMileageStore.resetStates()
})
</script>

<template>
  <section class="wear-vehicle-calculator-form container">
    <p class="wear-vehicle-calculator-form__title">{{ $t("app.calculatorPage.wearVehicle.title") }}</p>
    <div class="wear-vehicle-calculator-form__row-inputs">
      <FFFieldset :title="$t('form.inputTitles.category')">
        <Dropdown
          v-model="wearVehicleObject.category"
          :options="categories.data"
          optionLabel="name"
          :panelStyle="{maxWidth: '246px', minWidth: '246px'}"
          panelClass="custom-dropdown-panel" />
      </FFFieldset>
      <FFFieldset :title="$t('form.inputTitles.stamp')">
        <Dropdown
          v-model="wearVehicleObject.brand"
          emptyMessage="    "
          :options="brands.data"
          optionLabel="name"
          :panelStyle="{maxWidth: '246px', minWidth: '246px'}"
          panelClass="custom-dropdown-panel" />
      </FFFieldset>
    </div>
    <div class="wear-vehicle-calculator-form__row-inputs">
      <FFFieldset :title="$t('form.inputTitles.yearOfRelease')">
        <FFInput v-model="wearVehicleObject.yearOfRelease" v-maska data-maska="####" type="text" inputmode="numeric" />
      </FFFieldset>
      <FFFieldset :title="$t('form.inputTitles.actualMileage')">
        <FFInput v-model="wearVehicleObject.actualMileage" type="text" inputmode="numeric" />
      </FFFieldset>
    </div>
    <div class="wear-vehicle-calculator-form__buttons">
      <FFButton
        @click="wearAndMileageStore.wearVehicleCalculate"
        :title="$t('app.buttons.calculateWear')"
        color="var(--color-white)"
        colorMobile="var(--color-white)"
        background="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
        backgroundMobile="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
        :uppercase="false"
        :disabled="!isWearVehicleObjectComplete" />
      <WearVehicleCalculatorResult
        v-if="wearVehicleCalculateResult"
        :wearVehicleCalculateResult="wearVehicleCalculateResult?.data"
        :actualMileage="wearVehicleObject.actualMileage" />
      <FFButton
        @click="router.push('/pages/vehicle-mileage')"
        :title="$t('app.buttons.mileageCalculator')"
        color="var(--color-white)"
        colorMobile="var(--color-white)"
        background="transparent"
        backgroundMobile="transparent"
        borderPixel="1px solid var(--color-white)"
        borderPixelMobile="1px solid var(--color-white)"
        :uppercase="false" />
    </div>
  </section>
</template>

<style scoped lang="scss">
.wear-vehicle-calculator-form {
  max-width: 540px;
  padding-top: 200px;
  padding-bottom: 200px;
  @include max-responsive(lg) {
    max-width: 360px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .wear-vehicle-calculator-form__title {
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    @include main-green-gradient;
    text-align: center;
  }
  .wear-vehicle-calculator-form__row-inputs {
    @include flex;
    gap: 20px;
    &:first-of-type {
      margin: 50px 0 20px;
    }

    @include max-responsive(lg) {
      @include column;
    }

    .input-fieldset {
      @include max-responsive(lg) {
      }
    }
  }
  .wear-vehicle-calculator-form__buttons {
    @include column;
    gap: 20px;
    margin-top: 20px;
  }
}
</style>
