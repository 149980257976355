<script setup lang="ts">
import {ref, onMounted, onUnmounted} from "vue"
import {useRoute, useRouter} from "vue-router"
import {useGatewayFetcher} from "@/composables/useGatewayFetcher"
import {useHelpers} from "@/composables/useHelpers"
import {useDeviceSize} from "@/composables/useDeviceSize"
import {externalUrls} from "@/constants/urls"
import ProductBankCashbackAmount from "@/components/shared/ProductBankCashbackAmount.vue"
import Modal from "@/components/FFUI/Modal.vue"
import Loader from "@/components/FFUI/Loader.vue"
import FFButton from "@/components/FFUI/FFButton.vue"

const route = useRoute()
const router = useRouter()
const {postData} = useGatewayFetcher()
const {currentLocale, openLink} = useHelpers()
const {isDesktop, isTablet} = useDeviceSize()

const calcId = route.query.calcId

const checkSubscriptionStatusData = ref<{status: string; message: string} | null | unknown>(null)
let intervalId: number | null = null
let isRequestInProgress = false
const loading = ref<boolean>(true)

const checkSubscriptionActivateStatus = async () => {
  if (isRequestInProgress) return
  isRequestInProgress = true

  const config = {
    headers: {
      "Accept-Language": currentLocale.value
    }
  }

  try {
    loading.value = true
    const response = await postData("/policy/kasko/subscribe/activate/status", {calcId}, config)
    if (response && response?.status === 200) {
      checkSubscriptionStatusData.value = response.data

      if (
        response.data?.status === "activate" ||
        response.data?.status === "notPayed" ||
        response.data?.status === "sendToActivate"
      ) {
        loading.value = false
        if (intervalId !== null) {
          clearInterval(intervalId)
          intervalId = null
        }
      }
    }
  } catch (error) {
    loading.value = false
    clearInterval(intervalId)
    intervalId = null
    console.error(error)
  } finally {
    isRequestInProgress = false
  }
}

const activateRetry = async () => {
  const config = {
    headers: {
      "Accept-Language": currentLocale.value
    }
  }

  try {
    loading.value = true
    const response = await postData("/policy/kasko/subscribe/activate/retry", {calcId}, config)
    if (response && response?.status === 200 && response?.data?.action === "add_card" && response?.data?.addCardUrl) {
      loading.value = false
      const url = response?.data?.addCardUrl
      openLink(url, "_self")
    }
  } catch (error) {
    loading.value = false
    console.error(error)
  }
}

//cashback
const paymentType = localStorage.getItem("paymentType")
const cashbackBalance = JSON.parse(localStorage.getItem("cashbackBalance"))
const cashbackAmount = JSON.parse(localStorage.getItem("cashbackAmount"))
const isClientBank = JSON.parse(localStorage.getItem("isClientBank"))
const isScoring = JSON.parse(localStorage.getItem("isScoring"))

const superAppLink =
  "https://loyalty.bankffin.kz/ru?utm_source=site&amp;utm_medium=banner&amp;utm_campaign=loyalty&amp;utm_content=ru"

const removePaymentTypeFromStorage = () => {
  localStorage.removeItem("paymentType")
  localStorage.removeItem("cashbackAmount")
  localStorage.removeItem("cashbackBalance")
  localStorage.removeItem("isClientBank")
  localStorage.removeItem("isScoring")
}

onMounted(() => {
  checkSubscriptionActivateStatus()
  intervalId = setInterval(checkSubscriptionActivateStatus, 15000)
})

onUnmounted(() => {
  if (intervalId !== null) {
    clearInterval(intervalId)
  }
  removePaymentTypeFromStorage()
})
</script>

<template>
  <Modal style="z-index: 103" :isOpen="loading">
    <Loader />
  </Modal>
  <div class="bg-image">
    <div class="subscription-activate-status container">
      <template v-if="checkSubscriptionStatusData?.status === 'notPayed'">
        <img src="/fail-pay-front/images/fail-cancel.png" alt="" />
        <p class="subscription-activate-status__title">{{ $t("app.subscriptionActivateStatusPage.title") }}</p>
        <p class="subscription-activate-status__description">
          {{ $t("app.subscriptionActivateStatusPage.notPayedTitle") }}
        </p>
        <FFButton
          @click="activateRetry"
          :title="$t('app.buttons.tryAgain')"
          :uppercase="false"
          maxWidth="300px"
          maxWidthMobile="300px" />
      </template>
      <template
        v-if="
          checkSubscriptionStatusData?.status === 'activate' ||
          checkSubscriptionActivateStatus?.status === 'sendToActivate'
        ">
        <img src="/success-pay-front/images/success-check.png" alt="" />
        <p class="subscription-activate-status__title">{{ $t("app.subscriptionActivateStatusPage.title") }}</p>
        <p class="subscription-activate-status__description">
          {{ $t("app.subscriptionActivateStatusPage.activateTitle") }}
        </p>
        <FFButton
          @click="router.push('/pages/personal-profile')"
          :title="$t('app.thankYou.login')"
          :uppercase="false"
          color="#4F9D3A"
          paddingx="15px"
          paddingy="30px"
          width="300px"
          border-pixel="1px solid #fff"
          background="#FFF" />
        <ProductBankCashbackAmount
          :isLoyaltyCalled="true"
          :isClientBank="false"
          :cashbackBalance="cashbackBalance"
          :paymentType="paymentType"
          :width="isDesktop || isTablet ? '370px' : '100%'"
          :url="externalUrls.superAppUrl" />
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.bg-image {
  background: url("/thankyou-front/images/thankyou-bg.png") no-repeat top;
  width: 100%;
  overflow: hidden;
  .subscription-activate-status {
    @include column;
    align-items: center;
    gap: 30px;
    width: 100%;
    height: 700px;
    padding: 100px 0;
    .subscription-activate-status__title {
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 900;
      line-height: 100%;
      @include main-green-gradient;
    }
    .subscription-activate-status__description {
      display: inline;
      position: relative;
      text-align: center;
      color: var(--color-white);
      width: fit-content;
      font-weight: 700;
      font-size: 21px;
      line-height: normal;
      color: #4fe8b0;

      @media (max-width: $lg) {
        font-size: 19px;
      }
    }
    .ff-button-wrapper {
      margin-top: 20px;
    }
  }
}
</style>
