<script setup lang="ts">
import {ref, computed, watch, onBeforeMount, onMounted} from "vue"
import {storeToRefs} from "pinia"
import {vMaska} from "maska"
import {z} from "zod"
import {useModalStore} from "@/stores/ModalStore"
import {useHomeProtectStore} from "@/stores/HomeProtectStore"
import {useHelpers} from "@/composables/useHelpers"
import {filterEmailInput} from "@/utils/inputEvents"
import {useToast} from "vue-toastification"
import {useI18n} from "vue-i18n"
import {
  whatsAppComfortMessageRu,
  whatsAppComfortMessageKk,
  whatsAppComfortMessageEn
} from "@/constants/whatsAppMessages"
import {filterLatinInput, filterCyrillicAndSpecialCharsInput, preventTextStartWithZero} from "@/utils/inputEvents"
import type {IHomeData} from "@/interfaces/Comfort/IHomeData"
import HProtectForms from "@/components/HomeProtect/HProtectForms.vue"
import Dropdown from "primevue/dropdown"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFPlainFieldset from "@/components/FFUI/FFPlainFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import FFErrorMessage from "@/components/FFUI/FFErrorMessage.vue"
import FFCheckbox from "@/components/FFUI/FFCheckbox.vue"
import FFTooltip from "@/components/FFUI/FFTooltip.vue"
import WhatsAppIcon from "@/components/icons/WhatsAppIcon.vue"
import QuestionIcon from "@/components/icons/QuestionIcon.vue"

const modalStore = useModalStore()
const homeProtectStore = useHomeProtectStore()
const {
  currentStep,
  calculationObject,
  apartmentClientData,
  propertyData,
  propertiesListData,
  isChoosedProperty,
  apartmentCount,
  choosedPropertyFromList,
  agreementsObj,
  isHomeDataComplete
} = storeToRefs(homeProtectStore)
const {openLink, currentLocale} = useHelpers()

const emit = defineEmits(["nextStep"])

const toast = useToast()
const {t} = useI18n()

const autoProlongation = ref<boolean>(false)

const apartmentAddress = computed(() => {
  return apartmentCount.value === 1 ? propertyData.value?.address : propertyData.value?.region
})

const handleExecutionFormStep = async () => {
  const response = await homeProtectStore.calculateCost()
  if (response) {
    emit("nextStep")
  } else {
    return
  }
}
</script>

<template>
  <div class="home-data">
    <div v-if="apartmentCount > 1" :class="propertiesListData ? 'home-data__choosing-property' : ''">
      <FFFieldset :title="$t('form.inputTitles.chooseAProperty')">
        <Dropdown
          v-model="choosedPropertyFromList"
          :options="propertiesListData.data"
          optionLabel="address"
          :panelStyle="{maxWidth: '266px', minWidth: '246px'}" />
      </FFFieldset>
    </div>
    <template v-if="isChoosedProperty && propertyData">
      <div class="home-data__location">
        <div>
          <FFPlainFieldset :title="$t('form.inputTitles.city')" :reason="apartmentAddress" titleBackground="#66b14e" />
        </div>
      </div>
      <div class="home-data__apartment">
        <div>
          <FFFieldset :title="$t('form.inputTitles.street')">
            <FFInput v-model="propertyData.street" type="text" :locked="true" />
          </FFFieldset>
        </div>
        <div>
          <FFFieldset :title="$t('form.inputTitles.homeAge')" :clear="false">
            <FFInput
              id="homeAge"
              v-model="propertyData.year"
              type="text"
              inputmode="numeric"
              name="homeAge"
              :locked="true" />
          </FFFieldset>
        </div>
      </div>
      <div class="home-data__apartment">
        <div>
          <FFFieldset :title="$t('form.inputTitles.homeNumber')">
            <FFInput id="home" v-model="propertyData.house_number" inputmode="numeric" type="text" :locked="true" />
          </FFFieldset>
        </div>
        <div>
          <FFFieldset :title="$t('form.inputTitles.apartmentNumber')">
            <FFInput
              v-maska
              data-maska="#####"
              id="apartmentNumber"
              v-model="propertyData.apartment_number"
              inputmode="numeric"
              type="text"
              :locked="true" />
          </FFFieldset>
        </div>
      </div>
      <div class="home-data__apartment">
        <div>
          <FFFieldset :title="$t('form.inputTitles.floor')">
            <FFInput id="floor" v-model="propertyData.floor" inputmode="numeric" type="text" :locked="true" />
          </FFFieldset>
        </div>
        <div>
          <FFFieldset :title="$t('form.inputTitles.overalFloor')">
            <FFInput
              id="overalFloor"
              v-model="propertyData.total_floors"
              inputmode="numeric"
              type="text"
              :locked="true" />
          </FFFieldset>
        </div>
      </div>
      <div class="home-data__apartment">
        <div>
          <FFFieldset :title="$t('form.inputTitles.numberOfRooms')">
            <FFInput
              id="apartmentNumber"
              v-model="propertyData.rooms"
              inputmode="numeric"
              type="text"
              :locked="true" />
          </FFFieldset>
        </div>
        <div>
          <FFFieldset :title="$t('form.inputTitles.areaSquareMeter')">
            <FFInput id="totalArea" v-model="propertyData.total_area" inputmode="numeric" type="text" :locked="true" />
          </FFFieldset>
        </div>
      </div>
      <div>
        <FFFieldset :title="$t('form.inputTitles.houseType')" :clear="false">
          <FFInput
            id="wallMaterial"
            v-model="propertyData.wall_material"
            name="homeType"
            type="text"
            inputmode="text"
            :locked="true" />
        </FFFieldset>
      </div>
    </template>
    <FFButton @click="handleExecutionFormStep" :title="$t('app.buttons.calculateCost')" :uppercase="false" />
  </div>
</template>

<style scoped lang="scss">
.home-data {
  margin-top: 30px;
  .home-data__choosing-property {
    margin-bottom: 20px;
  }
  .home-data__location {
    margin-bottom: 20px;
  }

  .home-data__apartment {
    display: flex;
    gap: 10px;

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }

  .ff-button-wrapper {
    margin-top: 30px;
  }

  .home-data__ask {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: #2e2e2e;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    margin: 0 auto;
    transition: all 0.3s ease;
    padding: 10px 0;
    margin-top: 30px;
    p {
      color: rgba(255, 255, 255, 0.5);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .home-data__ask:hover {
    scale: 1.1;
  }
}
</style>
