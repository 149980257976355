<script setup lang="ts">
import {useRouter} from "vue-router"
import {useHelpers} from "@/composables/useHelpers"
import {useAuthStore} from "@/stores/AuthStore"

const router = useRouter()
const authStore = useAuthStore()
const {openLink} = useHelpers()

const handleLogout = () => {
  authStore.logout()
  router.push("/pages/auth")
}
</script>

<template>
  <div class="container">
    <div class="cards">
      <div @click="router.push('ogpo')" class="cards__card">
        <div class="cards__card--texts">
          <p class="cards__card--texts__title">{{ $t("app.personalCabinetPage.buyPolisOnline") }}</p>
        </div>
        <img src="/user-profile-front/images/card-car.png" alt="card" />
      </div>
      <div @click="openLink('https://dtp.kz', '_self')" class="cards__card">
        <div class="cards__card--texts">
          <p class="cards__card--texts__title">{{ $t("app.personalCabinetPage.insuredEvent") }}</p>
          <p class="cards__card--texts__description">{{ $t("app.personalCabinetPage.onlineOffer") }}</p>
        </div>
        <img src="/user-profile-front/images/card-case.png" alt="card" />
      </div>
      <a href="tel:5777" class="cards__card">
        <div class="cards__card--texts">
          <p class="cards__card--texts__title">{{ $t("app.personalCabinetPage.callTo") }} 5777</p>
          <p class="cards__card--texts__description">{{ $t("app.personalCabinetPage.callCenter") }} 24/7</p>
        </div>
        <img src="/user-profile-front/images/card-call.png" alt="card" />
      </a>
      <div @click="router.push('user-profile')" class="cards__card">
        <div class="cards__card--texts">
          <p class="cards__card--texts__title">{{ $t("app.personalCabinetPage.profile") }}</p>
        </div>
        <img src="/user-profile-front/images/card-profile.png" alt="card" />
      </div>
      <div @click="handleLogout" class="cards__card">
        <div class="cards__card--texts">
          <p class="cards__card--texts__title">{{ $t("app.personalCabinetPage.logout") }}</p>
        </div>
        <img src="/user-profile-front/images/card-exit.png" alt="card" />
      </div>
    </div>
    <div class="mobile-cards">
      <div @click="router.push('ogpo')" class="mobile-cards__card">
        <img src="/user-profile-front/images/card-car-mobile.png" alt="icon" />
        <p>{{ $t("app.personalCabinetPage.buyPolis") }}</p>
      </div>
      <div @click="openLink('https://dtp.kz', '_self')" class="mobile-cards__card">
        <img src="/user-profile-front/images/card-case-mobile.png" alt="icon" />
        <p>{{ $t("app.personalCabinetPage.insuredEvent") }}</p>
      </div>
      <a href="tel:5777" class="mobile-cards__card">
        <img src="/user-profile-front/images/card-call-mobile.png" alt="icon" />
        <p>{{ $t("app.personalCabinetPage.callTo") }} 5777</p>
      </a>
      <div @click="router.push('user-profile')" class="mobile-cards__card">
        <img src="/user-profile-front/images/card-profile-mobile.png" alt="icon" />
        <p>{{ $t("app.personalCabinetPage.profile") }}</p>
      </div>
      <div @click="handleLogout" class="mobile-cards__card">
        <img src="/user-profile-front/images/card-exit-mobile.png" alt="icon" />
        <p>{{ $t("app.personalCabinetPage.logout") }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.cards {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding-top: 50px;
  &__card {
    position: relative;
    min-width: 214px;
    min-height: 118px;
    cursor: pointer;
    transition: all 0.1s ease;
    border-radius: 10px;

    &--texts {
      position: absolute;
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      padding-left: 20px;
      max-width: 138px;
      &__title {
        color: var(--White, #fff);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      &__description {
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        opacity: 0.6;
        max-width: 100px;
      }
    }
  }
  &__card:hover {
    box-shadow: 0 0 5px #fff;
  }
}
.mobile-cards {
  display: none;
  padding-top: 50px;
  gap: 16px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &__card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 70px;
    p {
      color: #fff;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}

@media (max-width: $xxl) {
  .cards {
    overflow-x: scroll;
  }
}

@media (max-width: $lg) {
  .cards {
    display: none;
  }
  .mobile-cards {
    display: flex;
    margin-right: -20px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 400px) {
  .mobile-cards {
    margin-right: unset;
  }
}
</style>
