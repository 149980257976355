<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_26030_44066)">
      <g filter="url(#filter0_d_26030_44066)">
        <path d="M10.0586 19.5C10.0586 19.5 1.84766 17.062 1.84766 5.09588V0.5H18.2975V5.09588C18.2695 16.6416 10.0586 19.5 10.0586 19.5Z" fill="#51AF3D"/>
        <path d="M14.7972 5.12393H8.18359V8.93514H14.7972V10.0841C14.7972 12.0177 14.2647 13.559 11.3222 13.5871H8.18359V18.6033C9.27651 19.2758 10.0612 19.5 10.0612 19.5C10.0612 19.5 18.2721 16.6416 18.2721 5.09591V1.649C18.2721 3.55461 17.4314 5.0959 14.7972 5.12393Z" fill="#0F5431"/>
      </g>
    </g>
    <defs>
      <filter id="filter0_d_26030_44066" x="-20.1523" y="-11.5" width="100.449" height="103" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="20" dy="30"/>
        <feGaussianBlur stdDeviation="21"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.34 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26030_44066"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26030_44066" result="shape"/>
      </filter>
      <clipPath id="clip0_26030_44066">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped>
svg {
  flex-shrink: 0;
}
</style>