import {computed, ref, watch} from "vue"
import {defineStore, storeToRefs} from "pinia"

import AddIdentificationCardForm from "@/components/AddDriverAuto/Forms/AddIdentificationCardForm.vue"
import AddKdp from "@/components/AddDriverAuto/AddKdp.vue"
import AddNeedBiometriModal from "@/components/AddDriverAuto/AddNeedBiometriModal.vue"
import FFOtp from "@/components/FFUI/FFOtp.vue"
import type {ICalculationResult} from "@/interfaces/AddDriverAuto/ICalculationResult"
import type {IPolicyDataResponse} from "@/interfaces/AddDriverAuto/IPolicyDataResponse"
import KaspiPaymentForm from "@/components/AddDriverAuto/Forms/KaspiPaymentForm.vue"
import _ from "lodash-es"
import {debounce} from "lodash-es"
import {identityCard} from "@/constants/documents"
import type {optionInterface} from "@/components/FFUI/FFSelect.vue"
import {useAuthStore} from "@/stores/AuthStore"
import {useFormatters} from "@/utils/formatters"
import {useGatewayFetcher} from "@/composables/useGatewayFetcher"
import {useHelpers} from "@/composables/useHelpers"
import {useI18n} from "vue-i18n"
import {useModalStore} from "@/stores/ModalStore"
import {usePaymentStore} from "@/stores/Payment"
import {usePdfOpener} from "@/composables/usePdfOpener"
import {usePersonalProfileStore} from "@/stores/PersonalProfileStore"
import {useRouter} from "vue-router"
import {useToast} from "vue-toastification"

export const useAddDriverAutoStore = defineStore("addDriverAutoStore", () => {
  const authStore = useAuthStore()
  const modalStore = useModalStore()
  const paymentStore = usePaymentStore()
  const personalProfileStore = usePersonalProfileStore()
  const {accessToken} = storeToRefs(authStore)
  const {selectedPayment} = storeToRefs(paymentStore)

  const {currentLocale, openLink} = useHelpers()
  const {dateFormatter, phoneNumberFormatter} = useFormatters()
  const {postData, loading} = useGatewayFetcher()
  const {openKdpAgreementPdf, openQuestionnairePdf} = usePdfOpener(postData)
  const toast = useToast()
  const router = useRouter()
  const {t} = useI18n()

  const identificationCard = ref<File | null>(null)
  const withoutRefund = ref<boolean>(false)
  const terminationAgreementCheckbox = ref<boolean>(false)

  const currentStep = ref<number>(1)
  const isBadScoring = ref<boolean>(false)
  const needKdp = ref<boolean>(true)

  const date = ref(new Date(new Date().setDate(new Date().getDate() + 1)))

  const month = ref<optionInterface>({name: computed(() => t("app.ogpoPage.forms.month-12")), value: 12})
  const monthes = ref([
    {name: computed(() => t("app.ogpoPage.forms.month-12")), value: 12},
    {name: computed(() => t("app.ogpoPage.forms.month-11")), value: 11},
    {name: computed(() => t("app.ogpoPage.forms.month-10")), value: 10},
    {name: computed(() => t("app.ogpoPage.forms.month-9")), value: 9},
    {name: computed(() => t("app.ogpoPage.forms.month-8")), value: 8},
    {name: computed(() => t("app.ogpoPage.forms.month-7")), value: 7},
    {name: computed(() => t("app.ogpoPage.forms.month-6")), value: 6}
  ])

  const isSeasonalOperationVehicle = ref<boolean>(true)

  const showSeasonalOperationVehicle = computed(() => {
    return month.value.value !== 12
  })

  const dateAndTimeApplying = ref()

  const insurant = ref()
  const insureds = ref()

  const iin = ref<string>("")
  const isIinLoadingState = ref<boolean>(false)
  const userByIIN = ref()
  const iinPassed = ref<boolean>(false)
  const pushedPersons = ref<
    Array<{
      iin: string
      fullName: string
      class: string
      isMain: boolean
      isDriver: boolean
      isPrivilege: boolean
      isNew: boolean
    }>
  >([])

  const addDriverAutoByGlobalId = ref<IPolicyDataResponse | null>()
  const initialPersonsState = ref([])

  const isProductCodeOgpo = computed(() => {
    return (
      addDriverAutoByGlobalId?.value?.product_code === "OS_GPO_VTS" ||
      addDriverAutoByGlobalId?.value?.product_code === "OGPOPLUS"
    )
  })

  let initialIsDriverVal
  const setInitialPersonsState = () => {
    if (!initialPersonsState.value.length) {
      const policyHolder = addDriverAutoByGlobalId.value?.insureds.find(
        (val) => val.iin === addDriverAutoByGlobalId.value?.holder_iin
      )

      const others =
        addDriverAutoByGlobalId.value?.insureds.filter(
          (val) => val.iin !== addDriverAutoByGlobalId.value?.holder_iin
        ) || []

      if (policyHolder) {
        initialIsDriverVal = policyHolder.isDriver
        initialPersonsState.value = _.cloneDeep([policyHolder, ...others])
      } else {
        initialIsDriverVal = false
        initialPersonsState.value = _.cloneDeep([...others])
      }
    }
  }

  const policyHolder = computed(() => {
    return Boolean(
      addDriverAutoByGlobalId.value?.insureds.find((val) => val.iin === addDriverAutoByGlobalId.value?.holder_iin)
    )
  })

  const getAddDriverAutoDataByGlobalId = async (globalId: string, productCode: string) => {
    try {
      const response = await postData(
        "/auth/policy",
        {
          globalId,
          productCode
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken.value}`,
            "Accept-Language": currentLocale.value
          }
        }
      )
      if (response?.status === 200) {
        addDriverAutoByGlobalId.value = response?.data?.data
        insurant.value = response?.data?.data?.insureds[0]
        setInitialPersonsState()
        setInitialVehiclesState()

        const selectedMonth = monthes.value.find((m) => m.value === addDriverAutoByGlobalId?.value?.period)
        if (selectedMonth) {
          month.value = selectedMonth
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const policyHolderFromOuterObject = ref()

  const persons = computed(() => {
    const policyHolder = addDriverAutoByGlobalId.value?.insureds.find(
      (val) => val.iin === addDriverAutoByGlobalId.value?.holder_iin
    )

    const others =
      addDriverAutoByGlobalId.value?.insureds.filter((val) => val.iin !== addDriverAutoByGlobalId.value?.holder_iin) ||
      []

    const localPolicyHolder = {
      class: "",
      fullName: addDriverAutoByGlobalId.value?.holder_fio,
      iin: addDriverAutoByGlobalId.value?.holder_iin,
      isDriver: currentValue.value,
      isMain: true,
      isPrivilege: false
    }

    if (policyHolder && policyHolder.isDriver) {
      return [policyHolder, ...others, ...pushedPersons.value]
    }
    if (!policyHolder && localPolicyHolder.isDriver) {
      return [localPolicyHolder, ...others, ...pushedPersons.value]
    }
    return [...others, ...pushedPersons.value]
  })

  const personsForCalculation = computed(() => {
    const policyHolder = addDriverAutoByGlobalId.value?.insureds.find(
      (val) => val.iin === addDriverAutoByGlobalId.value?.holder_iin
    )

    const others =
      addDriverAutoByGlobalId.value?.insureds.filter((val) => val.iin !== addDriverAutoByGlobalId.value?.holder_iin) ||
      []

    policyHolderFromOuterObject.value = {
      class: "",
      fullName: addDriverAutoByGlobalId.value?.holder_fio,
      iin: addDriverAutoByGlobalId.value?.holder_iin,
      isDriver: currentValue.value,
      isMain: true,
      isPrivilege: false
    }

    if (policyHolder) {
      return [policyHolder, ...others, ...pushedPersons.value]
    } else {
      return [policyHolderFromOuterObject.value, ...others, ...pushedPersons.value]
    }
  })

  const addDriverCheckboxDisabled = computed(() => {
    return persons.value.length === 1 && vehicles.value.length > 1
  })

  const currentValue = ref(false)

  watch(
    () => personsForCalculation.value[0].isDriver,
    (newVal) => {
      currentValue.value = newVal
    },
    {deep: true}
  )

  const isDriverUnchanged = computed(() => {
    return initialIsDriverVal === currentValue.value
  })

  watch(iin, (newVal) => {
    if (newVal.length < 12) {
      iinPassed.value = false
    } else {
      iinPassed.value = true
    }
  })

  const isIINExistsInInitialState = ref<boolean>(false)
  const iinCheck = async (data: string) => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }

    if (addDriverAutoByGlobalId?.value?.holder_iin === data) {
      toast.error(t("app.toasterMessages.iinNumCheck.iinPolicyholderCantDuplicateWithInsured"))
      iin.value = ""
      return
    }

    const isDuplicateInArray = persons.value.some((item) => item.iin === data)
    if (isDuplicateInArray) {
      toast.error(t("app.toasterMessages.iinNumCheck.currentIINisInList"))
      iin.value = ""
      return
    }

    try {
      if (data.length === 12) {
        isIinLoadingState.value = true
        iinPassed.value = false
        const response = await postData(
          "/object/client/check/isnew",
          {
            iin: data,
            clientType: "Individual",
            globalId: addDriverAutoByGlobalId.value?.global_id
          },
          config
        )
        isIinLoadingState.value = true
        if (response && response.data && response.status === 200) {
          userByIIN.value = response?.data?.data
          iinPassed.value = true
          isIinLoadingState.value = false
          isIINExistsInInitialState.value = response?.data?.data?.isNew
          return true
        } else {
          return false
        }
      }
    } catch (error) {
      iin.value = ""
      iinPassed.value = false
      isIinLoadingState.value = false
      isIINExistsInInitialState.value = false
      console.error(error)
    }
  }

  const autoNumber = ref<string>("")
  const autoName = ref<string>("")

  const pushedVehicles = ref<Array<{regNum: string; carFullName?: string; isNew: boolean}>>([])

  const initialVehiclesState = ref([])

  const setInitialVehiclesState = () => {
    initialVehiclesState.value = _.cloneDeep(addDriverAutoByGlobalId?.value?.vehicles)
  }

  const vehicles = computed(() => {
    const allVehicles = [...(addDriverAutoByGlobalId?.value?.vehicles || []), ...pushedVehicles.value]
    const seenRegNums = new Set()
    const uniqueVehicles = allVehicles.filter((vehicle) => {
      const isDuplicate = seenRegNums.has(vehicle.regNum)
      if (!isDuplicate) {
        seenRegNums.add(vehicle.regNum)
        seenRegNums.add(vehicle.dtRegCert)
      }
      return !isDuplicate
    })
    return uniqueVehicles
  })

  const isAutonumtechpassFilled = computed(() => {
    return autoNumber.value.length >= 3
  })

  const autonumtechpasCheck = async (regNum: string) => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }

    try {
      const response = await postData(
        "/object/car/check-dtRegCert",
        {
          regNum: regNum.toUpperCase(),
          globalId: addDriverAutoByGlobalId.value?.global_id
        },
        config
      )
      if (response && response.data && response.status === 200) {
        autoNumber.value = ""
        autoName.value = response?.data?.data?.car
        return {
          status: true,
          isNew: response?.data?.data?.isNew
        }
      } else {
        autoNumber.value = ""
        autoName.value = ""
        return {
          status: false,
          isNew: false
        }
      }
    } catch (error) {
      autoNumber.value = ""
      autoName.value = ""
      console.error(error)
      return {
        status: false,
        isNew: false
      }
    }
  }

  //disabling calculate button
  watch([date, month, persons, vehicles, currentValue], () => {
    resetCalcStates()
  })

  const dateMonthChanged = ref(false)
  const initialDate = date.value
  const initialMonth = month.value

  // watch(
  //   [date, month],
  //   ([newDate, newMonth]) => {
  //     if (dateFormatter(newDate) !== dateFormatter(initialDate) || newMonth.value !== initialMonth.value) {
  //       dateMonthChanged.value = true
  //     } else {
  //       dateMonthChanged.value = false
  //     }
  //   },
  //   {immediate: false}
  // )

  const isPersonsLengthEqualToVehicles = computed(() => {
    const hasNewVehicle = pushedVehicles.value.some((pushedVehicle) => pushedVehicle.isNew)
    const hasNewPerson = pushedPersons.value.some((pushedPerson) => pushedPerson.isNew)
    const hasOnlyOldVehicles = !pushedVehicles.value.some((pushedVehicle) => pushedVehicle.isNew)
    const hasOnlyOldPersons = !pushedPersons.value.some((pushedPerson) => pushedPerson.isNew)

    if (vehicles.value.length === 0 || persons.value.length === 0) {
      return true
    }

    if (initialIsDriverVal !== currentValue.value) {
      return false
    }

    // if (dateMonthChanged.value) {
    //   return false
    // }

    const deletedPerson = initialPersonsState.value.some((initialPerson) => {
      return !persons.value?.some((person) => person.iin === initialPerson.iin)
    })

    const deletedVehicle = initialVehiclesState.value.some((initialVehicle) => {
      return !vehicles.value?.some((vehicle) => vehicle.regNum === initialVehicle.regNum)
    })

    if (deletedPerson) {
      return false
    }

    if (deletedVehicle && vehicles.value.length !== 0) {
      return false
    }

    if (hasNewPerson && vehicles.value.length !== 0) {
      return false
    }

    if (hasNewVehicle) {
      return false
    }

    if (vehicles.value.length === 0 || (hasNewPerson && vehicles.value.length === 0)) {
      return true
    }

    return true
  })

  const currentUser = JSON.parse(localStorage.getItem("current-user")).data
  const isDriverAutoSumCalculated = ref<boolean>(false)
  const calculationResult = ref<ICalculationResult | null>(null)
  const iAgreeState = ref<boolean>(false)

  const calculateDriverAutoSum = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken.value}`,
          "Accept-Language": currentLocale.value
        }
      }
      const tomorowsDate = new Date(new Date().setDate(new Date().getDate() + 1))
      const otherClients = personsForCalculation.value.map((person) => ({
        iinBin: person.iin,
        isMain: person.isMain,
        isDriver: person.isDriver,
        isPrivilege: person.isPrivilege
      }))

      const payload = {
        currentContractGlobalId: addDriverAutoByGlobalId?.value?.global_id,
        phone: currentUser?.phone,
        cars: vehicles.value.map((vehicle) => ({
          regNum: vehicle.regNum
        })),
        clients: otherClients,
        fromDate: dateFormatter(date.value),
        period: month.value?.value
      }

      const response = await postData("/auth/policy/renewal/calculate", payload, config)

      if (response?.status === 200) {
        isDriverAutoSumCalculated.value = true
        calculationResult.value = response?.data?.data
        isBadScoring.value = calculationResult.value?.isScoring || false

        return true
      } else {
        isDriverAutoSumCalculated.value = false
        calculationResult.value = null
        return false
      }
    } catch (error) {
      if (error.response.status === 400 && error.response.data.errors?.type === identityCard) {
        const firstClient = {iin: error.response.data.errors?.iin, clientType: "individual"}
        const clients = [firstClient]
        const response = await postData("/portal/get-client-data", {clients})
        identificationCardNotFoundResponse.value = error.response.data.errors
        identificationCardNotFound.value = true

        if (response && response.status === 200) {
          identificationCardNotVerifiedUsers.value = [
            {iin: error.response.data.errors?.iin, fio: response.data.data[0].fullName}
          ]
          if (identificationCardNotFound.value) {
            modalStore.openModal({
              component: AddIdentificationCardForm
            })
          }
        }
        return
      }
      console.error(error)
    }
  }

  //Identification card form
  const identificationCardNotFound = ref<boolean>(false)
  const identificationCardNotFoundResponse = ref()
  const identificationCardNotVerifiedUsers = ref<{iin: string; fio: string}[]>([])

  const identificationCardObj = ref({
    iin: "",
    documentGivedDate: null,
    documentNumber: ""
  })

  const resetIdentificationCardObj = () => {
    identificationCardObj.value = {
      iin: "",
      documentGivedDate: null,
      documentNumber: ""
    }
  }

  const setIdentificationCardNewClientData = async (index: number) => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }
      const payload = {
        documentType: "IdentityCard",
        iin: identificationCardNotVerifiedUsers.value[index].iin
      }
      const response = await postData("/object/client/set", payload, config)
      if (response && response.status === 200) {
        modalStore.closeModal()
        clearAllIntervals()
        return true
      } else {
        return
      }
    } catch (error) {
      console.error(error)
    }
  }

  const sendIdentificationCard = async () => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }
      const response = await postData(
        "/object/client/set",
        {
          iin: identificationCardNotFoundResponse.value.iin,
          documentGivedDate: dateFormatter(identificationCardObj?.value?.documentGivedDate),
          documentNumber: driverLicenseObj.value.documentNumber,
          documentType: identificationCardNotFoundResponse.value.type
        },
        config
      )
      if (response?.status === 200) {
        identificationCardNotFound.value = false
      } else {
        return
      }
    } catch (error) {
      console.error(error)
      return
    }
  }

  //Before KDP premium calculation
  const handleKdpFormOpen = async () => {
    modalStore.openModal({
      component: AddKdp
    })
  }

  const handleAddDriverAutoPayment = async () => {
    if (!isDriverAutoSumCalculated.value) {
      await calculateDriverAutoSum()
    }

    if (isDriverAutoSumCalculated.value && needKdp.value) {
      await fetchNotVerifiedIins()
      if (!allUsersAreVerificated.value) {
        await handleKdpFormOpen()
      } else if (iAgreeState.value) {
        handleOtpFormOpen()
      } else {
        return
      }
    }
    if (isDriverAutoSumCalculated.value && !needKdp.value) {
      if (iAgreeState.value) {
        handleOtpFormOpen()
      } else {
        return
      }
    }
  }

  //KDP
  const verificationUsersData = ref<{iin: string; verifyClient: boolean}[]>([])
  const agreementLoaderHide = ref<boolean>(false)

  const fetchNotVerifiedIins = async () => {
    try {
      const clients = persons?.value?.map((state) => ({iin: state.iin, clientType: "individual"}))
      const response = await postData("/portal/get-client-data", {clients})
      if (response?.status === 200) {
        verificationUsersData.value = response.data.data
      }
    } catch (error) {
      console.error(error)
    }
  }

  const notVerificatedUsers = computed(() => {
    if (calculationResult.value?.isScoring) {
      return verificationUsersData.value
    }
    const policyHolder = verificationUsersData.value.find(
      (user) => user.iin === addDriverAutoByGlobalId.value?.holder_iin
    )

    if (policyHolder && !policyHolder.verifyClient) {
      return [
        {
          fullName: addDriverAutoByGlobalId.value?.holder_fio,
          iin: addDriverAutoByGlobalId.value?.holder_iin,
          verifyClient: policyHolder.verifyClient
        }
      ]
    }

    return []
  })

  const allUsersAreVerificated = computed(() => {
    return notVerificatedUsers.value.every((verficated) => verficated.verifyClient)
  })

  // watch(allUsersAreVerificated, (newVal) => {
  //   if (newVal) {
  //     setTimeout(() => {
  //       modalStore.closeModal()
  //     }, 2000)
  //   }
  // })

  const intervalIds = ref<Record<number, number | undefined>>({})
  const clearAllIntervals = () => {
    Object.keys(intervalIds.value).forEach((index) => {
      clearInterval(intervalIds.value[Number(index)])
      delete intervalIds.value[Number(index)]
    })
  }

  const sendCode = async (index: number) => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }
      const iin = notVerificatedUsers.value[index].iin
      const response = await postData("/portal/access-control", {iin}, config)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const sendIdentificationCardCode = async (index: number) => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }
      const iin = identificationCardNotVerifiedUsers.value[index].iin
      const response = await postData("/portal/access-control", {iin}, config)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const openAddDriverKdpAgreementPdf = async (fullName: string, iin: string) => {
    openKdpAgreementPdf(fullName, iin)
  }

  const openAddDriverQuestionnairePdf = async (calcId: string) => {
    openQuestionnairePdf("/policy/ogpo/get-form", {calcId})
  }

  //OTP
  const otpCode = ref<string | number>()
  const showOtp = ref<boolean>(false)
  const codeIsSent = ref<boolean>(false)
  const sentOtpResponse = ref<{message: string; status: boolean}>()
  const timer = ref(0)
  const localStorageCurrentUser = JSON.parse(localStorage.getItem("current-user"))?.data || null
  const localStorageAccessToken = localStorage.getItem("access_token")

  const handleOtpFormOpen = async () => {
    const otpProps = {
      codeIsSent: codeIsSent,
      buttonTitle: buttonTitle,

      sendOtp: handleSendOtp,
      checkOtp: checkOtpCode,
      closeOtp: closeOtpForm
    }
    modalStore.openModal({
      component: FFOtp,
      props: otpProps
    })
  }

  const buttonTitle = computed(() => {
    if (checkOtpResponse.value?.status) {
      return t("app.buttons.sendCode")
    } else if (timer.value > 0) {
      const minutes = Math.floor(timer.value / 60)
      const seconds = timer.value % 60
      return `${t("app.buttons.sendCodeAgainThrough.text-1")} ${minutes}:${seconds.toString().padStart(2, "0")}`
    }
    return t("app.buttons.sendCode")
  })

  const startTimer = () => {
    timer.value = 90
    const interval = setInterval(() => {
      timer.value--
      if (timer.value <= 0) {
        clearInterval(interval)
        codeIsSent.value = false
      }
    }, 1000)
  }

  const handleSendOtp = async () => {
    try {
      const response = await postData(
        "/auth/send-otp",
        {phone: localStorageCurrentUser.phone, type: "security_purposes_renewal"},
        {
          headers: {
            Authorization: `Bearer ${localStorageAccessToken}`
          }
        }
      )
      if (response?.status === 200) {
        startTimer()
        codeIsSent.value = true
        showOtp.value = true
        sentOtpResponse.value = response?.data?.data
      } else {
        otpCode.value = ""
        codeIsSent.value = false
        showOtp.value = false
      }
    } catch (error) {
      console.error(error)
    }
  }

  const checkOtpResponse = ref()
  const checkOtpCode = async (code: string | number) => {
    try {
      otpCode.value = code
      const response = await postData(
        "/auth/check-otp",
        {
          phone: localStorageCurrentUser.phone,
          otpCode: otpCode.value,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorageAccessToken}`
          }
        }
      )
      if (response?.status === 200 && response?.data) {
        showOtp.value = false
        codeIsSent.value = false
        checkOtpResponse.value = response?.data?.data
        if (checkOtpResponse.value.status && !isAddInsSumGreterThan2Mrp.value) {
          closeOtpFormWithoutIAgreeReset()
          await handleFinalPayment()
          return true
        } else if (checkOtpResponse.value.status && isAddInsSumGreterThan2Mrp.value) {
          closeOtpFormWithoutIAgreeReset()
          modalStore.openModal({
            component: AddNeedBiometriModal
          })
        } else {
          return false
        }
      } else {
        return false
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  //Payment
  const paymentUrl = ref<string>("")
  const kaspiPayPhoneNumber = ref<string>("")

  const handleKaspiFormOpen = async () => {
    modalStore.openModal({
      component: KaspiPaymentForm
    })
  }

  const isAddInsSumGreterThan2Mrp = computed(() => {
    return calculationResult.value?.refundSum && calculationResult.value?.refundSum > 500
  })

  const handleGetBiometriLink = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken.value}`,
          "Accept-Language": currentLocale.value
        }
      }
      const payload = {
        globalId: addDriverAutoByGlobalId.value?.global_id,
        calc_id: calculationResult?.value?.calcId,
        phone: currentUser.phone,
        iin: currentUser.iin,
        clientType: "individual",
        email: currentUser.email,
        payment_type: "FreedomPay",
        kaspiPayNumber: "",
        insureds: personsForCalculation.value?.map((person) => {
          return {
            iinBin: person.iin
          }
        })
      }
      const response = await postData("/auth/policy/renewal/biometrics", payload, config)
      if (response && response.status === 200 && response?.data?.aitu_link) {
        const aituLink = response?.data?.aitu_link
        window.open(aituLink, "_self")
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleFinalPayment = async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      if (
        calculationResult.value?.refundSum === 0 &&
        calculationResult.value.surchargeSum === 0 &&
        !isBadScoring.value
      ) {
        const response = await postData(
          "/auth/policy/renewal",
          {
            calcId: calculationResult.value.calcId,
            globalId: addDriverAutoByGlobalId.value?.global_id,
            email: currentUser.email,
            insureds: personsForCalculation.value?.map((person) => {
              return {
                iinBin: person.iin
              }
            })
          },
          config
        )
        if (response && response.status === 200) {
          router.push({name: "SuccessPolicyReissue"})
        }
      } else if (
        calculationResult.value?.refundSum !== 0 &&
        calculationResult.value.surchargeSum === 0 &&
        !isBadScoring.value
      ) {
        const response = await postData(
          "/auth/policy/renewal/refund",
          {
            calcId: calculationResult.value.calcId,
            globalId: addDriverAutoByGlobalId.value?.global_id,
            email: currentUser.email,
            insureds: personsForCalculation.value?.map((person) => {
              return {
                iinBin: person.iin
              }
            })
          },
          config
        )
        if (response && response.status === 200 && response.data?.payment_url) {
          localStorage.removeItem("paymentType")

          paymentUrl.value = response.data?.payment_url
          window.location.href = paymentUrl.value
        }
      } else {
        if (currentStep.value !== 2 && !isBadScoring.value) {
          currentStep.value++
        }
        if (currentStep.value !== 2 && isBadScoring.value) {
          currentStep.value = 1
        }
        const finalPayObject = {
          calc_id: calculationResult.value.calcId,
          premium: !isBadScoring.value ? calculationResult.value?.surchargeSum : calculationResult.value?.premium,
          phone: phoneNumberFormatter(currentUser.phone),
          iin: currentUser.iin,
          clientType: "individual",
          email: currentUser.email,
          payment_type: !isBadScoring.value ? selectedPayment.value : "FreedomPay",
          kaspiPayNumber: kaspiPayPhoneNumber ? phoneNumberFormatter(kaspiPayPhoneNumber.value) : "",
          insureds: personsForCalculation.value?.map((person) => {
            return {
              iinBin: person.iin
            }
          })
        }
        if (currentStep.value === 2 && selectedPayment.value === "FreedomPay" && !isBadScoring.value) {
          const response = await postData("/policy/ogpo/renewal/order/create", finalPayObject, config)

          localStorage.removeItem("paymentType")

          paymentUrl.value = response.data?.payment_url
          window.location.href = paymentUrl.value
        } else if (currentStep.value === 2 && selectedPayment.value === "KaspiPay" && !isBadScoring.value) {
          modalStore.openModal({
            component: KaspiPaymentForm
          })
        } else if (currentStep.value === 1 && !selectedPayment.value && isBadScoring.value) {
          const response = await postData("/policy/ogpo/renewal/order/create", finalPayObject, config)
          localStorage.removeItem("paymentType")
          paymentUrl.value = response.data?.payment_url
          window.location.href = paymentUrl.value
        }
      }
    } catch (error) {
      if(error.response.status === 400 && !error.response.data.errors?.is_valid) {
        resetCalcStates()
        closeOtpFormWithoutIAgreeReset()
        closeOtpForm()
      }
      console.error(error)
    }
  }

  const handleKaspiPayment = async () => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }
      const finalPayObject = {
        calc_id: calculationResult.value.calcId,
        premium: !isBadScoring.value ? calculationResult.value?.surchargeSum : calculationResult.value?.premium,
        phone: phoneNumberFormatter(currentUser.phone),
        iin: currentUser.iin,
        clientType: "individual",
        email: currentUser.email,
        payment_type: "KaspiPay",
        kaspiPayNumber: phoneNumberFormatter(kaspiPayPhoneNumber.value),
        insureds: personsForCalculation.value?.map((person) => {
          return {
            iinBin: person.iin
          }
        })
      }
      const response = await postData("/policy/ogpo/renewal/order/create", finalPayObject, config)
      if (response && response.status === 200) {
        localStorage.setItem("paymentType", "kaspi")
        modalStore.closeModal()
        router.push({name: "SuccessPolicyReissue"})
      } else {
        return
      }
    } catch (error) {
      if(error.response.status === 400 && !error.response.data.errors?.is_valid) {
        resetCalcStates()
        closeOtpFormWithoutIAgreeReset()
        closeOtpForm()
      }
      console.error(error)
    }
  }

  //Reset
  const closeOtpFormWithoutIAgreeReset = () => {
    showOtp.value = false
    codeIsSent.value = false
    timer.value = 0
    modalStore.closeModal()
  }

  const closeOtpForm = () => {
    showOtp.value = false
    codeIsSent.value = false
    iAgreeState.value = false
    timer.value = 0
    modalStore.closeModal()
  }

  const resetCalcStates = () => {
    currentStep.value = 1
    paymentStore.selectedPayment = ""
    isDriverAutoSumCalculated.value = false
    calculationResult.value = null
    iAgreeState.value = false
    identificationCardNotFound.value = false
  }

  return {
    identificationCard,
    withoutRefund,
    terminationAgreementCheckbox,
    currentStep,
    isBadScoring,
    needKdp,
    loading,
    date,
    month,
    monthes,
    isSeasonalOperationVehicle,
    showSeasonalOperationVehicle,
    dateAndTimeApplying,
    insurant,
    insureds,
    autoNumber,
    autoName,
    pushedVehicles,
    vehicles,
    isAutonumtechpassFilled,
    addDriverAutoByGlobalId,
    isProductCodeOgpo,
    iin,
    isIinLoadingState,
    userByIIN,
    iinPassed,
    isIINExistsInInitialState,
    pushedPersons,
    persons,
    isPersonsLengthEqualToVehicles,
    isDriverAutoSumCalculated,
    calculationResult,
    iAgreeState,
    verificationUsersData,
    notVerificatedUsers,
    allUsersAreVerificated,
    intervalIds,
    agreementLoaderHide,
    paymentUrl,
    kaspiPayPhoneNumber,
    initialPersonsState,
    initialVehiclesState,
    otpCode,
    showOtp,
    codeIsSent,
    checkOtpResponse,
    buttonTitle,
    policyHolder,
    addDriverCheckboxDisabled,
    personsForCalculation,
    isDriverUnchanged,
    identificationCardNotFound,
    identificationCardNotFoundResponse,
    identificationCardNotVerifiedUsers,
    identificationCardObj,

    autonumtechpasCheck,
    getAddDriverAutoDataByGlobalId,
    iinCheck,
    calculateDriverAutoSum,
    handleKdpFormOpen,
    handleAddDriverAutoPayment,
    fetchNotVerifiedIins,
    clearAllIntervals,
    sendCode,
    handleKaspiFormOpen,
    handleFinalPayment,
    handleKaspiPayment,
    resetCalcStates,
    handleSendOtp,
    checkOtpCode,
    openAddDriverKdpAgreementPdf,
    openAddDriverQuestionnairePdf,
    closeOtpForm,
    handleGetBiometriLink,
    sendIdentificationCardCode,
    resetIdentificationCardObj,
    setIdentificationCardNewClientData,
    sendIdentificationCard
  }
})
