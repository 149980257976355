<script setup lang="ts">
import {computed} from "vue"
import SliderArrow from "@/components/icons/SliderArrow.vue"

interface IProps {
  numberRange: number
  currentPage: number
  maxPage: number
}

const props = withDefaults(defineProps<IProps>(), {
  numberRange: 5
})

const emit = defineEmits(["changeCurrentPage"])

const setCurrentPage = (number: number) => {
  if (number >= 1 && number <= props.maxPage) {
    emit("changeCurrentPage", number)
  }
}

const startPage = computed(() => Math.floor((props.currentPage - 1) / props.numberRange) * props.numberRange + 1)
const pageNumbers = computed(() => {
  const range = []
  for (let i = 0; i < props.numberRange && startPage.value + i <= props.maxPage; i++) {
    range.push(startPage.value + i)
  }
  if (range[range.length - 1] < props.maxPage) {
    range.push("...", props.maxPage);
  }
  return range
})

const increasePageRange = () => {
  const nextPage = props.currentPage + 1
  if (nextPage <= props.maxPage) {
    emit("changeCurrentPage", nextPage)
  }
}

const decreasePageRange = () => {
  if (props.currentPage > 1) {
    const prevPage = props.currentPage - 1
    emit("changeCurrentPage", prevPage)
  }
}
</script>

<template>
  <div class="paginator">
    <button @click="decreasePageRange" class="paginator__arrow">
      <img src="/icons-front/white-arrowhead-icon.svg" alt="" />
    </button>
    <ul class="paginator__number-list">
      <li
        v-for="number in pageNumbers"
        :key="number"
        @click="number !== '...' ? setCurrentPage(number) : null"
        class="paginator__number-list-item"
        :class="{'paginator__number-list-item--active': currentPage === number}">
        {{ number }}
      </li>
    </ul>
    <button @click="increasePageRange" class="paginator__arrow">
      <img src="/icons-front/white-arrowhead-icon.svg" alt="" />
    </button>
  </div>
</template>

<style scoped lang="scss">
.paginator {
  display: flex;
  align-items: center;
  gap: 20px;
  .paginator__number-list {
    display: flex;
    gap: 5px;
    height: 100%;
    .paginator__number-list-item {
      padding: 5px 11px;
      background: transparent;
      color: #ffffff;
      border-radius: 4px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      color: var(--color-black);
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      max-width: fit-content;
      height: 32px;

      &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
          linear-gradient(132deg, #8dd979 -0.7%, #2a5704 103.63%);
        color: var(--color-white);
      }
      &--active {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
          linear-gradient(132deg, #8dd979 -0.7%, #2a5704 103.63%);
        color: var(--color-white);
      }
    }
  }
  .paginator__arrow {
    img {
      width: 30px;
      height: 30px;
      filter: hue-rotate(0deg) brightness(0) saturate(1000%) sepia(100%) hue-rotate(-50deg) saturate(300%);
    }
  }
  .paginator__arrow:last-of-type {
    transform: rotate(180deg);
  }
}
</style>
