<script setup lang="ts">
import {useModalStore} from "@/stores/ModalStore"
import KaskoForms from "@/components/Kasko/KaskoForms.vue"
import FFButton from "@/components/FFUI/FFButton.vue"

interface IProps {
  title: string
  risks: string[]
}
defineProps<IProps>()

const modalStore = useModalStore()

const handleCloseRiskModal = () => {
  modalStore.closeModal()
  modalStore.openModal({
    component: KaskoForms
  })
}
</script>

<template>
  <div class="risks-modal">
    <div class="risks-modal__upper">
      <p class="risks-modal__uppper-title">{{ title }}</p>
      <div class="risks-modal__upper-risks-list">
        <p v-for="(risk, index) in risks" :key="index">
          {{ risk }}
        </p>
      </div>
    </div>
    <span @click="handleCloseRiskModal" class="risks-modal__bottom-button">{{ $t("app.buttons.close") }}</span>
  </div>
</template>

<style scoped lang="scss">
.risks-modal {
  display: flex;
  width: 278px;
  padding: 24px 20px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #282828;
  .risks-modal__upper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 24px;
    border-bottom: 1px solid #e2e8f0;
    .risks-modal__uppper-title {
      color: #ffffff;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
    .risks-modal__upper-risks-list {
      text-align: center;

      p {
        display: inline;
        color: #ffffff;
        margin-right: 4px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.68px;
      }
    }
  }
  .risks-modal__bottom-button {
    padding: 12px 0;
    width: 100%;
    height: 100%;
    color: #f8fafc;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 24px;
  }
  span {
    cursor: pointer;
  }
}
</style>
