<script setup lang="ts">
import {ref, computed} from "vue"
import {useHomeProtectStore} from "@/stores/HomeProtectStore"
import {useModalStore} from "@/stores/ModalStore"
import {useDeviceSize} from "@/composables/useDeviceSize"
import {useI18n} from "vue-i18n"
import {useHelpers} from "@/composables/useHelpers"
import GreenLogoIcon from "@/components/icons/greens/GreenLogoIcon.vue"
import FFForm from "@/components/FFUI/FFForm.vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import HProtectForms from "@/components/HomeProtect/HProtectForms.vue"
import HProtectCalculateForm from "@/components/HomeProtect/Forms/HProtectCalculateForm.vue"

const modalStore = useModalStore()
const homeProtectStore = useHomeProtectStore()
const {t} = useI18n()
const {isDesktop} = useDeviceSize()
const {currentLocale} = useHelpers()

const listInfoTexts = ref([
  {
    id: 1,
    title: computed(() => t("app.homeProtectPage.listInfoTexts.listInfoTextTitle-1")),
    description: computed(() => t("app.homeProtectPage.listInfoTexts.listInfoTextDescription-1"))
  },
  {
    id: 2,
    title: computed(() => t("app.homeProtectPage.listInfoTexts.listInfoTextTitle-2")),
    description: computed(() => t("app.homeProtectPage.listInfoTexts.listInfoTextDescription-2"))
  },
  {
    id: 3,
    title: computed(() => t("app.homeProtectPage.listInfoTexts.listInfoTextTitle-3")),
    description: computed(() => t("app.homeProtectPage.listInfoTexts.listInfoTextDescription-3"))
  }
])

const handleOpenFormModal = () => {
  modalStore.openModal({
    component: HProtectForms
  })
}
</script>

<template>
  <section class="home-protect-hero container">
    <div class="home-protect-hero__left-part">
      <div class="home-protect-hero__box">{{ $t("app.homeProtectPage.fullyOnline") }}</div>
      <div class="home-protect-hero__titles">
        <div v-if="currentLocale !== 'ru'" class="home-protect-hero__main-title">
          <h1 class="home-protect-hero__title">{{ $t("app.homeProtectPage.mainTitleFirstLine") }}</h1>
          <h1 class="home-protect-hero__title">{{ $t("app.homeProtectPage.mainTitleSecondLine") }}</h1>
        </div>
        <template v-else>
          <h1 class="home-protect-hero__title">{{ $t("app.homeProtectPage.mainTitleFirstLine") }}</h1>
        </template>
        <span class="home-protect-hero__title-2">{{ $t("app.homeProtectPage.homeProtect") }}</span>
      </div>
      <p class="home-protect-hero__subtitle">
        {{ $t("app.homeProtectPage.subtitle") }}
      </p>
      <div class="home-protect-hero__list-info">
        <template v-for="data in listInfoTexts" :key="data.id">
          <div class="home-protect-hero__list-item">
            <GreenLogoIcon />
            <div class="home-protect-hero__list-item-texts">
              <p>{{ data.title }}</p>
              <p>{{ data.description }}</p>
            </div>
          </div>
        </template>
      </div>

      <FFButton
        v-if="!isDesktop"
        @click="handleOpenFormModal"
        :title="t('app.buttons.calculateCost')"
        maxWidth="320px"
        :uppercase="false"
        backgroundMobile="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
        colorMobile="#ffffff" />
    </div>
    <div class="home-protect-hero__right-part">
      <FFForm class="scrollbar-hide">
        <HProtectCalculateForm />
      </FFForm>
    </div>
  </section>
</template>

<style scoped lang="scss">
.home-protect-hero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  gap: 20px;
  padding-top: 150px;
  padding-bottom: 92px;
  z-index: 0;

  @media (max-width: $lg) {
    padding-top: 100px;
    padding-bottom: 100px;
    gap: 0;
  }
  .home-protect-hero__left-part {
    max-width: 730px;

    @media (max-width: $md) {
      max-width: 278px;
    }
    .home-protect-hero__box {
      display: inline-block;
      color: #ffffff;
      text-transform: uppercase;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 11.048px;
      text-transform: uppercase;

      border-radius: 2.053px;
      background: #f2994a;
      padding: 5px 10px;
    }
    .home-protect-hero__titles {
      margin: 10px 0 30px;
      .home-protect-hero__main-title {
        max-width: 700px;
      }
      .home-protect-hero__title {
        font-size: 50px;
        font-style: normal;
        font-weight: 900;
        line-height: 55px;
        background: linear-gradient(95deg, #79b0e2 -3.54%, #1cd491 56.19%, #3fed6e 102.64%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media (max-width: $lg) {
          font-size: 40px;
          line-height: 44px;
        }
      }
      .home-protect-hero__title-2 {
        font-size: 50px;
        font-style: normal;
        font-weight: 900;
        line-height: 55px;
        color: #4faf3b;

        @media (max-width: $lg) {
          font-size: 40px;
          line-height: 44px;
        }
      }
    }
    .home-protect-hero__subtitle {
      color: #ffffff;
      font-size: 16px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
    .home-protect-hero__list-info {
      display: flex;
      flex-direction: column;
      gap: 30px;
      margin-top: 30px;
      .home-protect-hero__list-item {
        display: flex;
        align-items: center;
        gap: 10px;
        .home-protect-hero__list-item-texts {
          p {
            color: #ffffff;
            font-size: 16px;
            font-style: normal;
            line-height: normal;
            text-transform: uppercase;
          }
          p:first-of-type {
            font-weight: 900;
          }
          p:last-of-type {
            font-weight: 400;
            opacity: 0.6;
            text-transform: none;
          }
        }
      }
    }
    .ff-button-wrapper {
      display: none;
      @media (max-width: $xl) {
        display: block;
        margin-top: 25px;
      }
    }
  }
  .home-protect-hero__right-part {
    .ff-form {
      overflow: hidden;

      @media (max-width: $xl) {
        display: none;
      }
    }
  }
}
</style>
