<script setup lang="ts">
import {reactive, computed, type ComputedRef} from "vue"
import {useI18n} from "vue-i18n"

interface IOgpoPlusHowWorksSteps {
  step: number
  title: ComputedRef<string>
  description: ComputedRef<string>
}

const {t} = useI18n()

const executingSteps = reactive<IOgpoPlusHowWorksSteps[]>([
  {
    step: 1,
    title: computed(() => t("app.ogpoPlusPage.howWorks.title-1")),
    description: computed(() => t("app.ogpoPlusPage.howWorks.paragraph-1"))
  },
  {
    step: 2,
    title: computed(() => t("app.ogpoPlusPage.howWorks.title-2")),
    description: computed(() => t("app.ogpoPlusPage.howWorks.paragraph-2"))
  },
  {
    step: 3,
    title: computed(() => t("app.ogpoPlusPage.howWorks.title-3")),
    description: computed(() => t("app.ogpoPlusPage.howWorks.paragraph-3"))
  }
])
</script>

<template>
  <section class="ogpo-plus-how-works">
    <div class="ogpo-plus-how-works__left-part">
      <img
        class="ogpo-plus-how-works__car-desk"
        src="/ogpo-plus-front/images/how-works-ogpo-plus-car-desk.png"
        alt=""
        draggable="false" />
    </div>
    <div class="ogpo-plus-how-works__right-part">
      <p class="ogpo-plus-how-works__right-part-title">{{ $t("app.ogpoPlusPage.howWorks.howOgpoPlusWorks") }}</p>
      <div class="ogpo-plus-how-works__right-part-executation-list">
        <div
          v-for="(step, index) in executingSteps"
          :key="index"
          class="ogpo-plus-how-works__right-part-executation-row">
          <div class="ogpo-plus-how-works__executation-row-number">{{ step.step }}</div>
          <div class="ogpo-plus-how-works__executation-row-texts">
            <p class="ogpo-plus-how-works__executation-row-title">{{ step.title }}</p>
            <p class="ogpo-plus-how-works__executation-row-description">{{ step.description }}</p>
          </div>
        </div>
      </div>
      <p class="ogpo-plus-how-works__right-part-amortization">
        {{ $t("app.ogpoPlusPage.howWorks.iAgreeToAmortization") }}
      </p>
    </div>
  </section>
</template>

<style scoped lang="scss">
.ogpo-plus-how-works {
  @include flex;
  position: relative;
  z-index: 1;
  gap: 30px;
  margin-bottom: 150px;

  @include max-responsive(xxl) {
    @include flex;
  }

  @include max-responsive(lg) {
    margin-bottom: 100px;
  }

  .ogpo-plus-how-works__left-part {
    @include max-responsive(721px) {
      display: none;
    }

    .ogpo-plus-how-works__car-desk {
      min-width: 445px;
      height: 579px;

      @include max-responsive(xl) {
        display: none;
      }
    }
  }
  .ogpo-plus-how-works__right-part {
    display: flex;
    flex-direction: column;
    .ogpo-plus-how-works__right-part-title {
      font-size: 40px;
      font-style: normal;
      font-weight: 900;
      line-height: 40px;

      background: linear-gradient(95deg, #79b0e2 -3.54%, #1cd491 56.19%, #3fed6e 102.64%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 50px;

      @include max-responsive(lg) {
        margin-bottom: 30px;
      }

      @include max-responsive(721px) {
        font-size: 30px;
      }
    }
    .ogpo-plus-how-works__right-part-executation-list {
      display: flex;
      flex-direction: column;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 20px;
        height: 344px;
        width: 1px;
        border-right: 1px solid #ffffff;
        border-style: dashed;
        z-index: 0;

        @include max-responsive(xxl) {
          content: "";
          height: 393px;
        }

        @include max-responsive(xl) {
          content: "";
          height: 337px;
        }

        @include max-responsive(721px) {
          content: "";
          position: absolute;
          top: 5px;
          left: 20px;
          height: 533px;
          width: 1px;
          border-right: 1px solid #ffffff;
          border-style: dashed;
          z-index: 0;
        }

        @include max-responsive(md) {
          content: "";
          height: 619px;
        }
      }

      .ogpo-plus-how-works__right-part-executation-row {
        display: flex;
        gap: 9px;
        z-index: 1;

        &:not(:last-of-type) {
          margin-bottom: 50px;
        }

        .ogpo-plus-how-works__executation-row-number {
          display: flex;
          height: min-content;
          color: #ffffff;
          text-align: center;
          font-size: 15.628px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          padding: 10px 15px;
          border-radius: 100px;
          border: 1px solid #ffffff;
          background-color: #3d3d3d;
        }

        .ogpo-plus-how-works__executation-row-texts {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .ogpo-plus-how-works__executation-row-title {
            color: #ffffff;
            font-size: 18px;
            font-style: normal;
            font-weight: 900;
            line-height: 18.36px;
            text-transform: uppercase;
          }
          .ogpo-plus-how-works__executation-row-description {
            color: #ffffff;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            opacity: 0.8;
          }
        }
      }
    }
    .ogpo-plus-how-works__right-part-amortization {
      margin-top: auto;
      color: #ffffff;
      font-size: 12px;
      font-style: italic;
      font-weight: 500;
      line-height: normal;

      @include max-responsive(xxl) {
        margin-top: 30px;
      }

      @include max-responsive(721px) {
        text-align: center;
      }
    }
  }
}
</style>
