<script setup lang="ts">
import {storeToRefs} from "pinia"
import {useAuthStore} from "@/stores/AuthStore"
import AuthTabs from "@/components/Auth/AuthTabs.vue"
import Modal from "@/components/FFUI/Modal.vue"
import Loader from "@/components/FFUI/Loader.vue"

const authStore = useAuthStore()
const {loading} = storeToRefs(authStore)
</script>

<template>
  <Modal style="z-index: 101" :isOpen="loading">
    <Loader />
  </Modal>
  <div class="auth"></div>
  <AuthTabs />
</template>

<style scoped lang="scss">
.auth {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("/auth-front/images/auth-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
