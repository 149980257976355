<script setup lang="ts">
import {onBeforeMount} from "vue"
import {storeToRefs} from "pinia"
import {useRoute} from "vue-router"
import {useVmsStore} from "@/stores/PolicyVmsStore"
import VmsBlock from "@/components/PolicyVms/VmsBlock.vue"
import Modal from "@/components/FFUI/Modal.vue"
import Loader from "@/components/FFUI/Loader.vue"
import SkeletonLoader from "@/components/partials/SkeletonLoader.vue"

const vmsStore = useVmsStore()
const {loading} = storeToRefs(vmsStore)
const route = useRoute()

const hash = route.params.hash

onBeforeMount(() => {
  vmsStore.getMigrantPolicy(hash)
})
</script>

<template>
  <Modal style="z-index: 103" :isOpen="loading">
    <Loader />
  </Modal>
  <section class="policy-vms">
    <SkeletonLoader v-if="loading" class="policy-vms__skeleton" />
    <VmsBlock ref="vmsBlockRef" v-else />
  </section>
</template>

<style scoped lang="scss">
.policy-vms {
  display: flex;
  justify-content: center;
  padding: 100px 15px 200px;
  height: 100%;
  background: url(/thankyou-front/images/thankyou-bg.png) no-repeat top;

  @media (max-width: $lg) {
    padding: 80px 15px 60px;
  }

  .policy-vms__skeleton {
    max-width: 650px;
    width: 100%;
    height: 350px;
    @media (max-width: $lg) {
      height: 370px;
    }
  }
}
</style>
