import zod from "zod"

export const authorizeSchema = zod
  .object({
    iin: zod.string().nonempty().length(12),
    phone: zod
      .string()
      .nonempty()
      .regex(/^\+7 \(\d{3}\) \d{3} \d{4}$/),
  })
