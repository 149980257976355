import zod from "zod"

export const hotlineFillFormSchema = zod.object({
  fullName: zod.string().min(1).nonempty(),
  phoneNumber: zod
    .string()
    .nonempty()
    .regex(/^\+7 \(\d{3}\) \d{3} \d{4}$/),
  email: zod.string().email().nonempty(),
  theme: zod.object({
    id: zod.number(),
    value: zod.string().nonempty()
  }),
  question: zod.string().min(30).nonempty(),
  file: zod.instanceof(File).nullable()
})
