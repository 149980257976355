<script setup lang="ts">
import {watchEffect, onUnmounted} from "vue"
import {useI18n} from "vue-i18n"
import {storeToRefs} from "pinia"
import {useOgpoPlusStore} from "@/stores/OgpoPlusStore"
import {useHelperStore} from "@/stores/HelperStore"
import OgpoPlusHero from "@/components/OgpoPlus/OgpoPlusHero.vue"
import OgpoPlusHowWorks from "@/components/OgpoPlus/OgpoPlusHowWorks.vue"
import OgpoPlusStatistics from "@/components/OgpoPlus/OgpoPlusStatistics.vue"
import OgpoPlusArrangeCarInsurance from "@/components/OgpoPlus/OgpoPlusArrangeCarinsurance.vue"
import OgpoPlusAnyQuestions from "@/components/OgpoPlus/OgpoPlusAnyQuestions.vue"
import OgpoPlusDifferences from "@/components/OgpoPlus/OgpoPlusDifferences/OgpoPlusDifferences.vue"
import OgpoPlusAmortization from "@/components/OgpoPlus/OgpoPlusAmortization.vue"
import Modal from "@/components/FFUI/Modal.vue"
import Loader from "@/components/FFUI/Loader.vue"

const helperStore = useHelperStore()
const {t} = useI18n()
const ogpoPlusStore = useOgpoPlusStore()
const {loading, agreementLoaderHide} = storeToRefs(ogpoPlusStore)

watchEffect(() => {
  helperStore.pageTitle = t("app.headerPageTitles.ogpoPlusPage")
})

onUnmounted(() => {
  ogpoPlusStore.resetStates()
})
</script>

<template>
  <template v-if="agreementLoaderHide"> </template>
  <template v-else>
    <Modal style="z-index: 103" :isOpen="loading">
      <Loader />
    </Modal>
  </template>
  <div class="ogpo-plus container">
    <div class="ogpo-plus__first-green-bg" />
    <OgpoPlusHero />
    <OgpoPlusDifferences />
    <div class="ogpo-plus__second-green-bg" />
    <OgpoPlusHowWorks />
    <OgpoPlusAmortization />
    <OgpoPlusStatistics />
    <div class="ogpo-plus__autumn-road-bg-desk"></div>
    <div class="ogpo-plus__autumn-road-bg-tablet"></div>
    <OgpoPlusArrangeCarInsurance />
    <OgpoPlusAnyQuestions />
  </div>
</template>

<style scoped lang="scss">
.ogpo-plus {

  .ogpo-plus__first-green-bg {
    position: absolute;
    background-image: url("/ogpo-plus-front/images/ellipse-1.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    top: 0;
    right: 0;
    width: 100%;
    height: 1607.094px;
    z-index: 0;
  }
  .ogpo-plus__second-green-bg {
    position: absolute;
    background-image: url("/ogpo-plus-front/images/ellipse-2.png");
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
    top: 1000px;
    left: 0;
    width: 100%;
    height: 2107.094px;
    z-index: 0;
  }
  .ogpo-plus__cars-bg-desk {
    position: absolute;
    background-image: url("/ogpo-front/images/cars-bg-desk.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 2934px;
    z-index: 0;
  }
  .ogpo-plus__cars-bg-tablet {
    display: none;
  }
  .ogpo-plus__cars-bg-md-mobile {
    display: none;
  }
  .ogpo-plus__autumn-road-bg-desk {
    position: absolute;
    background-image: url("/ogpo-front/images/autumn-road-bg-desk.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    top: 3484px;
    min-height: 820px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
  .ogpo-plus__autumn-road-bg-tablet {
    display: none;
  }
}

@media (max-width: $xxlWide) {
  .ogpo-plus {
    .ogpo-plus__autumn-road-bg-desk {
      position: absolute;
      background-image: url("/ogpo-front/images/autumn-road-bg-desk.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      top: 3260px;
      min-height: 820px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
    }
    .ogpo-plus__autumn-road-bg-tablet {
      display: none;
    }
  }
}

@media (max-width: $wide) {
  .ogpo-plus {
    .ogpo-plus__autumn-road-bg-desk {
      position: absolute;
      background-image: url("/ogpo-front/images/autumn-road-bg-desk.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      top: 3260px;
      min-height: 820px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
    }
    .ogpo-plus__autumn-road-bg-tablet {
      display: none;
    }
  }
}

@media (max-width: $xxl) {
  .ogpo-plus {
    .ogpo-plus__cars-bg-desk {
      display: none;
    }
    .ogpo-plus__cars-bg-tablet {
      display: flex;
      position: absolute;
      background-image: url("/ogpo-front/images/cars-bg-tablet.png");
      background-position: top;
      background-size: cover;
      background-repeat: no-repeat;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      min-height: 3534px;
      z-index: 0;
    }
    .ogpo-plus__autumn-road-bg-desk {
      display: none;
    }
    .ogpo-plus__autumn-road-bg-tablet {
      display: flex;
      position: absolute;
      background-image: url("/ogpo-front/images/autumn-road-bg-tablet.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      top: 3260px;
      min-height: 652px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
    }
  }
}

@media (max-width: $xl) {
  .ogpo-plus {
    .ogpo-plus__autumn-road-bg-desk {
      display: none;
    }
    .ogpo-plus__autumn-road-bg-tablet {
      display: flex;
      position: absolute;
      background-image: url("/ogpo-front/images/autumn-road-bg-tablet.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      top: 3706px;
      min-height: 652px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
    }
  }
}

@media (max-width: 768px) {
  .ogpo-plus {
    .ogpo-plus__autumn-road-bg-desk {
      display: none;
    }
    .ogpo-plus__autumn-road-bg-tablet {
      display: flex;
      position: absolute;
      background-image: url("/ogpo-front/images/autumn-road-bg-sm-tablet.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      top: 3681px;
      min-height: 652px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
    }
  }
}

@media (max-width: $lg) {
  .ogpo-plus {
    .ogpo-plus__cars-bg-tablet {
      display: none;
    }
    .ogpo-plus__cars-bg-md-mobile {
      display: flex;
      position: absolute;
      background-image: url("/ogpo-front/images/cars-bg-md-mobile.png");
      background-position: top;
      background-size: cover;
      background-repeat: no-repeat;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      min-height: 3500px;
      z-index: 0;
    }
    .ogpo-plus__autumn-road-bg-desk {
      display: none;
    }
    .ogpo-plus__autumn-road-bg-tablet {
      display: flex;
      position: absolute;
      background-image: url("/ogpo-front/images/autumn-road-bg-sm-tablet.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      top: 4673px;
      min-height: 652px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
    }
  }
}

@media (max-width: $md) {
  .ogpo-plus {
    .ogpo-plus__autumn-road-bg-desk {
      display: none;
    }
    .ogpo-plus__autumn-road-bg-tablet {
      display: none;
    }
  }
}
</style>
