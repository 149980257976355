import {ref} from "vue"
import {useToast} from "vue-toastification"
import {useI18n} from "vue-i18n"
import {useHelpers} from "@/composables/useHelpers"

export function usePdfOpener(postData: any) {
  const {t} = useI18n()
  const toast = useToast()
  const {currentLocale} = useHelpers()

  const openPdf = async (url: string, data: Object) => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }

      const response = await postData(url, data, config)

      if (response?.status === 200 && response?.data?.pdf) {
        const binaryData = atob(response.data.pdf)

        const arrayBuffer = new Uint8Array(binaryData.length)
        for (let i = 0; i < binaryData.length; i++) {
          arrayBuffer[i] = binaryData.charCodeAt(i)
        }

        const blob = new Blob([arrayBuffer], {type: "application/pdf"})
        const pdfUrl = URL.createObjectURL(blob)

        const popUpCheck = window.open(pdfUrl, "_blank")
        if (!popUpCheck) {
          toast.clear()
          toast.info(t("app.toasterMessages.turnOffPopupBlocker"))
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const openKdpAgreementPdf = (fullName: string, iin: string): Promise<unknown> => {
    return openPdf("/portal/consent/pdf", {fio: fullName, iin})
  }

  const openQuestionnairePdf = (endpoint: string, params: object): Promise<unknown> => {
    return openPdf(endpoint, params)
  }

  return {
    openKdpAgreementPdf,
    openQuestionnairePdf
  }
}
