import {defineStore} from "pinia"
import {ref} from "vue"
import {useDeviceSize} from "@/composables/useDeviceSize"

export const useHelperStore = defineStore("helperStore", () => {
  const {isMobileDevice} = useDeviceSize()
  //page title
  let pageTitle = ref("")

  //input focus
  const inputIsFocused = ref<boolean>(false)
  const inputId = ref<string>("")

  //bottom sheet
  const isSheetVisible = ref(false)

  const toggleSheet = () => {
    isSheetVisible.value = !isSheetVisible.value
  }

  //ReCaptcha
  const isRecaptchaVerified = ref(false)
  const recaptchaToken = ref<string | null>(null)
  const widgetId = ref<number | null>(null)

  return {
    pageTitle,
    inputIsFocused,
    inputId,
    isSheetVisible,
    isRecaptchaVerified,
    recaptchaToken,
    widgetId,
    toggleSheet
  }
})
