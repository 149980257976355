<script lang="ts" setup>
interface Props {
  title?: string
  clear?: boolean
  password?: boolean
  eyeOpened?: boolean
  borderType?: "solid" | "dashed"
  borderColor?: string
  calendarIcon?: boolean
  timeIcon?: boolean
  isError?: boolean
  required?: boolean
  minHeight?: string
}

withDefaults(defineProps<Props>(), {
  clear: false,
  password: false,
  eyeOpened: true,
  borderType: "solid",
  borderColor: "#ffffff",
  calendarIcon: false,
  timeIcon: false,
  isError: false,
  required: false,
  minHeight: "51px"
})

const emit = defineEmits(["clear", "password", "text", "calendar", "time"])
</script>

<template>
  <fieldset class="input-fieldset" :class="{isError: isError, required: required}">
    <legend v-if="title" class="input-legend">{{ title }}</legend>
    <slot />
    <button
      v-if="calendarIcon"
      @click="emit('calendar')"
      class="input-button"
      type="button">
      <img src="/declare-insured-event/images/calendar-input.svg" alt="icon" />
    </button>
    <button v-if="timeIcon" @click="emit('time')" class="input-button" type="button">
      <img src="/declare-insured-event/images/time-input.svg" alt="icon" />
    </button>
    <button v-if="clear" @click="emit('clear')" class="input-button" type="button">
      <img src="/auth-front/images/clear-input.svg" alt="clear" />
    </button>
    <button
      v-if="password && eyeOpened"
      @click="emit('text')"
      class="input-button"
      type="button">
      <img src="/auth-front/images/opened-eye.svg" alt="clear" />
    </button>
    <button
      v-if="password && !eyeOpened"
      @click="emit('password')"
      class="input-button"
      type="button">
      <img src="/auth-front/images/closed-eye.svg" alt="clear" />
    </button>
  </fieldset>
</template>

<style lang="scss" scoped>
.input-fieldset {
  border: 2px v-bind(borderColor) v-bind(borderType);
  border-radius: 5px;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: v-bind(minHeight);
}

.input-legend {
  color: white;
  padding: 0 4px;
  white-space: nowrap;
  font-weight: 600;
  font-size: 12px;
  margin-left: 10px;
  width: fit-content;
  float: initial;
}

.input-button {
  position: absolute;
  right: 5px;
  top: 20%;
}

.input-icon {
  position: absolute;
  top: 2px;
  right: 7px;
  cursor: pointer;
}

.isError {
  border: 2px #ff7c7c solid;

  .input-legend {
    color: #ff7c7c;
  }
}

.required {
  border: 2px #ff7c7c solid;

  .input-legend {
    color: #ff7c7c;
  }
}
</style>
