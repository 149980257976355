<script lang="ts" setup>
import {vMaska} from "maska"
import {useToast} from "vue-toastification"
import CloseIcon from "@/components/icons/CloseIcon.vue"

interface Props {
  id?: string
  type?: string
  inputmode?: "search" | "text" | "email" | "tel" | "numeric" | "url"
  name?: string
  locked?: boolean
  lockedErrorText?: string
  placeholder?: string
  modelValue?: any
  textarea?: boolean
  placeholderColor?: string
  textareaMinHeight?: string
  paddingY?: string
  paddingX?: string
  isUppercase?: boolean

  func?: () => void
  blur?: () => void
  loading?: boolean

  padding?: string
  needUnderText?: boolean
  underText?: string
  inputHeight?: string

  removeInputText?: boolean
  handleRemoveInput?: () => void

  dataMaska?: string
}

const props = withDefaults(defineProps<Props>(), {
  textarea: false,
  placeholderColor: "rgba(255, 255, 255, 0.2)",
  isUppercase: false,

  needUnderText: false,
  padding: "6px 5px 7px 14px",
  inputHeight: "51px",
  locked: false
})
const toast = useToast()
const inputValue = defineModel<string | number>()

const handleLockedError = () => {
  if (props.locked) {
    toast.info(props.lockedErrorText)
  }
}
</script>

<template>
  <textarea
    v-if="textarea"
    :class="{'set-padding': paddingX && paddingY, uppercase: isUppercase}"
    :placeholder="placeholder"
    v-model="inputValue" />
  <div v-if="!textarea">
    <input
      v-maska
      :data-maska="dataMaska"
      :id="id"
      :name="name"
      :type="type"
      autocomplete="on"
      :inputmode="inputmode"
      :value="modelValue"
      :placeholder="placeholder"
      v-model="inputValue"
      :disabled="locked"
      :class="{'set-padding': paddingX && paddingY, uppercase: isUppercase}"
      @input="func"
      @blur="blur" />

    <div v-if="locked" @click="handleLockedError" class="locked-area"></div>

    <img v-if="loading" class="loading-icon" src="/main-front/images/loading-gif.gif" alt="loading" />

    <CloseIcon v-if="removeInputText" class="remove-icon" @click="handleRemoveInput" />
  </div>

  <p v-if="needUnderText" class="input-under-text">{{ underText }}</p>
</template>

<style lang="scss" scoped>
input {
  background: transparent;
  border: none;
  color: #ffffff;
  width: 100%;
  padding: v-bind(padding);
  max-height: v-bind(inputHeight);
  outline: none;
  font-size: 16px;
}

.remove-icon {
  cursor: pointer;
  position: absolute;
  right: 5%;
  top: 10px;
}

.loading-icon {
  position: absolute;
  right: 15%;
  top: 4px;
  width: 24px;
}

.input-under-text {
  font-size: 12px;
  color: #b3b3b3;
  padding-left: 14px;
}

input::placeholder,
textarea::placeholder {
  color: v-bind(placeholderColor);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

textarea::placeholder {
  padding-top: 4px;
}

input:focus,
textarea:focus {
  border-color: inherit;
  box-shadow: none;
  -webkit-box-shadow: none;
}

textarea {
  background: transparent;
  border: none;
  color: white;
  width: 100%;
  padding: 0 5px 7px 14px;
  min-height: v-bind(textareaMinHeight);
  outline: none;
  font-size: 14px;
  resize: none;
}

.set-padding {
  padding: v-bind(paddingY) v-bind(paddingX);
}
.uppercase {
  text-transform: uppercase;
}
.locked-area {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  cursor: not-allowed !important;
  pointer-events: auto !important;
}
</style>
