<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
    <g clip-path="url(#clip0_518_11297)">
      <path
        d="M23 13.1111V21.1111C23 21.3469 22.9052 21.573 22.7364 21.7397C22.5676 21.9064 22.3387 22 22.1 22H5.9C5.66131 22 5.43239 21.9064 5.2636 21.7397C5.09482 21.573 5 21.3469 5 21.1111V13.1111H23ZM23 9.55556H5V6.88889C5 6.65314 5.09482 6.42705 5.2636 6.26035C5.43239 6.09365 5.66131 6 5.9 6H22.1C22.3387 6 22.5676 6.09365 22.7364 6.26035C22.9052 6.42705 23 6.65314 23 6.88889V9.55556Z"
        fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_518_11297">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
