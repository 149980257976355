import zod from "zod"

export const contactsFormSchema = zod.object({
  fullName: zod.string().min(2).nonempty(),
  phone: zod
    .string()
    .nonempty()
    .regex(/^\+7 \(\d{3}\) \d{3} \d{4}$/),
  email: zod.string().min(1).email().nonempty(),
  comment: zod.string().min(5).nonempty(),
})
