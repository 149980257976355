<script setup lang="ts">
import {watchEffect} from "vue"
import {storeToRefs} from "pinia"
import {useMSTRemasteredStore} from "@/stores/MSTRemastered"
import {useHelperStore} from "@/stores/HelperStore"
import {useI18n} from "vue-i18n"
import MSTBanner from "@/components/MSTRemastered/MSTBanner.vue"
import MSTGuideLine from "@/components/MSTRemastered/MSTGuideLine.vue"
import MSTHero from "@/components/MSTRemastered/MSTHero.vue"
import MSTProduct from "@/components/MSTRemastered/MSTProduct.vue"
import MSTInsuranceCase from "@/components/MSTRemastered/MSTInsuranceCase.vue"
import Modal from "@/components/FFUI/Modal.vue"
import Loader from "@/components/FFUI/Loader.vue"

const mstStore = useMSTRemasteredStore()
const helperStore = useHelperStore()
const {loading, agreementLoaderHide} = storeToRefs(mstStore)
const {t} = useI18n()

watchEffect(() => {
  helperStore.pageTitle = t("app.headerPageTitles.mstPage")
})
</script>

<template>
  <template v-if="agreementLoaderHide"> </template>
  <template v-else>
    <Modal style="z-index: 103" :isOpen="loading">
      <Loader />
    </Modal>
  </template>
  <MSTHero />
  <MSTProduct />
  <MSTGuideLine />
  <MSTBanner />
  <MSTInsuranceCase />
</template>

<style scoped lang="scss">

</style>
