<script setup lang="ts">
interface Props {
  id?: string
  type?: string
  inputmode?: "search" | "text" | "email" | "tel" | "numeric" | "url"
  name?: string
  locked?: boolean | null
  placeholder?: string
  modelValue?: any
  textarea?: boolean
  textareaMinHeight?: string
  paddingY?: string
  paddingX?: string
  placeholderColor?: string
  isUppercase?: boolean
  required?: boolean
  inputMaxLength?: string
}

withDefaults(defineProps<Props>(), {
  textarea: false,
  placeholderColor: "rgba(255, 255, 255, 0.2)",
  isUppercase: false,
  required: false,
  textareaMinHeight: "200px"
})

const inputValue = defineModel<string | number>()
</script>

<template>
  <textarea
    v-if="textarea"
    :placeholder="placeholder"
    :disabled="locked"
    v-model="inputValue" />
  <input
    v-else
    :id="id"
    :name="name"
    autocomplete="on"
    :type="type"
    :inputmode="inputmode"
    :value="modelValue"
    :placeholder="placeholder"
    v-model="inputValue"
    :disabled="locked"
    :class="{'set-padding': paddingX && paddingY, uppercase: isUppercase}"
    :required="required"
    :maxlength="inputMaxLength"
    />
</template>

<style scoped lang="scss">
input {
  background: transparent;
  border: none;
  color: var(--color-white);
  width: 100%;
  padding: 6px 5px 7px 14px;
  max-height: 51px;
  outline: none;
  font-size: 16px;
}

input::placeholder,
textarea::placeholder {
  color: v-bind(placeholderColor);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

textarea::placeholder {
  padding-top: 4px;
}

input:focus,
textarea:focus {
  border-color: inherit;
  box-shadow: none;
  -webkit-box-shadow: none;
}

input:disabled,
textarea:disabled {
  color: var(--color-white); 
  opacity: 1;
}

textarea {
  background: transparent;
  border: none;
  color: white;
  width: 100%;
  padding: 0 5px 7px 14px;
  outline: none;
  font-size: 14px;
  resize: none;
  min-height: v-bind(textareaMinHeight);
}

.set-padding {
  padding: v-bind(paddingY) v-bind(paddingX);
}

.uppercase {
  text-transform: uppercase;
}
</style>
