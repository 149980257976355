import {computed} from "vue"
import {useWindowSize} from "@vueuse/core"

export function useDeviceSize() {
  const {width, height} = useWindowSize()
  const isMobileDevice = computed(() => {
    return width.value <= 722
  })

  const isTablet = computed(() => {
    return width.value > 722 && width.value <= 962;
  })

  const isDesktop = computed(() => {
    return width.value >= 963
  })

  return {
    isMobileDevice,
    isTablet,
    isDesktop
  }
}
