<script setup lang="ts">
import {onBeforeMount, ref, onMounted} from "vue"
import VueDatePicker from "@vuepic/vue-datepicker"
import "@vuepic/vue-datepicker/dist/main.css"
import {useHelpers} from "@/composables/useHelpers"
import {vMaska} from "maska"
import {useFetcher} from "@/composables/useFetcher"
import {useMSTStore} from "@/stores/MSTStore"
import {useToast} from "vue-toastification"
import {useRules} from "@/utils/rules"
import {debounce} from "lodash-es"
import {useI18n} from "vue-i18n"
import {getDobStringByIIN} from "@/utils/date"
import type {CalculationCredentials} from "@/interfaces/Auth/CalculationCredentials"
import Loader from "@/components/FFUI/Loader.vue"
import Modal from "@/components/FFUI/Modal.vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import FFCheckbox from "@/components/FFUI/FFCheckbox.vue"

const mstStore = useMSTStore()

const toast = useToast()
const {postData, loading} = useFetcher()
const {currentLocale, openLink} = useHelpers()
const {isEmail, isIIN, isPassport, isLatinAndNoNumbersOrSpecialChars} = useRules()
const {t} = useI18n()

const emit = defineEmits(["success"])

interface Props {
  credentials: CalculationCredentials
}

interface IFormValidation {
  iin: Boolean
  passportNumb: Boolean
  passportIssueDate: Boolean
  firstNameEng: Boolean
  lastNameEng: Boolean
}

defineProps<Props>()

const isResidentOnly = true

const isPDL = ref<boolean>(false)
const isOffshtore = ref<boolean>(false)
const isConfirmRules = ref<boolean>(false)
const currentForm = ref(-1)

const formValidation = ref<IFormValidation[]>([])
const emailValidation = ref(false)

const buttonClicked = ref(false)

onMounted(() => {
  for (let i = 0; i < mstStore.insuredList.length; i++) {
    let insured: IFormValidation = {
      iin: false,
      passportNumb: false,
      passportIssueDate: false,
      firstNameEng: false,
      lastNameEng: false
    }
    formValidation.value.push(insured)
  }
})

onBeforeMount(() => {
  const count = mstStore.calculateForm.ageTravels.length
  const insuredListCount = mstStore.insuredList.length
  if (count !== insuredListCount) {
    mstStore.insuredList.length = 0
    for (let i = 0; i < count; i++) {
      mstStore.insuredList[i] = createInsured()
    }
  }
})

function createInsured() {
  return {
    iin: "",
    passportNumb: "",
    passportIssueDate: "",
    isResident: true,
    firstName: "",
    lastName: "",
    middleName: "",
    firstNameEng: "",
    lastNameEng: "",
    birthDate: "",
    gender: "Unknown",
    checked: false
  }
}

const createOrder = () => {
  buttonClicked.value = true
  if (!isPDL.value || !isOffshtore.value || !isConfirmRules.value) {
    toast.clear()
    toast.error(t("app.toasterMessages.mstPage.agreeToTheTerms"))
    return
  }

  if (!isEmail(mstStore.email)) {
    currentForm.value = -1
    emailValidation.value = true
    return
  } else {
    emailValidation.value = false
  }

  let validationFailed = false

  for (let index = 0; index < mstStore.insuredList.length; index++) {
    const insured = mstStore.insuredList[index]

    // IIN number
    if (!insured.iin) {
      currentForm.value = index
      formValidation.value[index].iin = true
      validationFailed = true
    } else if (!isIIN(insured.iin)) {
      currentForm.value = index
      formValidation.value[index].iin = true
      validationFailed = true
    } else {
      formValidation.value[index].iin = false
    }

    // Passport number
    if (!insured.passportNumb) {
      currentForm.value = index
      formValidation.value[index].passportNumb = true
      validationFailed = true
    } else if (!isPassport(insured.passportNumb)) {
      currentForm.value = index
      formValidation.value[index].passportNumb = true
      validationFailed = true
    } else {
      formValidation.value[index].passportNumb = false
    }

    // Passport issue date
    if (!insured.passportIssueDate) {
      currentForm.value = index
      formValidation.value[index].passportIssueDate = true
      validationFailed = true
    } else {
      formValidation.value[index].passportIssueDate = false
    }

    // First name latin
    if (!insured.firstNameEng) {
      currentForm.value = index
      formValidation.value[index].firstNameEng = true
      validationFailed = true
    } else {
      formValidation.value[index].firstNameEng = false
    }

    // Last name latin
    if (!insured.lastNameEng) {
      currentForm.value = index
      formValidation.value[index].lastNameEng = true
      validationFailed = true
    } else {
      formValidation.value[index].lastNameEng = false
    }

    if (
      !insured.iin ||
      !insured.passportNumb ||
      !insured.passportIssueDate ||
      !insured.lastNameEng ||
      !insured.firstNameEng
    ) {
      toast.clear()
      toast.error(t("app.toasterMessages.mstPage.mustFillAllInputsOfAllInsured"))
      currentForm.value = index
      validationFailed = true
    }

    if (validationFailed) {
      break
    }
  }
  if (
    formValidation.value.some(
      (validation) =>
        validation.iin ||
        validation.passportNumb ||
        validation.passportIssueDate ||
        validation.lastNameEng ||
        validation.firstNameEng
    )
  ) {
    return
  }
  emit("success", prepareData())
}

function prepareData() {
  setDob()
  return {
    email: mstStore.email,
    insured: mstStore.insuredList
  }
}

function setDob() {
  for (let i = 0; i < mstStore.insuredList.length; i++) {
    mstStore.insuredList[i].birthDate = getDobStringByIIN(mstStore.insuredList[i].iin)
  }
}

function checkIINValue(insuredIndex: number, iinValue: string) {
  if (iinValue.length === 12) {
    postData("/core/mst/authorize", {identifier: iinValue})
      .then((response) => {
        if (response && response.status === 200) {
          mstStore.insuredList[insuredIndex].firstName = response.data.firstName
          mstStore.insuredList[insuredIndex].lastName = response.data.lastName
          mstStore.insuredList[insuredIndex].middleName = response.data.middleName

          mstStore.insuredList[insuredIndex].checked = true
        } else {
          mstStore.insuredList[insuredIndex].iin = ""
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

const debounceCheckIINValue = debounce(checkIINValue, 300)

let lang = localStorage.getItem("locale")

const options = {
  preProcess: (val) => val.toUpperCase()
}
</script>

<template>
  <Modal style="z-index: 100" :is-open="loading">
    <Loader />
  </Modal>
  <div class="ff-personal-control">
    <div class="item-wrapper">
      <div
        class="ff-personal-item"
        :class="-1 == currentForm ? 'on-item' : ''"
        @click="
          () => {
            currentForm = -1
          }
        ">
        <div class="ff-personal-item-inner">{{ $t("app.mstPage.form.personalDataForm.policyHolder") }}</div>
      </div>
      <div
        class="ff-personal-item"
        :class="index == currentForm ? 'on-item' : ''"
        v-for="(insured, index) in mstStore.insuredList"
        :key="index"
        @click="currentForm = index">
        <div class="ff-personal-item-inner" v-if="mstStore.insuredList.length === 1">
          {{ $t("app.mstPage.form.personalDataForm.tourist") }} {{ index + 1 }}
        </div>
        <div class="ff-personal-item-inner" v-else>{{ index + 1 }}</div>
      </div>
    </div>
  </div>
  <template v-for="(insured, index) in mstStore.insuredList" :key="insured.key">
    <template v-if="currentForm === index">
      <FFCheckbox
        class="ff-personal__isResident"
        :title="$t('app.mstPage.form.personalDataForm.residentRk')"
        v-model="insured.isResident"
        :locked="true" />

      <div class="personal-data-form__row">
        <FFFieldset
          :title="$t('app.mstPage.form.personalDataForm.iin')"
          :required="buttonClicked && !insured.iin"
          :isError="buttonClicked && insured.iin && !isIIN(insured.iin)">
          <FFInput
            @input="debounceCheckIINValue(index, insured.iin)"
            v-model="insured.iin"
            v-maska
            data-maska="############"
            placeholder="700120300900"
            type="text"
            inputmode="numeric" />
        </FFFieldset>
        <div v-if="buttonClicked && !insured.iin" class="validation-error-color">
          {{ $t("app.mstPage.form.personalDataForm.validationErrors.iinRequired") }}
        </div>
        <div v-if="buttonClicked && insured.iin && !isIIN(insured.iin)" class="validation-error-color">
          {{ $t("app.mstPage.form.personalDataForm.validationErrors.iinIncorrect") }}
        </div>
      </div>

      <div class="personal-data-form__row">
        <FFFieldset
          :title="$t('app.mstPage.form.personalDataForm.passportNumber')"
          :required="buttonClicked && !insured.passportNumb"
          :isError="buttonClicked && insured.passportNumb && !isPassport(insured.passportNumb)">
          <FFInput
            v-model="insured.passportNumb"
            v-maska
            data-maska="N########"
            placeholder="N754566582"
            type="text"
            inputmode="numeric" />
        </FFFieldset>
        <div v-if="buttonClicked && !insured.passportNumb" class="validation-error-color">
          {{ $t("app.mstPage.form.personalDataForm.validationErrors.passportNumbRequired") }}
        </div>
        <div
          v-if="buttonClicked && insured.passportNumb && !isPassport(insured.passportNumb)"
          class="validation-error-color">
          {{ $t("app.mstPage.form.personalDataForm.validationErrors.passportNumbIncorrect") }}
        </div>
      </div>

      <div class="personal-data-form__row">
        <FFFieldset
          :title="$t('app.mstPage.form.personalDataForm.passportIssueDate')"
          :required="buttonClicked && !insured.passportIssueDate"
          :isError="buttonClicked && !insured.passportIssueDate">
          <VueDatePicker
            :enable-time-picker="false"
            auto-apply
            format="dd.MM.yyyy"
            :locale="lang === 'ru' ? 'ru' : lang === 'en' ? 'en' : 'kk'"
            :max-date="new Date()"
            v-model="insured.passportIssueDate">
            <template #clear-icon="{clear}"></template>
          </VueDatePicker>
        </FFFieldset>
        <div v-if="buttonClicked && !insured.passportIssueDate" class="validation-error-color">
          {{ $t("app.mstPage.form.personalDataForm.validationErrors.passportIssueDateRequired") }}
        </div>
      </div>

      <div class="personal-data-form__row">
        <FFFieldset
          :title="$t('app.mstPage.form.personalDataForm.surnameInLatin')"
          :required="buttonClicked && !insured.lastNameEng"
          :isError="buttonClicked && !insured.lastNameEng">
          <FFInput
            type="text"
            v-model="insured.lastNameEng"
            @input="
              () => (insured.lastNameEng = isLatinAndNoNumbersOrSpecialChars(insured.lastNameEng.toUpperCase()))
            " />
        </FFFieldset>
        <div v-if="buttonClicked && !insured.lastNameEng" class="validation-error-color">
          {{ $t("app.mstPage.form.personalDataForm.validationErrors.surnameRequired") }}
        </div>
      </div>

      <div class="personal-data-form__row">
        <FFFieldset
          :title="$t('app.mstPage.form.personalDataForm.nameInLatin')"
          :required="buttonClicked && !insured.firstNameEng"
          :isError="buttonClicked && !insured.firstNameEng">
          <FFInput
            type="text"
            v-model="insured.firstNameEng"
            @input="
              () => (insured.firstNameEng = isLatinAndNoNumbersOrSpecialChars(insured.firstNameEng.toUpperCase()))
            " />
        </FFFieldset>
        <div v-if="buttonClicked && !insured.firstNameEng" class="validation-error-color">
          {{ $t("app.mstPage.form.personalDataForm.validationErrors.nameRequired") }}
        </div>
      </div>
    </template>
  </template>

  <template v-if="currentForm == -1">
    <FFCheckbox
      class="ff-personal__isResident"
      :title="$t('app.mstPage.form.personalDataForm.residentRk')"
      v-model="isResidentOnly"
      :locked="true" />

    <div class="personal-data-form__row">
      <FFFieldset :title="$t('app.mstPage.form.personalDataForm.iin')">
        <FFInput placeholder="700120300900" type="text" v-model="credentials.identifier" :locked="true" />
      </FFFieldset>
    </div>

    <div class="personal-data-form__row">
      <FFFieldset
        :title="$t('app.mstPage.form.personalDataForm.email')"
        :required="!!(buttonClicked && !mstStore.email)"
        :isError="!!(buttonClicked && mstStore.email && !isEmail(mstStore.email))">
        <FFInput
          id="email"
          name="email"
          placeholder="email@mail.kz"
          type="text"
          inputmode="email"
          v-model="mstStore.email" />
      </FFFieldset>
      <div v-if="buttonClicked && !mstStore.email" class="validation-error-color">
        {{ $t("app.mstPage.form.personalDataForm.validationErrors.emailRequired") }}
      </div>
      <div v-if="buttonClicked && mstStore.email && !isEmail(mstStore.email)" class="validation-error-color">
        {{ $t("app.mstPage.form.personalDataForm.validationErrors.emailIncorrect") }}
      </div>
    </div>
  </template>

  <div class="ff-personal__checkboxes">
    <FFCheckbox :title="$t('app.mstPage.form.personalDataForm.validationErrors.isPublicFace')" v-model="isPDL" />
    <FFCheckbox
      :title="$t('app.mstPage.form.personalDataForm.validationErrors.billsInBanksRegisteredInOff')"
      v-model="isOffshtore" />
    <FFCheckbox v-model="isConfirmRules">
      <template #title>
        <p v-if="currentLocale === 'ru' || currentLocale === 'en'" class="anketa-rules">
          <span>
            {{ $t("app.mstPage.form.personalDataForm.validationErrors.agreeWithForm.firstParagraph") }}
          </span>
          {{ " " }}
          <span @click="mstStore.postQuestionnaireData" class="underline cursor-pointer">
            {{ $t("app.mstPage.form.personalDataForm.validationErrors.agreeWithForm.secondParagraph") }}</span
          >
          {{ " " }}
          {{ $t("app.mstPage.form.personalDataForm.validationErrors.agreeWithForm.subThirdParagraph") }}
          <span @click="openLink('https://ffins.kz/report-file/221.pdf', '_blank')" class="underline">
            {{ $t("app.mstPage.form.personalDataForm.validationErrors.agreeWithForm.thirdParagraph") }}</span
          >
        </p>
        <p v-if="currentLocale === 'kk'" class="anketa-rules">
          <span @click="mstStore.postQuestionnaireData" class="underline cursor-pointer">
            {{ $t("app.mstPage.form.personalDataForm.validationErrors.agreeWithForm.firstParagraph") }}
          </span>
          {{ " " }}
          <span>
            {{ $t("app.mstPage.form.personalDataForm.validationErrors.agreeWithForm.subSecondParagraph") }}
          </span>
          {{ " " }}
          <a href="https://ffins.kz/report-file/221.pdf" target="_blank">
            {{ $t("app.mstPage.form.personalDataForm.validationErrors.agreeWithForm.secondParagraph") }}</a
          >
          {{ " " }}
          <span>{{ $t("app.mstPage.form.personalDataForm.validationErrors.agreeWithForm.thirdParagraph") }}</span>
        </p>
      </template>
    </FFCheckbox>
  </div>

  <FFButton :title="$t('app.mstPage.form.personalDataForm.proceedToPayment')" @click="createOrder" />
</template>

<style scoped lang="scss">
.personal-data-form__row {
  margin-bottom: 20px;
}

.ff-personal-control {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
}

.item-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
}

.ff-personal__isResident {
  margin-bottom: 20px;
}

.add-btnn {
  cursor: pointer;
  display: flex;
  width: 45px;
  height: 40px;
  text-align: center;
  justify-content: flex-end;
  align-items: center;
  color: #fff;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.ff-personal-item {
  cursor: pointer;
  width: 100%;
  padding: 10px 10px;
  align-items: center;
  border-bottom: 2px solid white;
  border-top: 2px solid transparent;
}

.ff-personal-item-inner {
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.on-item {
  border-bottom: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-top: 2px solid white;
  border-left: 2px solid white;
  border-right: 2px solid white;
}

.anketa-rules {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-underline-offset: 2px;
  a {
    text-decoration: underline;
    transition: all 0.3s ease;
    &:hover {
      opacity: 0.7;
    }
  }
}

.ff-personal__checkboxes {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  .ff-checkbox:not(:last-of-type) {
    margin-bottom: 10px;
  }
}
</style>
