<script setup lang="ts">
import {useI18n} from "vue-i18n"
import FFButton from "@/components/FFUI/FFButton.vue"
import PhoneIconFooter from "@/components/icons/PhoneIconFooter.vue"

const {t} = useI18n()
</script>

<template>
  <section class="ogpo-any-questions">
    <div class="ogpo-any-questions__content">
      <div class="ogpo-any-questions__texts">
        <p>{{ $t("app.ogpoPage.anyquestions.mainTitle") }}</p>
        <p>{{ $t("app.ogpoPage.anyquestions.description") }}</p>
      </div>
      <div class="ogpo-any-questions__call">
        <div class="ogpo-any-questions__contact-item">
          <PhoneIconFooter />
          <div class="contact-item__sub">
            <p>5777</p>
            <p>{{ $t("app.footer.phone") }}</p>
          </div>
        </div>
        <a href="tel:5777"
          ><FFButton
            :title="t('app.buttons.callBack')"
            background="transparent"
            backgroundMobile="transparent"
            borderPixel="1px solid #ffffff"
            borderPixelMobile="1px solid #ffffff"
            color="#ffffff"
            colorMobile="#ffffff"
            :uppercase="false"
        /></a>
      </div>
    </div>
  </section>
  <div class="ogpo-any-questions-images">
    <img
      class="ogpo-any-questions__sms-ask-image"
      src="/ogpo-front/images/sms-ask.png"
      data-src="/ogpo-front/images/sms-ask.png"
      alt=""
      draggable="false" />
  </div>
</template>

<style scoped lang="scss">
.ogpo-any-questions {
  position: relative;
  z-index: 1;
  padding: 55px 60px 55px 150px;
  margin-bottom: 185px;

  border-radius: 30px;
  background: rgba(74, 74, 74, 0.2);
  backdrop-filter: blur(16px);
  border: 1px solid rgba(255, 255, 255, 0.1);

  @include max-responsive(xxl) {
    padding: 30px;
  }

  @include max-responsive(xl) {
    padding: 30px;
    margin-bottom: 100px;
  }

  .ogpo-any-questions__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 203px;

    @include max-responsive(xxl) {
      display: flex;
      justify-content: unset;
    }

    @include max-responsive(lg) {
      display: flex;
      justify-content: unset;
      flex-direction: column;
      height: fit-content;
    }

    .ogpo-any-questions__texts {

      @include max-responsive(xxl) {
        margin-right: 30px;
      }

      @include max-responsive(xl) {
        margin-right: 0;
        padding-bottom: 30px;
      }

      p:first-of-type {
        font-size: 40px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        @include main-green-gradient;
        
        @include max-responsive(lg) {
          font-size: 30px;
        }
      }

      p:last-of-type {
        color: #ffffff;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        opacity: 0.8;
        margin-top: 20px;

        @include max-responsive(lg) {
          font-size: 16px;
        }
      }
    }

    .ogpo-any-questions__call {
      height: 100%;
      border-left: 1px solid rgba(255, 255, 255, 0.1);
      padding-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include max-responsive(lg) {
        width: 100%;
        padding-left: 0;
        padding-top: 30px;
        border-left: 0;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
      }

      a {
        .ff-button-wrapper {
          @include max-responsive(xl) {
            width: 234px;
          }
        }
      }

      .ogpo-any-questions__contact-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        margin-bottom: 24px;
        .contact-item__sub {
          display: flex;
          flex-direction: column;

          p:first-of-type {
            color: #fff;
            font-size: 24px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            letter-spacing: 0.2px;
          }
          p:last-of-type {
            color: rgba(255, 255, 255, 0.5);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.2px;
          }
        }
      }
    }
  }
}
.ogpo-any-questions-images {
  position: relative;

  @include max-responsive(lg) {
    display: none;
  }
  
  .ogpo-any-questions__sms-ask-image {
    position: absolute;
    top: -309px;
    left: 34px;
    width: 304px;
    height: 235px;
    z-index: 0;

    @include max-responsive(xxl) {
      position: absolute;
      top: -261px;
      left: -30px;
      width: 250px;
      height: 185px;
      z-index: 0;
    }

    @include max-responsive(xl) {
      position: absolute;
      top: -261px;
      left: -10px;
      width: 250px;
      height: 185px;
      z-index: 0;
    }
  }
}
</style>
