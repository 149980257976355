<script setup lang="ts">
import {storeToRefs} from "pinia"
import {useModalStore} from "@/stores/ModalStore"
import {useAddDriverAutoStore} from "@/stores/AddDriverAutoStore"
import FFOtp from "@/components/FFUI/FFOtp.vue"
import CloseIcon from "@/components/icons/CloseIcon.vue"
import FFButton from "@/components/FFUI/FFButton.vue"

const modalStore = useModalStore()
const addDriverAutoStore = useAddDriverAutoStore()
// addDriverAutoStore.handleAddDriverAutoPayment
const {buttonTitle, otpCode, showOtp, codeIsSent, checkOtpResponse} = storeToRefs(addDriverAutoStore)
</script>

<template>
  <div class="add-badscoring-sms-text">
    <CloseIcon @click="addDriverAutoStore.closeOtpForm" class="add-badscoring-sms-text__close-icon" />
    <FFOtp
      :needWrapper="false"
      :codeIsSent="codeIsSent"
      :buttonTitle="buttonTitle"
      :sendOtp="addDriverAutoStore.handleSendOtp"
      :checkOtp="addDriverAutoStore.checkOtpCode"
      :closeOtp="addDriverAutoStore.closeOtpForm" />
  </div>
</template>

<style scoped lang="scss">
.add-badscoring-sms-text {
  position: relative;
  gap: 50px;
  padding: 30px 20px;
  margin: 0 20px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);
  max-width: 400px;

  .add-badscoring-sms-text__close-icon {
    position: absolute;
    right: 12px;
    top: 14px;
  }

  p {
    color: #fff;
    font-size: 20px;
    text-align: center;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-bottom: 15px;
  }

  div {
    p {
      color: #fff;
      font-size: 16px;
      text-align: start;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
</style>
