<script setup lang="ts">
import {useRouter} from "vue-router"
import {useDeviceSize} from "@/composables/useDeviceSize"

interface IProps {
  width?: string
  height?: string
  mobileWidth?: string
  mobileHeight?: string
}

withDefaults(defineProps<IProps>(), {
  width: "217px",
  height: "65px",
  mobileWidth: "115px",
  mobileHeight: "35px"
})

const router = useRouter()
const {isMobileDevice} = useDeviceSize()
</script>

<template>
  <template v-if="!isMobileDevice">
    <svg class="freedom-white-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 217 65" fill="none">
      <path
        d="M72.3301 31.7721V9.65625H85.9251V11.2838C85.9251 12.9114 84.5847 14.2517 82.9572 14.2517H77.7872V18.943H84.5847V20.4748C84.5847 22.1981 83.2444 23.5385 81.5211 23.5385H77.883V31.7721H72.3301Z"
        fill="white" />
      <path
        d="M94.3497 19.7084H96.7432C97.6048 19.7084 98.275 19.5169 98.8494 19.0382C99.3281 18.5595 99.6154 17.8894 99.6154 17.0277C99.6154 15.7831 99.1367 15.0172 98.275 14.6342C97.7963 14.4428 97.1261 14.347 96.2645 14.347H94.4454V19.7084H94.3497ZM88.9883 31.7716V9.65576H96.7432C98.658 9.65576 100.094 9.84725 101.051 10.2302C102.296 10.7089 103.349 11.5706 104.019 12.6237C104.785 13.7726 105.073 15.1129 105.073 16.6447C105.073 17.8894 104.785 19.0382 104.211 20.1871C103.636 21.336 102.775 22.1019 101.722 22.6763V22.7721C101.913 23.0593 102.2 23.4423 102.583 24.1124L106.892 31.8673H100.86L96.9347 24.3996H94.3497V31.8673H88.9883V31.7716Z"
        fill="white" />
      <path
        d="M109.381 31.7716V9.65576H123.263V14.347H114.742V18.3681H121.54V22.9636H114.742V27.1761H123.646V31.7716H109.381Z"
        fill="white" />
      <path
        d="M127.094 31.7716V9.65576H140.976V14.347H132.551V18.3681H139.348V22.9636H132.551V27.1761H141.455V31.7716H127.094Z"
        fill="white" />
      <path
        d="M150.358 27.1761H152.56C154.474 27.1761 155.91 26.6017 156.964 25.5485C158.017 24.3997 158.591 22.8678 158.591 20.7616C158.591 18.6553 158.017 17.1235 156.964 16.0703C155.91 15.0172 154.379 14.4427 152.56 14.4427H150.358V27.1761ZM144.9 31.7716V9.65576H152.751C156.293 9.65576 159.07 10.6132 161.08 12.6237C163.091 14.5385 164.144 17.3149 164.144 20.7616C164.144 24.2082 163.091 26.9846 161.08 28.8994C159.07 30.9099 156.198 31.8673 152.751 31.8673H144.9V31.7716Z"
        fill="white" />
      <path
        d="M172.092 20.5702C172.092 22.485 172.666 24.0168 173.815 25.3572C174.964 26.6018 176.4 27.272 178.124 27.272C179.847 27.272 181.283 26.6018 182.432 25.3572C183.581 24.1126 184.155 22.485 184.155 20.5702C184.155 18.7512 183.581 17.2193 182.432 16.0705C181.283 14.8258 179.847 14.2514 178.124 14.2514C176.4 14.2514 174.964 14.8258 173.815 16.0705C172.666 17.3151 172.092 18.7512 172.092 20.5702ZM166.539 20.5702C166.539 17.3151 167.592 14.6344 169.794 12.5281C171.996 10.3261 174.773 9.27295 178.124 9.27295C181.474 9.27295 184.251 10.3261 186.453 12.5281C188.655 14.7301 189.708 17.4108 189.708 20.5702C189.708 23.8253 188.655 26.6018 186.453 28.8038C184.251 31.0058 181.474 32.1547 178.124 32.1547C174.773 32.1547 171.996 31.0058 169.794 28.8038C167.592 26.6975 166.539 23.9211 166.539 20.5702Z"
        fill="white" />
      <path
        d="M192.291 31.7716L194.11 9.65576H199.95L203.205 19.0382L204.354 22.6763H204.45C204.833 21.1445 205.216 19.9956 205.599 19.0382L208.854 9.65576H214.694L216.513 31.7716H211.056L210.386 21.8147C210.386 21.336 210.29 20.7615 210.29 20.1871C210.29 19.6127 210.29 19.0382 210.29 18.6553V18.0808H210.194C209.716 19.6127 209.333 20.8573 208.95 21.8147L206.652 28.2292H201.961L199.663 21.8147L198.418 18.0808H198.323C198.418 19.4212 198.418 20.6658 198.323 21.8147L197.652 31.7716H192.291V31.7716Z"
        fill="white" />
      <path d="M72.7129 39.2393H76.5425V55.0363H72.7129V39.2393Z" fill="white" />
      <path
        d="M79.8945 39.2393H83.8199L88.2239 46.6112C88.7983 47.6643 89.5642 49.2919 89.5642 49.2919C89.5642 49.2919 89.3727 47.5686 89.3727 46.6112V39.2393H93.2023V55.0363H89.277L84.873 47.6644C84.2985 46.6112 83.5326 44.9836 83.5326 44.9836C83.5326 44.9836 83.7241 46.707 83.7241 47.6644V55.0363H79.8945V39.2393Z"
        fill="white" />
      <path
        d="M97.5105 50.1536C98.3722 50.9195 99.904 51.7812 101.34 51.7812C102.393 51.7812 102.968 51.3025 102.968 50.6323C102.968 48.4303 95.9787 48.7175 95.9787 43.8348C95.9787 40.9626 98.3722 39.0479 101.723 39.0479C103.446 39.0479 105.265 39.6223 106.702 40.8669L105.074 44.0263C104.212 43.2604 102.776 42.5902 101.627 42.5902C100.478 42.5902 99.904 43.0689 99.904 43.7391C99.904 46.0368 106.893 45.4624 106.893 50.4408C106.893 53.1216 104.882 55.3236 101.244 55.3236C99.2338 55.3236 97.0318 54.5576 95.5 53.0258L97.5105 50.1536Z"
        fill="white" />
      <path
        d="M109.094 39.2393H112.923V49.1962C112.923 50.8237 113.976 51.7811 115.7 51.7811C117.327 51.7811 118.476 50.9195 118.476 49.1962V39.2393H122.306V49.1962C122.306 52.7385 119.721 55.2278 115.7 55.2278C111.679 55.2278 109.094 52.7385 109.094 49.1962V39.2393Z"
        fill="white" />
      <path
        d="M125.465 39.2393H130.922C132.55 39.2393 133.316 39.335 133.986 39.6222C135.805 40.2924 136.858 41.92 136.858 44.2177C136.858 45.941 136.092 47.7601 134.464 48.526C134.464 48.526 134.656 48.8132 135.039 49.4834L138.102 55.0363H133.794L131.018 49.6749H129.199V55.0363H125.369V39.2393H125.465ZM131.018 46.4197C132.262 46.4197 133.028 45.7495 133.028 44.5049C133.028 43.739 132.837 43.0689 132.071 42.7816C131.688 42.5902 131.209 42.5901 130.635 42.5901H129.294V46.4197H131.018Z"
        fill="white" />
      <path
        d="M143.561 39.2393H147.582L152.943 55.0363H148.922L147.965 51.6854H143.082L142.125 55.0363H138.104L143.561 39.2393ZM147.199 48.6217L146.337 45.5581C146.05 44.6007 145.667 42.8774 145.667 42.8774C145.667 42.8774 145.284 44.6007 144.997 45.5581L144.135 48.6217H147.199Z"
        fill="white" />
      <path
        d="M154.666 39.2393H158.591L162.995 46.6112C163.57 47.6643 164.336 49.2919 164.336 49.2919C164.336 49.2919 164.144 47.5686 164.144 46.6112V39.2393H167.974V55.0363H164.048L159.644 47.6644C159.07 46.6112 158.304 44.9836 158.304 44.9836C158.304 44.9836 158.496 46.707 158.496 47.6644V55.0363H154.666V39.2393Z"
        fill="white" />
      <path
        d="M178.697 39.0479C180.803 39.0479 183.101 39.718 184.632 41.2498L182.813 44.122C181.76 43.2604 180.228 42.4945 178.888 42.4945C175.729 42.4945 174.388 44.6965 174.388 46.9942C174.388 49.292 175.824 51.7812 178.888 51.7812C180.42 51.7812 182.047 50.9195 183.1 49.8664L185.111 52.7386C183.675 54.1747 181.664 55.4193 178.792 55.4193C173.718 55.4193 170.559 51.8769 170.559 47.1857C170.463 42.4945 173.814 39.0479 178.697 39.0479Z"
        fill="white" />
      <path
        d="M187.027 39.2393H196.889V42.5901H190.857V45.4623H195.644V48.8132H190.857V51.7811H197.176V55.132H186.932V39.2393H187.027Z"
        fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.5668 43.4619H32.3393C41.4046 43.4619 43.0762 38.5757 43.0762 32.5965V29.0604H22.5668V17.2305H43.0762C51.1771 17.1019 53.8131 12.3442 53.8131 6.36498V17.1019C53.8131 53.0416 28.3531 61.914 28.3531 61.914C28.3531 61.914 25.91 61.2068 22.5668 59.0851V43.4619V43.4619ZM0 17.1662C0 37.3541 7.39367 49.184 13.5658 55.549C20.3808 62.5569 27.2601 64.6786 27.5816 64.7429L28.4817 65L29.3818 64.6785C29.639 64.5499 36.454 62.1711 43.2691 55.0346C49.4412 48.541 56.8348 36.6469 56.8348 17.1662V0H0.0642967V17.1662H0Z"
        fill="white" />
    </svg>
  </template>

  <template v-else>
    <svg class="freedom-white-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 217 65" fill="none">
      <path
        d="M72.3301 31.7721V9.65625H85.9251V11.2838C85.9251 12.9114 84.5847 14.2517 82.9572 14.2517H77.7872V18.943H84.5847V20.4748C84.5847 22.1981 83.2444 23.5385 81.5211 23.5385H77.883V31.7721H72.3301Z"
        fill="white" />
      <path
        d="M94.3497 19.7084H96.7432C97.6048 19.7084 98.275 19.5169 98.8494 19.0382C99.3281 18.5595 99.6154 17.8894 99.6154 17.0277C99.6154 15.7831 99.1367 15.0172 98.275 14.6342C97.7963 14.4428 97.1261 14.347 96.2645 14.347H94.4454V19.7084H94.3497ZM88.9883 31.7716V9.65576H96.7432C98.658 9.65576 100.094 9.84725 101.051 10.2302C102.296 10.7089 103.349 11.5706 104.019 12.6237C104.785 13.7726 105.073 15.1129 105.073 16.6447C105.073 17.8894 104.785 19.0382 104.211 20.1871C103.636 21.336 102.775 22.1019 101.722 22.6763V22.7721C101.913 23.0593 102.2 23.4423 102.583 24.1124L106.892 31.8673H100.86L96.9347 24.3996H94.3497V31.8673H88.9883V31.7716Z"
        fill="white" />
      <path
        d="M109.381 31.7716V9.65576H123.263V14.347H114.742V18.3681H121.54V22.9636H114.742V27.1761H123.646V31.7716H109.381Z"
        fill="white" />
      <path
        d="M127.094 31.7716V9.65576H140.976V14.347H132.551V18.3681H139.348V22.9636H132.551V27.1761H141.455V31.7716H127.094Z"
        fill="white" />
      <path
        d="M150.358 27.1761H152.56C154.474 27.1761 155.91 26.6017 156.964 25.5485C158.017 24.3997 158.591 22.8678 158.591 20.7616C158.591 18.6553 158.017 17.1235 156.964 16.0703C155.91 15.0172 154.379 14.4427 152.56 14.4427H150.358V27.1761ZM144.9 31.7716V9.65576H152.751C156.293 9.65576 159.07 10.6132 161.08 12.6237C163.091 14.5385 164.144 17.3149 164.144 20.7616C164.144 24.2082 163.091 26.9846 161.08 28.8994C159.07 30.9099 156.198 31.8673 152.751 31.8673H144.9V31.7716Z"
        fill="white" />
      <path
        d="M172.092 20.5702C172.092 22.485 172.666 24.0168 173.815 25.3572C174.964 26.6018 176.4 27.272 178.124 27.272C179.847 27.272 181.283 26.6018 182.432 25.3572C183.581 24.1126 184.155 22.485 184.155 20.5702C184.155 18.7512 183.581 17.2193 182.432 16.0705C181.283 14.8258 179.847 14.2514 178.124 14.2514C176.4 14.2514 174.964 14.8258 173.815 16.0705C172.666 17.3151 172.092 18.7512 172.092 20.5702ZM166.539 20.5702C166.539 17.3151 167.592 14.6344 169.794 12.5281C171.996 10.3261 174.773 9.27295 178.124 9.27295C181.474 9.27295 184.251 10.3261 186.453 12.5281C188.655 14.7301 189.708 17.4108 189.708 20.5702C189.708 23.8253 188.655 26.6018 186.453 28.8038C184.251 31.0058 181.474 32.1547 178.124 32.1547C174.773 32.1547 171.996 31.0058 169.794 28.8038C167.592 26.6975 166.539 23.9211 166.539 20.5702Z"
        fill="white" />
      <path
        d="M192.291 31.7716L194.11 9.65576H199.95L203.205 19.0382L204.354 22.6763H204.45C204.833 21.1445 205.216 19.9956 205.599 19.0382L208.854 9.65576H214.694L216.513 31.7716H211.056L210.386 21.8147C210.386 21.336 210.29 20.7615 210.29 20.1871C210.29 19.6127 210.29 19.0382 210.29 18.6553V18.0808H210.194C209.716 19.6127 209.333 20.8573 208.95 21.8147L206.652 28.2292H201.961L199.663 21.8147L198.418 18.0808H198.323C198.418 19.4212 198.418 20.6658 198.323 21.8147L197.652 31.7716H192.291V31.7716Z"
        fill="white" />
      <path d="M72.7129 39.2393H76.5425V55.0363H72.7129V39.2393Z" fill="white" />
      <path
        d="M79.8945 39.2393H83.8199L88.2239 46.6112C88.7983 47.6643 89.5642 49.2919 89.5642 49.2919C89.5642 49.2919 89.3727 47.5686 89.3727 46.6112V39.2393H93.2023V55.0363H89.277L84.873 47.6644C84.2985 46.6112 83.5326 44.9836 83.5326 44.9836C83.5326 44.9836 83.7241 46.707 83.7241 47.6644V55.0363H79.8945V39.2393Z"
        fill="white" />
      <path
        d="M97.5105 50.1536C98.3722 50.9195 99.904 51.7812 101.34 51.7812C102.393 51.7812 102.968 51.3025 102.968 50.6323C102.968 48.4303 95.9787 48.7175 95.9787 43.8348C95.9787 40.9626 98.3722 39.0479 101.723 39.0479C103.446 39.0479 105.265 39.6223 106.702 40.8669L105.074 44.0263C104.212 43.2604 102.776 42.5902 101.627 42.5902C100.478 42.5902 99.904 43.0689 99.904 43.7391C99.904 46.0368 106.893 45.4624 106.893 50.4408C106.893 53.1216 104.882 55.3236 101.244 55.3236C99.2338 55.3236 97.0318 54.5576 95.5 53.0258L97.5105 50.1536Z"
        fill="white" />
      <path
        d="M109.094 39.2393H112.923V49.1962C112.923 50.8237 113.976 51.7811 115.7 51.7811C117.327 51.7811 118.476 50.9195 118.476 49.1962V39.2393H122.306V49.1962C122.306 52.7385 119.721 55.2278 115.7 55.2278C111.679 55.2278 109.094 52.7385 109.094 49.1962V39.2393Z"
        fill="white" />
      <path
        d="M125.465 39.2393H130.922C132.55 39.2393 133.316 39.335 133.986 39.6222C135.805 40.2924 136.858 41.92 136.858 44.2177C136.858 45.941 136.092 47.7601 134.464 48.526C134.464 48.526 134.656 48.8132 135.039 49.4834L138.102 55.0363H133.794L131.018 49.6749H129.199V55.0363H125.369V39.2393H125.465ZM131.018 46.4197C132.262 46.4197 133.028 45.7495 133.028 44.5049C133.028 43.739 132.837 43.0689 132.071 42.7816C131.688 42.5902 131.209 42.5901 130.635 42.5901H129.294V46.4197H131.018Z"
        fill="white" />
      <path
        d="M143.561 39.2393H147.582L152.943 55.0363H148.922L147.965 51.6854H143.082L142.125 55.0363H138.104L143.561 39.2393ZM147.199 48.6217L146.337 45.5581C146.05 44.6007 145.667 42.8774 145.667 42.8774C145.667 42.8774 145.284 44.6007 144.997 45.5581L144.135 48.6217H147.199Z"
        fill="white" />
      <path
        d="M154.666 39.2393H158.591L162.995 46.6112C163.57 47.6643 164.336 49.2919 164.336 49.2919C164.336 49.2919 164.144 47.5686 164.144 46.6112V39.2393H167.974V55.0363H164.048L159.644 47.6644C159.07 46.6112 158.304 44.9836 158.304 44.9836C158.304 44.9836 158.496 46.707 158.496 47.6644V55.0363H154.666V39.2393Z"
        fill="white" />
      <path
        d="M178.697 39.0479C180.803 39.0479 183.101 39.718 184.632 41.2498L182.813 44.122C181.76 43.2604 180.228 42.4945 178.888 42.4945C175.729 42.4945 174.388 44.6965 174.388 46.9942C174.388 49.292 175.824 51.7812 178.888 51.7812C180.42 51.7812 182.047 50.9195 183.1 49.8664L185.111 52.7386C183.675 54.1747 181.664 55.4193 178.792 55.4193C173.718 55.4193 170.559 51.8769 170.559 47.1857C170.463 42.4945 173.814 39.0479 178.697 39.0479Z"
        fill="white" />
      <path
        d="M187.027 39.2393H196.889V42.5901H190.857V45.4623H195.644V48.8132H190.857V51.7811H197.176V55.132H186.932V39.2393H187.027Z"
        fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.5668 43.4619H32.3393C41.4046 43.4619 43.0762 38.5757 43.0762 32.5965V29.0604H22.5668V17.2305H43.0762C51.1771 17.1019 53.8131 12.3442 53.8131 6.36498V17.1019C53.8131 53.0416 28.3531 61.914 28.3531 61.914C28.3531 61.914 25.91 61.2068 22.5668 59.0851V43.4619V43.4619ZM0 17.1662C0 37.3541 7.39367 49.184 13.5658 55.549C20.3808 62.5569 27.2601 64.6786 27.5816 64.7429L28.4817 65L29.3818 64.6785C29.639 64.5499 36.454 62.1711 43.2691 55.0346C49.4412 48.541 56.8348 36.6469 56.8348 17.1662V0H0.0642967V17.1662H0Z"
        fill="white" />
    </svg>
  </template>
</template>

<style scoped lang="scss">
.freedom-white-logo {
  width: v-bind(width);
  height: v-bind(height);
  cursor: pointer;

  @include max-responsive(lg) {
    width: v-bind(mobileWidth);
    height: v-bind(mobileHeight);
  }
}
</style>
