import {defineStore, storeToRefs} from "pinia"
import {ref, computed} from "vue"
import {useI18n} from "vue-i18n"
import {useModalStore} from "@/stores/ModalStore"
import {useHelperStore} from "@/stores/HelperStore"
import {useGatewayFetcher} from "@/composables/useGatewayFetcher"
import {useFormatters} from "@/utils/formatters"
import {useHelpers} from "@/composables/useHelpers"
import {useToast} from "vue-toastification"
import {throttle} from "lodash-es"
import {type ITheme} from "@/interfaces/Hotline/ITheme"
import FFOtp from "@/components/FFUI/FFOtp.vue"

export const useChairmanStore = defineStore("chairmanStore", () => {
  const helperStore = useHelperStore()
  const {recaptchaToken} = storeToRefs(helperStore)
  const {isRecaptchaVerified} = storeToRefs(helperStore)
  const modalStore = useModalStore()

  const {getData, postData, loading} = useGatewayFetcher()
  const {currentLocale} = useHelpers()
  const {t} = useI18n()
  const {phoneNumberFormatter} = useFormatters()
  const toast = useToast()

  const chairmanForm = ref({
    fullName: "",
    phoneNumber: "",
    email: "",
    theme: null,
    question: "",
    file: null
  })

  const resetChairmanForm = () => {
    chairmanForm.value = {
      fullName: "",
      phoneNumber: "",
      email: "",
      theme: null,
      question: "",
      file: null
    }
  }

  const themes = ref<ITheme[] | unknown>([])
  const getThemes = async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await getData("/chairman/question-types", config)
      if (response) {
        themes.value = response
      }
    } catch (error) {
      console.error(error)
    }
  }

  //OTP
  const otpCode = ref<string | number>()
  const showOtp = ref<boolean>(false)
  const codeIsSent = ref<boolean>(false)
  const sentOtpResponse = ref<{message: string; status: boolean}>()
  const timer = ref(0)

  const handleOtpFormOpen = async () => {
    const otpProps = {
      codeIsSent: codeIsSent,
      buttonTitle: buttonTitle,

      sendOtp: handleSendOtp,
      checkOtp: checkOtpCode,
      closeOtp: closeOtpForm
    }
    modalStore.openModal({
      component: FFOtp,
      props: otpProps
    })
  }

  const buttonTitle = computed(() => {
    if (checkOtpResponse.value?.status) {
      return t("app.buttons.sendCode")
    } else if (timer.value > 0) {
      const minutes = Math.floor(timer.value / 60)
      const seconds = timer.value % 60
      return `${t("app.buttons.sendCodeAgainThrough.text-1")} ${minutes}:${seconds.toString().padStart(2, "0")}`
    }
    return t("app.buttons.sendCode")
  })

  const startTimer = () => {
    timer.value = 90
    const interval = setInterval(() => {
      timer.value--
      if (timer.value <= 0) {
        clearInterval(interval)
        codeIsSent.value = false
      }
    }, 1000)
  }

  const handleSendOtp = throttle(async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value,
        "Content-Type": "multipart/form-data"
      }
    }
    try {
      const response = await postData(
        "/chairman/send-otp-chairman",
        {
          phone: phoneNumberFormatter(chairmanForm.value.phoneNumber),
          recaptcha_token: recaptchaToken.value
        },
        config
      )
      if (response?.status === 200) {
        handleOtpFormOpen()
        startTimer()
        isRecaptchaVerified.value = false
        codeIsSent.value = true
        showOtp.value = true
        sentOtpResponse.value = response?.data?.data
      } else {
        otpCode.value = ""
        codeIsSent.value = false
        showOtp.value = false
      }
    } catch (error) {
      isRecaptchaVerified.value = false
      console.error(error)
    }
  }, 3000)

  const checkOtpResponse = ref()
  const responseIsSent = ref<boolean>(false)
  const checkOtpCode = throttle(async (code: string | number) => {
    try {
      otpCode.value = code
      const formData = new FormData()
      formData.append("phone", phoneNumberFormatter(chairmanForm.value.phoneNumber))
      formData.append("otpCode", otpCode.value.toString())
      formData.append("fullname", chairmanForm.value.fullName)
      formData.append("email", chairmanForm.value.email)
      formData.append("type_id", chairmanForm.value.theme.id)
      formData.append("question", chairmanForm.value.question)
      formData.append("file", chairmanForm.value.file)

      const response = await postData("/chairman/verify-and-send", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Accept-Language": currentLocale.value
        }
      })

      if (response?.status === 200 && response?.data) {
        showOtp.value = false
        codeIsSent.value = false
        responseIsSent.value = true
        checkOtpResponse.value = response?.data?.data
        resetChairmanForm()
        closeOtpForm()
        toast.success(response.data.message)
        return true
      } else {
        return false
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }, 3000)

  const closeOtpForm = () => {
    showOtp.value = false
    codeIsSent.value = false
    timer.value = 0
    modalStore.closeModal()
  }

  return {
    loading,
    chairmanForm,
    themes,
    responseIsSent,
    getThemes,
    handleSendOtp
  }
})
