<script setup lang="ts"></script>

<template>
  <div class="policy-vms__block-row">
    <div class="policy-vms__left-row">
      <slot name="icon" />
      <p class="policy-vms__title">
        <slot name="title" />
      </p>
    </div>
    <p class="policy-vms__paragraph">
      <slot name="paragraph" />
    </p>
  </div>
</template>

<style scoped lang="scss">
.policy-vms__block-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .policy-vms__left-row {
    display: flex;
    align-items: center;
    gap: 10px;

    .policy-vms__title {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: 0.2px;
      background: linear-gradient(132deg, #2f4d3b -0.69%, #162522 103.63%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .policy-vms__paragraph {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: 0.2px;
    background: linear-gradient(132deg, #2f4d3b -0.69%, #162522 103.63%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: $lg) {
      max-width: 100px;
    }
  }
}
</style>
