<script setup lang="ts">
import {ref, onUnmounted} from "vue"
import {storeToRefs} from "pinia"
import {useHelperStore} from "@/stores/HelperStore"
import {RecaptchaV2} from "vue3-recaptcha-v2"

const helperStore = useHelperStore()
const {isRecaptchaVerified, recaptchaToken, widgetId} = storeToRefs(helperStore)

const handleLoadCallback = (response: string) => {
  recaptchaToken.value = response
  isRecaptchaVerified.value = true
}

const handleErrorCallback = () => {
  isRecaptchaVerified.value = false
}

const handleExpiredCallback = () => {
  recaptchaToken.value = null
  isRecaptchaVerified.value = false
}

const handleWidgetId = (_widgetId: number) => {
  widgetId.value = _widgetId
}

onUnmounted(() => {
  isRecaptchaVerified.value = false
  recaptchaToken.value = null
  widgetId.value = null
})
</script>

<template>
  <RecaptchaV2
    @widget-id="handleWidgetId"
    @error-callback="handleErrorCallback"
    @expired-callback="handleExpiredCallback"
    @load-callback="handleLoadCallback" />
</template>

<style scoped lang="scss"></style>
