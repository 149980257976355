<script setup lang="ts">
import {useRouter} from "vue-router"
import {useHelpers} from "@/composables/useHelpers"
import FFButton from "@/components/FFUI/FFButton.vue"

const {openLink} = useHelpers()
const router = useRouter()
</script>

<template>
  <div class="bg-image">
    <div class="container">
      <div class="fail-wrapper">
        <div class="fail">
          <img src="/fail-pay-front/images/fail-cancel.png" alt="" />
          <p>{{ $t("app.failBiometricsPage.title") }}</p>
          <p>{{ $t("app.failBiometricsPage.subtitle") }}</p>

          <FFButton
            @click="router.push('/pages/personal-profile')"
            class="fail-message__button"
            :title="$t('app.thankYou.login')"
            :uppercase="false"
            color="#4F9D3A"
            paddingx="15px"
            paddingy="30px"
            width="300px"
            border-pixel="1px solid #fff"
            background="#FFF" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.bg-image {
  background: url("/success-pay-front/images/fail-pay-bg.png") no-repeat top;
}
.fail-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 107px 0;

  .fail {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    img {
      width: 210px;
      height: 210px;
    }
    p:first-of-type {
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 900;
      line-height: 100%;
      background: linear-gradient(103deg, #bde0ff -2.91%, #1cd491 58.89%, #00ff45 106.95%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    p:last-of-type {
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      background: linear-gradient(103deg, #bde0ff -2.91%, #1cd491 58.89%, #00ff45 106.95%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .fail-message__button {
    }
  }
}

@media (max-width: $lg) {
  .thank-you {
    padding: 50px 0;
    gap: 50px;
  }
}
</style>
