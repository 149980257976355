<script setup lang="ts">
import {ref, onMounted, computed} from "vue"
import {useNewsStore} from "@/stores/NewsStore"
import {useDeviceSize} from "@/composables/useDeviceSize"
import {useHelpers} from "@/composables/useHelpers"
import {kaskoUrl} from "@/constants/urls"
import {useI18n} from "vue-i18n"
import MainSwiper from "@/components/Main/MainSwiper.vue"
import MainCards from "@/components/Main/MainCards.vue"
import MainGuarantee from "@/components/Main/MainGuarantee.vue"
import MainStatistics from "@/components/Main/MainStatistics.vue"
import MainAbout from "@/components/Main/MainAbout.vue"
import MainAwards from "@/components/Main/MainAwards.vue"
import MainNews from "@/components/Main/MainNews.vue"

const newsStore = useNewsStore()
const {currentLocale, openLink} = useHelpers()
const {isMobileDevice, isTablet} = useDeviceSize()
const {t} = useI18n()

onMounted(async () => {
  await newsStore.getNews(4, 1)
})

const sliderData = ref([
  {
    title1: computed(() => t("main.banner-bank-cashback.title-1")),
    title2: computed(() => t("main.banner-bank-cashback.title-2")),
    paragraph1: computed(() => t("main.banner-bank-cashback.paragraph-1")),
    paragraph2: computed(() => t("main.banner-bank-cashback.paragraph-2")),
    imageDesktop: "/main-front/images/slider-image-bank-cashback-desk.jpg",
    imageTablet: "/main-front/images/slider-image-bank-cashback-tablet.jpg",
    imageMobile: "/main-front/images/slider-image-bank-cashback-mobile.jpg"
  },
  {
    license: computed(() => t("main.banner-dtp-earn-money.license")),
    title: computed(() => t("main.banner-dtp-earn-money.title")),
    description: computed(() => t("main.banner-dtp-earn-money.description")),
    remark: computed(() => t("main.banner-dtp-earn-money.remark")),
    image: "/main-front/images/slider-image-dtp-earn-money.png",
  },
  {
    title: computed(() => t("main.banner-dtp.title")),
    subTitle: computed(() => t("main.banner-dtp.subtitle")),
    description: computed(() => t("main.banner-dtp.description")),
    btnTitle: computed(() => t("main.banner-dtp.button")),
    image: "/main-front/images/slider-image-dtp.jpg",
    to: `https://dtp.kz`
  },
  {
    title: computed(() => t("main.banner-ogpo.title")),
    subTitle: computed(() => t("main.banner-ogpo.subtitle")),
    description: computed(() => t("main.banner-ogpo.description")),
    btnTitle: computed(() => t("main.banner-ogpo.button")),
    image: "/main-front/images/slider-image-ogpo.jpg",
    to: "pages/ogpo"
  },
  {
    title: computed(() => t("main.banner-ogpoPlus.title")),
    subTitle: computed(() => t("main.banner-ogpoPlus.subtitle")),
    description: computed(() => t("main.banner-ogpoPlus.description")),
    btnTitle: computed(() => t("main.banner-ogpoPlus.button")),
    image: "/main-front/images/slider-image-ogpoPlus.jpg",
    to: "pages/ogpo-plus"
  },
  {
    title: computed(() => t("main.banner-comfort.title")),
    subTitle: computed(() => t("main.banner-comfort.subtitle")),
    description: computed(() => t("main.banner-comfort.description")),
    btnTitle: computed(() => t("main.banner-comfort.button")),
    image: "/main-front/images/slider-image-comfort.jpg",
    to: "pages/comfort"
  },
  {
    title: computed(() => t("main.banner-homeProtect.title")),
    subTitle: computed(() => t("main.banner-homeProtect.subtitle")),
    description: computed(() => t("main.banner-homeProtect.description")),
    btnTitle: computed(() => t("main.banner-homeProtect.button")),
    image: "/main-front/images/slider-image-homeProtect.jpg",
    to: "pages/home-protect"
  },
  {
    title: computed(() => t("main.banner-kasko.title")),
    subTitle: computed(() => t("main.banner-kasko.subtitle")),
    description: computed(() => t("main.banner-kasko.description")),
    btnTitle: computed(() => t("main.banner-kasko.button")),
    image: "/main-front/images/slider-image-kasko.jpg",
    to: `pages/kasko`
  },
  {
    title: computed(() => t("main.banner-mst.title")),
    subTitle: "",
    description: "",
    btnTitle: computed(() => t("main.banner-mst.button")),
    image: "/main-front/images/slider-image-mst.jpg",
    to: "pages/mst"
  }
])

const cardsData = computed(() => {
  return [
    {
      title: "main.ogpoCard.title",
      product: "OGPO",
      subTitle: "",
      image: "/main-front/images/ogpoCard.png",
      gradientTitle: false,
      background: "linear-gradient(132deg, #71c35a -0.7%, #254709 103.63%)",
      backdropFilter: false,
      to: "pages/ogpo"
    },
    {
      title: "main.ogpoPlusCard.title",
      product: "OGPO-PLUS",
      subTitle: "main.ogpoPlusCard.subTitle",
      image: "/main-front/images/ogpo-plus-card.png",
      gradientTitle: true,
      background: "#2c3829",
      backdropFilter: true,
      to: "pages/ogpo-plus"
    },
    {
      title: "main.kaskoCard.title",
      product: "KASKO",
      subTitle: "",
      image: "/main-front/images/kaskoCard.png",
      gradientTitle: true,
      background: "#2c3829",
      backdropFilter: true,
      to: "pages/kasko"
    },
    {
      title: "main.comfortCard.title",
      product: "COMFORT",
      subTitle: "main.comfortCard.subTitle",
      image: "/main-front/images/comfortCard.png",
      gradientTitle: true,
      background: "#2c3829",
      backdropFilter: true,
      to: "pages/comfort"
    },
    {
      title: "main.homeProtectCard.title",
      product: "HOME-PROTECT",
      subTitle: "main.homeProtectCard.subTitle",
      image: "/main-front/images/homeProtectCard.png",
      gradientTitle: true,
      background: "#2c3829",
      backdropFilter: true,
      to: "pages/home-protect"
    },
    {
      title: "main.mstCard.title",
      product: "MST",
      subTitle: "main.mstCard.subTitle",
      image: "/main-front/images/mstCard.png",
      gradientTitle: true,
      background: "#2c3829",
      backdropFilter: true,
      to: "pages/mst-remastered"
    }
  ]
})
</script>

<template>
  <div class="bg-black relative">
    <img
      src="/main-front/images/green-kz-area.png"
      data-src="/main-front/images/green-kz-area.png"
      alt=""
      class="main-page__green-kz-area" />

    <img
      src="/main-front/images/mobile-high-green-bg.png"
      data-src="/main-front/images/mobile-high-green-bg.png"
      alt=""
      class="main-page__mobile-high-green-bg" />

    <img
      src="/main-front/images/mobile-high-green-bg.png"
      data-src="/main-front/images/mobile-high-green-bg.png"
      alt=""
      class="main-page__mobile-small-green-bg" />

    <img
      src="/main-front/images/car-rides-road-among-forest-tablet.png"
      data-src="/main-front/images/car-rides-road-among-forest-tablet.png"
      alt=""
      class="main-page__car-rides-road-among-forest"
      :class="
        currentLocale === 'kk' ? 'main-page__car-rides-road-among-forest-kk' : 'main-page__car-rides-road-among-forest'
      " />
    <div class="main-page__first-part">
      <MainSwiper class="pt-[50px]" :data="sliderData" />
      <MainCards class="pt-[50px] pb-[100px]" :data="cardsData" />
      <MainGuarantee class="pb-[100px]" />
    </div>
    <div class="main-page__second-part">
      <MainStatistics />
      <MainAbout class="pt-[100px] pb-[400px]" />
      <MainAwards />
    </div>
    <MainNews class="py-[100px] !bg-black" />
    <div class="flex justify-center pb-[50px]">
      <span class="text-[#A9A9A9] text-[10px]">*{{ $t("main.lowTarifs") }}</span>
      <span
        @click="
          openLink(
            `https://www.gov.kz/memleket/entities/ardfm/press/news/details/654085?lang=${currentLocale}`,
            '_self'
          )
        "
        class="text-[#7064FF] text-[10px] underline underline-offset-[1.5px] ml-[5px] cursor-pointer"
        >{{ $t("main.moreAbout") }}</span
      >
    </div>

    <div class="main-page__xl-background-image"></div>
  </div>
</template>

<style scoped lang="scss">
.main-page__green-kz-area {
  display: none;
}
.main-page__mobile-high-green-bg {
  display: none;
}
.main-page__mobile-small-green-bg {
  display: none;
}
.main-page__car-rides-road-among-forest {
  display: none;
}
.main-page__first-part {
  background-image: url("/main-front/images/sliderBg.png");
  background-repeat: no-repeat;
  background-position: top;
}
.main-page__second-part {
  background-image: url("/main-front/images/kzmap_road-bg.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}
@media (max-width: $xl) {
  .main-page__first-part,
  .main-page__second-part {
    background-image: none;
    position: relative;
    z-index: 1;
  }
  .main-page__green-kz-area {
    display: flex;
    position: absolute;
    top: 2200px;
    left: 0;
    right: 0;
    width: 100%;
    height: 422px;
    object-fit: cover;
    z-index: 0;
  }
  .main-page__car-rides-road-among-forest {
    display: flex;
    position: absolute;
    top: 3800px;
    left: 0;
    right: 0;
    width: 100%;
    height: 440px;
    object-fit: cover;
    z-index: 0;
  }
  .main-page__car-rides-road-among-forest-kk {
    display: flex;
    position: absolute;
    top: 3800px;
    left: 0;
    right: 0;
    width: 100%;
    height: 440px;
    object-fit: cover;
    z-index: 0;
  }
}

@media (max-width: $lg) {
  .main-page__green-kz-area {
    display: flex;
    position: absolute;
    top: 2400px;
    left: 0;
    right: 0;
    width: 100%;
    height: 313.699px;
    object-fit: cover;
    z-index: 0;
  }
  .main-page__mobile-high-green-bg {
    display: flex;
    position: absolute;
    top: 3000px;
    left: 0;
    right: 0;
    width: 100%;
    height: 1803px;
    -o-object-fit: cover;
    object-fit: cover;
    z-index: 0;
  }
  .main-page__mobile-small-green-bg {
    display: flex;
    position: absolute;
    top: -100px;
    left: 0;
    right: 0;
    width: 100%;
    height: 1303px;
    object-fit: cover;
    z-index: 0;
  }
  .main-page__car-rides-road-among-forest {
    display: flex;
    position: absolute;
    top: 4720px;
    left: 0;
    right: 0;
    width: 100%;
    object-fit: cover;
    z-index: 0;
  }
  .main-page__car-rides-road-among-forest-kk {
    display: flex;
    position: absolute;
    top: 3800px;
    left: 0;
    right: 0;
    width: 100%;
    height: 440px;
    object-fit: cover;
    z-index: 0;
  }
}

@media (min-width: 100px) and (max-width: $sm) {
  .main-page__car-rides-road-among-forest {
    display: flex;
    position: absolute;
    top: 4840px;
    left: 0;
    right: 0;
    width: 100%;
    object-fit: cover;
    z-index: 0;
  }
  .main-page__car-rides-road-among-forest-kk {
    display: flex;
    position: absolute;
    top: 3800px;
    left: 0;
    right: 0;
    width: 100%;
    height: 440px;
    object-fit: cover;
    z-index: 0;
  }
}

@media (min-width: $sm) and (max-width: $md) {
  .main-page__car-rides-road-among-forest {
    display: flex;
    position: absolute;
    top: 4840px;
    left: 0;
    right: 0;
    width: 100%;
    object-fit: cover;
    z-index: 0;
  }
  .main-page__car-rides-road-among-forest-kk {
    display: flex;
    position: absolute;
    top: 3800px;
    left: 0;
    right: 0;
    width: 100%;
    height: 440px;
    object-fit: cover;
    z-index: 0;
  }
}

@media (min-width: $md) and (max-width: $lg) {
  .main-page__car-rides-road-among-forest {
    display: flex;
    position: absolute;
    top: 4780px;
    left: 0;
    right: 0;
    width: 100%;
    object-fit: cover;
    z-index: 0;
  }
  .main-page__car-rides-road-among-forest-kk {
    display: flex;
    position: absolute;
    top: 4845px;
    left: 0;
    right: 0;
    width: 100%;
    object-fit: cover;
    z-index: 0;
  }
}
</style>
