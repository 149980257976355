<script setup lang="ts">
import {ref, watch} from "vue"
import {useFormatters} from "@/utils/formatters"
import GreenAddPlusIcon from "@/components/icons/greens/GreenAddPlusIcon.vue"
import AddCircleAddPlusIcon from "@/components/icons/AddCircleAddPlusIcon.vue"
import GreenSuccessTickIconVue from "@/components/icons/greens/GreenSuccessTickIcon.vue"
import DeleteFileIcon from "@/components/icons/DeleteFileIcon.vue"

type TComponentAppearanceType = "firstType" | "secondType"

interface Props {
  title?: string
  inputId: string
  fileTypes?: string
  isFileUploaded?: boolean
  hasFileInputFormats?: boolean
  componentAppearanceType?: TComponentAppearanceType
}

const props = withDefaults(defineProps<Props>(), {
  title: "",
  fileTypes: "*.jpg *.png *.pdf",
  isFileUploaded: false,
  hasFileInputFormats: true,
  componentAppearanceType: "firstType"
})

const {trimFileName} = useFormatters()

const file = defineModel()
const fileName = ref<string>("")

const openInput = () => {
  document.getElementById(props.inputId)?.click()
}

const onFileChange = (event: Event) => {
  const target = event.target as HTMLInputElement
  if (!target.files?.length) return
  file.value = target.files[0]
  fileName.value = target.files[0]?.name
}

const handleFileDeletion = () => {
  file.value = null
  fileName.value = ""
  document.getElementById(props.inputId).value = ""
}
</script>
<template>
  <div v-if="componentAppearanceType === 'firstType'" class="file-input">
    <p class="file-input__title">{{ title }}</p>
    <div class="file-input__file">
      <div class="file-input__file--plus" @click="openInput">
        <template v-if="isFileUploaded">
          <GreenSuccessTickIconVue />
        </template>

        <template v-else>
          <GreenAddPlusIcon />
        </template>
      </div>
      <input
        :id="inputId"
        type="file"
        accept="image/png, image/jpeg, image/jpg, application/pdf"
        @change="onFileChange"
        class="hidden" />
      <p class="file-input__file--name">
        {{ !fileName ? $t("app.declareInsuredEvent.noChoosenFile") : trimFileName(fileName, 23) }}
      </p>
      <div v-if="fileName" @click="handleFileDeletion" class="file-input__file--delete">
        <DeleteFileIcon />
      </div>
    </div>
    <template v-if="hasFileInputFormats">
      <p class="file-input__formats">{{ fileTypes }}</p>
    </template>
  </div>

  <div v-if="componentAppearanceType === 'secondType'" @click="openInput" class="second-file-input">
    <div class="second-file-input__content">
      <p class="second-file-input__title">{{ title }}</p>
      <p v-if="file" class="second-file-input__name">
        {{ trimFileName(fileName, 23) }}
      </p>
    </div>
    <input
      :id="inputId"
      type="file"
      accept="image/png, image/jpeg, image/jpg, application/pdf"
      @change="onFileChange"
      class="hidden" />
    <AddCircleAddPlusIcon />
  </div>
</template>
<style lang="scss" scoped>
.file-input {
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
  &__title {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    opacity: 0.6;
  }
  &__file {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    &--plus {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 40px;
      min-height: 40px;
      border-radius: 5px;
      background: #fff;
      box-shadow:
        -20px 20px 40px 0px rgba(0, 0, 0, 0.1),
        0px 0px 40px 0px rgba(0, 0, 0, 0.05);
      cursor: pointer;
      transition: all 0.3s ease;
    }
    &--plus:hover {
      background: gainsboro;
    }
    &--name {
      color: #c9c9c9;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border-bottom: 1px solid #b7b7b7;
      padding-bottom: 7px;
      width: 100%;
    }
    &--delete {
      position: absolute;
      right: 0;
      bottom: 12px;
      cursor: pointer;
    }
  }
  &__formats {
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.second-file-input {
  @include between;
  gap: 15px;
  position: relative;
  padding: 16px 14px;
  border-radius: 10px;
  background: rgba(74, 74, 74, 0.2);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  cursor: pointer;
  
  @include max-responsive(lg) {
    width: 100%;
  }

  .second-file-input__content {
    @include column;
    gap: 7px;
    .second-file-input__title {
      color: var(--color-white);
      font-size: 12px;
      font-style: normal;
      font-weight: 900;
      line-height: 14.14px;
    }
    .second-file-input__name {
      color: var(--color-white);
      font-size: 14px;
    }
  }

  input {
    position: absolute;
    inset: 0;
    background: red;
    z-index: 1;
  }
}
</style>
