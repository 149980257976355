<script setup lang="ts">
import {useSlots} from "vue"

interface Props {
  title?: string
  modelValue: boolean
  locked?: boolean
  fontSize?: string
  fontWeight?: string
  isCheckboxBlack?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  locked: false,
  fontSize: "12px",
  fontWeight: "700",
  isCheckboxBlack: false
})
const emit = defineEmits(["update:modelValue", "update"])
const slots = useSlots()

function toggle() {
  if (!props.locked) {
    emit("update:modelValue", !props.modelValue)
    emit("update")
  }
}
</script>

<template>
  <div class="ff-checkbox">
    <div @click="toggle">
      <svg
        id="svg"
        v-if="modelValue"
        width="26"
        height="24"
        viewBox="0 0 26 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19 5V19H5V5H19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z"
          :fill="isCheckboxBlack ? '#000000' : '#ffffff'" />
        <path
          d="M19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.11 21 21 20.1 21 19V5C21 3.9 20.11 3 19 3ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
          :fill="isCheckboxBlack ? '#000000' : '#ffffff'" />
      </svg>

      <svg
        id="notactive"
        v-else
        width="26"
        height="24"
        viewBox="0 0 26 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19 5V19H5V5H19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z"
          :fill="isCheckboxBlack ? '#000000' : '#ffffff'" />
      </svg>
    </div>

    <div v-if="slots.title" class="ff-checkbox__title">
      <slot name="title"></slot>
    </div>
    <div v-else class="ff-checkbox__title">
      {{ title }}
    </div>
  </div>
</template>
<style scoped lang="scss">
.ff-checkbox {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 4px;

  div:first-of-type {
    cursor: pointer;
  }

  &__title {
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: v-bind(fontWeight);
    line-height: 16px;
    cursor:default;
  }
}
</style>
