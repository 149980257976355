<script setup lang="ts">
import SearchIcon from "@/components/icons/SearchIcon.vue"
import {ref} from "vue"
import {useHelpers} from "@/composables/useHelpers"

const {openLink} = useHelpers()

const searchText = ref<string | null>(null)

const doSearch = () => {
  openLink(`https://ffins.kz/search?q=${searchText.value}`, "_self")
}
</script>

<template>
  <form @submit.prevent="doSearch" class="search">
    <input v-model="searchText" :placeholder="$t('app.navigation.search_toggle')" type="text" class="search__input" />
    <SearchIcon class="search__icon" v-if="!searchText" />
  </form>
</template>

<style scoped lang="scss">
.search {
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: #fff;
  width: 100%;
  color: #7d7d7d;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;

  padding: 0 10px;

  &__input {
    width: 100%;
    outline: none;
    border: none;
  }

  &__input::placeholder {
    color: #7d7d7d;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
  }

  &__input:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &__icon {
  }
}
</style>
