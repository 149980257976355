<script setup lang="ts">
import {ref, onMounted, computed} from "vue"
import {storeToRefs} from "pinia"
import {useI18n} from "vue-i18n"
import {useAddDriverAutoStore} from "@/stores/AddDriverAutoStore"
import {useModalStore} from "@/stores/ModalStore"
import {usePaymentStore} from "@/stores/Payment"
import {useHelpers} from "@/composables/useHelpers"
import {useFormatters} from "@/utils/formatters"
import {
  whatsAppAddDriverAutoMessageRu,
  whatsAppAddDriverAutoMessageKk,
  whatsAppAddDriverAutoMessageEn
} from "@/constants/whatsAppMessages"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFCheckbox from "@/components/FFUI/FFCheckbox.vue"
import FFTooltip from "@/components/FFUI/FFTooltip.vue"
import AddBadScoringSmsText from "@/components/AddDriverAuto/AddBadScoringSmsText.vue"
import FFWhatsAppAsk from "@/components/FFUI/FFWhatsAppAsk.vue"

const emit = defineEmits(["payment", "next", "toggleTooltip"])

interface Props {
  showAmountOfInsurancePremium?: boolean
  showPremiumSum?: boolean
  showDescription?: boolean
  sumText?: string
  sum?: string
  showSumTooltip?: boolean
  tooltipText?: string
  premiumSum?: string
  disabled?: boolean
  showCheckboxSection?: boolean
  showFirstCheckboxSection?: boolean
  showSecondCheckboxSection?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showPremiumSum: true,
  showDescription: false,
  disabled: false,
  showAmountOfInsurancePremium: false,
  showCheckboxSection: true,
  showFirstCheckboxSection: false,
  showSecondCheckboxSection: false,
  showSumTooltip: false
})

onMounted(() => {
  scrollToTop()
})

const addDriverAutoStore = useAddDriverAutoStore()
const paymentStore = usePaymentStore()
const modalStore = useModalStore()
const {iAgreeState, currentStep, isDriverAutoSumCalculated, calculationResult, isBadScoring} =
  storeToRefs(addDriverAutoStore)
const {openLink, currentLocale, scrollToTop} = useHelpers()
const {thousandSeparator} = useFormatters()
const {t} = useI18n()

const toggleTooltipState = ref<boolean>(false)
const toggleTooltipShow = () => {
  toggleTooltipState.value = !toggleTooltipState.value
}

const handleSubmition = async () => {
  if (!props.disabled) {
    if (currentStep.value === 2) {
      await addDriverAutoStore.handleFinalPayment()
    } else {
      emit("payment")
      emit("next")
    }
  }
}

const handleStepBack = () => {
  addDriverAutoStore.resetCalcStates()
}

const toggleIAgreeState = () => {
  iAgreeState.value = !iAgreeState.value

  if (iAgreeState.value && isBadScoring.value) {
    modalStore.openModal({
      component: AddBadScoringSmsText
    })
  }
}

const sumTextText = computed(() => {
  return isBadScoring.value
    ? `${t("app.paymentPage.sumToBeRefund")}:`
    : calculationResult?.value.refundSum !== 0 && calculationResult?.value.surchargeSum === 0 && !isBadScoring.value
      ? `${t("app.paymentPage.sumToBeRefund")}:`
      : `${t("app.paymentPage.sumToBeSurcharge")}:`
})

const totalSum = computed(() => {
  return isBadScoring.value
    ? `${calculationResult?.value.refundSum} ${t("form.formAbbreviations.tg")}`
    : calculationResult?.value.refundSum !== 0 && calculationResult?.value.surchargeSum === 0 && !isBadScoring.value
      ? `${calculationResult?.value.refundSum} ${t("form.formAbbreviations.tg")}`
      : `${calculationResult?.value.surchargeSum} ${t("form.formAbbreviations.tg")}`
})

const calculateCostButtonTitle = computed(() => {
  return !isDriverAutoSumCalculated.value
    ? t("app.buttons.calculateCost")
    : isBadScoring.value
      ? `${t("app.buttons.pay")} ${thousandSeparator(calculationResult?.value.premium)} ${t("form.formAbbreviations.tg")}`
      : calculationResult?.value.refundSum !== 0 && calculationResult?.value.surchargeSum === 0 && !isBadScoring.value
        ? `${t("app.buttons.sumToBeRefund")} ${thousandSeparator(calculationResult?.value.refundSum)} ${t("form.formAbbreviations.tg")}`
        : `${t("app.buttons.sumToBeSurcharge")} ${thousandSeparator(calculationResult?.value.surchargeSum)} ${t("form.formAbbreviations.tg")}`
})
</script>

<template>
  <div class="payment">
    <template v-if="showPremiumSum">
      <div class="payment__sum">
        <div class="payment__sum--premium">
          <p>{{ $t("app.paymentPage.insurancePremiumUnderNewContract") }}:</p>
          <p>
            {{
              calculationResult?.premium
                ? thousandSeparator(`${calculationResult.premium} ${$t("form.formAbbreviations.tg")}`)
                : ""
            }}
          </p>
        </div>
        <div class="payment__sum--total">
          <template v-if="showSumTooltip">
            <div>
              <p>{{ sumTextText }}</p>
              <img @click="toggleTooltipShow" src="/icons-front/info-black-icon.svg" alt="" />
              <div v-if="toggleTooltipState">
                <FFTooltip
                  @closeTooltip="toggleTooltipState = false"
                  :isBlack="true"
                  top="25px"
                  :description="tooltipText" />
              </div>
            </div>
            <p>{{ thousandSeparator(totalSum) }}</p>
          </template>
          <template v-else>
            <p>{{ sumTextText }}</p>
            <p>{{ thousandSeparator(totalSum) }}</p>
          </template>
        </div>
      </div>
      <div class="payment__line"></div>
      <div v-if="showCheckboxSection" class="payment__agreement-checkbox">
        <div>
          <FFCheckbox
            font-weight="400"
            :isCheckboxBlack="true"
            :modelValue="iAgreeState"
            @update:modelValue="toggleIAgreeState" />
        </div>
        <template v-if="currentLocale === 'kk'">
          <p>
            <span @click="openLink('https://ffins.kz/kk/report-file/228', '_blank')">{{
              $t("app.paymentPage.agreeWithInsuranceRulesAndFamiliarWithAgreement-1")
            }}</span>
            {{ $t("app.paymentPage.agreeWithInsuranceRulesAndFamiliarWithAgreement-2") }}
            {{ $t("app.paymentPage.agreeWithInsuranceRulesAndFamiliarWithAgreement-3") }}
            <span @click="() => addDriverAutoStore.openAddDriverQuestionnairePdf(calculationResult?.calcId)">
              {{ $t("app.paymentPage.agreeWithInsuranceRulesAndFamiliarWithAgreement-4") }}
            </span>
            {{ $t("app.paymentPage.agreeWithInsuranceRulesAndFamiliarWithAgreement-5") }}
          </p>
        </template>
        <template v-else>
          <p>
            {{ $t("app.paymentPage.agreeWithInsuranceRulesAndFamiliarWithAgreement-1") }}
            <span @click="openLink('https://ffins.kz/ru/report-file/228', '_blank')">{{
              $t("app.paymentPage.agreeWithInsuranceRulesAndFamiliarWithAgreement-2")
            }}</span>
            {{ $t("app.paymentPage.agreeWithInsuranceRulesAndFamiliarWithAgreement-3") }}
            <span @click="() => addDriverAutoStore.openAddDriverQuestionnairePdf(calculationResult?.calcId)">
              {{ $t("app.paymentPage.agreeWithInsuranceRulesAndFamiliarWithAgreement-4") }}
            </span>
          </p>
        </template>
      </div>
    </template>

    <div class="payment__buttons">
      <FFButton
        v-if="currentStep === 2"
        @click="handleStepBack"
        :title="$t('app.buttons.back')"
        :uppercase="false"
        color="white"
        paddingx="17px"
        paddingy="20px"
        width="100%"
        background="linear-gradient(132deg, #2F4D3B -0.69%, #162522 103.63%)"
        backgroundMobile="linear-gradient(132deg, #2F4D3B -0.69%, #162522 103.63%)"
        colorMobile="#ffffff" />
      <FFButton
        @click="handleSubmition"
        class="payment__button"
        :title="calculateCostButtonTitle"
        :uppercase="false"
        color="white"
        paddingx="17px"
        paddingy="20px"
        width="100%"
        background="linear-gradient(132deg, #2F4D3B -0.69%, #162522 103.63%)"
        backgroundMobile="linear-gradient(132deg, #2F4D3B -0.69%, #162522 103.63%)"
        colorMobile="#ffffff"
        :disabled="disabled" />
    </div>
    <p v-if="showDescription" class="payment__description">
      {{ $t("app.paymentPage.ruleDescription") }}
    </p>
    <div
      class="payment__ask"
      @click="
        openLink(
          `https://api.whatsapp.com/send?phone=77788700014&text=${currentLocale === 'ru' ? whatsAppAddDriverAutoMessageRu : currentLocale === 'kk' ? whatsAppAddDriverAutoMessageKk : whatsAppAddDriverAutoMessageEn}`,
          '_blank'
        )
      ">
      <img src="/main-front/images/dashicons_whatsapp.svg" alt="icon" />
      <p>{{ $t("app.paymentPage.askQuestion") }}</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.payment {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 10px;
  background: #e2e2e2;
  padding: 20px;
  .payment__buttons {
    display: flex;
    gap: 20px;
    position: relative;
    z-index: 0;
  }
  .payment__sum {
    display: flex;
    flex-direction: column;
    gap: 8px;
    z-index: 1;
    .payment__sum--premium {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      opacity: 0.7;
      & > p {
        color: #2e2e2e;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      :nth-child(2) {
        white-space: nowrap;
      }
    }
    .payment__sum--total {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      div {
        display: flex;
        align-items: center;
        gap: 5px;
        p {
          color: #2e2e2e;
          font-size: 18px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
          width: fit-content;
        }
      }
      & > p {
        color: #2e2e2e;
        font-size: 18px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        width: fit-content;
      }
      :nth-child(2) {
        white-space: nowrap;
      }
      :nth-child(1) {
        text-transform: uppercase;
      }
    }
  }
  .payment__agreement-checkbox {
    display: flex;
    align-items: center;
    padding: 10px;

    background-color: #ffffff;
    border-radius: 10px;

    p:nth-child(2) {
      color: #2e2e2e;
      font-size: 12px;
      font-style: normal;
      font-weight: 900;
      line-height: 16px;

      span {
        color: #4d52d4;
        font-size: 12px;
        font-style: normal;
        font-weight: 900;
        line-height: 16px;
        text-decoration-line: underline;
        text-underline-offset: 2px;
        cursor: pointer;
      }
    }
  }
  &__line {
    width: 100%;
    border-bottom: 1px solid #2e2e2e;
    margin-top: -4px;
  }
  &__button:hover {
    transform: scale(1.02);
  }
  &__description {
    color: #2e2e2e;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &__ask {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #2e2e2e;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    margin: 0 auto;
    transition: all 0.3s ease;
    padding: 10px 0;
  }
  &__ask:hover {
    transform: scale(1.05);
  }
}

@media (max-width: $lg) {
  .payment {
    .payment__buttons {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    &__button:hover,
    &__ask:hover {
      transform: scale(1);
    }
  }
}
</style>
