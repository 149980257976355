<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.3 8.09999C9.56667 7.74444 9.56667 7.25556 9.3 6.90001L6.30004 2.90005C5.96867 2.45823 5.34188 2.36869 4.90005 2.70006C4.45823 3.03142 4.36869 3.65822 4.70006 4.10004L7.25003 7.5L4.70006 10.9C4.36869 11.3418 4.45823 11.9686 4.90005 12.2999C5.34188 12.6313 5.96867 12.5418 6.30004 12.0999L9.3 8.09999Z"
      fill="white" />
  </svg>
</template>

<style scoped></style>
