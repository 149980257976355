export const getUnixTime = () => Math.round(+new Date() / 1000)

export const getDobStringByIIN = (iin: string) => {
  //2002-04-12T00:00:00.000Z string
  const yearPart = parseInt(iin.substring(0, 2))
  const month = parseInt(iin.substring(2, 4))
  const day = parseInt(iin.substring(4, 6))

  const currentYear = new Date().getFullYear()
  let adjustedYear =
    yearPart <= currentYear % 100
      ? currentYear - (currentYear % 100) + yearPart
      : currentYear - (currentYear % 100) - 100 + yearPart

  const parsedDate = new Date(Date.UTC(adjustedYear, month - 1, day))

  return parsedDate.toISOString()
}

export const getDobObjectByIIN = (iin: string) => {
  //2002-04-12T00:00:00.000Z object
  const yearPart = parseInt(iin.substring(0, 2))
  const month = parseInt(iin.substring(2, 4))
  const day = parseInt(iin.substring(4, 6))

  const currentYear = new Date().getFullYear()
  let adjustedYear =
    yearPart <= currentYear % 100
      ? currentYear - (currentYear % 100) + yearPart
      : currentYear - (currentYear % 100) - 100 + yearPart

  return new Date(Date.UTC(adjustedYear, month - 1, day))
}
