<script setup lang="ts">
const about = [
  {
    iconPath: "/main-front/images/about-fond.svg",
    title: "main.about.card1"
  },
  {
    iconPath: "/main-front/images/about-global.png",
    title: "main.about.card2"
  },
  {
    iconPath: "/main-front/images/about-akab.png",
    title: "main.about.card3"
  },
  {
    iconPath: "/main-front/images/about-fin.png",
    title: "main.about.card4"
  }
]
</script>

<template>
  <div class="about container">
    <p class="about__title">{{ $t("main.about.title") }}</p>
    <div class="about__content">
      <p class="about__content--text">
        {{ $t("main.about.description") }}
      </p>
      <div class="about__content--grid">
        <div class="about__content--grid__item" v-for="(i, index) in about" :key="index">
          <img :src="i.iconPath" alt="photo" />
          <p>{{ $t(i.title) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.about {
  display: flex;
  flex-direction: column;
  gap: 50px;
  
  &__title {
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    background: linear-gradient(95deg, #79b0e2 -3.54%, #1cd491 56.19%, #3fed6e 102.64%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &__content {
    display: flex;
    gap: 30px;
    &--text {
      color: #d6d6d6;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      max-width: 540px;
      white-space: pre-wrap;
    }
    &--grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 79px;
      &__item {
        display: flex;
        flex-direction: column;
        gap: 20px;
        img {
          height: fit-content;
          width: fit-content;
        }
        p {
          color: #d6d6d6;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}

@media (max-width: $xxl) {
  .about {
    &__content {
      &--text {
        max-width: 450px;
      }
    }
  }
}

@media (max-width: $xl) {
  .about {
    padding-bottom: 400px;
    &__content {
      flex-direction: column;
      &--text {
        max-width: inherit;
      }
    }
  }
}

@media (max-width: $lg) {
  .about {
    &__title {
      font-size: 30px;
    }
    &__content {
      gap: 50px;
      &--grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(4, 1fr);
        grid-column-gap: 0;
        grid-row-gap: 50px;
        &__item {
          gap: 10px;
        }
      }
    }
  }
}

@media (max-width: $md) {
  .about {
    &__content {
      gap: 30px;
      &--grid {
        grid-row-gap: 20px;
        &__item {
          gap: 20px;
        }
      }
    }
  }
}
</style>
