import zod from "zod"

const currentYear = new Date().getFullYear();

export const wearVehicleSchema = zod.object({
  category: zod.object({
    id: zod.number().nonnegative(),
    name: zod.string().nonempty()
  }),
  brand: zod.object({
    id: zod.number().nonnegative(),
    name: zod.string().nonempty()
  }),
  yearOfRelease: zod
    .string()
    .nonempty()
    .refine((val) => {
      const year = parseInt(val, 10)
      return year >= 1900 && year <= currentYear
    }),
  actualMileage: zod.string().nonempty()
})
