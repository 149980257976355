<script setup lang="ts">
import {watchEffect} from "vue"
import {storeToRefs} from "pinia"
import {useComfortStore} from "@/stores/ComfortStore"
import {useHelperStore} from "@/stores/HelperStore"
import {useI18n} from "vue-i18n"
import ComfortHero from "@/components/Comfort/ComfortHero.vue"
import ComfortAdvantages from "@/components/Comfort/ComfortAdvantages.vue"
import ComfortSubscription from "@/components/Comfort/ComfortSubscription.vue"
import ComfortWhatSecure from "@/components/Comfort/ComfortWhatSecure.vue"
import ComfortFromWhatSecure from "@/components/Comfort/ComfortFromWhatSecure.vue"
import ComfortHowProductWorks from "@/components/Comfort/ComfortHowProductWorks.vue"
import ComfortSubscribe from "@/components/Comfort/ComfortSubscribe.vue"
import Modal from "@/components/FFUI/Modal.vue"
import Loader from "@/components/FFUI/Loader.vue"

const helperStore = useHelperStore()
const comfortStore = useComfortStore()
const {agreementLoaderHide, loading} = storeToRefs(comfortStore)
const {t} = useI18n()

watchEffect(() => {
  helperStore.pageTitle = t("app.headerPageTitles.comfortPage")
})
</script>

<template>
  <template v-if="agreementLoaderHide"> </template>
  <template v-else>
    <Modal style="z-index: 103" :isOpen="loading">
      <Loader />
    </Modal>
  </template>
  <div class="comfort">
    <img class="comfort__green-background-1" src="/comfort-front/images/green-bg-1.png" alt="" />
    <img class="comfort__green-background-2" src="/comfort-front/images/green-bg-2.png" alt="" />
    <img class="comfort__glass-white-house" src="/comfort-front/images/glass-white-house.png" alt="" />
    <div class="comfort__wrapper container">
      <ComfortHero />
      <ComfortAdvantages />
      <ComfortSubscription />
      <ComfortWhatSecure />
      <ComfortFromWhatSecure />
      <ComfortHowProductWorks />
      <ComfortSubscribe />
    </div>
  </div>
</template>

<style scoped lang="scss">
.comfort {
  overflow: hidden;
  position: relative;

  .comfort__green-background-1 {
    position: absolute;
    top: -308px;
    left: -758px;
    pointer-events: none;
  }

  .comfort__green-background-2 {
    position: absolute;
    top: 2150px;
    left: 358px;
    pointer-events: none;
  }

  .comfort__glass-white-house {
    display: none;
  }

  .comfort__wrapper {
    z-index: 0;
    position: relative;
  }
}

@media (max-width: $lg) {
  .comfort {
    background-image: url("/comfort-front/images/comfort-mobile-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0px -145px;
    width: 100%;

    .comfort__green-background-1,
    .comfort__green-background-2 {
      display: none;
    }

    .comfort__glass-white-house {
      display: flex;
    }
  }
}
</style>
