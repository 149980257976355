<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.6803 10.7C14.6803 11.4108 14.398 12.0925 13.8954 12.5951C13.3928 13.0977 12.7111 13.38 12.0003 13.38C11.2895 13.38 10.6079 13.0977 10.1053 12.5951C9.60267 12.0925 9.32031 11.4108 9.32031 10.7C9.32031 9.98924 9.60267 9.30757 10.1053 8.80497C10.6079 8.30238 11.2895 8.02002 12.0003 8.02002C12.7111 8.02002 13.3928 8.30238 13.8954 8.80497C14.398 9.30757 14.6803 9.98924 14.6803 10.7V10.7Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.7008 10.7C18.7008 17.0802 12.0008 20.75 12.0008 20.75C12.0008 20.75 5.30078 17.0802 5.30078 10.7C5.30078 8.92305 6.00667 7.21888 7.26317 5.96238C8.51966 4.70589 10.2238 4 12.0008 4C13.7777 4 15.4819 4.70589 16.7384 5.96238C17.9949 7.21888 18.7008 8.92305 18.7008 10.7V10.7Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<style scoped>
svg {
  flex-shrink: 0;
}
</style>