<script setup lang="ts">
import {useHelpers} from "@/composables/useHelpers"
import {externalUrls} from "@/constants/urls"
import GreenGradientTickIcon from "@/components/icons/greens/GreenGradientTickIcon.vue"
import GreenGradientCalendarIcon from "@/components/icons/greens/GreenGradientCalendarIcon.vue"
import GreenGradientCreditCardIcon from "@/components/icons/greens/GreenGradientCreditCardIcon.vue"
import GreenGradientFreedomIcon from "@/components/icons/greens/GreenGradientFreedomIcon.vue"

const {openLink} = useHelpers()
</script>

<template>
  <section class="dtp-conditions container">
    <div class="dtp-conditions__card">
      <GreenGradientTickIcon />
      <p class="dtp-conditions__card-title">{{ $t("app.dtpLandingPage.conditions.title-1") }}</p>
      <p class="dtp-conditions__card-description">{{ $t("app.dtpLandingPage.conditions.description-1") }}</p>
    </div>

    <div class="dtp-conditions__card">
      <GreenGradientCalendarIcon />
      <p class="dtp-conditions__card-title">{{ $t("app.dtpLandingPage.conditions.title-2") }}</p>
      <p class="dtp-conditions__card-description">{{ $t("app.dtpLandingPage.conditions.description-2") }}</p>
    </div>

    <div class="dtp-conditions__card">
      <GreenGradientCreditCardIcon />
      <p class="dtp-conditions__card-title">{{ $t("app.dtpLandingPage.conditions.title-3") }}</p>
      <p class="dtp-conditions__card-description">{{ $t("app.dtpLandingPage.conditions.description-3") }}</p>
    </div>

    <div class="dtp-conditions__card">
      <GreenGradientFreedomIcon />
      <p class="dtp-conditions__card-title">{{ $t("app.dtpLandingPage.conditions.title-4") }}</p>
      <p class="dtp-conditions__card-description">
        {{ $t("app.dtpLandingPage.conditions.description-4-1") }} {{ " " }}
        <span @click="openLink(externalUrls.freedomCurrencyUrl, '_blank')">
          {{ $t("app.dtpLandingPage.conditions.description-4-2") }}
        </span>
      </p>
    </div>
  </section>
</template>

<style scoped lang="scss">
.dtp-conditions {
  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 20px;
  z-index: 1;
  position: relative;

  @include max-responsive(xl) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 10px;
  }

  @include max-responsive(lg) {
    margin-top: -100px;
    grid-template-columns: repeat(1, 1fr);
  }

  .dtp-conditions__card {
    display: flex;
    padding: 30px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;

    border-radius: 20px;
    background: radial-gradient(54.59% 70.24% at 0% 100%, rgba(38, 73, 52, 0.6) 0%, rgba(27, 27, 27, 0.6) 100%);
    backdrop-filter: blur(8px);

    .dtp-conditions__card-title {
      color: var(--color-white);
      font-size: 18px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      text-transform: uppercase;
    }
    .dtp-conditions__card-description {
      color: rgba(255, 255, 255, 0.7);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22.4px;

      span {
        color: #5439ff;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
  }
}
</style>
