<script setup lang="ts">
import {computed, onMounted} from "vue"
import {storeToRefs} from "pinia"
import {useMSTRemasteredStore} from "@/stores/MSTRemastered"
import {useHelpers} from "@/composables/useHelpers"
import {useFormatters} from "@/utils/formatters"
import {calculateSchemaWithSportType, calculateSchemaWithoutSportType} from "@/schemas/MST/calculateSchema"
import MSTProgramBadge from "@/components/MSTRemastered/Forms/MSTProgramBadge.vue"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFSelect from "@/components/FFUI/FFSelect.vue"
import FFCheckbox from "@/components/FFUI/FFCheckbox.vue"
import FFTooltip from "@/components/FFUI/FFTooltip.vue"
import MSTWhoWillFly from "@/components/MSTRemastered/Forms/MSTWhoWillFly.vue"
import QuestionIcon from "@/components/icons/QuestionIcon.vue"
import Dropdown from "primevue/dropdown"
import VueDatePicker from "@vuepic/vue-datepicker"

import "@formatjs/intl-datetimeformat/polyfill-force"
import "@formatjs/intl-datetimeformat/locale-data/kk"
import "@formatjs/intl-datetimeformat/locale-data/ru"
import "@formatjs/intl-datetimeformat/locale-data/en"
import "@formatjs/intl-datetimeformat/add-all-tz"

const mstStore = useMSTRemasteredStore()
const {
  tourismGoal,
  activeLifestyle,
  sports,
  countries,
  sums,
  insureds,
  activeLifestyleDescriptions,
  calculateForm,
  getDaysCount,
  totalCategoryCount,
  calculationResponse,
  isCalculationReset
} = storeToRefs(mstStore)

const {currentLocale} = useHelpers()
const {thousandSeparator} = useFormatters()

onMounted(() => {
  mstStore.getMstSportsAndCountries()
})

const isCalculateFormFilled = computed(() => {
  const tourismGoal = calculateForm.value?.tourismGoal?.value; 
  if (tourismGoal === "Sport") {
    return calculateSchemaWithSportType.safeParse(calculateForm.value).success;
  } else {
    return calculateSchemaWithoutSportType.safeParse(calculateForm.value).success;
  }
})

const handleNextStep = () => {
  if (calculateForm.value.policyHolderIsTourist) {
    mstStore.fillFirstInsured()
  } else {
    if(!insureds.value[0].iinProcessed) {
      mstStore.resetFirstInsured()
    }
  }
  mstStore.handleStepNext()
}
</script>

<template>
  <div class="mst-calculate-form__fields">
    <FFFieldset :title="$t('form.inputTitles.travelAim')">
      <Dropdown
        @update:modelValue="mstStore.resetCalculation"
        v-model="calculateForm.tourismGoal"
        :options="tourismGoal"
        optionLabel="name"
        :placeholder="$t('form.inputPlaceholders.choose')">
        <template #dropdownicon>
          <img src="/user-profile-front/images/arrow.svg" class="arrow-icon" width="12" height="12" alt="arrow" />
        </template>
      </Dropdown>
    </FFFieldset>

    <div v-if="calculateForm.tourismGoal.value === 'Tourism'" class="mst-calculate-form__checkbox-row">
      <FFCheckbox
        class="active-type-of-sport"
        :title="$t('app.mstPage.form.calculationForm.activeTypeSport')"
        v-model="calculateForm.isActiveTravel"
        :locked="false" />
      <QuestionIcon
        @click="mstStore.handleMouseOverActiveLifestyle"
        @mouseover="mstStore.handleMouseOverActiveLifestyle"
        @mouseleave="mstStore.handleMouseLeaveActiveLifestyle"
        class="calculation-form__question-icon" />
    </div>

    <FFFieldset v-if="calculateForm.tourismGoal.value === 'Sport'" :title="$t('form.inputTitles.sportType')">
      <FFSelect
        @update="mstStore.resetCalculation"
        v-model="calculateForm.sportType"
        :multiple="false"
        :search-enable="true"
        :options="sports"
        :title="$t('app.mstPage.form.calculationForm.placeholders.choose')" />
    </FFFieldset>

    <FFTooltip v-show="activeLifestyle" :descriptions="activeLifestyleDescriptions" top="190px" left="5px" />

    <FFFieldset :title="$t('form.inputTitles.finalCountry')">
      <FFSelect
        @update="mstStore.resetCalculation"
        v-model="calculateForm.countries"
        :multiple="true"
        :max-count="3"
        :search-enable="true"
        :options="countries"
        :title="$t('app.mstPage.form.calculationForm.placeholders.choose')" />
    </FFFieldset>

    <div class="mst-calculate-form__dates-picker">
      <FFFieldset :title="$t('app.mstPage.form.calculationForm.beginDate')">
        <VueDatePicker
          @date-update="mstStore.resetCalculation"
          v-model="calculateForm.dateStart"
          :enable-time-picker="false"
          auto-apply
          format="dd.MM.yyyy"
          :locale="currentLocale"
          :min-date="new Date(new Date().setDate(new Date().getDate() + 1))"
          :max-date="new Date(new Date().setDate(new Date().getDate() + 1) + 89 * 24 * 60 * 60 * 1000)"
          :start-date="new Date(new Date().setDate(new Date().getDate() + 2))">
          <template #clear-icon="{clear}"></template>
        </VueDatePicker>
      </FFFieldset>
      <FFFieldset :title="$t('app.mstPage.form.calculationForm.endDate')">
        <VueDatePicker
          @date-update="mstStore.resetCalculation"
          v-model="calculateForm.dateStop"
          :enable-time-picker="false"
          auto-apply
          format="dd.MM.yyyy"
          :locale="currentLocale"
          :min-date="new Date(new Date(calculateForm.dateStart).getTime() + 24 * 60 * 60 * 1000)"
          :max-date="new Date(new Date(calculateForm.dateStart).getTime() + 364 * 24 * 60 * 60 * 1000)"
          :start-date="
            new Date(
              JSON.stringify(calculateForm.dateStart) === '{}'
                ? Date.now() + 1000 * 60 * 60 * 24
                : new Date(calculateForm.dateStart).getTime() + 24 * 60 * 60 * 1000
            )
          "
          :disabled="JSON.stringify(calculateForm.dateStart) === '{}'">
          <template #clear-icon="{clear}"></template>
        </VueDatePicker>
      </FFFieldset>
    </div>

    <div class="mst-calculate-form__days-count">
      <p>{{ $t("app.mstPage.form.calculationForm.dayQuantity") }}: {{ getDaysCount }}</p>
    </div>

    <FFFieldset :title="$t('form.inputTitles.whoWillFly')">
      <Dropdown :placeholder="`${$t('app.mstPage.form.calculationForm.quantity')} ${totalCategoryCount}`">
        <template #empty>
          <MSTWhoWillFly />
        </template>
      </Dropdown>
    </FFFieldset>

    <FFFieldset :title="$t('form.inputTitles.insuranceAmout')">
      <Dropdown
        @update:modelValue="mstStore.resetCalculation"
        v-model="calculateForm.insuranceSumId"
        :options="sums"
        optionLabel="amount"
        :placeholder="$t('app.mstPage.form.calculationForm.placeholders.chooseAmount')" />
    </FFFieldset>

    <div class="mst-calculate-form__checkbox-row">
      <FFCheckbox
        :title="$t('app.mstPage.form.calculationForm.policyHolderIsTourist')"
        v-model="calculateForm.policyHolderIsTourist"
        :locked="false" />
    </div>

    <MSTProgramBadge
      v-if="isCalculationReset && calculationResponse"
      :program="calculateForm.tourismGoal.value"
      :premium="calculationResponse.premium"
      :currency-name="calculationResponse.currencyName"
      :currency-value="calculationResponse.currencyValue" />
      
    <FFButton
      @click="isCalculationReset ? handleNextStep() : mstStore.calculateCost()"
      :title="isCalculationReset ? $t('app.buttons.next') : $t('app.buttons.calculateCost')"
      :disabled="!isCalculateFormFilled" />
  </div>
</template>

<style setup lang="scss">
.mst-calculate-form__fields {
  @include column;
  gap: 20px;
  margin-top: 20px;

  .mst-calculate-form__checkbox-row {
    @include flex;
    align-items: center;
    position: relative;
    gap: 10px;
    .ff-checkbox {
      width: unset;
    }
  }

  .arrow-icon {
    width: 16px;
    height: 16px;
  }

  .mst-calculate-form__dates-picker {
    @include flex;
    gap: 10px;
  }

  .mst-calculate-form__days-count {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.2);
    padding: 10px 20px;
    p {
      color: #d7d7d7;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}
</style>
