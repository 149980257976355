<template>
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.9914 12.378L13.9515 12.2565C13.8577 11.9769 13.5487 11.6852 13.264 11.6071L12.2129 11.3197C11.9282 11.2425 11.522 11.3467 11.3129 11.5559L10.9327 11.9361C9.55085 11.562 8.46675 10.4777 8.09351 9.09558L8.47369 8.71532C8.68201 8.50696 8.78616 8.10068 8.70892 7.81506L8.42248 6.76374C8.34523 6.47899 8.05184 6.16994 7.77237 6.07618L7.65257 6.03623C7.37221 5.94247 6.97208 6.03709 6.76376 6.24633L6.19437 6.81583C6.09283 6.91653 6.02772 7.20651 6.02772 7.20651C6.00861 9.01399 6.71691 10.7546 7.99457 12.0334C9.26876 13.3078 11.003 14.0153 12.804 13.9997C12.8136 13.9997 13.1113 13.9363 13.2128 13.8347L13.7822 13.2652C13.9906 13.0578 14.0843 12.6575 13.9914 12.378Z"
      fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0275 0C4.50467 0 0.0273438 4.47733 0.0273438 10.0001C0.0273438 15.5229 4.50467 20.0002 10.0275 20.0002C15.5503 20.0002 20.0276 15.5229 20.0276 10.0001C20.0276 4.47733 15.5503 0 10.0275 0ZM4.87104 16.3529C2.52737 14.0002 1.84555 11.9001 1.84555 10.0001C1.84571 8.70098 2.15523 7.42056 2.74849 6.2648C3.34176 5.10903 4.20171 4.11115 5.2572 3.35372C6.31268 2.5963 7.53336 2.10109 8.81823 1.90909C10.1031 1.71709 11.4152 1.8338 12.646 2.24958C13.8768 2.66536 14.9909 3.36825 15.8962 4.30008C16.8014 5.23192 17.4717 6.36592 17.8516 7.60826C18.2316 8.85059 18.3102 10.1655 18.0811 11.4443C17.852 12.7231 17.3216 13.9289 16.5339 14.962C14.5339 17.462 11.9389 18.1847 10.0275 18.182C8.14921 18.1849 6.32766 17.5387 4.87104 16.3529Z"
      fill="white" />
  </svg>
</template>
