<script setup lang="ts">
import {storeToRefs} from "pinia"
import {useWearAndMileageStore} from "@/stores/CalculatorStore"
import VehicleMileageCalculatorForm from "@/components/VehicleMileageCalculator/VehicleMileageCalculatorForm.vue"
import Modal from "@/components/FFUI/Modal.vue"
import Loader from "@/components/FFUI/Loader.vue"

const wearAndMileageStore = useWearAndMileageStore()
const {loading} = storeToRefs(wearAndMileageStore)
</script>

<template>
  <Modal style="z-index: 103" :isOpen="loading">
    <Loader />
  </Modal>
  <div class="vehicle-mileage-calculator">
    <VehicleMileageCalculatorForm />
  </div>
</template>

<style scoped lang="scss">
.vehicle-mileage-calculator {
  background-image: url("/calculator-front/images/car-among-forest-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}
</style>
