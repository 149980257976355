<template>
  <svg width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_27125_38433)">
      <path d="M8.74609 1.61426H15.2289V13.4774H8.74609V1.61426Z" fill="#FF5F00" />
      <path
        d="M9.15834 7.54548C9.15834 5.13516 10.2697 2.99723 11.9778 1.61385C10.7225 0.607809 9.13781 0 7.40906 0C3.31341 0 0 3.37446 0 7.54548C0 11.7164 3.31341 15.091 7.40897 15.091C9.13772 15.091 10.7224 14.4832 11.9778 13.477C10.2697 12.1146 9.15834 9.95581 9.15834 7.54548Z"
        fill="#EB001B" />
      <path
        d="M23.9758 7.54548C23.9758 11.7164 20.6624 15.091 16.5669 15.091C14.8381 15.091 13.2535 14.4832 11.998 13.477C13.7268 12.0937 14.8176 9.95581 14.8176 7.54548C14.8176 5.13516 13.7062 2.99723 11.998 1.61385C13.2534 0.607809 14.8381 0 16.5669 0C20.6624 0 23.9759 3.39546 23.9759 7.54548H23.9758Z"
        fill="#F79E1B" />
    </g>
    <defs>
      <clipPath id="clip0_27125_38433">
        <rect width="24" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
