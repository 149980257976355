<script setup lang="ts">
import {computed, ref} from "vue"
import {storeToRefs} from "pinia"
import {useHomeProtectStore} from "@/stores/HomeProtectStore"
import {useI18n} from "vue-i18n"
import {useModalStore} from "@/stores/ModalStore"
import {useDeviceSize} from "@/composables/useDeviceSize"
import FFForm from "@/components/FFUI/FFForm.vue"
import CloseIcon from "@/components/icons/socials/CloseIcon.vue"
import ModalHeader from "@/components/FFUI/ModalHeader.vue"
import HProtectCalculateForm from "@/components/HomeProtect/Forms/HProtectCalculateForm.vue"
import HProtectBankApartmentCheckForm from "@/components/HomeProtect/Forms/HProtectBankApartmentCheckForm.vue"
import HProtectAgreementForm from "@/components/HomeProtect/Forms/HProtectAgreementForm.vue"
import HProtectHomeDataForm from "@/components/HomeProtect/Forms/HProtectHomeDataForm.vue"
import HProtectExecutionForm from "@/components/HomeProtect/Forms/HProtectExecutionForm.vue"
import HProtectPaymentMethodForm from "@/components/HomeProtect/Forms/HProtectPaymentMethodForm.vue"
import HProtectKaspiPaymentForm from "@/components/HomeProtect/Forms/HProtectKaspiPaymentForm.vue"
import SuperAppQrBlock from "@/components/shared/SuperAppQrBlock.vue"

const modalStore = useModalStore()
const homeProtectStore = useHomeProtectStore()
const {
  currentStep,
  apartmentClientData,
  needKdp,
  isBankCheckModalNeed,
  isShowExecutionFormWhileFetchNotVerifiedIins,
  verificationUsersData,
  choosedPropertyFromList,
  apartmentCount,
  isChoosedProperty,
  showKaspiForm,
  showSuperAppBlock,
  agreementLoaderHide,
  isCheckLoadingHide,
  allUsersAreVerificated,
  paymentUrl,
  calculationObject
} = storeToRefs(homeProtectStore)
const {isDesktop, isMobileDevice} = useDeviceSize()

const {t} = useI18n()

const cantScanQr = ref<boolean>(false)

const handleCloseModal = () => {
  modalStore.closeModal()
}

const handleStepBack = () => {
  if (currentStep.value === 4 && showKaspiForm.value && !showSuperAppBlock.value) {
    showKaspiForm.value = false
  } else if (currentStep.value === 4 && !showKaspiForm.value && showSuperAppBlock.value && !cantScanQr.value) {
    showSuperAppBlock.value = false
  } else if (currentStep.value === 4 && !showKaspiForm.value && showSuperAppBlock.value && cantScanQr.value) {
    cantScanQr.value = false
  } else if (currentStep.value === 3 && needKdp.value && isMobileDevice.value) {
    homeProtectStore.handleStepBack()
    agreementLoaderHide.value = false
    isCheckLoadingHide.value = false
    verificationUsersData.value = []
    homeProtectStore.clearAllIntervals()
  } else if (currentStep.value === 3 && needKdp.value && isDesktop.value) {
    homeProtectStore.handleStepBack()
    homeProtectStore.clearAllIntervals()
    verificationUsersData.value = []
  } else if (currentStep.value === 3 && !needKdp.value && isDesktop.value) {
    homeProtectStore.handleStepBack()
    homeProtectStore.clearAllIntervals()
    verificationUsersData.value = []
  } else if (currentStep.value === 3 && !needKdp.value && !isDesktop.value) {
    homeProtectStore.handleStepBack()
    homeProtectStore.clearAllIntervals()
    verificationUsersData.value = []
  } else if (currentStep.value === 2 && !isDesktop.value) {
    homeProtectStore.handleStepBack()
    isCheckLoadingHide.value = false
    choosedPropertyFromList.value = null
    isChoosedProperty.value = false
  } else if (currentStep.value === 2 && isDesktop.value) {
    homeProtectStore.handleStepBack()
    modalStore.closeModal()
    isCheckLoadingHide.value = false
    choosedPropertyFromList.value = null
    isChoosedProperty.value = false
  } else {
    homeProtectStore.handleStepBack()
  }
}

const formHeaderTitle = computed(() => {
  if (currentStep.value === 1) {
    return t("form.formHeaders.costCalculation")
  } else if (currentStep.value === 2) {
    return t("form.formHeaders.insuranceObject")
  } else if (currentStep.value === 3) {
    return t("form.formHeaders.execution")
  } else {
    return t("form.formHeaders.paymentMethod")
  }
})

const handleProblemsWithScan = (value: boolean) => {
  cantScanQr.value = value
}
</script>

<template>
  <FFForm>
    <CloseIcon v-if="currentStep === 1" class="ff-form-close" @click="modalStore.closeModal" />
    <ModalHeader
      v-if="
        ((currentStep === 2 || currentStep === 3 || currentStep === 4) &&
          allUsersAreVerificated &&
          needKdp &&
          apartmentClientData?.is_confirmed &&
          !isShowExecutionFormWhileFetchNotVerifiedIins) ||
        ((currentStep === 2 || currentStep === 3 || currentStep === 4) &&
          !needKdp &&
          apartmentClientData?.is_confirmed &&
          !isShowExecutionFormWhileFetchNotVerifiedIins)
      "
      :title="formHeaderTitle"
      :currentStep="currentStep"
      @stepBack="handleStepBack"
      :totalSteps="4" />
    <HProtectCalculateForm v-if="currentStep === 1" />
    <HProtectBankApartmentCheckForm
      v-if="currentStep === 2 && !apartmentClientData?.is_confirmed && isBankCheckModalNeed"
      @stepBack="handleStepBack" />
    <HProtectHomeDataForm
      v-if="currentStep === 2 && apartmentClientData?.is_confirmed && !isBankCheckModalNeed"
      @nextStep="homeProtectStore.handleStepNext" />
    <HProtectAgreementForm
      v-if="currentStep === 3 && needKdp && !allUsersAreVerificated"
      @handleKdp="homeProtectStore.handleKdp"
      @stepBack="handleStepBack" />
    <HProtectExecutionForm
      v-if="
        currentStep === 3 &&
        ((needKdp && allUsersAreVerificated) || !needKdp)
      "
      @next-step="homeProtectStore.handleStepNext" />
    <HProtectPaymentMethodForm v-if="currentStep === 4 && !showKaspiForm && !showSuperAppBlock" />
    <HProtectKaspiPaymentForm v-if="currentStep === 4 && showKaspiForm" />
    <SuperAppQrBlock
      v-if="currentStep === 4 && showSuperAppBlock && !showKaspiForm"
      :url="paymentUrl"
      @cantScan="handleProblemsWithScan"
      :cantScanQr="cantScanQr"
      :orderId="calculationObject?.orderId"
      level="Q">
      <template #super-app-qr-block__title-first-row>
        {{ $t("app.ogpoPage.forms.superAppQrCode-1") }}
      </template>
      <template #super-app-qr-block__title-second-row>
        {{ $t("app.ogpoPage.forms.superAppQrCode-2") }}
      </template>
    </SuperAppQrBlock>
  </FFForm>
</template>

<style scoped lang="scss">
.ff-form-close {
  position: absolute;
  top: 27px;
  left: 20px;
}
</style>
