<script setup lang="ts">
import {ref, watch, onUnmounted, computed} from "vue"
import {storeToRefs} from "pinia"
import {vMaska} from "maska"
import {useAuthStore} from "@/stores/AuthStore"
import {useHelperStore} from "@/stores/HelperStore"
import {useValidation} from "@/composables/useValidation"
import {onPhoneFocus} from "@/utils/inputEvents"
import {filterEmailInput} from "@/utils/inputEvents"
import {useToast} from "vue-toastification"
import {useI18n} from "vue-i18n"
import {filterCyrillicInput} from "@/utils/inputEvents"
import {registerSchema} from "@/schemas"
import FFOtp from "@/components/FFUI/FFOtp.vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import FFReCaptcha from "@/components/FFUI/FFReCaptcha.vue"
import FFErrorMessage from "@/components/FFUI/FFErrorMessage.vue"

const toast = useToast()
const authStore = useAuthStore()
const helperStore = useHelperStore()
const {registerData, otpCode, showOtp, codeIsSent, buttonTitle} = storeToRefs(authStore)
const {isRecaptchaVerified} = storeToRefs(helperStore)
const {t} = useI18n()

const {isValid, getError, validate, clearErrors} = useValidation(registerSchema, registerData, {
  mode: "eager"
})

const passwordType = ref<string>("password")
const repeatPasswordType = ref<string>("password")

const handleRegister = async () => {
  try {
    await validate()

    if (isValid.value && isRecaptchaVerified.value) {
      const response = await authStore.validateRegisterForm()
      if (response) {
        clearErrors()
        showOtp.value = true
      } else {
        showOtp.value = false
      }
    }
    return
  } catch (error) {
    console.error(error)
  }
}

const preventPasswordCyrillicInput = () => {
  registerData.value.password = filterCyrillicInput(registerData.value.password)
}
const preventRepeatPasswordCyrillicInput = () => {
  registerData.value.repeatPassword = filterCyrillicInput(registerData.value.repeatPassword)
}

const isRegisterFormFilled = computed(() => {
  return registerSchema.safeParse(registerData.value).success && isRecaptchaVerified.value
})
</script>

<template>
  <div class="form-wrapper">
    <form v-if="!showOtp" @submit.prevent="handleRegister" autocomplete="false" class="form-wrapper__register">
      <div>
        <FFFieldset :isError="getError('phone')" :title="$t('form.inputTitles.phone')">
          <FFInput
            v-maska
            data-maska="+7 (###) ### ####"
            placeholder="+7 (___) ___ ____"
            id="phone"
            v-model="registerData.phone"
            name="1"
            type="tel"
            @focus="onPhoneFocus" />
        </FFFieldset>
        <template v-if="!!getError('phone')">
          <FFErrorMessage
            v-if="registerData?.phone?.length === 0"
            :errorMessage="t('form.validationErrors.phoneFieldIsRequired')" />
          <FFErrorMessage
            v-if="registerData?.phone?.length > 0 && getError('phone')"
            :errorMessage="t('form.validationErrors.phoneIsNotValid')" />
        </template>
      </div>
      <div>
        <FFFieldset
          :isError="getError('iin')"
          :title="$t('form.inputTitles.iin')"
          @clear="registerData.iin = undefined"
          :clear="true">
          <FFInput v-maska data-maska="############" id="iin" v-model="registerData.iin" name="iin" type="tel" />
        </FFFieldset>
        <template v-if="!!getError('iin')">
          <FFErrorMessage
            v-if="registerData?.iin?.length === 0"
            :errorMessage="t('form.validationErrors.iinFieldIsRequired')" />
          <FFErrorMessage
            v-if="registerData?.iin?.length > 0 && getError('iin')"
            :errorMessage="t('form.validationErrors.iinIsNotValid')" />
        </template>
      </div>

      <div>
        <FFFieldset
          :isError="getError('password')"
          :title="$t('form.inputTitles.password')"
          :password="true"
          :eye-opened="passwordType === 'password'"
          @text="passwordType = 'text'"
          @password="passwordType = 'password'">
          <FFInput
            id="password"
            v-model="registerData.password"
            name="password"
            :type="passwordType"
            @input="preventPasswordCyrillicInput" />
        </FFFieldset>
        <template v-if="!!getError('password')">
          <FFErrorMessage
            v-if="registerData?.password?.length === 0"
            :errorMessage="t('form.validationErrors.passwordFieldIsRequired')" />
          <FFErrorMessage
            v-if="registerData?.password?.length > 0 && getError('password')"
            :errorMessage="t('form.validationErrors.passwordIsNotValid')" />
        </template>
      </div>
      <div>
        <FFFieldset
          :isError="getError('repeatPassword')"
          :title="$t('form.inputTitles.repeatPassword')"
          :password="true"
          :eye-opened="repeatPasswordType === 'password'"
          @text="repeatPasswordType = 'text'"
          @password="repeatPasswordType = 'password'">
          <FFInput
            id="repeatPassword"
            v-model="registerData.repeatPassword"
            name="repeatPassword"
            :type="repeatPasswordType"
            @input="preventRepeatPasswordCyrillicInput" />
        </FFFieldset>
        <template v-if="!!getError('repeatPassword')">
          <FFErrorMessage
            v-if="registerData?.repeatPassword?.length === 0"
            :errorMessage="t('form.validationErrors.repeatPasswordFieldIsRequired')" />
          <FFErrorMessage
            v-if="registerData?.repeatPassword.length > 0 && registerData?.password !== registerData?.repeatPassword"
            :errorMessage="t('form.validationErrors.repeatPasswordNotValid')" />
        </template>
      </div>
      <div>
        <FFFieldset :isError="getError('email')" :title="$t('form.inputTitles.email')" :clear="false">
          <FFInput id="email" v-model="registerData.email" name="email" type="email" @input="filterEmailInput" />
        </FFFieldset>
        <template v-if="!!getError('email')">
          <FFErrorMessage
            v-if="registerData?.email?.length === 0"
            :errorMessage="t('form.validationErrors.emailFieldIsRequired')" />
          <FFErrorMessage
            v-if="registerData?.email?.length > 0 && getError('email')"
            :errorMessage="t('form.validationErrors.emailIsNotValid')" />
        </template>
      </div>
      <div class="recaptcha-wrapper">
        <FFReCaptcha />
      </div>
      <FFButton
        class="form-wrapper__button"
        :title="$t('app.buttons.register')"
        :uppercase="false"
        :disabled="!isRegisterFormFilled"
        color="#FFFFFF"
        colorMobile="#FFFFFF"
        paddingx="17px"
        paddingy="30px"
        width="100%"
        type="submit"
        background="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
        backgroundMobile="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)" />
    </form>
    <div v-else class="form-wrapper__otp">
      <FFOtp
        v-model="otpCode"
        :codeIsSent="codeIsSent"
        :buttonTitle="buttonTitle"
        :sendOtp="authStore.validateRegisterForm"
        :checkOtp="authStore.handleRegister"
        :needWrapper="false" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 50px 0;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  .form-wrapper__register {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    width: 100%;
    max-width: 548px;

    .recaptcha-wrapper {
      transform: scale(0.87);
      transform-origin: 0 0;
      width: 221px;
    }

    .form-wrapper__button {
      margin: 0;
    }
  }
  .form-wrapper__otp {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 320px;

    p {
      color: #fff;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      max-width: 330px;
      margin-bottom: 30px;
    }

    .otp-input-container {
      margin-bottom: 50px;
    }

    .form-wrapper__otp-buttons {
      display: flex;
      gap: 20px;
      width: 400px;
    }
  }
  .captcha {
    margin-top: 20px;
  }
}

@media (max-width: $xl) {
  .form-wrapper {
    padding: 50px 80px;
    .form-wrapper__register {
      max-width: 264px;
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

@media (max-width: $lg) {
  .form-wrapper {
    padding: 50px 20px;
    .form-wrapper__register {
      max-width: 264px;
    }
    .captcha {
      margin-top: 40px;
    }
    .form-wrapper__otp {
      .form-wrapper__otp-buttons {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
</style>
