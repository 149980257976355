<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
    <g clip-path="url(#clip0_152_4631)">
      <path
        d="M8.5013 1.4165C12.4134 1.4165 15.5846 4.58771 15.5846 8.49984C15.5846 12.412 12.4134 15.5832 8.5013 15.5832C4.58918 15.5832 1.41797 12.412 1.41797 8.49984C1.41797 4.58771 4.58918 1.4165 8.5013 1.4165ZM8.5013 11.3332C8.31344 11.3332 8.13327 11.4078 8.00044 11.5406C7.8676 11.6735 7.79297 11.8536 7.79297 12.0415C7.79297 12.2294 7.8676 12.4095 8.00044 12.5424C8.13327 12.6752 8.31344 12.7498 8.5013 12.7498C8.68916 12.7498 8.86933 12.6752 9.00217 12.5424C9.13501 12.4095 9.20964 12.2294 9.20964 12.0415C9.20964 11.8536 9.13501 11.6735 9.00217 11.5406C8.86933 11.4078 8.68916 11.3332 8.5013 11.3332ZM8.5013 4.604C7.8203 4.604 7.1672 4.87453 6.68566 5.35607C6.20412 5.83761 5.93359 6.49071 5.93359 7.17171C5.93359 7.35957 6.00822 7.53974 6.14106 7.67258C6.2739 7.80542 6.45407 7.88005 6.64193 7.88005C6.82979 7.88005 7.00996 7.80542 7.14279 7.67258C7.27563 7.53974 7.35026 7.35957 7.35026 7.17171C7.3505 6.96278 7.40759 6.75785 7.51543 6.5789C7.62327 6.39995 7.77779 6.25372 7.96241 6.15591C8.14703 6.05809 8.35479 6.01236 8.56342 6.02364C8.77204 6.03491 8.97367 6.10276 9.14667 6.21991C9.31967 6.33705 9.45753 6.49908 9.54545 6.68861C9.63338 6.87814 9.66806 7.08803 9.64578 7.29577C9.62349 7.50351 9.54509 7.70127 9.41896 7.86784C9.29284 8.03441 9.12376 8.16351 8.92984 8.2413C8.45101 8.43255 7.79297 8.92271 7.79297 9.73942V9.9165C7.79297 10.1044 7.8676 10.2845 8.00044 10.4174C8.13327 10.5502 8.31344 10.6248 8.5013 10.6248C8.68916 10.6248 8.86933 10.5502 9.00217 10.4174C9.13501 10.2845 9.20964 10.1044 9.20964 9.9165C9.20964 9.74367 9.24505 9.65725 9.39451 9.58359L9.45614 9.55525C10.0092 9.33276 10.4677 8.9248 10.7529 8.40132C11.0381 7.87784 11.1324 7.27143 11.0195 6.68607C10.9066 6.1007 10.5936 5.57284 10.1342 5.19296C9.67474 4.81309 9.09745 4.60487 8.5013 4.604Z"
        fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_152_4631">
        <rect width="17" height="17" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
