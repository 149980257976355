import {defineStore} from "pinia"
import {ref, computed, watch} from "vue"
import {useRouter} from "vue-router"
import {useI18n} from "vue-i18n"
import {useToast} from "vue-toastification"
import {useModalStore} from "@/stores/ModalStore"
import {useGatewayFetcher} from "@/composables/useGatewayFetcher"
import {useHelpers} from "@/composables/useHelpers"
import {useDeviceSize} from "@/composables/useDeviceSize"
import {usePdfOpener} from "@/composables/usePdfOpener"
import {useFormatters} from "@/utils/formatters"
import {debounce} from "lodash-es"
import {insuredSchema} from "@/schemas/MST/insuredSchema"
import MSTForms from "@/components/MSTRemastered/MSTForms.vue"
import FFOtp from "@/components/FFUI/FFOtp.vue"

interface IPolicyHolder {
  iin: string
  passportNumb: string
  passportIssueDate: string
  firstNameEng: string
  lastNameEng: string
}

export const useMSTRemasteredStore = defineStore("mstRemasteredStore", () => {
  const modalStore = useModalStore()
  const {currentLocale, openLink} = useHelpers()
  const {phoneNumberFormatter, dateFormatter, thousandSeparator} = useFormatters()
  const {loading, postData, getData} = useGatewayFetcher()
  const {isDesktop} = useDeviceSize()
  const {openKdpAgreementPdf} = usePdfOpener(postData)
  const {t} = useI18n()
  const router = useRouter()
  const toast = useToast()

  const config = {
    headers: {
      "Accept-Language": currentLocale.value
    },
    timeout: 600000
  }

  const currentStep = ref<number>(1)
  const handleStepNext = (): void => {
    currentStep.value += 1
  }
  const handleStepBack = (): void => {
    if (currentStep.value !== 1) {
      currentStep.value--
    }
  }
  const resetStep = (): void => {
    currentStep.value = 1
  }

  //Authorize form
  const authorizeForm = ref<{phone: string; iin: string}>({
    phone: "",
    iin: ""
  })
  const isIinLoadingState = ref<boolean>(false)
  const iinName = ref<string>("")

  const iinCheck = debounce(async (data: string) => {
    if (!data) {
      iinName.value = ""
      return
    }

    isIinLoadingState.value = true

    try {
      if (data.length === 12) {
        const response = await postData(
          "/client/check",
          {
            iin: data,
            clientType: "Individual"
          },
          config
        )
        if (response && response.data && response.status === 200) {
          iinName.value = response.data.data.client
        } else {
          iinName.value = ""
          authorizeForm.value.iin = ""
        }
      } else {
        iinName.value = ""
      }
      isIinLoadingState.value = false
    } catch (error) {
      authorizeForm.value.iin = ""
      iinName.value = ""
      isIinLoadingState.value = false
      console.error(error)
    }
  }, 500)

  const handleAuthorizeForm = async () => {
    try {
      const verifyResponse = await postData(
        "/portal/mobile/verify-phone-info",
        {
          phone: phoneNumberFormatter(authorizeForm.value.phone),
          iin: authorizeForm.value.iin
        },
        config
      )
      if (verifyResponse?.status === 200) {
        if (needKdp.value) {
          const notVerificatedUsersResponse = await fetchNotVerifiedIinsInAuthorization(authorizeForm.value.iin)
          if (!notVerificatedUsersResponse) {
            return
          }
          if (verificationUsersData.value[0].verifyClient && !verificationUsersData.value[0].otp_confirmed) {
            await handleOtpFormOpen()
          } else {
            modalStore.openModal({
              component: MSTForms
            })
            currentStep.value = 2
          }
        } else {
          modalStore.openModal({
            component: MSTForms
          })
          currentStep.value = 2
        }
      } else {
        return
      }
    } catch (error) {
      console.error(error)
    }
  }

  //KDP
  const needKdp = ref<boolean>(true)
  const verificationUsersData = ref<{fullName: string; iin: string; verifyClient: boolean; otp_confirmed?: boolean}[]>(
    []
  )
  const agreementLoaderHide = ref<boolean>(false)

  const handleKdp = (): void => {
    needKdp.value = false
  }

  const fetchNotVerifiedIins = async (iin: string) => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }
      const firstClient = {iin, clientType: "individual"}
      const clients = [firstClient]
      const response = await postData("/portal/get-client-data", {clients}, config)
      if (response?.status === 200) {
        verificationUsersData.value = response.data.data
        return true
      } else {
        return false
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const fetchNotVerifiedIinsInAuthorization = async (iin: string) => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }
      const requestId = sessionStorage.getItem("request_id")
      const response = await postData("/policy/mst/get-client-data", {iin, requestId}, config)
      if (response?.status === 200) {
        verificationUsersData.value = response.data.data
        return true
      } else {
        return false
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const notVerificatedUsers = computed(() => {
    const policyHolder = verificationUsersData.value.find((user) => user.iin === authorizeForm.value.iin)

    if (policyHolder && !policyHolder.verifyClient) {
      return [
        {
          fullName: policyHolder.fullName,
          iin: policyHolder.iin,
          verifyClient: policyHolder.verifyClient
        }
      ]
    }

    return []
  })

  const allUsersAreVerificated = computed(() => {
    return notVerificatedUsers.value.every((verficated) => verficated.verifyClient)
  })

  const intervalIds = ref<Record<number, number | undefined>>({})
  const clearAllIntervals = () => {
    Object.keys(intervalIds.value).forEach((index) => {
      clearInterval(intervalIds.value[Number(index)])
      delete intervalIds.value[Number(index)]
    })
  }

  const sendCode = async (index: number) => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }
      const iin = notVerificatedUsers.value[index].iin
      const response = await postData("/portal/access-control", {iin}, config)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const openOgpoAgreementPdf = async (fullName: string, iin: string) => {
    openKdpAgreementPdf(fullName, iin)
  }

  //---------OTP----------
  const otpCode = ref<string | number>()
  const showOtp = ref<boolean>(false)
  const codeIsSent = ref<boolean>(false)
  const sentOtpResponse = ref<{message: string; status: boolean}>()
  const timer = ref(0)
  let timerInterval = null

  const handleOtpFormOpen = async () => {
    const otpProps = {
      codeIsSent: codeIsSent,
      buttonTitle: buttonTitle,
      confirmSmsText: currentStep.value === 3 ? "app.mstPage.form.confirmTouristSms" : "app.auth.confirmSms",
      needSubText: currentStep.value === 3,
      subText: "Заполнить форму вручную?",

      sendOtp: handleSendOtp,
      checkOtp: checkOtpCode,
      closeOtp: closeOtpForm,
      handleSubTextFunc: otpSubTextFunc
    }
    modalStore.openModal({
      component: FFOtp,
      props: otpProps
    })
  }

  const buttonTitle = computed(() => {
    if (checkOtpResponse.value?.status) {
      return t("app.buttons.sendCode")
    } else if (timer.value > 0) {
      const minutes = Math.floor(timer.value / 60)
      const seconds = timer.value % 60
      return `${t("app.buttons.sendCodeAgainThrough.text-1")} ${minutes}:${seconds.toString().padStart(2, "0")}`
    }
    return t("app.buttons.sendCode")
  })

  const startTimer = () => {
    timer.value = 90
    codeIsSent.value = true
    checkOtpResponse.value = null
    timerInterval = setInterval(() => {
      timer.value--
      if (timer.value <= 0) {
        clearInterval(timerInterval)
        timerInterval = null
        codeIsSent.value = false
      }
    }, 1000)
  }

  const resetTimer = () => {
    if (timerInterval) {
      clearInterval(timerInterval)
      timerInterval = null
    }
    timer.value = 0
    codeIsSent.value = false
    checkOtpResponse.value = null
  }

  const handleSendOtp = async () => {
    try {
      const response = await postData(
        "/policy/mst/send-otp",
        {
          phone:
            currentStep.value !== 3
              ? phoneNumberFormatter(authorizeForm.value.phone)
              : phoneNumberFormatter(insureds.value[currentForm.value - 1].phone)
        },
        config
      )
      if (response?.status === 200) {
        startTimer()
        codeIsSent.value = true
        showOtp.value = true
        sentOtpResponse.value = response?.data?.data
        sessionStorage.setItem("request_id", response?.data?.requestId)
        return true
      } else {
        otpCode.value = ""
        codeIsSent.value = false
        showOtp.value = false
        sessionStorage.clear()
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const checkOtpResponse = ref<{message: string; status: boolean} | null>(null)
  const checkOtpCode = async (code: string | number) => {
    const requestId = sessionStorage.getItem("request_id")
    try {
      otpCode.value = code
      const response = await postData(
        "/policy/mst/check-otp",
        {
          phone:
            currentStep.value === 1
              ? phoneNumberFormatter(authorizeForm.value.phone)
              : phoneNumberFormatter(insureds.value[currentForm.value - 1].phone),
          otpCode: otpCode.value,
          requestId
        },
        config
      )
      if (response?.status === 200 && response?.data) {
        checkOtpResponse.value = response.data
        if (response.data?.status) {
          resetTimer()
          modalStore.closeModal()
          modalStore.openModal({
            component: MSTForms
          })
          if (currentStep.value !== 3) {
            handleStepNext()
          } else {
            await fillInsured()
            resetTimer()
          }
          return true
        }
      } else {
        return false
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const closeOtpForm = () => {
    resetTimer()
    if (currentStep.value === 3) {
      currentStep.value = 3
      resetInsured()
    } else {
      currentStep.value = 1
    }
    modalStore.closeModal()
    if (currentStep.value === 3) {
      modalStore.openModal({
        component: MSTForms
      })
    }
  }

  const otpSubTextFunc = () => {
    closeOtpForm()
    if (insureds.value) {
      insureds.value[currentForm.value - 1].iinProcessed = false
      insureds.value[currentForm.value - 1].fillFormByYourself = true
      delayedVisibility.value[currentForm.value - 1] = false
      insureds.value[currentForm.value - 1].phone = ""
    }
  }

  //---------Calculate form----------

  interface CalculateForm {
    tourismGoal: any
    isActiveTravel: boolean
    policyHolderIsTourist: boolean
    sportType: any
    countries: any[]
    dateStart: any
    dateStop: any
    ageTravels: any[]
    insuranceSumId: any
  }

  const calculateForm = ref<CalculateForm>({
    tourismGoal: {},
    isActiveTravel: false,
    policyHolderIsTourist: true,
    sportType: {},
    countries: [],
    dateStart: {},
    dateStop: {},
    ageTravels: [],
    insuranceSumId: {}
  })

  const tourismGoal = ref([
    {name: "Туризм (гостевая поездка)", value: "Tourism"},
    {name: "Бизнес (деловая поездка)", value: "Business"},
    {name: "Работа по найму", value: "Job"},
    {name: "Обучение", value: "Study"},
    {name: "Спорт", value: "Sport"}
  ])

  const activeLifestyle = ref<boolean>(false)
  const handleMouseOverActiveLifestyle = (): void => {
    activeLifestyle.value = true
  }
  const handleMouseLeaveActiveLifestyle = (): void => {
    activeLifestyle.value = false
  }
  const activeLifestyleDescriptions = ref<string[]>([
    "app.mstPage.form.calculationForm.activeTypeSportTooltip.text-1",
    "app.mstPage.form.calculationForm.activeTypeSportTooltip.text-2",
    "app.mstPage.form.calculationForm.activeTypeSportTooltip.text-3",
    "app.mstPage.form.calculationForm.activeTypeSportTooltip.text-4",
    "app.mstPage.form.calculationForm.activeTypeSportTooltip.text-5",
    "app.mstPage.form.calculationForm.activeTypeSportTooltip.text-6"
  ])

  const sports = ref<{id: string; name: string}[]>([])
  const countries = ref<{id: string; name: string}[]>([])
  const shengenCountries = ref<{id: string; name: string}[]>([])

  const getMstSportsAndCountries = async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      if (sports.value.length === 0 && countries.value.length === 0) {
        const [sportsResponse, countriesResponse] = await Promise.all([
          getData("/policy/mst/sports", config),
          getData("/policy/mst/countries", config)
        ])
        if (sportsResponse) {
          sports.value = sportsResponse.data
        }
        if (countriesResponse) {
          countries.value = countriesResponse.allCountries
          shengenCountries.value = countriesResponse.schengen
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const sums = ref<{id: string; amount: number; currency: string}[]>([])
  const getSums = async (payload: {countryIds: string[]}) => {
    try {
      const response = await postData("/policy/mst/ins-sum", payload, config)
      if (response?.status === 200) {
        sums.value = response.data.data.map((item: {id: string; amount: number; currency: string}) => ({
          id: item.id,
          amount: `${thousandSeparator(item.amount)} ${item.currency}`,
          currency: item.currency
        }))
      }
    } catch (error) {
      sums.value = []
      calculateForm.value.insuranceSumId = {}
      console.error(error)
    }
  }

  watch(
    () => calculateForm.value.countries,
    async (newCountries) => {
      if (newCountries.length > 0) {
        calculateForm.value.insuranceSumId = {}
        const countryIds = newCountries.map((country) => country.id)
        await getSums({id: [...countryIds]})
      }
    },
    {deep: true}
  )

  const getDaysCount = computed(() => {
    if (
      JSON.stringify(calculateForm.value.dateStart) === "{}" ||
      JSON.stringify(calculateForm.value.dateStop) === "{}"
    ) {
      return 0
    }

    const startUtc = Date.UTC(
      calculateForm.value.dateStart.getFullYear(),
      calculateForm.value.dateStart.getMonth(),
      calculateForm.value.dateStart.getDate()
    )
    const stopUtc = Date.UTC(
      calculateForm.value.dateStop.getFullYear(),
      calculateForm.value.dateStop.getMonth(),
      calculateForm.value.dateStop.getDate()
    )

    const timeDifference = stopUtc - startUtc
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

    return daysDifference + 1
  })

  const categories = ref([
    {value: "Under1", count: 0, name: "До 1 года"},
    {value: "From1To60", count: 0, name: "От 1 до 60 лет"},
    {value: "From61To70", count: 0, name: "От 61 до 70 лет"},
    {value: "From71To80", count: 0, name: "От 71 до 80 лет"}
  ])

  const totalCategoryCount = computed(() => {
    return categories.value.reduce((sum, category) => sum + category.count, 0)
  })

  const isIncrementDisabled = computed(() => totalCategoryCount.value >= 5)

  const createPolicyHolder = () => {
    return {
      iin: "",
      passportNumb: "",
      passportIssueDate: "",
      firstNameEng: "",
      lastNameEng: "",
      iinProcessed: false,
      isOlder18: null,
      fillFormByYourself: null
    }
  }

  const syncInsuredList = () => {
    const insuredListCount = insureds.value?.length

    if (totalCategoryCount.value !== insuredListCount) {
      insureds.value = Array.from({length: totalCategoryCount.value}, createPolicyHolder)
    }
  }

  const incrementAgeCategoryCount = (index: number) => {
    if (!isIncrementDisabled.value && categories.value[index].count < 5) {
      categories.value[index].count++
      calculateForm.value.ageTravels.push(categories.value[index].value)
      syncInsuredList()
      delayedVisibility.value = []
    }
  }

  const decrementAgeCategoryCount = (index: number) => {
    if (categories.value[index].count > 0) {
      categories.value[index].count--
      const valueToRemove = categories.value[index].value
      const valueIndex = calculateForm.value.ageTravels.lastIndexOf(valueToRemove)
      delayedVisibility.value = []
      if (valueIndex !== -1) {
        calculateForm.value.ageTravels.splice(valueIndex, 1)
      }
      syncInsuredList()
    }
  }

  watch(
    categories,
    () => {
      resetCalculation()
    },
    {deep: true}
  )

  watch(
    () => calculateForm.value.dateStart,
    () => {
      calculateForm.value.dateStop = {}
    }
  )

  const calculationResponse = ref<{
    calcId: string
    currencyName: string
    currencyPremium: number
    currencyValue: number
    expireDate: string
    orderId: number
    premium: number
  } | null>(null)
  const isCalculationReset = ref<boolean>(false)
  const resetCalculation = () => {
    isCalculationReset.value = false
    calculationResponse.value = null
  }

  const calculateCost = async () => {
    try {
      resetCalculation()
      const response = await postData(
        "/policy/mst/calculate",
        {
          iin: authorizeForm.value.iin,
          phone: phoneNumberFormatter(authorizeForm.value.phone),
          tripPurpose: calculateForm.value.tourismGoal.value,
          countries: calculateForm.value.countries.map((item) => item.id),
          fromDate: dateFormatter(calculateForm.value.dateStart),
          toDate: dateFormatter(calculateForm.value.dateStop),
          ageGroups: calculateForm.value.ageTravels,
          insuranceSumId: calculateForm.value.insuranceSumId.id,
          sportType: calculateForm.value.tourismGoal.value !== "Sport" ? null : calculateForm.value.sportType.id,
          isActiveTravel: calculateForm.value.isActiveTravel,
          isMultipleTravel: false
        },
        config
      )
      if (response?.status === 200) {
        isCalculationReset.value = true
        calculationResponse.value = response.data?.data
      }
    } catch (error) {
      console.error(error)
    }
  }

  //---------Personal data form----------

  const delayedVisibility = ref<boolean[]>([])

  const policyHolderForm = ref<{isResidentRk: boolean; iin: string; email: string}>({
    isResidentRk: true,
    iin: authorizeForm.value.iin,
    email: ""
  })

  const personalDataCheckboxStates = ref<{notPublicOfficial: boolean; noBankAccount: boolean; iAgree: boolean}>({
    notPublicOfficial: false,
    noBankAccount: false,
    iAgree: false
  })

  const currentForm = ref<number>(0)
  const insureds = ref<
    | {
        iin: string
        phone: string
        passportNumb: string
        passportIssueDate: string
        firstNameEng: string
        lastNameEng: string
        iinProcessed: boolean
        isOlder18: boolean | null
        fillFormByYourself: boolean | null
      }[]
    | null
  >([])

  const fillFirstInsured = async () => {
    insuredData.value = null
    const response = await getInsuredData(authorizeForm.value.iin)
    if (response) {
      insureds.value[0].iinProcessed = true
      insureds.value[0].iin = authorizeForm.value.iin
      insureds.value[0].phone = authorizeForm.value.phone
      insureds.value[0].passportNumb = insuredData.value?.number
      insureds.value[0].passportIssueDate = insuredData.value?.beginDate
      insureds.value[0].firstNameEng = insuredData.value?.engFirstName
      insureds.value[0].lastNameEng = insuredData.value?.engSurname
      insureds.value[0].isOlder18 = true
      insureds.value[0].fillFormByYourself = false
    } else {
      insureds.value[0].iinProcessed = false
      insureds.value[0].iin = ""
      insureds.value[0].phone = ""
      insureds.value[0].passportNumb = ""
      insureds.value[0].passportIssueDate = ""
      insureds.value[0].firstNameEng = ""
      insureds.value[0].lastNameEng = ""
      insureds.value[0].isOlder18 = null
      insureds.value[0].fillFormByYourself = true
    }
  }

  const fillInsured = async () => {
    insuredData.value = null
    const response = await getInsuredData(insureds.value[currentForm.value - 1].iin)
    if (response) {
      insureds.value[currentForm.value - 1].iinProcessed = true
      insureds.value[currentForm.value - 1].passportNumb = insuredData.value?.number
      insureds.value[currentForm.value - 1].passportIssueDate = insuredData.value?.beginDate
      insureds.value[currentForm.value - 1].firstNameEng = insuredData.value?.engFirstName
      insureds.value[currentForm.value - 1].lastNameEng = insuredData.value?.engSurname
      insureds.value[currentForm.value - 1].fillFormByYourself = false
    } else {
      insureds.value[currentForm.value - 1].iinProcessed = false
      insureds.value[currentForm.value - 1].phone = ""
      insureds.value[currentForm.value - 1].passportNumb = ""
      insureds.value[currentForm.value - 1].passportIssueDate = ""
      insureds.value[currentForm.value - 1].firstNameEng = ""
      insureds.value[currentForm.value - 1].lastNameEng = ""
      insureds.value[currentForm.value - 1].fillFormByYourself = false
    }
  }

  const resetFirstInsured = () => {
    if (!calculateForm.value.policyHolderIsTourist && insureds.value.length !== 0) {
      insureds.value[0].iin = ""
      insureds.value[0].phone = ""
      insureds.value[0].passportNumb = ""
      insureds.value[0].passportIssueDate = ""
      insureds.value[0].firstNameEng = ""
      insureds.value[0].lastNameEng = ""
      insureds.value[0].iinProcessed = false
      insureds.value[0].isOlder18 = null
    }
  }

  watch(
    () => calculateForm.value.policyHolderIsTourist,
    () => {
      resetFirstInsured()
      delayedVisibility.value = []
    },
    {
      deep: true,
      immediate: true
    }
  )

  const resetInsured = () => {
    insureds.value[currentForm.value - 1].iinProcessed = false
    insureds.value[currentForm.value - 1].phone = ""
    insureds.value[currentForm.value - 1].passportNumb = ""
    insureds.value[currentForm.value - 1].passportIssueDate = ""
    insureds.value[currentForm.value - 1].firstNameEng = ""
    insureds.value[currentForm.value - 1].lastNameEng = ""
    insureds.value[currentForm.value - 1].isOlder18 = null
  }

  const insuredData = ref<{
    number: string
    beginDate: string
    engSurname: string
    engFirstName: string
  } | null>(null)

  const getInsuredData = async (iin: string) => {
    try {
      const response = await postData("/policy/mst/get-client-data-and-token", {iin}, config)
      if (response?.status === 200) {
        insuredData.value = response.data
        return true
      } else {
        return false
      }
    } catch (error) {
      if (error.response.status === 400 && error.response.data.errors?.type === "CLIENT_NOTFOUND") {
        insureds.value[index].fillFormByYourself = true
      }
      console.error(error)
      return false
    }
  }

  const notFoundClients = ref<
    {iin: string; firstName: string; lastName: string; passportNumber: string; passportIssueDate: string}[]
  >([])
  const checkInsuredAge = debounce(async (index: number) => {
    try {
      const insured = insureds.value[index]

      if (insured.iin.length < 12) {
        insured.isOlder18 = null
        insured.iinProcessed = false
        resetInsured()
        return
      }

      if (insured.iinProcessed) return

      const checkAgeResponse = await postData("/policy/mst/check-age", {iin: insured.iin}, config)

      if (checkAgeResponse?.status === 200 && checkAgeResponse.data?.is18) {
        insured.isOlder18 = true
      } else {
        insured.iinProcessed = true
        insured.isOlder18 = false
        insured.fillFormByYourself = false
      }
    } catch (error) {
      console.error(error)
      insured.isOlder18 = false
    }
  }, 100)

  const getKdpFromInsured = debounce(async (index: number) => {
    try {
      const insured = insureds.value[index]

      if (insured && (insured.iin.length < 12 || insured.phone.length < 17)) {
        insureds.value.iin = ""
        insureds.value.phone = ""
        insured.isOlder18 = null
        return
      }

      if (insured.iinProcessed) return

      if (!insured.fillFormByYourself || insured.fillFormByYourself !== null) {
        const verifyResponse = await postData(
          "/portal/mobile/verify-phone-info",
          {
            phone: phoneNumberFormatter(insured.phone),
            iin: insured.iin
          },
          config
        )

        if (verifyResponse.data.data.verificated && insured.iin.length === 12 && !insured.iinProcessed) {
          if (insured.isOlder18) {
            verificationUsersData.value = []
            const response = await fetchNotVerifiedIins(insured.iin)

            if (response) {
              insured.isOlder18 = true

              if (currentStep.value === 3 && verificationUsersData.value[0]?.verifyClient) {
                if (!insured.iinProcessed) {
                  await handleOtpFormOpen()
                }
              } else {
                needKdp.value = true
              }
            }
          } else {
            insured.isOlder18 = false
          }
        } else {
          return
        }
      }
    } catch (error) {
      console.error(error)
    }
  }, 400)

  const setClientData = async () => {
    try {
      for (const insured of insureds.value) {
        const checkResponse = await postData("/policy/mst/check-age", {iin: insured.iin}, config)

        if (checkResponse?.status === "CLIENT_NOTFOUND") {
          notFoundClients.value.push({
            iin: insured.iin,
            firstName: insured.firstNameEng,
            lastName: insured.lastNameEng,
            passportNumber: insured.passportNumb,
            passportIssueDate: insured.passportIssueDate
          })
        }
      }

      if (notFoundClients.value.length > 0) {
        const payload = {
          clients: notFoundClients.value
        }

        const response = await postData("/policy/mst/set-client", payload, config)
        if (response?.status === 200) {
          handleStepNext()
        } else {
          return
        }
      } else {
        handleStepNext()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const isAllInsuredsVerified = computed(() => {
    return verificationUsersData.value.every((value) => value.verifyClient)
  })

  const notVerificatedInsureds = computed(() => {
    const insured = verificationUsersData.value.find((user) => user)

    if (!insured?.verifyClient) {
      return [
        {
          fullName: insured?.fullName,
          iin: insured?.iin,
          verifyClient: insured?.verifyClient
        }
      ]
    }

    return []
  })

  const sendCodeToInsured = async (index: number) => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }
      const iin = notVerificatedInsureds.value[index].iin
      const response = await postData("/portal/access-control", {iin}, config)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const clearInsuredIin = () => {
    insureds.value[currentForm.value - 1].iin = ""
  }

  const postQuestionnaireData = async () => {
    const formatedInsuredList = insureds.value?.map((insured) => ({
      iin: insured.iin,
      passportNumber: insured.passportNumb,
      passportIssueDate: dateFormatter(insured.passportIssueDate),
      firstNameEng: insured.firstNameEng,
      lastNameEng: insured.lastNameEng,
      isResident: true
    }))

    const questionnaireDataObj = {
      calcId: calculationResponse.value?.calcId,
      holderIin: authorizeForm.value.iin,
      holderPhone: phoneNumberFormatter(authorizeForm.value.phone),
      holderEmail: policyHolderForm.value.email,
      isPublicOfficial: false,
      clients: formatedInsuredList
    }

    try {
      const response = await postData("/policy/mst/form", questionnaireDataObj)
      if (response && response.data && response.data.pdf) {
        const binaryData = atob(response.data.pdf)

        const arrayBuffer = new Uint8Array(binaryData.length)
        for (let i = 0; i < binaryData.length; i++) {
          arrayBuffer[i] = binaryData.charCodeAt(i)
        }

        const blob = new Blob([arrayBuffer], {type: "application/pdf"})

        const pdfUrl = URL.createObjectURL(blob)

        openLink(pdfUrl, "_blank")
      }
    } catch (error) {
      console.error(error)
    }
  }

  const areAllValuesInPersonalDataFormFilled = computed(() => {
    const insuredsFilled = insureds.value?.every(
      (insured) =>
        insured.iin !== "" &&
        insured.passportNumb !== "" &&
        insured.passportIssueDate !== "" &&
        insured.firstNameEng !== "" &&
        insured.lastNameEng !== ""
    )

    const policyHolderFilled = policyHolderForm.value.email

    const personalDataCheckboxFilled =
      personalDataCheckboxStates.value.notPublicOfficial !== false &&
      personalDataCheckboxStates.value.noBankAccount !== false &&
      personalDataCheckboxStates.value.iAgree !== false

    if (insuredsFilled && policyHolderFilled && personalDataCheckboxFilled) {
      return true
    } else {
      return false
    }
  })

  //---------Payment methods form----------

  const selectedPayment = ref<string>("")

  const showKaspiForm = ref<boolean>(false)
  const showSuperAppBlock = ref<boolean>(false)
  const cantScanQr = ref<boolean>(false)
  const paymentUrl = ref<string>("")
  const kaspiPayPhoneNumber = ref<string>("")

  const handleCurrentMethod = (type: string) => {
    selectedPayment.value = type
  }

  const finalPay = async () => {
    try {
      const payload = {
        iin: authorizeForm.value.iin,
        email: policyHolderForm.value.email,
        calcId: calculationResponse.value?.calcId,
        givedConsent: true,
        orderId: calculationResponse.value?.orderId,
        kaspiPayNumber: phoneNumberFormatter(kaspiPayPhoneNumber.value),
        paymentType: selectedPayment.value,
        clients: insureds.value.map((insured) => {
          return {
            iin: insured.iin,
            passportNumber: insured.passportNumb,
            passportIssueDate: dateFormatter(insured.passportIssueDate),
            firstNameEng: insured.firstNameEng,
            lastNameEng: insured.lastNameEng,
            isResident: true
          }
        })
      }
      const response = await postData("/policy/mst/order/create", payload, config)
      if (response?.status === 200) {
        if (selectedPayment.value === "FreedomPay") {
          localStorage.removeItem("paymentType")

          paymentUrl.value = response.data?.payment_url
          window.location.href = paymentUrl.value
        }
        if (selectedPayment.value === "FreedomSuperApp") {
          localStorage.removeItem("paymentType")
          paymentUrl.value = response.data?.payment_url

          if (isDesktop.value) {
            showSuperAppBlock.value = true
          } else {
            openLink(paymentUrl.value, "_self")
          }
        }
        if (selectedPayment.value === "KaspiPay") {
          localStorage.setItem("paymentType", "KaspiPay")
          modalStore.closeModal()
          router.replace("success-pay")
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  return {
    loading,
    currentStep,
    authorizeForm,
    isIinLoadingState,
    iinName,
    iinCheck,
    tourismGoal,
    activeLifestyle,
    activeLifestyleDescriptions,
    sports,
    countries,
    sums,
    calculateForm,
    getDaysCount,
    categories,
    totalCategoryCount,
    isIncrementDisabled,
    delayedVisibility,
    policyHolderForm,
    personalDataCheckboxStates,
    currentForm,
    insureds,
    calculationResponse,
    isCalculationReset,
    checkOtpResponse,
    needKdp,
    timer,
    verificationUsersData,
    notVerificatedUsers,
    agreementLoaderHide,
    allUsersAreVerificated,
    intervalIds,
    insuredData,
    cantScanQr,
    isAllInsuredsVerified,
    notVerificatedInsureds,
    areAllValuesInPersonalDataFormFilled,
    notFoundClients,
    paymentUrl,
    selectedPayment,
    showKaspiForm,
    kaspiPayPhoneNumber,
    showSuperAppBlock,
    handleStepBack,
    handleStepNext,
    resetStep,
    handleMouseOverActiveLifestyle,
    handleMouseLeaveActiveLifestyle,
    handleAuthorizeForm,
    handleKdp,
    handleOtpFormOpen,
    fetchNotVerifiedIins,
    getMstSportsAndCountries,
    incrementAgeCategoryCount,
    decrementAgeCategoryCount,
    calculateCost,
    resetCalculation,
    clearAllIntervals,
    sendCode,
    openOgpoAgreementPdf,
    fillFirstInsured,
    fillInsured,
    resetFirstInsured,
    resetInsured,
    getInsuredData,
    syncInsuredList,
    checkInsuredAge,
    getKdpFromInsured,
    setClientData,
    sendCodeToInsured,
    clearInsuredIin,
    handleCurrentMethod,
    postQuestionnaireData,
    finalPay
  }
})
