<script setup lang="ts">
import {useHelpers} from "@/composables/useHelpers"
import FFButton from "@/components/FFUI/FFButton.vue"

const {scrollToTop} = useHelpers()
</script>

<template>
  <section class="comfort-subscribe">
    <h2 class="comfort-subscribe__title">{{ $t("app.comfortPage.getFreedomComfort-title") }}</h2>
    <p class="comfort-subscribe__subtitle">{{ $t("app.comfortPage.getFreedomComfort-description") }}</p>
    <FFButton @click="scrollToTop" class="comfort-subscribe__btn" :title="$t('app.buttons.calculateCost')" />
  </section>
</template>

<style scoped lang="scss">
.comfort-subscribe {
  background-image: url("/comfort-front/images/subscribe-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px;
  padding: 185px 137px 80px;
  margin-bottom: 231px;

  .comfort-subscribe__title {
    color: #ffffff;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 12px;
  }
  .comfort-subscribe__subtitle {
    color: #dfdfdf;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ff-button-wrapper {
    margin-top: 70px;
    max-width: 290px;
  }
}

@media (max-width: $lg) {
  .comfort-subscribe {
    background-image: url("/comfort-front/images/subscribe-mobile-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 33px 20px;
    min-height: 532px;
    margin-bottom: 100px;

    .comfort-subscribe__title {
      margin-top: 315px;
      color: #ffffff;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      text-transform: uppercase;
    }
    .comfort-subscribe__subtitle {
      color: #dfdfdf;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .ff-button-wrapper {
      margin: 70px auto auto auto;
      max-width: 250px;
    }
  }
}
</style>
