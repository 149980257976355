import {clearAxiosAuthHeader, setAxiosAuthHeader} from "@/utils/auth"
import {createRouter, createWebHistory} from "vue-router"

import AboutCompanyPage from "@/views/AboutCompanyPage.vue"
import AddDriverAutoPage from "@/views/AddDriverAutoPage.vue"
import AuthPage from "@/views/AuthPage.vue"
import ComfortPage from "@/views/ComfortPage.vue"
import ContactsPage from "@/views/ContactsPage.vue"
import DeclareInsuredEventPage from "@/views/DeclareInsuredEventPage.vue"
import DocumentsPage from "@/views/DocumentsPage.vue"
import FailBiometricsPage from "@/views/FailBiometricsPage.vue"
import FailPayPage from "@/views/FailPayPage.vue"
import FailPayPaymentServicePage from "@/views/FailPayPaymentServicePage.vue"
import HomeProtectPage from "@/views/HomeProtectPage.vue"
import HotlinePage from "@/views/HotlinePage.vue"
import KaskoPage from "@/views/KaskoPage.vue"
import MSTBoardingPage from "@/views/MSTBoardingPage.vue"
import MSTPage from "@/views/MSTPage.vue"
import MSTRemasteredPage from "@/views/MSTRemasteredPage.vue"
import MainPage from "@/views/MainPage.vue"
import OgpoPage from "@/views/OgpoPage.vue"
import OgpoPlusPage from "@/views/OgpoPlusPage.vue"
import OurTeamPage from "@/views/OurTeamPage.vue"
import PersonalProfilePage from "@/views/PersonalProfilePage.vue"
import PolicyVmsPage from "@/views/PolicyVmsPage.vue"
import SuccessPayPage from "@/views/SuccessPayPage.vue"
import SuccessPolicyReissue from "@/views/SuccessPolicyReissue.vue"
import SuccessPolicyReissueIsBadScoring from "@/views/SuccessPolicyReissueIsBadScoring.vue"
import SuccessTerminated from "@/views/SuccessTerminated.vue"
import TerminationAgreement from "@/views/TerminationAgreement.vue"
import TerminationSubscriptionAgreement from "@/views/TerminationSubscriptionAgreement.vue"
import ThankYouPage from "@/views/ThankYouPage.vue"
import UserProfilePage from "@/views/UserProfilePage.vue"
import DtpLandingPage from "@/views/DtpLandingPage.vue"
import WearVehicleCalculatorPage from "@/views/WearVehicleCalculatorPage.vue"
import VehicleMileageCalculatorPage from "@/views/VehicleMileageCalculatorPage.vue"
import NewsPage from "@/views/NewsPage.vue"
import NewsDetailsPage from "@/views/NewsDetailsPage.vue"
import axios from "axios"
import {hasQueryParams} from "@/utils/hasQueryParams"
import {isTokenExpired} from "@/utils/jwt"
import SubscriptionActivateStatusPage from "@/views/SubscriptionActivateStatusPage.vue"

const routes = [
  {
    path: "/:lang",
    name: "Home",
    component: MainPage
  },
  {
    path: "/:lang/pages/contacts",
    name: "Contacts",
    component: ContactsPage
  },
  {
    path: "/:lang/pages/about-company",
    name: "AboutCompany",
    component: AboutCompanyPage
  },
  {
    path: "/:lang/pages/our-team",
    name: "OurTeam",
    component: OurTeamPage
  },
  {
    path: "/:lang/pages/auth",
    name: "Auth",
    component: AuthPage
  },
  {
    path: "/:lang/pages/personal-profile",
    name: "Profile",
    component: PersonalProfilePage,
    meta: {requiresAuth: true}
  },
  {
    path: "/:lang/pages/declare-event",
    name: "DeclareEvent",
    component: DeclareInsuredEventPage
  },
  {
    path: "/:lang/pages/user-profile",
    name: "UserProfile",
    component: UserProfilePage,
    meta: {requiresAuth: true}
  },
  {
    path: "/:lang/pages/thank-you",
    name: "ThankYou",
    component: ThankYouPage
  },
  {
    path: "/:lang/pages/mst",
    name: "MST",
    component: MSTPage
  },
  {
    path: "/:lang/pages/mst-remastered",
    name: "MSTRemastered",
    component: MSTRemasteredPage
  },
  {
    path: "/:lang/pages/add-driver-auto/:id/:product",
    name: "AddDriverAuto",
    component: AddDriverAutoPage,
    meta: {requiresAuth: true}
  },
  {
    path: "/:lang/pages/termination-agreement/:id/:product",
    name: "TerminationAgreement",
    component: TerminationAgreement,
    meta: {requiresAuth: true}
  },
  {
    path: "/:lang/pages/termination-subscription-agreement/:id/:product",
    name: "TerminationSubscriptionAgreement",
    component: TerminationSubscriptionAgreement,
    meta: {requiresAuth: true}
  },
  {
    path: "/:lang/pages/comfort",
    name: "Comfort",
    component: ComfortPage
  },
  {
    path: "/:lang/pages/ogpo",
    name: "Ogpo",
    component: OgpoPage
  },
  {
    path: "/:lang/pages/ogpo-plus",
    name: "OgpoPlus",
    component: OgpoPlusPage
  },
  {
    path: "/:lang/pages/kasko",
    name: "Kasko",
    component: KaskoPage
  },
  {
    path: "/:lang/pages/home-protect",
    name: "HomeProtect",
    component: HomeProtectPage
  },
  {
    path: "/:lang/pages/success-pay",
    name: "SuccessPay",
    component: SuccessPayPage
  },
  {
    path: "/:lang/pages/fail-pay",
    name: "FailPay",
    component: FailPayPage
  },
  {
    path: "/:lang/pages/fail-pay/payment-service",
    name: "FailPayPaymentService",
    component: FailPayPaymentServicePage
  },
  {
    path: "/:lang/pages/fail-biometrics",
    name: "FailBiometrics",
    component: FailBiometricsPage
  },
  {
    path: "/:lang/pages/successfully-terminated",
    name: "SuccessTerminated",
    component: SuccessTerminated
  },
  {
    path: "/:lang/pages/successfully-reissued",
    name: "SuccessPolicyReissue",
    component: SuccessPolicyReissue
  },
  {
    path: "/:lang/pages/successfully-reissued/scoring",
    name: "SuccessPolicyReissueIsBadScoring",
    component: SuccessPolicyReissueIsBadScoring
  },
  {
    path: "/:lang/pages/subscription-activate-status",
    name: "SubscriptionActivateStatus",
    component: SubscriptionActivateStatusPage
  },
  {
    path: "/:lang/pages/vms/:hash",
    name: "PolicyVms",
    component: PolicyVmsPage
  },
  {
    path: "/:lang/pages/chairman",
    name: "Hotline",
    component: HotlinePage
  },
  {
    path: "/:lang/pages/documents",
    name: "Documents",
    component: DocumentsPage
  },
  {
    path: "/:lang/pages/earn-money-with-dtp-app",
    name: "DtpLanding",
    component: DtpLandingPage
  },
  {
    path: "/:lang/pages/wear-vehicle",
    name: "WearVehicleCalculator",
    component: WearVehicleCalculatorPage
  },
  {
    path: "/:lang/pages/vehicle-mileage",
    name: "VehicleMileageCalculator",
    component: VehicleMileageCalculatorPage
  },
  {
    path: "/:lang/pages/news",
    name: "News",
    component: NewsPage
  },
  {
    path: "/:lang/pages/news/:id",
    name: "NewsDetails",
    component: NewsDetailsPage
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      el: from.hash,
      behavior: "smooth",
      top: 100,
      offset: {x: 0, y: 100}
    }
  }
})

router.beforeEach(async (to, from, next) => {
  let locale = localStorage.getItem("locale") || "ru"
  if (!localStorage.getItem("locale")) {
    localStorage.setItem("locale", locale)
  }

  if (to.params.lang !== locale) {
    if (to.path === "/") {
      next({name: "Home", params: {lang: locale}})
    } else {
      if (!to.params.lang) {
        next({path: `/${locale}${to.path}`, params: {lang: locale}, query: to.query})
      } else {
        next({name: to.name, params: {lang: locale}, query: to.query})
      }
    }
    return
  }

  if (
    to.name !== "Profile" &&
    to.name !== "Auth" &&
    to.name !== "Home" &&
    to.name !== "NewsDetails" &&
    to.name !== "News" &&
    to.name !== "Contacts" &&
    !hasQueryParams(to) &&
    hasQueryParams(from)
  ) {
    next({name: to.name, query: from.query})
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let accessToken = localStorage.getItem("access_token")
    const refreshToken = localStorage.getItem("refresh_token")

    if (!accessToken) {
      localStorage.clear()
      clearAxiosAuthHeader()
      next("/pages/auth")
      return
    }

    if (isTokenExpired(accessToken)) {
      if (refreshToken && !isTokenExpired(refreshToken)) {
        try {
          const refreshTokenUrl = `${import.meta.env.VITE_API_GATEWAY_URL}/auth/refresh-token`
          const response = await axios.get(refreshTokenUrl, {
            headers: {
              Authorization: `Bearer ${refreshToken}`
            }
          })
          accessToken = response.data.access_token
          localStorage.setItem("access_token", accessToken)
          setAxiosAuthHeader(accessToken)
          next()
        } catch {
          localStorage.clear()
          clearAxiosAuthHeader()
          next("/pages/auth")
        }
      } else {
        localStorage.clear()
        clearAxiosAuthHeader()
        next("/pages/auth")
      }
    } else {
      setAxiosAuthHeader(accessToken)
      next()
    }
  } else {
    next()
  }
})

export default router
