<script setup lang="ts">
import {ref} from "vue"
import type {PaymentType} from "@/interfaces/Payment/PaymentType"
import KaspiIcon from "@/components/icons/payment/KaspiIcon.vue"
import CardIcon from "@/components/icons/payment/CardIcon.vue"
import MasterCardIcon from "@/components/icons/payment/MasterCardIcon.vue"
import VisaCardIcon from "@/components/icons/payment/VisaCardIcon.vue"
import FreedomPayIcon from "@/components/icons/payment/FreedomPayIcon.vue"

interface Props {
  methods: PaymentType[]
  modelValue: PaymentType
}
const props = defineProps<Props>()
const emit = defineEmits(["update:modelValue"])
const currentPayment = ref(props.modelValue)
function select(method) {
  currentPayment.value = method
  emit("update:modelValue", method)
}

</script>
<template>
  <div class="payment-method__wrapper">
    <div
      v-for="method in methods"
      :key="method.type"
      @click="select(method)"
      class="payment-method__item"
      :class="currentPayment.type == method.type ? 'payment-method__item--active' : ''">
      <template v-if="method.type == 'KaspiPay'">
        <div class="payment-method__item-wrapper">
          <KaspiIcon />
          {{ $t("app.mstPage.form.paymentForm.kaspikz") }}
        </div>
      </template>

      <template v-if="method.type == 'FreedomPay'">
        <div class="payment-method__item-wrapper">
          <CardIcon />
          {{ $t("app.mstPage.form.paymentForm.bankCard") }}
          <span class="payment-method__item-icons">
            <VisaCardIcon />
            <MasterCardIcon />
            <FreedomPayIcon />
          </span>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.payment-method__wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
}
.payment-method__item {
  cursor: pointer;
  padding: 10px 20px 10px 10px;
  border-radius: 10px;
  background: rgba(226, 226, 226, 0.17);
  border: 1px solid transparent;
  &--active {
    border: 1px solid rgba(255, 255, 255, 0.5);
    background: rgba(0, 0, 0, 0.25);
    transition: background 0.3s ease;
  }
  &-wrapper {
    display: flex;
    justify-content: left;
    align-items: center;
    text-align: center;
    text-wrap: nowrap;
    gap: 10px;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &-icons {
    margin-left: 6px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
</style>
