<script setup lang="ts">
import {ref, computed, watch} from "vue"
import {storeToRefs} from "pinia"
import {vMaska} from "maska"
import {z} from "zod"
import {useComfortStore} from "@/stores/ComfortStore"
import {useValidation} from "@/composables/useValidation"
import {useHelpers} from "@/composables/useHelpers"
import {filterEmailInput} from "@/utils/inputEvents"
import {useToast} from "vue-toastification"
import {useI18n} from "vue-i18n"
import {
  whatsAppComfortMessageRu,
  whatsAppComfortMessageKk,
  whatsAppComfortMessageEn
} from "@/constants/whatsAppMessages"
import {filterLatinInput, filterCyrillicAndSpecialCharsInput, preventTextStartWithZero} from "@/utils/inputEvents"
import type {IHomeData} from "@/interfaces/Comfort/IHomeData"
import Dropdown from "primevue/dropdown"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import FFErrorMessage from "@/components/FFUI/FFErrorMessage.vue"
import FFCheckbox from "@/components/FFUI/FFCheckbox.vue"
import FFTooltip from "@/components/FFUI/FFTooltip.vue"
import WhatsAppIcon from "@/components/icons/WhatsAppIcon.vue"
import QuestionIcon from "@/components/icons/QuestionIcon.vue"

const comfortStore = useComfortStore()
const {homeDataObj, cities, isHomeDataComplete, isHomeDataCompleteWithoutIAmFamiliar} = storeToRefs(comfortStore)

const {openLink, currentLocale} = useHelpers()

const emit = defineEmits(["nextStep"])

const toast = useToast()
const {t} = useI18n()

const currentYear = new Date().getFullYear()
const homeDataSchema = z.object({
  city: z.object({
    code: z.string().nonempty(),
    name: z.string().nonempty()
  }),
  street: z.string().nonempty(),
  home: z.string().nonempty(),
  apartmentNumber: z.string().nonempty(),
  floor: z
    .string()
    .nonempty()
    .refine((value) => parseInt(value) <= parseInt(homeDataObj.value.overalFloor)),
  overalFloor: z
    .string()
    .nonempty()
    .refine((value) => parseInt(value) >= parseInt(homeDataObj.value.floor)),
  iAmFamiliar: z.literal<boolean>(true),
  homeAge: z
    .string()
    .nonempty()
    .refine((value) => {
      const year = parseInt(value, 10)
      return year >= 1970 && year <= currentYear
    }),
  email: z.string().email()
})

const {validate, isValid, getError} = useValidation(homeDataSchema, homeDataObj, {
  mode: "lazy"
})

const handleNextStep = async () => {
  await validate()
  if (parseInt(homeDataObj.value.floor) > parseInt(homeDataObj.value.overalFloor)) {
    toast.error(t("app.toasterMessages.comfort.floorCannotBeGreaterThanTotalFloor"))
  }
  if (isValid.value) {
    const response = comfortStore.getClientCardData()
    if (response) {
      emit("nextStep")
    } else {
      return
    }
  }
}

const autoProlongation = ref<boolean>(false)
const handleMouseOver = (type: string) => {
  if (type === "autoProlongation") {
    autoProlongation.value = true
  }
}
const handleMouseLeave = (type: string) => {
  if (type === "autoProlongation") {
    autoProlongation.value = false
  }
}

const preventStreetLatinInput = () => {
  homeDataObj.value.street = filterCyrillicAndSpecialCharsInput(homeDataObj.value.street)
}
const filterCyrillicAndSpecialCharsForHomeInput = () => {
  homeDataObj.value.home = filterCyrillicAndSpecialCharsInput(homeDataObj.value.home)
}
const preventTextStartFromZero = (input: keyof IHomeData, text: string) => {
  homeDataObj.value[input] = preventTextStartWithZero(text)
}
</script>

<template>
  <div class="comfort-data">
    <div class="comfort-data__location">
      <FFFieldset :isError="getError('city')" :title="$t('form.inputTitles.city')">
        <Dropdown
          v-model="homeDataObj.city"
          :placeholder="$t('form.inputPlaceholders.chooseCity')"
          :options="cities"
          optionLabel="name" />
      </FFFieldset>
    </div>
    <div class="comfort-data__location">
      <FFFieldset :isError="getError('street')" :title="$t('form.inputTitles.street')">
        <FFInput v-model="homeDataObj.street" @input="preventStreetLatinInput" inputMaxLength="30" type="text" />
      </FFFieldset>
    </div>
    <div class="comfort-data__apartment">
      <div>
        <FFFieldset :isError="getError('home')" :title="$t('form.inputTitles.homeNumber')">
          <FFInput
            id="home"
            v-model="homeDataObj.home"
            inputMaxLength="30"
            @input="filterCyrillicAndSpecialCharsForHomeInput"
            inputmode="numeric"
            type="text" />
        </FFFieldset>
      </div>
      <div>
        <FFFieldset :isError="getError('apartmentNumber')" :title="$t('form.inputTitles.apartmentNumber')">
          <FFInput
            v-maska
            data-maska="#####"
            id="apartmentNumber"
            v-model="homeDataObj.apartmentNumber"
            @input="preventTextStartFromZero('apartmentNumber', homeDataObj.apartmentNumber)"
            inputmode="numeric"
            type="text" />
        </FFFieldset>
      </div>
    </div>
    <div class="comfort-data__apartment">
      <div>
        <FFFieldset :isError="getError('floor')" :title="$t('form.inputTitles.floor')">
          <FFInput
            v-maska
            data-maska="##"
            id="floor"
            v-model="homeDataObj.floor"
            @input="preventTextStartFromZero('floor', homeDataObj.floor)"
            inputmode="numeric"
            type="text" />
        </FFFieldset>
      </div>
      <div>
        <FFFieldset :isError="getError('overalFloor')" :title="$t('form.inputTitles.overalFloor')">
          <FFInput
            v-maska
            data-maska="##"
            id="overalFloor"
            v-model="homeDataObj.overalFloor"
            @input="preventTextStartFromZero('overalFloor', homeDataObj.overalFloor)"
            inputmode="numeric"
            type="text" />
        </FFFieldset>
      </div>
    </div>
    <div class="comfort-data__apartment flex flex-col">
      <div>
        <FFFieldset :isError="getError('homeAge')" :title="$t('form.inputTitles.homeAge')" :clear="false">
          <FFInput
            v-maska
            data-maska="####"
            id="homeAge"
            v-model="homeDataObj.homeAge"
            type="text"
            inputmode="numeric"
            name="homeAge" />
        </FFFieldset>
      </div>
      <p class="comfort-data__home-age-text">{{ $t("app.comfortPage.forms.homeData.homeAgeNotLess1970") }}</p>
    </div>
    <div :class="{'mb-[20px]': !isHomeDataCompleteWithoutIAmFamiliar}">
      <FFFieldset :isError="getError('email')" :title="$t('form.inputTitles.email')" :clear="false">
        <FFInput
          id="email"
          v-model="homeDataObj.email"
          name="email"
          type="email"
          inputmode="email"
          @input="filterEmailInput" />
      </FFFieldset>
      <template v-if="!!getError('email')">
        <FFErrorMessage
          v-if="homeDataObj?.email?.length > 0 && getError('email')"
          :darkMode="false"
          :errorMessage="t('form.validationErrors.emailIsNotValid')" />
      </template>
    </div>
    <div v-show="isHomeDataCompleteWithoutIAmFamiliar" class="comfort-data__checkbox">
      <!-- <FFCheckbox v-model="homeDataObj.automaticMoneyCharge" class="flex">
        <template #title>
          {{ $t("app.comfortPage.forms.homeData.automaticMoneyCharge") }}
        </template>
        <QuestionIcon
          @mouseover="handleMouseOver('autoProlongation')"
          @mouseleave="handleMouseLeave('autoProlongation')"
          class="comfort-data__question-icon" />
      </FFCheckbox>
      <FFTooltip
        v-show="autoProlongation"
        :description="$t('app.comfortPage.forms.homeData.autoProlongation')"
        fontWeight="500"
        top="50px"
        left="0px" /> -->
      <FFCheckbox v-if="currentLocale === 'kk'" v-model="homeDataObj.iAmFamiliar">
        <template #title>
          {{ $t("form.checkboxTitles.iAgree.iAgree-1") }}
          <span @click="comfortStore.openComfortQuestionnairePdf" class="underline cursor-pointer">
            {{ $t("form.checkboxTitles.iAgree.iAgree-2") }}
          </span>
          {{ $t("form.checkboxTitles.iAgree.iAgree-3") }}
          <span @click="openLink('https://ffins.kz/kk/report-file/264', '_blank')" class="underline cursor-pointer">
            {{ $t("form.checkboxTitles.iAgree.iAgree-4") }}
          </span>
          {{ $t("form.checkboxTitles.iAgree.iAgree-5") }}
        </template>
      </FFCheckbox>
      <FFCheckbox v-else v-model="homeDataObj.iAmFamiliar">
        <template #title>
          {{ $t("form.checkboxTitles.iAgree.iAgree-1") }}
          <span @click="comfortStore.openComfortQuestionnairePdf" class="underline cursor-pointer">
            {{ $t("form.checkboxTitles.iAgree.iAgree-2") }}
          </span>
          {{ $t("form.checkboxTitles.iAgree.iAgree-3") }}
          <span @click="openLink('https://ffins.kz/ru/report-file/264', '_blank')" class="underline cursor-pointer"
            >{{ $t("form.checkboxTitles.iAgree.iAgree-4") }} {{ $t("form.checkboxTitles.iAgree.iAgree-5") }}</span
          >
        </template>
      </FFCheckbox>
    </div>

    <FFButton @click="handleNextStep" :disabled="!isHomeDataComplete" :title="$t('app.buttons.next')" />
  </div>
</template>

<style scoped lang="scss">
.comfort-data {
  margin-top: 30px;
  .comfort-data__location {
    margin-bottom: 20px;
  }

  .comfort-data__apartment {
    display: flex;
    gap: 10px;

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }

  .ff-checkbox {
    margin: 30px 0;
  }

  .comfort-data__home-age-text {
    color: #ffffff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .comfort-data__checkbox {
    position: relative;

    .comfort-data__question-icon {
      margin-left: 5px;
      cursor: pointer;
    }
  }

  .comfort-data__ask {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: #2e2e2e;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    margin: 0 auto;
    transition: all 0.3s ease;
    padding: 10px 0;
    margin-top: 30px;
    p {
      color: rgba(255, 255, 255, 0.5);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .comfort-data__ask:hover {
    scale: 1.1;
  }
}
</style>
