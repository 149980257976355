<script setup lang="ts">
import {ref, onUnmounted} from "vue"
import {useTerminationAgreementStore} from "@/stores/TerminationAgreementStore"
import {useRouter} from "vue-router"
import FFButton from "@/components/FFUI/FFButton.vue"

const terminationAgreement = useTerminationAgreementStore()
const router = useRouter()

const terminationInsSumIsZero = ref(localStorage.getItem("termination-type"))

onUnmounted(() => {
  localStorage.removeItem("termination-type")
})
</script>

<template>
  <div class="bg-image">
    <div class="container">
      <div class="sucess-wrapper">
        <div class="success">
          <img src="/termination-front/images/temination-cross.png" alt="temination cross image" />

          <p>{{ $t("app.successfullyTerminated.title") }}</p>
          <template v-if="terminationInsSumIsZero === 'ins-sum-is-zero'">
            <p>{{ $t("app.successfullyTerminated.subtitleWithoutRefund") }}</p>
          </template>
          <template v-if="terminationInsSumIsZero === 'cancelation'">
            <p>{{ $t("app.successfullyTerminated.subtitleCancelation") }}</p>
          </template>
          <template v-if="terminationInsSumIsZero === 'subscription-cancelation'">
            <p>{{ $t("app.successfullyTerminated.subtitleSubscriptionCancelation") }}</p>
          </template>
          <template v-if="!terminationInsSumIsZero">
            <p>{{ $t("app.successfullyTerminated.subtitle") }}</p>
          </template>

          <FFButton
            @click="router.push('/pages/personal-profile')"
            class="sucess-message__button"
            :title="$t('app.thankYou.login')"
            :uppercase="false"
            color="#4F9D3A"
            paddingx="15px"
            paddingy="30px"
            width="300px"
            border-pixel="1px solid #fff"
            background="#FFF" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.bg-image {
  background: url("/success-pay-front/images/success-pay-bg.png") no-repeat top;
  height: 100%;
}
.sucess-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 107px 0;

  .success {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    p:first-of-type {
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 900;
      line-height: 100%;
      background: linear-gradient(103deg, #bde0ff -2.91%, #1cd491 58.89%, #00ff45 106.95%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    p:last-of-type {
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      background: linear-gradient(103deg, #bde0ff -2.91%, #1cd491 58.89%, #00ff45 106.95%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .sucess-message__button {
    }
  }
}

@media (max-width: $lg) {
  .thank-you {
    padding: 50px 0;
    gap: 50px;
  }
}
</style>
