<script lang="ts" setup>
import {onMounted, ref} from "vue"

import {storeToRefs} from "pinia"
import {useFetcher} from "@/composables/useFetcher"
import type {CalculationType} from "@/interfaces/MST/CalculationType"
import type {PaymentType} from "@/interfaces/Payment/PaymentType"
import {useFormatters} from "@/utils/formatters"
import {useMSTStore} from "@/stores/MSTStore"
import {throttle} from "lodash-es"

import FFForm from "@/components/FFUI/FFForm.vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import Modal from "@/components/FFUI/Modal.vue"
import ModalHeader from "@/components/FFUI/ModalHeader.vue"

import CalculateForm from "@/components/MST/Form/CalculateForm.vue"
import LoginForm from "@/components/MST/Form/LoginForm.vue"
import PaymentMethod from "@/components/MST/Form/PaymentMethod.vue"
import PremiumAmount from "@/components/MST/Form/PremiumAmount.vue"
import KaspiPayment from "@/components/MST/Form/KaspiPayment.vue"
import type {CalculationCredentials} from "@/interfaces/Auth/CalculationCredentials"
import CloseIcon from "@/components/icons/socials/CloseIcon.vue"
import PersonalDataForm from "@/components/MST/Form/PersonalDataForm.vue"
import Loader from "@/components/FFUI/Loader.vue"

const {getData, postData} = useFetcher()
const {phoneNumberFormatter} = useFormatters()

const mstStore = useMSTStore()
const {loading} = storeToRefs(mstStore)

interface IProps {
  isModal: boolean
  currentStep: number
  handleStepBack: () => void
  forward: () => void
  toggleModal: () => void
  reset: () => void
}

const props = defineProps<IProps>()
const emit = defineEmits(["stepBackToCalcForm"])

const data = ref()
const waitingPayment = ref<boolean>(false)
const kaspiPayModal = ref<boolean>(false)
const orderNumber = ref<string>("")

onMounted(async () => {
  data.value = await getData("/core/mst/data")
})
const credentials = ref<CalculationCredentials | null>()
const calculation = ref<CalculationType>()
const payment = ref({
  title: "freedomPay",
  type: "FreedomPay"
})
const paymentMethods: PaymentType[] = [
  {
    title: "freedomPay",
    type: "FreedomPay"
  },
  {
    title: "kaspiPay",
    type: "KaspiPay"
  }
]

const personalData = ref()

const kaspiBillIsSent = ref<boolean>(false)

function showCalculationForm(insurant: CalculationCredentials) {
  if (props.currentStep == 0) {
    props.forward()
    props.toggleModal()
  }
  credentials.value = insurant
  props.forward()
}

function saveCalculation(calculatedAmount: CalculationType) {
  calculation.value = calculatedAmount
  props.forward()
}

function toPayment(data) {
  personalData.value = data
  props.forward()
}

function prepareSaveRequest() {
  return {
    personal: {
      contract: {
        calculateId: calculation.value?.guid,
        insuranceFormDate: calculation.value?.dateStart,
        insuranceTillDate: calculation.value?.dateStop
      },
      policyHolder: {
        holderIin: credentials.value?.identifier,
        phone: credentials.value?.phone,
        email: personalData.value.email,
        firstName: credentials.value?.firstName,
        lastName: credentials.value?.lastName,
        middleName: credentials.value?.middleName
      },
      insureds: personalData.value.insured
    },
    calculation: calculation.value,
    payment: payment.value,
    kaspiPayNumber: phoneNumberFormatter(mstStore.kaspiPayPhoneNumber)
  }
}

let shortPolling = ref()
const paymentUrl = ref(null)
const checkOrder = async (id) => {
  try {
    const response = await postData("/core/mst/order/check", {order_id: id})
    if (response && response.data.status === "activated") {
      clearInterval(shortPolling.value)
      window.location.href = "thank-you"
    }
    if (response?.status === 400) {
      emit("stepBackToCalcForm")
      paymentUrl.value = null
      mstStore.resetCalculationForm()
    }
  } catch (error) {
    console.error(error)
  }
}

const createOrder = throttle(async () => {
  try {
    if (payment.value.type === "FreedomPay") {
      const response = await postData("/core/mst/order/create", prepareSaveRequest())
      if (response && response?.status === 200) {
        waitingPayment.value = true
        shortPolling.value = setInterval(() => checkOrder(response.data.order_id), 5000)
        paymentUrl.value = response.data.payment_url
      } else {
        emit("stepBackToCalcForm")
        paymentUrl.value = null
        mstStore.resetCalculationForm()
      }
    }
    if (payment.value.type === "KaspiPay") {
      kaspiPayModal.value = true
    }
  } catch (error) {
    console.error(error)
  }
}, 5000)

const createOrderInKaspi = throttle(async () => {
  try {
    const response = await postData("/core/mst/order/create", prepareSaveRequest())
    if (response && response.status === 400) {
      emit("stepBackToCalcForm")
      paymentUrl.value = null
      mstStore.resetCalculationForm()
    }
    return response
  } catch (error) {
    console.error(error)
  }
}, 5000)
</script>

<template>
  <Modal style="z-index: 101" :isOpen="loading">
    <Loader />
  </Modal>

  <Modal style="z-index: 101" :isOpen="waitingPayment">
    <div v-if="paymentUrl !== null" class="ff-payment-modal">
      <iframe :src="paymentUrl" frameborder="0" width="320px" height="640px"> </iframe>
    </div>
  </Modal>

  <div class="ff-form-desktop">
    <FFForm class="scrollbar-hide">
      <LoginForm @success="showCalculationForm" :forward="props.forward" />
    </FFForm>
  </div>

  <Modal style="z-index: 100" :isOpen="isModal">
    <FFForm class="scrollbar-hide" v-if="currentStep !== 4">
      <ModalHeader v-if="currentStep > 1" @stepBack="handleStepBack" :current-step="currentStep" :total-steps="4" />
      <template v-if="currentStep === 1">
        <CloseIcon class="ff-form-close" @click="reset" />

        <LoginForm @success="showCalculationForm" />
      </template>

      <template v-if="currentStep === 2">
        <CalculateForm :data="data" @success="saveCalculation" />
      </template>

      <template v-if="currentStep === 3">
        <PersonalDataForm :credentials="credentials" @success="toPayment" />
      </template>

      <template v-if="currentStep === 4">
        <PremiumAmount :amount="calculation.premium" />
        <PaymentMethod :methods="paymentMethods" v-model="payment" />
        <FFButton :title="$t('mst.form.personal_data.to_pay')" @click="createOrder" />
      </template>
    </FFForm>
    <FFForm class="scrollbar-hide" v-if="currentStep === 4">
      <ModalHeader
        v-if="currentStep > 1"
        @stepBack="kaspiPayModal ? (kaspiPayModal = false) : handleStepBack()"
        :current-step="currentStep"
        :total-steps="4"
        :title="kaspiPayModal ? $t('app.mstPage.form.payThroughKaspi') : ''" />
      <template v-if="kaspiPayModal">
        <KaspiPayment :createOrderInKaspi="createOrderInKaspi" :orderNumber="orderNumber" />
      </template>
      <template v-else>
        <PremiumAmount :amount="calculation.premium" />
        <PaymentMethod :methods="paymentMethods" v-model="payment" />
        <FFButton :title="$t('app.mstPage.form.paymentForm.goToPay')" @click="createOrder" />
      </template>
    </FFForm>
  </Modal>
</template>

<style scoped lang="scss">
.ff-form-close {
  position: absolute;
  cursor: pointer;
  top: 25px;
  left: 28px;
}

.ff-form {
  overflow-y: scroll;
  overflow-x: hidden;
}

.ff-modal-header {
  margin-bottom: 30px;
}

@media (max-width: 1024px) {
  .ff-form-desktop {
    display: none;
  }
  .ff-form-mobile-btn {
    display: block;
    z-index: 1;
  }
}

.dp__input {
  --dp-background-color: transparent;
  color: white;
  border: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.dp__icon {
  fill: #fff;
}

.mst-datepicker {
  gap: 4px;
  display: flex;
}

.ff-payment-modal {
  width: 320px;
  height: max-content;
  display: grid;
  border-radius: 20px;
  border: 6px solid rgba(255, 255, 255, 0.63);
  background: white;
}
</style>
