<script setup lang="ts"></script>

<template>
  <div class="awards container">
    <div class="awards__content">
      <p class="awards__content--title">Global Banking & Finance Awards</p>
      <p class="awards__content--description">{{ $t("main.awards.title") }}</p>
      <div class="awards__content--text">
        {{ $t("main.awards.description") }}
        <br />
        <br />
        <ol>
          <li>Best Online Insurance Company Kazakhstan 2021;</li>
          <li>Best General Insurance Company for Innovation Kazakhstan 2021;</li>
          <li>Best Auto Insurance Company Kazakhstan 2021.</li>
        </ol>
      </div>
    </div>
    <img src="/main-front/images/awards.png" alt="awards" />
  </div>
</template>

<style scoped lang="scss">
.awards {
  padding: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 33px;
  border-radius: 10px;
  background: linear-gradient(270deg, #0c0b10 23.99%, rgba(80, 80, 80, 0.24) 104.74%);
  backdrop-filter: blur(5px);
  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    &--title {
      color: #fff;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.65px;
      text-transform: uppercase;
    }
    &--description {
      font-size: 30px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      text-transform: uppercase;
      background: linear-gradient(95deg, #79b0e2 -3.54%, #1cd491 56.19%, #3fed6e 102.64%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &--text {
      color: #d6d6d6;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      ol {
        list-style: none;
        counter-reset: my-counter;
        padding: 0 0 0 7px;
      }

      ol li {
        counter-increment: my-counter;
        display: flex;
      }

      ol li:before {
        content: counter(my-counter) ". ";
        margin-right: 5px;
        font-weight: bold;
      }
    }
  }
}

@media (max-width: $xl) {
  .awards {
    &__content {
      &--title {
        font-size: 12px;
      }
      &--description {
        font-size: 20px;
      }
    }
  }
}

@media (max-width: $lg) {
  .awards {
    padding: 30px 20px;
    flex-direction: column;
    &__content {
      &--description {
        font-size: 30px;
      }
    }
    img {
      width: 100%;
    }
  }
}
</style>
