<script setup lang="ts">
import {storeToRefs} from "pinia"
import {useVmsStore} from "@/stores/PolicyVmsStore"
import {useHelpers} from "@/composables/useHelpers"
import VmsRow from "@/components/PolicyVms/VmsRow.vue"
import CalendarIcon from "@/components/icons/CalendarIcon.vue"
import FioIcon from "@/components/icons/FioIcon.vue"
import BarCodeIcon from "@/components/icons/BarCodeIcon.vue"
import TimerIcon from "@/components/icons/TimerIcon.vue"

const vmsStore = useVmsStore()
const {vmsData} = storeToRefs(vmsStore)
const {currentLocale} = useHelpers()
</script>

<template>
  <div class="policy-vms__block">
    <img class="policy-vms__block-briefcase" loading="lazy" src="/vms-front/images/briefcase-machine.png" alt="" />
    <div class="policy-vms__block-row-header">
      <h2 class="policy-vms__title">{{ $t("app.vmsPage.policy") }} {{ vmsData?.product_type }}</h2>
      <div class="policy-vms__global-id">№ {{ vmsData?.global_id }}</div>
    </div>
    <VmsRow>
      <template #icon>
        <CalendarIcon />
      </template>
      <template #title>{{ $t("app.vmsPage.validityPeriod") }}:</template>
      <template #paragraph>
        <p v-if="currentLocale === 'kk'" class="policy-vms__paragraph">
          {{ vmsData?.from_date }} {{ $t("app.vmsPage.from") }} {{ vmsData?.till_date }} {{ $t("app.vmsPage.to") }}
        </p>
        <p v-else class="policy-vms__paragraph">
          {{ $t("app.vmsPage.from") }} {{ vmsData?.from_date }} {{ $t("app.vmsPage.to") }} {{ vmsData?.till_date }}
        </p>
      </template>
    </VmsRow>
    <div class="policy-vms__hr">
      <hr />
      <p>{{ $t("app.vmsPage.insurer") }}</p>
      <hr />
    </div>
    <VmsRow class="mb-[20px]">
      <template #icon>
        <FioIcon />
      </template>
      <template #title>{{ $t("app.vmsPage.fio") }}:</template>
      <template #paragraph>{{ vmsData?.holder_fio }}</template>
    </VmsRow>
    <!-- <VmsRow>
      <template #icon>
        <BarCodeIcon />
      </template>
      <template #title>№ паспорта:</template>
      <template #paragraph>FA4399180</template>
    </VmsRow> -->
    <div class="policy-vms__hr">
      <hr />
      <p>{{ $t("app.vmsPage.insureds") }}</p>
      <hr />
    </div>
    <template v-for="(data, index) in vmsData?.insureds" :key="index">
      <VmsRow class="mb-[20px]">
        <template #icon>
          <FioIcon />
        </template>
        <template #title>{{ $t("app.vmsPage.fio") }}:</template>
        <template #paragraph>{{ data.fullName }}</template>
      </VmsRow>
    </template>
    <!-- <VmsRow>
      <template #icon>
        <BarCodeIcon />
      </template>
      <template #title>№ паспорта:</template>
      <template #paragraph>FA4399180</template>
    </VmsRow> -->
    <hr class="policy-vms__hr" />
    <VmsRow>
      <template #icon>
        <TimerIcon />
      </template>
      <template #title>{{ $t("app.vmsPage.status") }}:</template>
      <template #paragraph>{{ vmsData?.status }}</template>
    </VmsRow>
  </div>
</template>

<style scoped lang="scss">
.policy-vms__block {
  padding: 30px 20px;
  border-radius: 10px;
  background: #e9e9e9;
  max-width: 650px;
  width: 100%;
  height: fit-content;
  position: relative;

  .policy-vms__paragraph {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: 0.2px;
    background: linear-gradient(132deg, #2f4d3b -0.69%, #162522 103.63%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: $lg) {
      max-width: 100px;
    }
  }

  .policy-vms__block-briefcase {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  hr {
    width: 100%;
    background: var(--Greyscale-500, #797979);
    height: 2px;
    opacity: 0.5;
  }

  .policy-vms__hr {
    margin: 20px 0;
    display: flex;
    align-items: center;
    p {
      margin: 0 12px;
      color: #282828;
      text-align: center;
      font-size: 10px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      letter-spacing: 0.2px;
      text-transform: uppercase;
      opacity: 0.5;
    }
  }

  .policy-vms__block-row-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    .policy-vms__title {
      font-size: 28px;
      font-style: normal;
      font-weight: 900;
      line-height: 28px;
      background: linear-gradient(228deg, #40bf7a -9.01%, #289112 99.01%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .policy-vms__global-id {
      color: #ffffff;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.2px;
      padding: 2px 8px;
      border-radius: 10px;
      background: #525252;
      white-space: nowrap;
    }
  }
}
</style>
