<script setup lang="ts">
import {ref} from "vue"
import {storeToRefs} from "pinia"
import {useModalStore} from "@/stores/ModalStore"
import {usePersonalProfileStore} from "@/stores/PersonalProfileStore"
import {subscriptionPaid, subscriptionWaitingForPayment} from "@/constants/policy"
import {useFormatters} from "@/utils/formatters"
import FFButton from "@/components/FFUI/FFButton.vue"
import CloseIcon from "@/components/icons/socials/CloseIcon.vue"
import PaidGreenIcon from "@/components/icons/payment/PaidGreenIcon.vue"
import WaitingForPaymentIcon from "@/components/icons/payment/WaitingForPaymentIcon.vue"

const modalStore = useModalStore()
const personalProfileStore = usePersonalProfileStore()
const {
  currentOpenedPolicySubscription,
  subscriptionPaymentInfoData,
  lastTwoPaymentInfo,
  groupedPaymentHistory,
  totalSubscriptionPaymentInfoDataAmount,
  showPaymentHistory
} = storeToRefs(personalProfileStore)
const {thousandSeparator} = useFormatters()

const calendarIsOpen = ref<boolean>(false)

const handleCloseCardModal = () => {
  currentOpenedPolicySubscription.value = null
  modalStore.closeModal()
}
</script>

<template>
  <div class="profile-card-popup">
    <p class="profile-card-popup__title">
      {{ !showPaymentHistory ? $t("app.personalCabinetPage.subscriptionCard.bankCard") : "История платежей" }}
    </p>
    <template v-if="!showPaymentHistory">
      <CloseIcon @click="handleCloseCardModal" class="profile-card-popup__close" />
      <div class="profile-card-popup__card-info">
        <img src="/user-profile-front/images/polis-card.svg" alt="icon" />
        <p>{{ currentOpenedPolicySubscription?.card?.cardHash }}</p>
      </div>
      <FFButton
        @click="personalProfileStore.replaceCard()"
        :title="$t('app.buttons.replaceCard')"
        background="transparent"
        color="var(--color-white)"
        backgroundMobile="transparent"
        borderPixel="1px solid var(--color-white)"
        borderPixelMobile="1px solid var(--color-white)"
        colorMobile="var(--color-white)"
        width="100%"
        :uppercase="false"
        size="14px" />
      <div class="profile-card-popup__payment-information">
        <div class="profile-card-popup__title-wrapper">
          <hr />
          <p>{{ $t("app.personalCabinetPage.subscriptionCard.paymentInformation") }}</p>
          <hr />
        </div>
        <div class="profile-card-popup__payment">
          <div v-for="item in lastTwoPaymentInfo" :key="item.monthIndex" class="profile-card-popup__payment-item">
            <div class="profile-card-popup__payment-left-part">
              <p>{{ thousandSeparator(item?.writeOffAmount) }} {{ $t("form.formAbbreviations.tg") }}.</p>
              <p>{{ item?.writeOffDate }}</p>
            </div>
            <div class="profile-card-popup__payment-right-part">
              <p>{{ item?.statusCodeTitle }}</p>
              <PaidGreenIcon v-if="item?.statusCode === subscriptionPaid " />
              <WaitingForPaymentIcon v-if="item?.statusCode === subscriptionWaitingForPayment" />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div
        v-for="item in groupedPaymentHistory"
        :key="item.cardPan + item.payments[0].monthIndex"
        class="profile-card-popup__card-history-items">
        <div class="profile-card-popup__card-history-item">
          <hr />
          <p>{{ $t("app.personalCabinetPage.card") }} **** {{ item?.cardPan?.slice(-4) }}</p>
          <hr />
        </div>
        <ul>
          <li v-for="(payment, index) in item.payments" :key="index">
            <span>{{ index + 1 }}. {{ payment.writeOffDate }}</span>
            <span>{{ thousandSeparator(payment.writeOffAmount) }} {{ $t("form.formAbbreviations.tg") }}.</span>
          </li>
        </ul>
        <div class="profile-card-popup__card-result">
          <p class="profile-card-popup__card-result-total">
            {{ $t("app.personalCabinetPage.subscriptionCard.total") }}:
          </p>
          <div class="profile-card-popup__card-result-right-part">
            <p>
              {{ thousandSeparator(totalSubscriptionPaymentInfoDataAmount) }} {{ $t("form.formAbbreviations.tg") }}.
            </p>
            <p v-if="groupedPaymentHistory.length === 1">
              {{ groupedPaymentHistory.length }} {{ $t("app.personalCabinetPage.subscriptionCard.payment") }}
            </p>
            <p v-else>
              {{ groupedPaymentHistory.length }} {{ $t("app.personalCabinetPage.subscriptionCard.payments") }}
            </p>
          </div>
        </div>
      </div>
    </template>
    <FFButton
      @click="showPaymentHistory = !showPaymentHistory"
      :title="showPaymentHistory ? $t('app.buttons.back') : $t('app.buttons.paymentHistory')"
      background="transparent"
      color="var(--color-white)"
      backgroundMobile="transparent"
      borderPixel="1px solid var(--color-white)"
      borderPixelMobile="1px solid var(--color-white)"
      colorMobile="var(--color-white)"
      width="100%"
      :uppercase="false"
      size="14px" />
  </div>
</template>

<style scoped lang="scss">
.v-enter-active {
  transition: opacity 0.5s ease;
}

.v-leave-active {
  transition: opacity 0s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.profile-card-popup {
  position: relative;
  margin: auto;
  z-index: 1002;
  padding: 20px 16px;
  border-radius: 10px;
  background: #404040;
  min-width: 343px;
  max-width: 343px;

  @include column;
  padding: 20px 16px;

  .profile-card-popup__title {
    color: var(--color-white);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .profile-card-popup__close {
    position: absolute;
    top: 8px;
    right: 6px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .profile-card-popup {
    position: absolute;
    top: 28px;
    left: 21px;
  }
  .profile-card-popup__card-info {
    @include flex;
    gap: 10px;
    margin: 24px 0 16px;
    align-items: center;

    img {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(305deg) brightness(103%) contrast(103%);
    }

    p {
      color: var(--color-white);
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
    }
  }
  .profile-card-popup__payment-information {
    @include column;
    margin: 24px 0 16px;
    .profile-card-popup__title-wrapper {
      @include flex;
      justify-content: center;
      align-items: center;
      opacity: 0.8;
      hr {
        height: 1px;
        flex: 1 0 0;
        border-radius: 50px;
        background: var(--color-white);
      }
      p {
        color: var(--color-white);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 0 12px;
      }
    }
    .profile-card-popup__payment {
      @include column;
      .profile-card-popup__payment-item {
        @include between;
        &:first-of-type {
          padding: 15px 0 8px;
        }
        &:last-of-type {
          border-top: 1px solid #7b7b7b;
          border-bottom: 1px solid #7b7b7b;
          padding: 20px 0 8px;
        }
        .profile-card-popup__payment-left-part {
          @include column;
          p:first-of-type {
            color: var(--color-white);
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 22.4px;
          }
          p:last-of-type {
            color: #7b7b7b;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 19.6px;
          }
        }
        .profile-card-popup__payment-right-part {
          @include center;
          gap: 4px;
          p {
            color: #7b7b7b;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 19.6px;
          }
        }
      }
    }
  }
  .profile-card-popup__calendar {
    @include column;
    gap: 10px;
    padding: 30px 20px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.1);
    .profile-card-popup__calendar-title {
      color: #4faf3b;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
    .profile-card-popup__calendar-description {
      color: rgba(255, 255, 255, 0.7);
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
    .profile-card-popup__calendar-content-list {
      @include column;
      gap: 4px;
      .profile-card-popup__calendar-content-list-header {
        @include flex;
        .profile-card-popup__calendar-content-list-header-date {
          text-align: start;
        }
        .profile-card-popup__calendar-content-list-header-sum {
          margin-left: 31px;
        }
        .profile-card-popup__calendar-content-list-header-status {
          text-align: start;
          white-space: nowrap;
          margin-right: 3px;
        }
        p {
          color: #4faf3b;
          font-size: 12px;
          font-weight: 700;
          line-height: 22.54px;
          flex: 1;
        }
      }
      .profile-card-popup__calendar-content-item {
        @include flex;
        gap: 10px;

        .profile-card-popup__calendar-content-item-index,
        .profile-card-popup__calendar-content-item-date,
        .profile-card-popup__calendar-content-item-premium,
        .profile-card-popup__calendar-content-item-status {
          color: #4faf3b;
          font-size: 14px;
          font-weight: 700;
          line-height: 22.54px;
          flex: 1;

          // Adjust font size or layout on smaller screens
          @include max-responsive(lg) {
            font-size: 12px;
            line-height: 18px;
            min-width: 50px;
          }
        }

        .profile-card-popup__calendar-content-item-date {
          max-width: 79px;
        }
        .profile-card-popup__calendar-content-item-index {
          min-width: 6px;
          max-width: 7px;
          text-align: right;
        }
        .profile-card-popup__calendar-content-item-premium {
          max-width: 61px;
          white-space: nowrap;
        }
        .profile-card-popup__calendar-content-item-status {
          text-align: start;
          white-space: nowrap;
        }
      }
    }
  }
  .profile-card-popup__card-history-items {
    @include column;
    margin: 24px 0;
    .profile-card-popup__card-history-item {
      @include between;
      align-items: center;
      opacity: 0.8;
      hr {
        height: 1px;
        flex: 1 0 0;
        border-radius: 50px;
        background: var(--color-white);
      }
      p {
        color: var(--color-white);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 0 8px;
      }
    }
    ul {
      margin-bottom: 24px;
      li {
        @include between;
        margin-top: 6px;
        span {
          color: #a7a7a7;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 22.54px;
        }
      }
    }
    .profile-card-popup__card-result {
      @include flex;
      justify-content: space-between;
      .profile-card-popup__card-result-total {
        color: var(--color-white);
        font-size: 14px;
        font-style: normal;
        font-weight: 900;
        line-height: 22.54px;
        text-transform: uppercase;
      }
      .profile-card-popup__card-result-right-part {
        @include column;
        gap: 8px;
        text-align: right;
        p {
          color: var(--color-white);
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }
  }
}

.paid {
  text-decoration: line-through;
  color: rgba(79, 175, 59, 0.5) !important;
}
</style>
