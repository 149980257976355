<script setup lang="ts">
import {ref} from "vue"
import DocumentsHero from "@/components/Documents/DocumentsHero.vue"
import DocumentsList from "@/components/Documents/DocumentsList.vue"
import Modal from "@/components/FFUI/Modal.vue"
import Loader from "@/components/FFUI/Loader.vue"

const loading = ref<boolean>(false)
</script>

<template>
  <Modal style="z-index: 103" :isOpen="loading">
    <Loader />
  </Modal>
  <div class="documents">
    <DocumentsHero />
    <DocumentsList @update:loading="loading = $event" />
  </div>
</template>

<style scoped lang="scss"></style>
