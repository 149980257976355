import {ref, computed, watch} from "vue"
import {defineStore, storeToRefs} from "pinia"
import {useI18n} from "vue-i18n"
import {useGatewayFetcher} from "@/composables/useGatewayFetcher"
import {useRouter, useRoute} from "vue-router"
import {useAuthStore} from "@/stores/AuthStore"
import {useModalStore} from "@/stores/ModalStore"
import {useHelpers} from "@/composables/useHelpers"
import type {IReason} from "@/interfaces/TerminationAgreement/IReason"
import type {ITermininationPolicyResponse} from "@/interfaces/TerminationAgreement/ITerminationPolicyResponse"
import type {ISubscriptionTermininationPolicyResponse} from "@/interfaces/TerminationSubscriptionAgreement/ISubscriptionTermininationPolicyResponse"
import type {ICalculationSubscriptionTerminationResponse} from "@/interfaces/TerminationSubscriptionAgreement/ICalculationSubscriptionTerminationResponse"
import FFOtp from "@/components/FFUI/FFOtp.vue"
import TerminationNeedBiometriModal from "@/components/TerminationAgreement/TerminationNeedBiometriModal.vue"

export const useTerminationSubscriptionAgreementStore = defineStore("terminationSubscriptionAgreementStore", () => {
  const {getData, postData, loading} = useGatewayFetcher()
  const {currentLocale, openLink} = useHelpers()
  const authStore = useAuthStore()
  const modalStore = useModalStore()
  const {accessToken, localStorageAccessToken} = storeToRefs(authStore)
  const {t} = useI18n()
  const router = useRouter()
  const route = useRoute()

  const identificationCard = ref<File | null>(null)
  const withoutRefund = ref<boolean>(false)
  const subscriptionWithoutRefund = ref<boolean>(false)

  const terminationByGlobalId = ref<ITermininationPolicyResponse | null>(null)
  const getTerminationAgreementDataByGlobalId = async (globalId: string, product_code: string) => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await postData(
        "/auth/policy",
        {
          globalId: globalId,
          productCode: product_code
        },
        config
      )
      if (response?.status === 200) {
        terminationByGlobalId.value = response?.data?.data
        return true
      } else {
        return false
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const terminationBySubsRegNum = ref<ISubscriptionTermininationPolicyResponse | null>(null)
  const getTerminationSubscriptionAgreementBySubsRegNum = async (subsRegNum: string) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorageAccessToken}`,
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await postData(
        "/auth/policy/subscription",
        {
          subsRegNum: subsRegNum
        },
        config
      )
      if (response?.status === 200) {
        terminationBySubsRegNum.value = response.data
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  //Termination reasons
  const terminationReason = ref<null | IReason>(null)
  const selectedReason = ref(null)
  const terminationReasons = ref([])
  const getTerminationReasons = async () => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }

      let endpoint
      if (isProductCodeComfort.value || isProductCodeHomeProtect.value) {
        endpoint = "/policy/terminate/reasons/2"
      } else if (
        isProductCodeOgpo.value ||
        isProductCodeOgpoPlus.value ||
        isProductCodeKasko.value ||
        isProductCodeKaskoSubscription.value
      ) {
        endpoint = "/policy/terminate/reasons/1"
      } else {
        endpoint = "/policy/terminate/reasons/3"
      }
      const response = await getData(endpoint, config)
      if (response?.data) {
        const data = response.data
        terminationReasons.value = data
      }
    } catch (error) {
      console.error(error)
    }
  }
  const resetTerminationReason = () => {
    terminationReason.value = null
  }

  //Product check
  const isProductCodeOgpo = computed(() => {
    return (
      terminationByGlobalId?.value?.product_code === "OS_GPO_VTS" || terminationByGlobalId?.value?.product_code === "9"
    )
  })
  const isProductCodeOgpoPlus = computed(() => {
    return terminationByGlobalId?.value?.product_code === "OGPOPLUS"
  })
  const isProductCodeKasko = computed(() => {
    return (
      terminationByGlobalId?.value?.product_code === "KASKO_CLASSIC" ||
      terminationByGlobalId?.value?.product_code === "301" ||
      terminationByGlobalId?.value?.product_code === "КАСКО KASKO_EXPRESS" ||
      terminationByGlobalId?.value?.product_code === "KASKO_EXPRESS" ||
      terminationByGlobalId?.value?.product_code === "KASKO_VIP"
    )
  })
  const isProductCodeKaskoSubscription = computed(() => {
    const productCode = route.params.product
    return (
      productCode === "KASKO_CLASSIC" ||
      productCode === "301" ||
      productCode === "КАСКО KASKO_EXPRESS" ||
      productCode === "KASKO_EXPRESS" ||
      productCode === "KASKO_VIP"
    )
  })
  const isProductCodeComfort = computed(() => {
    return (
      terminationByGlobalId?.value?.product_code === "367" ||
      terminationByGlobalId?.value?.product_code === "Freedom Comfort" ||
      terminationByGlobalId?.value?.product_code === "FFINS_COMFORT"
    )
  })
  const isProductCodeMst = computed(() => {
    return terminationByGlobalId?.value?.product_code === "TOURISM_INS"
  })
  const isProductCodeHomeProtect = computed(() => {
    return terminationByGlobalId?.value?.product_code === "HOME_PROTECT"
  })

  //Calculation
  const terminationInsSum = ref<number | null>(null)
  const terminationOgpoPlusSum = ref<number | null>(null)
  const terminationOgpoSum = ref<number | null>(null)
  const isTerminationInsSumGreterThan2Mrp = computed(() => {
    return terminationInsSum.value && terminationInsSum.value > 500
  })
  const calculationTerminationResponse = ref(null)
  const calculateTerminateSum = async (
    globalId: string,
    productCode: string,
    reason: string,
    withoutRefund: boolean
  ) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken.value}`,
          "Accept-Language": currentLocale.value
        }
      }
      const payload = {
        globalId,
        productCode,
        reason,
        withoutRefund
      }
      let endpoint
      switch (productCode) {
        case "KASKO_CLASSIC":
        case "301":
        case "КАСКО KASKO_EXPRESS":
        case "KASKO_VIP":
          endpoint = "/auth/policy/kasko/terminate/calculate"
          break
        case "367":
        case "Freedom Comfort":
          endpoint = "/auth/policy/comfort/terminate/calculate"
          break
        case "OS_GPO_VTS":
        case "OGPOPLUS":
          endpoint = "/auth/policy/terminate/calculate"
          break
        default:
          endpoint = "/auth/policy/terminate/calculate"
          break
      }

      const response = await postData(endpoint, payload, config)
      if (response?.status === 200) {
        calculationTerminationResponse.value = response?.data?.data
        terminationInsSum.value = response?.data?.data?.refundAmount
        if (response?.data?.data?.ogpoPlusRefundAmount !== null && response?.data?.data?.ogpoRefundAmount !== null) {
          terminationOgpoPlusSum.value = response?.data?.data?.ogpoPlusRefundAmount
          terminationOgpoSum.value = response?.data?.data?.ogpoRefundAmount
        }
        return true
      } else {
        return false
      }
    } catch (error) {
      console.error(error)
    }
  }

  const calculationSubscriptionTerminationResponse = ref<null>(null)
  const calculateSubscriptionTerminateSum = async (subsRegNum: string, reason: string, isWithoutRefund: boolean) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken.value}`,
          "Accept-Language": currentLocale.value
        }
      }
      const payload = {
        subsRegNum,
        reason,
        isWithoutRefund
      }
      const productCode = route.params.product
      let endpoint
      switch (productCode) {
        case "KASKO_CLASSIC":
        case "301":
        case "КАСКО KASKO_EXPRESS":
        case "KASKO_EXPRESS":
        case "KASKO_VIP":
        case "367":
        case "Freedom Comfort":
          if (terminationBySubsRegNum.value?.isCancellable) {
            endpoint = "/auth/policy/subscription/cancellation/calculate"
            break
          } else {
            endpoint = "/auth/policy/subscription/terminate/calculate"
            break
          }
        default:
          break
      }

      const response = await postData(endpoint, payload, config)
      if (response?.status === 200) {
        calculationSubscriptionTerminationResponse.value = response?.data
        return true
      } else {
        return false
      }
    } catch (error) {
      console.error(error)
    }
  }

  watch(
    withoutRefund,
    () => {
      calculateTerminateSum(
        terminationByGlobalId.value?.global_id,
        terminationByGlobalId.value?.product_code,
        selectedReason.value.reasonCode,
        withoutRefund?.value
      )
    },
    {immediate: false}
  )

  watch(
    subscriptionWithoutRefund,
    () => {
      calculateSubscriptionTerminateSum(
        terminationBySubsRegNum.value?.subscriptionRegNum,
        selectedReason.value.reasonCode,
        subscriptionWithoutRefund?.value
      )
    },
    {immediate: false}
  )

  //OTP
  const otpCode = ref<string | number>()
  const showOtp = ref<boolean>(false)
  const codeIsSent = ref<boolean>(false)
  const sentOtpResponse = ref<{message: string; status: boolean}>()
  const timer = ref(0)
  const localStorageCurrentUser = JSON.parse(localStorage.getItem("current-user"))?.data || null

  const buttonTitle = computed(() => {
    if (checkOtpResponse.value?.status) {
      return t("app.buttons.sendCode")
    } else if (timer.value > 0) {
      const minutes = Math.floor(timer.value / 60)
      const seconds = timer.value % 60
      return `${t("app.buttons.sendCodeAgainThrough.text-1")} ${minutes}:${seconds.toString().padStart(2, "0")}`
    }
    return t("app.buttons.sendCode")
  })

  const startTimer = () => {
    timer.value = 90
    const interval = setInterval(() => {
      timer.value--
      if (timer.value <= 0) {
        clearInterval(interval)
        codeIsSent.value = false
      }
    }, 1000)
  }

  const handleSendOtp = async (type: string) => {
    try {
      const response = await postData(
        "/auth/send-otp",
        {phone: localStorageCurrentUser.phone, type: type === "cancellation" ? "subscribe_cancel" : "security_purposes_terminate"},
        {
          headers: {
            Authorization: `Bearer ${localStorageAccessToken}`
          }
        }
      )
      if (response?.status === 200) {
        startTimer()
        codeIsSent.value = true
        showOtp.value = true
        sentOtpResponse.value = response?.data?.data
      } else {
        otpCode.value = ""
        codeIsSent.value = false
        showOtp.value = false
      }
    } catch (error) {
      console.error(error)
    }
  }

  const checkOtpResponse = ref()
  const checkOtpCode = async (code: string | number) => {
    try {
      otpCode.value = code
      const response = await postData(
        "/auth/check-otp",
        {
          phone: localStorageCurrentUser.phone,
          otpCode: otpCode.value
        },
        {
          headers: {
            Authorization: `Bearer ${localStorageAccessToken}`
          }
        }
      )
      if (response?.status === 200 && response?.data) {
        showOtp.value = false
        codeIsSent.value = false
        checkOtpResponse.value = response?.data?.data
        if (checkOtpResponse.value.status) {
          await terminateSubscriptionAgreement()
          modalStore.closeModal()
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const closeOtpForm = () => {
    showOtp.value = false
    codeIsSent.value = false
    timer.value = 0
    modalStore.closeModal()
  }

  const handleOtpFormOpen = async (type?: string) => {
    const otpProps = {
      codeIsSent: codeIsSent,
      buttonTitle: buttonTitle,

      sendOtp: () => handleSendOtp(type as string),
      checkOtp: checkOtpCode,
      closeOtp: closeOtpForm
    }
    modalStore.openModal({
      component: FFOtp,
      props: otpProps
    })
  }

  //Biometri
  const handleBiometriModalOpen = () => {
    modalStore.openModal({
      component: TerminationNeedBiometriModal
    })
  }

  const handleGetBiometriLink = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken.value}`,
          "Accept-Language": currentLocale.value
        }
      }
      const payload = {
        calcId: calculationTerminationResponse?.value.calcId,
        globalId: terminationByGlobalId.value?.global_id,
        otpCode: otpCode.value
      }
      const response = await postData("/auth/policy/terminate/biometrics", payload, config)
      if (response && response.status === 200 && response?.data?.aitu_link) {
        const aituLink = response?.data?.aitu_link
        window.open(aituLink, "_self")
      }
    } catch (error) {
      console.error(error)
    }
  }

  //Termination && Payment
  const paymentUrl = ref<string>("")
  const terminateSubscriptionAgreement = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken.value}`,
        "Accept-Language": currentLocale.value
      }
    }
    try {
      if (terminationBySubsRegNum.value?.isCancellable) {
        const response = await postData(
          "/auth/policy/subscription/cancellation",
          {
            subsRegNum: calculationSubscriptionTerminationResponse.value?.subsRegNum
              ? calculationSubscriptionTerminationResponse.value?.subsRegNum
              : terminationBySubsRegNum.value?.subscriptionRegNum
          },
          config
        )
        if (response && response.status === 200) {
          localStorage.setItem("termination-type", "subscription-cancelation")
          router.push("/pages/successfully-terminated")
          return true
        } else {
          return false
        }
      }
      if (!terminationBySubsRegNum.value?.isCancellable) {
        const response = await postData(
          "/auth/policy/subscription/terminate-with-policy",
          {
            calcId: calculationSubscriptionTerminationResponse.value?.calcId
          },
          config
        )
        if (response && response.status === 200 && response.data) {
          if (response.data.step === "termination") {
            localStorage.setItem("termination-type", "ins-sum-is-zero")
            router.push("/pages/successfully-terminated")
            return true
          }
          if (response.data.step === "aitu" && response.data.link) {
            openLink(response.data.link, "_self")
          }
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  return {
    loading,
    identificationCard,
    terminationByGlobalId,
    terminationBySubsRegNum,
    calculationTerminationResponse,
    calculationSubscriptionTerminationResponse,
    terminationInsSum,
    terminationOgpoPlusSum,
    terminationOgpoSum,
    isTerminationInsSumGreterThan2Mrp,
    terminationReason,
    selectedReason,
    terminationReasons,
    withoutRefund,
    subscriptionWithoutRefund,
    otpCode,
    showOtp,
    codeIsSent,
    checkOtpResponse,
    isProductCodeOgpo,
    isProductCodeOgpoPlus,
    isProductCodeKasko,
    isProductCodeKaskoSubscription,
    isProductCodeHomeProtect,
    getTerminationAgreementDataByGlobalId,
    getTerminationSubscriptionAgreementBySubsRegNum,
    getTerminationReasons,
    calculateTerminateSum,
    calculateSubscriptionTerminateSum,
    resetTerminationReason,
    handleOtpFormOpen,
    terminateSubscriptionAgreement
  }
})
