import {ref, type Component, computed, watch} from "vue"
import {useI18n} from "vue-i18n"
import {useRouter} from "vue-router"
import {defineStore} from "pinia"
import {useRoute} from "vue-router"
import {useModalStore} from "@/stores/ModalStore"
import {useGatewayFetcher} from "@/composables/useGatewayFetcher"
import {useHelpers} from "@/composables/useHelpers"
import {usePdfOpener} from "@/composables/usePdfOpener"
import {useDeviceSize} from "@/composables/useDeviceSize"
import {useFormatters} from "@/utils/formatters"
import {debounce} from "lodash-es"
import {driverLicense, identityCard} from "@/constants/documents"
import {pushGtagEvent} from "@/utils/pushGtagEvent"
import type {IPayment} from "@/interfaces/Payment/IPayment"
import type {ICalcObj} from "@/interfaces/OgpoPlus/ICalcObj"
import OgpoPlusForms from "@/components/OgpoPlus/OgpoPlusForms.vue"
import CardIcon from "@/components/icons/payment/CardIcon.vue"
import KaspiIcon from "@/components/icons/payment/KaspiIcon.vue"

export const useOgpoPlusStore = defineStore("ogpoPlusStore", () => {
  const {postData, loading} = useGatewayFetcher()
  const {phoneNumberFormatter, dateFormatter} = useFormatters()
  const router = useRouter()
  const route = useRoute()
  const modalStore = useModalStore()
  const {currentLocale, openLink} = useHelpers()
  const {openKdpAgreementPdf, openQuestionnairePdf} = usePdfOpener(postData)
  const {isDesktop} = useDeviceSize()
  const {t} = useI18n()

  //Form steps
  const currentStep = ref<number>(1)

  const handleStepNext = () => {
    currentStep.value += 1
  }
  const handleStepBack = () => {
    currentStep.value -= 1
  }
  const resetStep = () => {
    currentStep.value = 1
  }

  //Calculation form
  const phone = ref<string>("")

  const autonumtechpass = ref<string>("")
  const autonumDuplicateMessage = ref<string>("")
  const carName = ref<string>("")
  const isAutoNumsLoadingState = ref<boolean>(false)

  const isPhoneFilled = computed(() => {
    if (phone?.value.length === 17) {
      pushGtagEvent("ogpo_nomer_telefona")
      return true
    } else {
      return false
    }
  })

  const isCarNumberFilled = computed(() => {
    if (autonumtechpass.value.length >= 3) {
      return true
    } else {
      return false
    }
  })

  const isIinNumberFilled = computed(() => {
    if (iin.value.length === 12) {
      return true
    } else {
      return false
    }
  })

  const isInputChanged = ref(true)
  watch(autonumtechpass, (newValue) => {
    if (newValue.length < 3) {
      carName.value = ""
      isInputChanged.value = true
    }
  })

  const autonumtechpasCheck = async (data) => {
    if (!data) {
      carName.value = ""
      return
    }

    isAutoNumsLoadingState.value = true
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const response = await postData(
        "/object/car/check",
        {
          regNum: data.toUpperCase()
        },
        config
      )
      if (response && response.data && response.status === 200) {
        pushGtagEvent("ogpo_nomer_avto")
        carName.value = response.data.data.car
      } else {
        carName.value = ""
        autonumtechpass.value = ""
      }
    } catch (error) {
      carName.value = ""
      autonumtechpass.value = ""
      console.error(error)
    } finally {
      isAutoNumsLoadingState.value = false
    }
  }

  const handleAutonumBlur = () => {
    autonumtechpasCheck(autonumtechpass.value)
  }

  //iin
  const iin = ref<string>("")
  const iinName = ref<string>("")

  const isIinLoadingState = ref<boolean>(false)

  const iinCheck = debounce(async (data: string) => {
    if (!data) {
      iinName.value = ""
      return
    }

    isIinLoadingState.value = true
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      if (data.length === 12) {
        const verifyResponse = await postData(
          "/portal/mobile/verify-phone-info",
          {
            phone: phoneNumberFormatter(phone.value),
            iin: iin.value
          },
          config
        )

        if (verifyResponse && verifyResponse.status === 200) {
          const response = await postData(
            "/object/client/check",
            {
              iin: data,
              clientType: "Individual",
              isDriver: willManageCar.value
            },
            config
          )
          if (response && response.data && response.status === 200) {
            pushGtagEvent("pole_iin")
            iinName.value = response.data.data.client
          } else {
            iinName.value = ""
            iin.value = ""
          }
        } else {
          iin.value = ""
        }
      } else {
        iinName.value = ""
      }
      isIinLoadingState.value = false
    } catch (error) {
      iin.value = ""
      iinName.value = ""
      isIinLoadingState.value = false
      console.error(error)
    }
  }, 500)

  //Individual value params
  const benefits = ref<boolean>(false)
  const willManageCar = ref<boolean>(true)

  const date = ref(new Date(new Date().setDate(new Date().getDate() + 1)))

  const month = ref({name: computed(() => t("app.ogpoPage.forms.month-12")), value: 12})
  const monthes = ref([
    {name: computed(() => t("app.ogpoPage.forms.month-12")), value: 12},
    {name: computed(() => t("app.ogpoPage.forms.month-11")), value: 11},
    {name: computed(() => t("app.ogpoPage.forms.month-10")), value: 10},
    {name: computed(() => t("app.ogpoPage.forms.month-9")), value: 9},
    {name: computed(() => t("app.ogpoPage.forms.month-8")), value: 8},
    {name: computed(() => t("app.ogpoPage.forms.month-7")), value: 7},
    {name: computed(() => t("app.ogpoPage.forms.month-6")), value: 6}
  ])

  const isSeasonalOperationVehicle = ref<boolean>(false)

  const showSeasonalOperationVehicle = computed(() => {
    return month.value.value !== 12
  })

  const isCalculationBtnDisabled = computed(() => {
    const commonConditions =
      isPhoneFilled.value &&
      isCarNumberFilled.value &&
      isIinNumberFilled.value &&
      date.value !== null &&
      month.value !== null &&
      isAutoNumsLoadingState.value === false &&
      isIinLoadingState.value === false

    if (showSeasonalOperationVehicle.value) {
      return commonConditions && isSeasonalOperationVehicle.value
    }

    return commonConditions
  })

  watch(month, (newVal) => {
    if (newVal.value === 12) {
      isSeasonalOperationVehicle.value = false
    }
  })

  //Admitad data from query params
  const admitadUid = route.query.admitad_uid ?? ""
  const utmSource = route.query.utm_source ?? ""
  const utmMedium = route.query.utm_medium ?? ""
  const utmCompany = route.query.utm_company ?? ""

  const calculationObject = ref<null | ICalcObj>(null)
  const driverLicenseNotFound = ref<boolean>(false)
  const driverLicenseNotFoundResponse = ref()

  const calculateCost = async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const verifyResponse = await postData(
        "/portal/mobile/verify-phone-info",
        {
          phone: phoneNumberFormatter(phone.value),
          iin: iin.value
        },
        config
      )
      if (verifyResponse && verifyResponse.status === 200) {
        const payload = {
          phone: phoneNumberFormatter(phone.value),
          regNum: autonumtechpass.value,
          holderIin: iin.value,
          dateBegin: dateFormatter(date.value),
          period: month.value.value
        }

        const response = await postData("/policy/ogpo-plus/calculate", payload, config)
        if (response && response.data && response.status === 200) {
          pushGtagEvent("ogpo_form_step1")
          addOgpoPlus.value = false
          identificationCardNotFound.value = false
          driverLicenseNotFound.value = false
          calculationObject.value = response.data

          if (needKdp.value) {
            const notVerificatedUsersResponse = await fetchNotVerifiedIins()
            if (!notVerificatedUsersResponse) {
              return
            }
          }

          modalStore.openModal({
            component: OgpoPlusForms
          })
          handleStepNext()

          return true
        }
      }
    } catch (error) {
      if (error.response.status === 400 && error.response.data.errors?.type === driverLicense) {
        driverLicenseNotFound.value = true
        driverLicenseNotFoundResponse.value = error.response.data.errors
        return
      }
      if (error.response.status === 400 && error.response.data.errors?.type === identityCard) {
        const firstClient = {iin: error.response.data.errors?.iin, clientType: "individual"}
        const clients = [firstClient]
        const response = await postData("/portal/get-client-data", {clients}, config)
        identificationCardNotFoundResponse.value = error.response.data.errors
        identificationCardNotFound.value = true

        if (response && response.status === 200) {
          identificationCardNotVerifiedUsers.value = [
            {iin: error.response.data.errors?.iin, fio: response.data.data[0].fullName}
          ]
          if (identificationCardNotFound.value) {
            modalStore.openModal({
              component: OgpoPlusForms
            })
          }
        }
        return
      }
      console.error(error)
      return false
    }
  }

  //Identification card form
  const identificationCardNotFound = ref<boolean>(false)
  const identificationCardNotFoundResponse = ref()
  const identificationCardNotVerifiedUsers = ref<{iin: string; fio: string}[]>([])

  const identificationCardObj = ref({
    iin: "",
    documentGivedDate: null,
    documentNumber: ""
  })

  const resetIdentificationCardObj = () => {
    identificationCardObj.value = {
      iin: "",
      documentGivedDate: null,
      documentNumber: ""
    }
  }

  const setIdentificationCardNewClientData = async (index: number) => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }
      const payload = {
        documentType: "IdentityCard",
        iin: identificationCardNotVerifiedUsers.value[index].iin
      }
      const response = await postData("/object/client/set", payload, config)
      if (response && response.status === 200) {
        await calculateCost()
      } else {
        return
      }
    } catch (error) {
      console.error(error)
    }
  }

  const sendIdentificationCard = async () => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }
      const response = await postData(
        "/object/client/set",
        {
          iin: identificationCardNotFoundResponse.value.iin,
          documentGivedDate: dateFormatter(identificationCardObj?.value?.documentGivedDate),
          documentNumber: driverLicenseObj.value.documentNumber,
          documentType: identificationCardNotFoundResponse.value.type
        },
        config
      )
      if (response?.status === 200) {
        identificationCardNotFound.value = false
      } else {
        return
      }
    } catch (error) {
      console.error(error)
      return
    }
  }

  //Agreement form
  const needKdp = ref<boolean>(true)
  const verificationUsersData = ref<{fullName: string; iin: string; verifyClient: boolean}[]>([])
  const agreementLoaderHide = ref<boolean>(false)

  const handleKdp = (): void => {
    needKdp.value = false
  }

  const fetchNotVerifiedIins = async () => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }
      const firstClient = {iin: iin.value, clientType: "individual"}
      const clients = [firstClient]
      const response = await postData("/portal/get-client-data", {clients}, config)
      if (response?.status === 200) {
        verificationUsersData.value = response.data.data
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const notVerificatedUsers = computed(() => {
    return verificationUsersData.value
  })

  const allUsersAreVerificated = computed(() => {
    return notVerificatedUsers.value.every((verficated) => verficated.verifyClient)
  })

  const intervalIds = ref<Record<number, number | undefined>>({})
  const clearAllIntervals = () => {
    Object.keys(intervalIds.value).forEach((index) => {
      clearInterval(intervalIds.value[Number(index)])
      delete intervalIds.value[Number(index)]
    })
  }

  const sendCode = async (index: number) => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }
      const iin = notVerificatedUsers.value[index].iin
      const response = await postData("/portal/access-control", {iin}, config)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const sendIdentificationCardCode = async (index: number) => {
    try {
      const config = {
        headers: {
          "Accept-Language": currentLocale.value
        }
      }
      const iin = identificationCardNotVerifiedUsers.value[index].iin
      const response = await postData("/portal/access-control", {iin}, config)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const openOgpoAgreementPdf = async (fullName: string, iin: string) => {
    openKdpAgreementPdf(fullName, iin)
  }

  const openOgpoQuestionnairePdf = async (calcId: string) => {
    openQuestionnairePdf("/policy/ogpo-plus/form", {calcId})
  }

  //Ogpo form
  const addOgpoPlus = ref<boolean>(false)
  const nomadClubMember = ref<boolean>(false)
  const ogpoPlusSum = ref<null | number>(null)

  const executionForm = ref({
    email: "",
    iAgreeWithAgreement: false,
    nomadCardNumber: ""
  })

  //Payment methods

  const selectedPayment = ref<string>("")

  const showKaspiForm = ref<boolean>(false)
  const showSuperAppBlock = ref<boolean>(false)

  watch(
    () => calculationObject?.value?.isScoring,
    (newValue) => {
      if (newValue) {
        const freedomPayMethod = paymentMethods.value.find((method) => method.type === "FreedomPay")
        if (freedomPayMethod) {
          selectedPayment.value = freedomPayMethod.type
        }
      }
    },
    {immediate: true}
  )

  const handleCurrentMethod = (type: string) => {
    selectedPayment.value = type
  }

  //Kaspi payment form
  const kaspiPayPhoneNumber = ref<string>("")

  //Final pay form
  const paymentUrl = ref<string>("")

  const finalPay = async () => {
    const config = {
      headers: {
        "Accept-Language": currentLocale.value
      }
    }
    try {
      const finalPayObject = {
        calcId: calculationObject?.value?.calcId,
        premium: calculationObject.value?.premium,
        email: executionForm.value.email,
        paymentType: selectedPayment.value,
        kaspiPayNumber: kaspiPayPhoneNumber.value ? phoneNumberFormatter(kaspiPayPhoneNumber.value) : "",
        giveConsent: true,
        orderId: String(calculationObject.value?.orderId)
      }
      const response = await postData("/policy/ogpo-plus/order/create", finalPayObject, config)
      if (response && response.status === 200) {
        if (selectedPayment.value === "FreedomPay") {
          localStorage.removeItem("paymentType")

          paymentUrl.value = response.data?.payment_url
          window.location.href = paymentUrl.value

          phone.value = ""
        }
        if (selectedPayment.value === "FreedomSuperApp") {
          paymentUrl.value = response.data?.payment_url

          if (isDesktop.value) {
            showSuperAppBlock.value = true
          } else {
            openLink(paymentUrl.value, "_self")
          }
        }
        if (selectedPayment.value === "KaspiPay") {
          localStorage.setItem("paymentType", "KaspiPay")
          modalStore.closeModal()
          router.replace("success-pay")
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const resetStates = () => {
    currentStep.value = 1
    needKdp.value = false
    phone.value = ""
    autonumtechpass.value = ""
    autonumDuplicateMessage.value = ""
    carName.value = ""
    isAutoNumsLoadingState.value = false
    isInputChanged.value = true
    iin.value = ""
    iinName.value = ""
    isIinLoadingState.value = false
    benefits.value = false
    willManageCar.value = true
    date.value = new Date(new Date().setDate(new Date().getDate() + 1))
    month.value = {name: computed(() => t("app.ogpoPage.forms.month-12")), value: 12}
    isSeasonalOperationVehicle.value = false
    calculationObject.value = null
    driverLicenseNotFound.value = false
    driverLicenseNotFoundResponse.value = null
    identificationCardNotFound.value = false
    identificationCardNotFoundResponse.value = null
    identificationCardNotVerifiedUsers.value = []
    resetIdentificationCardObj()
    verificationUsersData.value = []
    agreementLoaderHide.value = false
    executionForm.value.email = ""
    executionForm.value.iAgreeWithAgreement = false
    selectedPayment.value = ""
    showKaspiForm.value = false
    kaspiPayPhoneNumber.value = ""
    paymentUrl.value = ""
  }
  return {
    loading,
    currentStep,
    needKdp,
    phone,
    autonumtechpass,
    autonumDuplicateMessage,
    carName,
    iin,
    iinName,
    benefits,
    willManageCar,
    date,
    month,
    monthes,
    isSeasonalOperationVehicle,
    showSeasonalOperationVehicle,
    agreementLoaderHide,
    notVerificatedUsers,
    allUsersAreVerificated,
    intervalIds,
    addOgpoPlus,
    nomadClubMember,
    ogpoPlusSum,
    executionForm,
    selectedPayment,
    driverLicenseNotFoundResponse,
    identificationCardNotFound,
    identificationCardNotFoundResponse,
    identificationCardNotVerifiedUsers,
    identificationCardObj,
    showKaspiForm,
    showSuperAppBlock,
    kaspiPayPhoneNumber,
    isAutoNumsLoadingState,
    isInputChanged,
    isPhoneFilled,
    isCarNumberFilled,
    isIinNumberFilled,
    isCalculationBtnDisabled,
    isIinLoadingState,
    calculationObject,
    paymentUrl,

    handleKdp,
    handleStepNext,
    handleStepBack,
    resetStep,
    resetIdentificationCardObj,
    sendIdentificationCard,
    fetchNotVerifiedIins,
    handleAutonumBlur,
    autonumtechpasCheck,
    setIdentificationCardNewClientData,
    handleCurrentMethod,
    clearAllIntervals,
    sendCode,
    sendIdentificationCardCode,
    openOgpoAgreementPdf,
    openOgpoQuestionnairePdf,
    iinCheck,
    calculateCost,
    finalPay,
    resetStates
  }
})
