<script setup lang="ts">
const about = [
  {
    iconPath: "/main-front/images/about-fond.svg",
    title: "main.about.card1"
  },
  {
    iconPath: "/main-front/images/about-global.png",
    title: "main.about.card2"
  },
  {
    iconPath: "/main-front/images/about-akab.png",
    title: "main.about.card3"
  },
  {
    iconPath: "/main-front/images/about-fin.png",
    title: "main.about.card4"
  }
]
</script>

<template>
  <section class="hotline-about container">
    <p class="hotline-about__title">{{ $t("main.about.title") }}</p>
    <div class="hotline-about__content">
      <p class="hotline-about__content--text">
        {{ $t("main.about.description") }}
      </p>
      <div class="hotline-about__content--grid">
        <div class="hotline-about__content--grid__item" v-for="(i, index) in about" :key="index">
          <img :src="i.iconPath" :alt="$t('main.about.title')" />
          <p>{{ $t(i.title) }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.hotline-about {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 80px;
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  
  .hotline-about__title {
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    @include main-green-gradient;

    @include max-responsive(lg) {
      font-size: 30px;
    }
  }
  .hotline-about__content {
    display: flex;
    gap: 30px;

    @include max-responsive(xl) {
      @include column;
    }

    @include max-responsive(lg) {
      gap: 50px;
    }

    @include max-responsive(md) {
      gap: 30px;
    }
    .hotline-about__content--text {
      color: #d6d6d6;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      max-width: 540px;
      white-space: pre-wrap;

      @include max-responsive(xxl) {
        max-width: 450px;
      }

      @include max-responsive(xl) {
        max-width: inherit;
      }
    }
    .hotline-about__content--grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 79px;

      @include max-responsive(lg) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(4, 1fr);
        grid-column-gap: 0;
        grid-row-gap: 50px;
      }

      @include max-responsive(md) {
        grid-row-gap: 20px;
      }

      .hotline-about__content--grid__item {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @include max-responsive(lg) {
          gap: 10px;
        }

        @include max-responsive(md) {
          gap: 20px;
        }

        img {
          height: fit-content;
          width: fit-content;
        }
        p {
          color: #d6d6d6;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}
</style>
