<script setup lang="ts">
const statistics = [
  {
    iconPath: "/main-front/images/statistic-rating.png",
    title: "main.statistics.top5",
    description: "main.statistics.inKazStore"
  },
  {
    iconPath: "/main-front/images/statistic-diagram.svg",
    title: "kzA-",
    description: "main.statistics.maxPayment"
  },
  {
    iconPath: "/main-front/images/statistic-map.svg",
    title: "main.statistics.area",
    description: "main.statistics.autoPolis"
  },
  {
    iconPath: "/main-front/images/statistic-car.png",
    title: "202 307",
    description: "main.statistics.polis2023"
  },
  {
    iconPath: "/main-front/images/statistic-shield.svg",
    title: "28 446",
    description: "main.statistics.optionalPolis"
  },
  {
    iconPath: "/main-front/images/statistic-cash.svg",
    title: "main.statistics.billion",
    description: "main.statistics.insuranseCase"
  },
]
</script>

<template>
  <div class="statistics container">
    <p class="statistics__title">{{ $t("main.statistics.title") }}</p>
    <div class="statistics__list">
      <div class="statistics__item" v-for="(i, index) in statistics" :key="index">
        <div class="statistics__item--header">
          <img :src="i?.iconPath" alt="photo" />
          <p>{{ $t(i?.title) }}</p>
        </div>
        <p class="statistics__item--description">
          {{ $t(i?.description) }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.statistics {
  display: flex;
  flex-direction: column;
  gap: 50px;
  &__title {
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 100%;
    background: linear-gradient(95deg, #79b0e2 -3.54%, #1cd491 56.19%, #3fed6e 102.64%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 25px;
    grid-row-gap: 70px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    gap: 18px;
    &--header {
      display: flex;
      align-items: center;
      gap: 17px;
      p {
        font-size: 40px;
        font-style: normal;
        font-weight: 900;
        line-height: 100%;
        background: var(--FF-gradient, linear-gradient(103deg, #bde0ff -2.91%, #1cd491 58.89%, #00ff45 106.95%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    &--description {
      color: #d6d6d6;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }
}

@media (max-width: $xl) {
  .statistics {
    &__list {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
      grid-column-gap: 50px;
    }
  }
}

@media (max-width: $lg) {
  .statistics {
    &__title {
      font-size: 30px;
    }
    &__list {
      grid-column-gap: 20px;
    }
    &__item {
      &--header {
        gap: 10px;
        p {
          font-size: 20px;
        }
      }
    }
  }
}

@media (max-width: $md) {
  .statistics {
    &__item {
      &--description {
        max-width: 110px;
      }
    }
  }
}
</style>
