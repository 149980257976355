<script setup lang="ts">
import {type INewsItem} from "@/interfaces/News/INews"
import NewsItem from "@/components/News/NewsItem.vue"
import FFPaginator from "@/components/FFUI/FFPaginator.vue"

interface IProps {
  news: INewsItem[] | undefined
  currentPage: number
  lastPage: number
}

const {news, currentPage} = defineProps<IProps>()

const emit = defineEmits<{
  (e: "page-change", newPage: number): void
}>()

const onPageChange = (newPage: number) => {
  emit("page-change", newPage)
}
</script>

<template>
  <section class="news-list container">
    <template v-for="(item, idx) in news" :key="item.news_id">
      <NewsItem :item="item" />
    </template>
    <FFPaginator :numberRange="5" :currentPage="currentPage" :maxPage="lastPage" @changeCurrentPage="onPageChange" />
  </section>
</template>

<style scoped lang="scss">
.news-list {
  @include column;
  gap: 20px;
  margin-top: 50px;
  padding-bottom: 50px;

  @include max-responsive(lg) {
    gap: 30px;
  }

  .paginator {
    margin: 30px auto 0;
  }
}
</style>
