<script setup lang="ts">
import {onMounted, ref, computed} from "vue"
import {storeToRefs} from "pinia"
import {useRouter} from "vue-router"
import {usePersonalProfileStore} from "@/stores/PersonalProfileStore"
import {useTerminationSubscriptionAgreementStore} from "@/stores/TerminationSubscriptionAgreementStore"
import {useAddDriverAutoStore} from "@/stores/AddDriverAutoStore"
import {useModalStore} from "@/stores/ModalStore"
import {useHelpers} from "@/composables/useHelpers"
import {useDeviceSize} from "@/composables/useDeviceSize"
import {useFormatters} from "@/utils/formatters"
import {isActive, isActivePolicy} from "@/constants/policy"
import PersonalActionButtons from "@/components/PersonalProfile/PersonalActionButtons.vue"
import ProfileCardPopup from "@/components/PersonalProfile/ProfileCardPopup.vue"
import DarkBackground from "@/components/partials/DarkBackground.vue"
import PolicyListIcon from "@/components/icons/personal-profile/PolicyListIcon.vue"
import PolicyCalendarIcon from "@/components/icons/personal-profile/PolicyCalendarIcon.vue"
import PolicyCardIcon from "@/components/icons/personal-profile/PolicyCardIcon.vue"
import ProfileTerminateSubscriptionModal from "@/components/PersonalProfile/ProfileTerminateSubscriptionModal.vue"

const router = useRouter()
const personalProfileStore = usePersonalProfileStore()
const terminationSubscriptionAgreementStore = useTerminationSubscriptionAgreementStore()
const addDriverAutoStore = useAddDriverAutoStore()
const modalStore = useModalStore()
const {loading, allUserPolicies, notActivePolicies, allUserSubscriptionPolicies, currentOpenedPolicySubscription} =
  storeToRefs(personalProfileStore)
const {dateAndTimeApplying} = storeToRefs(addDriverAutoStore)
const {openLink, currentLocale} = useHelpers()
const {convertISOToDateTime, thousandSeparator, trimString} = useFormatters()
const {isMobileDevice, isDesktop} = useDeviceSize()

const isActivePolises = ref<boolean>(false)
const isTerminatedPolises = ref<boolean>(false)
const cardPopup = ref<boolean>(false)

const mstCardOpened = ref<boolean>(false)
const openedCards = ref<Set<string>>(new Set())
const selectedGlobalId = ref<string>("")

const setPolisType = (globalId: string) => {
  selectedGlobalId.value = globalId

  if (openedCards.value.has(globalId)) {
    openedCards.value.delete(globalId)
  } else {
    openedCards.value.add(globalId)
  }
}

const getDataByGlobalId = async (globalId: string, type: string, productCode?: string) => {
  try {
    localStorage.setItem("date-time-applying", convertISOToDateTime(new Date().toISOString()))
    if (type === "termination") {
      router.push(`termination-agreement/${globalId}/${productCode}`)
    }
  } catch (error) {
    console.error(error)
  }
}

const openPoliciePdf = async (globalId: string, productCode: string) => {
  try {
    await personalProfileStore.openPoliciePdf(globalId, productCode)
  } catch (error) {
    console.log(error)
  }
}

const groupedPolicies = computed(() => {
  const groups = {
    casco: [],
    comfort: []
  }

  if (Array.isArray(allUserSubscriptionPolicies.value)) {
    allUserSubscriptionPolicies.value.forEach((policy: any) => {
      switch (policy.productType) {
        case "KASKO_CLASSIC":
          groups.casco.push(policy)
          break
        case "KASKO_EXPRESS":
          groups.casco.push(policy)
          break
        case "KASKO_VIP":
          groups.casco.push(policy)
          break
        case "FFINS_COMFORT":
          groups.comfort.push(policy)
          break
        case "Freedom Comfort":
          groups.comfort.push(policy)
          break
      }
    })
  }

  return groups
})

const groupActivePoliciesLengths = computed(() => {
  const groups = groupedPolicies.value
  const policyTypes = ["casco", "comfort"]
  const policyQuantity = policyTypes.reduce((acc, type) => {
    const activePolicyCount =
      groups[type]?.filter((policy) => policy.statusCode === isActive || policy.statusCode === isActivePolicy).length ||
      0
    return acc + activePolicyCount
  }, 0)
  return policyQuantity
})

const handleCurrentPolicySubscription = async (policy) => {
  currentOpenedPolicySubscription.value = policy
  const response = await personalProfileStore.getPoliciesPaymentInfoData()
  if (response) {
    modalStore.openModal({
      component: ProfileCardPopup
    })
  } else {
    return
  }
}

const handleTerminateSubscriptionModalOpen = () => {
  localStorage.setItem("date-time-applying", convertISOToDateTime(new Date().toISOString()))
  modalStore.openModal({
    component: ProfileTerminateSubscriptionModal
  })
}

const handleCancellationOfSubscription = async (subsRegNum: string) => {
  const response =
    await terminationSubscriptionAgreementStore.getTerminationSubscriptionAgreementBySubsRegNum(subsRegNum)
  if (response) {
    terminationSubscriptionAgreementStore.handleOtpFormOpen("cancellation")
  } else {
    return
  }
}
</script>

<template>
  <div class="profile-my-subscription-policies container">
    <div @click="isActivePolises = !isActivePolises" class="profile-my-subscription-policies__category">
      <div class="profile-my-subscription-policies__category-title-wrapper">
        <p class="profile-my-subscription-policies__category-title">
          {{ $t("app.personalCabinetPage.mySubscriptionPolicies") }}
        </p>
        <p class="number">{{ groupActivePoliciesLengths }}</p>
      </div>
      <img
        :class="{'rotate-180': isActivePolises}"
        src="/user-profile-front/images/arrow.svg"
        width="24"
        height="24"
        alt="arrow" />
    </div>
    <Transition>
      <div v-show="isActivePolises" class="profile-my-subscription-policies__list">
        <template v-if="groupedPolicies.casco.length !== 0">
          <template v-for="policy in groupedPolicies.casco" :key="policy.subscriptionRegNum">
            <div
              class="profile-my-subscription-policies__list-item"
              :class="policy.statusCode === isActive ? 'kasko-bg' : 'kasko-not-active-subscription-bg'">
              <div class="profile-my-subscription-policies__list-item-header">
                <span
                  v-tooltip.top="{
                    value: 'Номер подписки',
                    pt: {
                      text: 'bg-[#525252] !text-primary-contrast !font-medium px-3 py-1.5 text-[14px]'
                    }
                  }"
                  class="profile-my-subscription-policies__subscriptionRegNum white-title"
                  >{{ policy.subscriptionRegNum }}</span
                >
                <p class="profile-my-subscription-policies__status white-title">
                  {{
                    policy.statusCode === isActive
                      ? $t("app.personalCabinetPage.subscriptionIsActive")
                      : $t("app.personalCabinetPage.subscriptionIsNotActive")
                  }}
                </p>
              </div>
              <div class="profile-my-subscription-policies__list-item-body">
                <div class="profile-my-subscription-policies__policy-name-wrapper">
                  <div class="profile-my-subscription-policies__policy-name-row">
                    <p class="profile-my-subscription-policies__policy-name white-title">
                      {{ $t("app.personalCabinetPage.casco") }}
                    </p>
                    <span class="profile-my-subscription-policies__policy-category-name white-title">
                      {{
                        policy.productType === "KASKO_EXPRESS"
                          ? "Express"
                          : policy.productType === "KASKO_CLASSIC"
                            ? "Classic"
                            : "Vip"
                      }}
                    </span>
                  </div>
                  <p class="profile-my-subscription-policies__policy-name white-title">
                    {{ $t("app.personalCabinetPage.subscription") }}
                  </p>
                </div>

                <div class="profile-my-subscription-policies__policy-content-wrapper">
                  <div class="profile-my-subscription-policies__card-row">
                    <img class="white-icon" src="/user-profile-front/images/polis-car.svg" alt="icon" />
                    <div class="profile-my-subscription-policies__specific-category-wrapper">
                      <p class="profile-my-subscription-policies__title white-title">
                        {{ policy.mark }} {{ policy.model }}
                      </p>
                      <p class="profile-my-subscription-policies__body white-title">{{ policy.carRegNum }}</p>
                    </div>
                  </div>
                  <div class="profile-my-subscription-policies__card-row">
                    <PolicyCalendarIcon />
                    <p class="profile-my-subscription-policies__single-text white-title">
                      {{ thousandSeparator(policy.premium) }} {{ $t("form.formAbbreviations.inMonth") }}
                    </p>
                  </div>
                  <div class="profile-my-subscription-policies__card-row z-50">
                    <PolicyListIcon />
                    <div class="profile-my-subscription-policies__specific-category-wrapper">
                      <p class="profile-my-subscription-policies__title white-title">
                        {{ $t("app.personalCabinetPage.agreementNumber") }}
                      </p>
                      <p class="profile-my-subscription-policies__body white-title">№ {{ policy.globalId }}</p>
                    </div>
                  </div>
                  <div v-if="policy.statusCode === isActive" class="profile-my-subscription-policies__card-row">
                    <PolicyCardIcon />
                    <div class="profile-my-subscription-policies__specific-category-wrapper">
                      <p class="profile-my-subscription-policies__title white-title">{{ policy?.card?.cardHash }}</p>
                      <p class="profile-my-subscription-policies__body white-title">
                        {{ $t("app.personalCabinetPage.nextPayment") }} {{ policy.nextPayDate }}
                      </p>
                    </div>
                  </div>
                  <div
                    v-if="policy.statusCode === isActivePolicy"
                    class="profile-my-subscription-policies__card-row z-50">
                    <PolicyCalendarIcon />
                    <p class="profile-my-subscription-policies__single-text white-title">
                      <template v-if="currentLocale !== 'kk'">
                        {{ $t("app.personalCabinetPage.policyTillDate") }} {{ policy.tillDate }}
                      </template>
                      <template v-else>
                        {{ $t("app.personalCabinetPage.policyTillDate-1") }} {{ policy.tillDate }}
                        {{ $t("app.personalCabinetPage.policyTillDate-2") }}
                      </template>
                    </p>
                  </div>
                </div>
              </div>
              <img
                class="profile-my-subscription-policies__card-image"
                src="/user-profile-front/images/polis-casco.png"
                alt="card" />

              <Transition>
                <PersonalActionButtons
                  v-if="openedCards.has(policy.subscriptionRegNum)"
                  polisType="cascoSubscription"
                  @click="currentOpenedPolicySubscription = policy"
                  @downloadPolis="openPoliciePdf(policy.globalId, policy.productType)"
                  @terminatePolis="getDataByGlobalId(policy.globalId, 'termination', policy.productType)"
                  @terminateSubscriptionPolis="handleTerminateSubscriptionModalOpen"
                  @cancelSubscriptionPolis="handleCancellationOfSubscription(policy.subscriptionRegNum)"
                  @insuranceCase="openLink('https://dtp.kz/', '_self')"
                  @cards="handleCurrentPolicySubscription(policy)"
                  :isPolicyCancellable="policy.isCancellable"
                  :subscriptionPolicyStatusCode="policy.statusCode === isActive ? isActive : isActivePolicy" />
              </Transition>

              <div
                class="profile-my-subscription-policies__list-item-bottom"
                :class="
                  !openedCards.has(policy.subscriptionRegNum)
                    ? 'profile-my-subscription-policies__list-item-bottom--not-active'
                    : ''
                "
                @click="setPolisType(policy.subscriptionRegNum)">
                <p class="white-title">
                  {{
                    openedCards.has(policy.subscriptionRegNum)
                      ? $t("app.personalCabinetPage.hide")
                      : $t("app.personalCabinetPage.disclose")
                  }}
                </p>
                <img
                  width="12px"
                  height="12px"
                  :class="{'rotate-180': openedCards.has(policy.subscriptionRegNum)}"
                  src="/user-profile-front/images/arrow.svg"
                  alt="icon" />
              </div>
            </div>
          </template>
        </template>

        <template v-if="groupedPolicies.comfort.length !== 0">
          <template v-for="policy in groupedPolicies.comfort" :key="policy.subscriptionRegNum">
            <div class="profile-my-subscription-policies__list-item comfort-item">
              <div class="profile-my-subscription-policies__list-item-header">
                <span class="profile-my-subscription-policies__subscriptionRegNum white-title">{{
                  policy.subscriptionRegNum
                }}</span>
                <p class="profile-my-subscription-policies__date dark-title">
                  <!-- {{ $t("form.formAbbreviations.till") }} {{ policy.tillDate }} -->
                  {{
                    currentLocale !== "kk"
                      ? `${$t("form.formAbbreviations.till")} ${policy.tillDate}`
                      : `${policy.tillDate} ${$t("form.formAbbreviations.till")}`
                  }}
                </p>
              </div>
              <div class="profile-my-subscription-policies__list-item-body">
                <div class="profile-my-subscription-policies__policy-name-wrapper">
                  <div class="profile-my-subscription-policies__policy-name-row">
                    <p class="profile-my-subscription-policies__policy-name dark-title">Freedom Comfort</p>
                  </div>
                  <p class="profile-my-subscription-policies__policy-name dark-title">
                    {{ $t("app.personalCabinetPage.subscription") }}
                  </p>
                </div>

                <div class="profile-my-subscription-policies__policy-content-wrapper">
                  <div class="profile-my-subscription-policies__card-row">
                    <PolicyHomeIcon fillColor="#404040" />
                    <div class="profile-my-subscription-policies__specific-category-wrapper">
                      <p class="profile-my-subscription-policies__title dark-title">
                        {{ policy.mark }} {{ policy.model }}
                      </p>
                      <p class="profile-my-subscription-policies__body dark-title">{{ policy.carRegNum }}</p>
                    </div>
                  </div>
                  <div class="profile-my-subscription-policies__card-row">
                    <PolicyCalendarIcon fillColor="#404040" />
                    <p class="profile-my-subscription-policies__single-text dark-title">
                      {{ policy.premium }} {{ $t("form.formAbbreviations.inMonth") }}
                    </p>
                  </div>
                  <div class="profile-my-subscription-policies__card-row z-50">
                    <PolicyListIcon fillColor="#404040" />
                    <div class="profile-my-subscription-policies__specific-category-wrapper">
                      <p class="profile-my-subscription-policies__title dark-title">
                        {{ $t("app.personalCabinetPage.agreementNumber") }}
                      </p>
                      <p class="profile-my-subscription-policies__body dark-title">№ {{ policy.globalId }}</p>
                    </div>
                  </div>
                  <div v-if="policy.statusCode === isActive" class="profile-my-subscription-policies__card-row">
                    <PolicyCardIcon fillColor="#404040" />
                    <div class="profile-my-subscription-policies__specific-category-wrapper">
                      <p class="profile-my-subscription-policies__title dark-title">{{ policy?.card?.cardHash }}</p>
                      <p class="profile-my-subscription-policies__body dark-title">
                        {{ $t("app.personalCabinetPage.nextPayment") }} {{ policy.nextPayDate }}
                      </p>
                    </div>
                  </div>
                  <div
                    v-if="policy.statusCode === isActivePolicy"
                    class="profile-my-subscription-policies__card-row z-50">
                    <PolicyHomeIcon fillColor="#404040" />
                    <p class="profile-my-subscription-policies__single-text dark-title">
                      <template v-if="currentLocale !== 'kk'">
                        {{ $t("app.personalCabinetPage.policyTillDate") }} {{ policy.tillDate }}
                      </template>
                      <template v-else>
                        {{ $t("app.personalCabinetPage.policyTillDate-1") }} {{ policy.tillDate }}
                        {{ $t("app.personalCabinetPage.policyTillDate-2") }}
                      </template>
                    </p>
                  </div>
                </div>
              </div>
              <img
                class="profile-my-subscription-policies__card-image"
                src="/user-profile-front/images/polis-casco.png"
                alt="card" />

              <Transition>
                <PersonalActionButtons
                  v-if="openedCards.has(policy.subscriptionRegNum)"
                  polisType="comfortSubscription"
                  @click="currentOpenedPolicySubscription = policy"
                  @downloadPolis="openPoliciePdf(policy.globalId, policy.productType)"
                  @terminatePolis="
                    getDataByGlobalId(policy.subscriptionRegNum, 'termination-subscription', policy.productType)
                  "
                  @terminateSubscriptionPolis="handleTerminateSubscriptionModalOpen"
                  @cancelSubscriptionPolis="handleCancellationOfSubscription(policy.subscriptionRegNum)"
                  @insuranceCase="openLink('https://dtp.kz/', '_self')"
                  @cards="handleCurrentPolicySubscription(policy)"
                  :isPolicyCancellable="policy.isCancellable"
                  :subscriptionPolicyStatusCode="policy.statusCode === isActive ? isActive : isActivePolicy" />
              </Transition>

              <div
                class="profile-my-subscription-policies__list-item-bottom"
                :class="
                  !openedCards.has(policy.subscriptionRegNum)
                    ? 'profile-my-subscription-policies__list-item-bottom--not-active'
                    : ''
                "
                @click="setPolisType(policy.subscriptionRegNum)">
                <p class="dark-title">
                  {{
                    openedCards.has(policy.subscriptionRegNum)
                      ? $t("app.personalCabinetPage.hide")
                      : $t("app.personalCabinetPage.disclose")
                  }}
                </p>
                <img
                  width="12px"
                  height="12px"
                  :class="{'rotate-180': openedCards.has(policy.subscriptionRegNum)}"
                  src="/user-profile-front/images/arrow.svg"
                  alt="icon" />
              </div>
            </div>
          </template>
        </template>
      </div>
    </Transition>
  </div>
</template>

<style scoped lang="scss">
.v-enter-active {
  transition: opacity 0.5s ease;
}

.v-leave-active {
  transition: opacity 0s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.profile-my-subscription-policies {
  padding-bottom: 50px;
  .profile-my-subscription-policies__category {
    @include between;
    .profile-my-subscription-policies__category-title-wrapper {
      @include center;
      gap: 4px;
      .profile-my-subscription-policies__category-title {
        color: var(--color-white);
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        transition: all 0.3s ease;
        cursor: pointer;
        &:hover {
          color: lightgray;
        }
      }
    }
  }

  .profile-my-subscription-policies__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    align-items: flex-start;
    margin-top: 20px;

    @include max-responsive(xxl) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include max-responsive(lg) {
      grid-template-columns: repeat(1, 1fr);
    }
    .profile-my-subscription-policies__list-item {
      position: relative;
      @include column;
      padding: 20px 16px 10px;
      border-radius: 10px;
      min-height: 370px;
      max-height: 515px;
      .profile-my-subscription-policies__list-item-header {
        @include between;
        margin-bottom: 20px;
        .profile-my-subscription-policies__subscriptionRegNum {
          display: flex;
          padding: 2px 8px;
          align-items: flex-start;
          gap: 16px;
          border-radius: 10px;
          background: #525252;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.2px;
          cursor: default;
        }
        .profile-my-subscription-policies__status {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 17px;
          letter-spacing: 0.2px;
          text-align: right;
          width: 86px;
          z-index: 100;
        }
      }
      .profile-my-subscription-policies__list-item-body {
        .profile-my-subscription-policies__policy-name-wrapper {
          @include column;
          margin-bottom: 20px;
          .profile-my-subscription-policies__policy-name-row {
            @include flex;
            gap: 10px;
            .profile-my-subscription-policies__policy-name {
              font-size: 35px;
              font-style: normal;
              font-weight: 900;
              line-height: 35px;
            }
            .profile-my-subscription-policies__policy-category-name {
              text-align: center;
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: 1;
              padding: 6px 10px;
              border-radius: 4px;
              background: #f2994a;
              height: fit-content;
            }
          }
          .profile-my-subscription-policies__policy-name {
            font-size: 25px;
            font-style: normal;
            font-weight: 900;
            line-height: 100%;
          }
        }

        .profile-my-subscription-policies__policy-content-wrapper {
          @include column;
          gap: 12px;
          .profile-my-subscription-policies__card-row {
            @include flex;
            align-items: center;
            gap: 10px;
            .profile-my-subscription-policies__single-text {
              font-size: 15px;
              font-style: normal;
              font-weight: 600;
              line-height: 14px;
              letter-spacing: 0.2px;
            }
            .profile-my-subscription-policies__specific-category-wrapper {
              @include column;
              .profile-my-subscription-policies__title {
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                letter-spacing: 0.2px;
              }
              .profile-my-subscription-policies__body {
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 12px;
                letter-spacing: 0.2px;
                opacity: 0.5;
              }
            }
          }
        }
      }
      .profile-my-subscription-policies__card-image {
        position: absolute;
        right: 0;
        top: 53px;
      }
      .profile-my-subscription-policies__list-item-bottom {
        @include center;
        gap: 6px;
        cursor: pointer;
        margin: 10px 0;
        z-index: 100;
        &--not-active {
          margin: auto 0 10px;
        }
        p {
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
        }
      }
    }
  }
}

.white-icon {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(305deg) brightness(103%) contrast(103%);
}
.number {
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 3px 10px;
  border-radius: 100%;
  background: #f2994a;
  max-width: 24px;
  text-align: center;
  transition: all 0.3s ease;
}
.kasko-bg {
  background: linear-gradient(200deg, #40bf7a 13.57%, #289112 98.35%);
}
.kasko-not-active-subscription-bg {
  background: linear-gradient(200deg, #40bf7a 13.57%, #289112 98.35%);
  position: relative;
}

.kasko-not-active-subscription-bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
}
.comfort-bg {
  background: linear-gradient(103deg, #bde0ff -2.91%, #1cd491 58.89%, #00ff45 106.95%);
}
.white-title {
  color: var(--color-white);
}
.white-subtitle {
  color: #dbdbdb;
}
.dark-title {
  color: #404040;
}
.dark-subtitle {
  color: #797979;
}
</style>
