<script setup lang="ts">
import {useDeviceSize} from "@/composables/useDeviceSize"
import CloseIcon from "@/components/icons/CloseIcon.vue"

interface IProps {
  title?: string
  description?: string
  descriptionWithLink?: string
  descriptions?: string[]
  descriptionLinkFunc?: (event: any) => void
  top?: string
  left?: string
  fontWeight?: string
  fontSize?: string
  width?: string
  maxWidth?: string
  isBlack?: boolean
  paddingXY?: string
  descriptionMaxWidth?: string
  isTooltipModeOpened?: boolean
}
withDefaults(defineProps<IProps>(), {
  top: "0px",
  left: "0px",
  fontWeight: "300",
  fontSize: "16px",
  maxWidth: "300px",
  isBlack: false,
  paddingXY: "20px",
  isTooltipModeOpened: false
})

const emit = defineEmits(["closeTooltip"])

const {isMobileDevice} = useDeviceSize()
</script>

<template>
  <div :class="{'black-tooltip': isBlack, tooltip: !isBlack}">
    <p v-if="Boolean(title)" class="tooltip__title">{{ title }}</p>
    <p
      v-if="descriptionWithLink"
      v-html="descriptionWithLink"
      @click="descriptionLinkFunc"
      class="tooltip__description"></p>
    <p v-else class="tooltip__description">
      {{ description }}
    </p>
    <template v-if="descriptions && descriptions.length">
      <ul>
        <li v-for="(description, index) in descriptions" :key="index">
          {{ $t(description) }}
        </li>
      </ul>
    </template>
    <CloseIcon v-if="isTooltipModeOpened" @click="emit('closeTooltip')" class="tooltip__close-icon" />
    <CloseIcon
      v-if="!isTooltipModeOpened && isMobileDevice"
      @click="emit('closeTooltip')"
      class="tooltip__close-icon" />
  </div>
</template>

<style scoped lang="scss">
.tooltip {
  position: absolute;
  padding: v-bind(paddingXY);
  background-color: #ffffff;
  max-width: v-bind(maxWidth);
  width: v-bind(width);
  height: fit-content;
  border-radius: 10px;
  z-index: 9999;

  top: v-bind(top);
  left: v-bind(left);

  .tooltip__title {
    color: #000000;
    font-size: v-bind(fontSize);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    opacity: 0.7;
    margin-bottom: 12px;
  }
  .tooltip__description {
    color: #181818;
    font-size: v-bind(fontSize);
    font-style: normal;
    font-weight: v-bind(fontWeight);
    line-height: normal;
  }
  .tooltip__close-icon {
    position: absolute;
    top: 7px;
    right: 7px;
    cursor: pointer;
  }
  ul {
    list-style-type: disc;
    padding-left: 5px;
    li {
      font-size: 14px;

      &:not(:last-of-type) {
        margin-bottom: 3px;
      }
    }
  }
}
.black-tooltip {
  position: absolute;
  padding: v-bind(paddingXY);
  max-width: v-bind(maxWidth);
  width: v-bind(width);
  height: fit-content;
  top: v-bind(top);
  left: v-bind(left);
  border-radius: 10px;
  z-index: 9999;
  border-radius: 10px;
  background: #000000;
  box-shadow:
    0px 0px 40px 0px rgba(0, 0, 0, 0.05),
    -20px 20px 40px 0px rgba(0, 0, 0, 0.1);

  .tooltip__title {
    color: #ffffff;
    font-size: v-bind(fontSize);
    font-style: normal;
    font-weight: v-bind(fontWeight);
    line-height: normal;
    opacity: 0.7;
    margin-bottom: 12px;
  }
  .tooltip__description {
    font-size: v-bind(fontSize);
    font-style: normal;
    font-weight: v-bind(fontWeight);
    line-height: normal;
    color: #ffffff;
    text-transform: none !important;
    max-width: v-bind(descriptionMaxWidth);
  }
  .tooltip__close-icon {
    position: absolute;
    top: 7px;
    right: 7px;
    cursor: pointer;
  }
  ul {
    list-style-type: disc;
    padding-left: 5px;
    li {
      font-size: 14px;

      &:not(:last-of-type) {
        margin-bottom: 3px;
      }
    }
  }
}
</style>
