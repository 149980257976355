<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="28" viewBox="0 0 80 28" fill="none">
    <g clip-path="url(#clip0_3369_14960)">
      <mask
        id="mask0_3369_14960"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="80"
        height="28">
        <path d="M80 0H0V28H80V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_3369_14960)">
        <mask
          id="mask1_3369_14960"
          style="mask-type: luminance"
          maskUnits="userSpaceOnUse"
          x="0"
          y="1"
          width="22"
          height="24">
          <path d="M21.2333 1.62109H0.164062V24.2705H21.2333V1.62109Z" fill="white" />
        </mask>
        <g mask="url(#mask1_3369_14960)">
          <path d="M18.8893 6.04311H0.164062L4.90611 1.875H18.8893V6.04311Z" fill="#53B1FD" />
          <path d="M18.8893 12.0743H0.164062L4.90611 7.90234H18.8893V12.0743Z" fill="#2E90FA" />
          <path d="M9.78723 18.1017H0.164062L5.03902 13.9336H9.78723V18.1017Z" fill="#1570EF" />
          <path d="M9.78723 24.1328H0.164062L5.03902 19.9609H9.78723V24.1328Z" fill="#175CD3" />
        </g>
        <path
          d="M74.6082 22.9523L73.5234 21.8672V20.5398H75.0139V21.1403L75.3352 21.4616H77.3578L77.6791 21.1403V20.3292L77.3578 20.0079H74.6398L73.5549 18.9228V16.6631L74.6398 15.5781H77.9848L79.0696 16.6631V17.9905H77.5792V17.39L77.2579 17.0687H75.3667L75.0401 17.39V18.1959L75.3667 18.5172H78.0847L79.1699 19.6023V21.8672L78.0847 22.9523H74.6082ZM74.7981 22.5467H77.8686L78.7483 21.667V19.7498L77.9214 18.9175H75.1772L74.6398 18.3855V17.1898L75.1719 16.6631H77.4736L77.9848 17.1635V17.6165H78.659V16.8369L77.8056 15.9836H74.8399L73.9606 16.8633V18.7068L74.8505 19.6023H77.5895L78.0688 20.0658V21.3405L77.537 21.8725H75.1297L74.6242 21.3668V20.9138H73.9447V21.6934L74.7981 22.5467Z"
          fill="white" />
        <path
          d="M69.2069 22.9523V17.0687H67.3789V15.5781H72.5198V17.0687H70.6921V22.9523H69.2069ZM69.6122 22.5572H70.2864V16.6526H72.1301V15.9731H67.7689V16.6526H69.6122V22.5572Z"
          fill="white" />
        <path
          d="M60.3315 22.5572H61.0056V16.6737H61.9222L63.8133 22.5467H65.9255V15.9731H65.2513V21.8567H64.3295L62.449 15.9889H60.3315V22.5572ZM59.9258 22.9523V15.5781H62.7859L64.6086 21.4406H64.835V15.5781H66.3258V22.9523H63.4654L61.6324 17.074H61.4166V22.9523H59.9258Z"
          fill="white" />
        <path
          d="M53.8633 22.9523V15.5781H58.6566V17.0687H55.3541V18.4856H58.2932V19.9763H55.3541V21.4616H58.6566V22.9523H53.8633ZM54.2795 22.5309H58.2985V21.8567H54.959V19.5654H57.9297V18.8912H54.959V16.6526H58.2985V15.9731H54.2795V22.5309Z"
          fill="white" />
        <path
          d="M44.75 22.9523V15.5781H47.6628L48.5531 20.8085H48.8061L49.696 15.5781H52.6036V22.9523H51.1131V17.074H50.9076L50.0018 22.3202H47.3574L46.4569 17.074H46.2408V22.9523H44.75ZM45.1556 22.5467H45.8298V16.6631H46.7569L47.705 21.8725H49.6486L50.5969 16.6631H51.5184V22.5467H52.1979V15.9783H50.0489L49.1062 21.1825H48.2527L47.3047 15.9783H45.1556V22.5467Z"
          fill="white" />
        <path
          d="M39.9311 22.9523V20.6188L37.8242 18.6067V15.5781H39.3147V18.0695L40.7265 19.4021H40.6262L42.0433 18.0695V15.5781H43.5338V18.6067L41.4216 20.6188V22.9523H39.9311ZM40.3368 22.5572H41.0159V20.4608L43.1281 18.4593V15.9731H42.449V18.2328L40.6896 19.892H40.6684L38.9041 18.2328V15.9731H38.2295V18.4593L40.3368 20.4608V22.5572Z"
          fill="white" />
        <path
          d="M31.0977 22.9523V16.6631L32.1828 15.5781H35.5855L36.6707 16.6631V22.9523H35.1852V20.7821H32.5831V22.9523H31.0977ZM31.5191 22.5151H32.1933V20.3871H35.5908V22.5151H36.2703V16.8422L35.3854 15.9625H32.3987L31.5191 16.8422V22.5151ZM32.5831 19.2968H35.1852V17.39L34.8639 17.0687H32.9044L32.5831 17.39V19.2968ZM32.1933 19.7076V17.1688L32.7253 16.6368H35.0587L35.5908 17.1688V19.7076H32.1933Z"
          fill="white" />
        <path
          d="M24.5273 22.9523V15.5781H28.9045L29.9895 16.6631V19.5654L28.8044 20.7505H26.018V22.9523H24.5273ZM24.9276 22.5941H25.6071V20.3292H28.6253L29.5997 19.3494V16.8527L28.7201 15.9731H24.9276V22.5941ZM26.018 19.2599H28.0775L28.5041 18.8385V17.39L28.1828 17.0687H26.018V19.2599ZM25.6071 19.6549V16.6526H28.3935L28.9255 17.1793V19.0281L28.2935 19.6549H25.6071Z"
          fill="white" />
        <path
          d="M41.8861 9.91668H42.5603V4.03309H43.4769L45.3676 9.90616H47.4798V3.33255H46.8057V9.21613H45.8838L44.0037 3.34835H41.8861V9.91668ZM41.4805 10.3117V2.9375H44.3406L46.163 8.8H46.3897V2.9375H47.8802V10.3117H45.0201L43.1871 4.43343H42.971V10.3117H41.4805Z"
          fill="white" />
        <path
          d="M35.8516 10.3117V8.82108H37.2949V4.42815H35.8516V2.9375H40.218V4.42815H38.78V8.82108H40.218V10.3117H35.8516ZM36.2572 9.90616H39.8177V9.23193H38.3482V4.01202H39.8177V3.33255H36.2572V4.01202H37.674V9.23193H36.2572V9.90616Z"
          fill="white" />
        <path
          d="M30.1602 10.3117V2.9375H34.8481V4.42815H31.6508V5.84504H34.6479V7.3357H31.6508V10.3117H30.1602ZM30.5658 9.91668H31.2452V6.9196H34.2423V6.2401H31.2452V4.01202H34.4531V3.33255H30.5658V9.91668Z"
          fill="white" />
        <path
          d="M24.4727 10.3117V2.9375H29.1606V4.42815H25.9633V5.84504H28.9604V7.3357H25.9633V10.3117H24.4727ZM24.8782 9.91668H25.5577V6.9196H28.5548V6.2401H25.5577V4.01202H28.7655V3.33255H24.8782V9.91668Z"
          fill="white" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3369_14960">
        <rect width="80" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
