<script setup lang="ts">
import {useRouter} from "vue-router"
import { useModalStore } from "@/stores/ModalStore";
import QrcodeVue from "qrcode.vue"
import FFButton from "@/components/FFUI/FFButton.vue"

interface IProps {
  url: string
  size: number
  level: "L" | "M" | "Q" | "H"
  margin: number
  orderId: string
  cantScanQr: boolean
}

withDefaults(defineProps<IProps>(), {
  size: 170,
  level: "Q",
  margin: 30,
  cantScanQr: false
})

const modalStore = useModalStore()
const router = useRouter()
const emit = defineEmits(["cantScan"])

const handleProblemsWithScan = () => {
  emit("cantScan", true)
}

const completeFreedomSuperAppPayment = () => {
  localStorage.setItem("paymentType", "FreedomSuperApp")
  modalStore.closeModal()
  router.replace("success-pay")
}
</script>

<template>
  <div class="super-app-qr-block">
    <template v-if="cantScanQr">
      <p class="super-app-qr-block__cant-scan-title">{{ $t("app.ogpoPage.forms.doesntScanQrInstructions.title") }}</p>
      <ul class="super-app-qr-block__cant-scan-list">
        <li>{{ $t("app.ogpoPage.forms.doesntScanQrInstructions.intruction-1") }};</li>
        <li>{{ $t("app.ogpoPage.forms.doesntScanQrInstructions.intruction-2") }};</li>
        <li>{{ $t("app.ogpoPage.forms.doesntScanQrInstructions.intruction-3") }};</li>
        <li>{{ $t("app.ogpoPage.forms.doesntScanQrInstructions.intruction-4") }};</li>
        <li>
          {{ $t("app.ogpoPage.forms.doesntScanQrInstructions.intruction-5-1") }} {{ orderId }}
          {{ $t("app.ogpoPage.forms.doesntScanQrInstructions.intruction-5-2") }}.
        </li>
      </ul>
    </template>
    <template v-else>
      <div class="super-app-qr-block__top">
        <p class="super-app-qr-block__title">
          <slot name="super-app-qr-block__title-first-row" />
        </p>
        <p class="super-app-qr-block__title">
          <slot name="super-app-qr-block__title-second-row" />
        </p>
      </div>
      <QrcodeVue :value="url" :size="size" :level="level" render-as="svg" />
      <div class="super-app-qr-block__bottom">
        <FFButton @click="completeFreedomSuperAppPayment" :title="$t('app.buttons.complete')" :uppercase="false" />
        <p @click="handleProblemsWithScan" class="super-app-qr-block__bottom-question">
          {{ $t("app.ogpoPage.forms.doesntScanQr") }}
        </p>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
.super-app-qr-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0;

  .super-app-qr-block__top {
    margin-bottom: 15px;

    .super-app-qr-block__title {
      font-size: 16px;
      font-weight: 700;
      line-height: normal;
      text-align: center;
      color: #ffffff;
    }
  }

  .super-app-qr-block__cant-scan-title {
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    line-height: normal;
    color: #ffffff;
    margin-bottom: 10px;
  }

  .super-app-qr-block__cant-scan-list {
    list-style: decimal;
    padding-left: 12px;
  }

  .super-app-qr-block__cant-scan-list li {
    position: relative;
    font-size: 13px;
    font-weight: 300;
    font-style: normal;
    line-height: normal;
    color: #ffffff;
  }

  .super-app-qr-block__cant-scan-list li:not(:last-of-type) {
    margin-bottom: 5px;
  }

  .super-app-qr-block__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 19px;
    margin-top: 17px;
    .super-app-qr-block__bottom-question {
      font-size: 13px;
      font-weight: 300;
      line-height: normal;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
      border-bottom: 1px solid #ffffff;
      padding-bottom: 2px;
      width: fit-content;
    }
  }
}
</style>
