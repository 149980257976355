import {createI18n} from "vue-i18n"

function loadLocaleMessages() {
  const messages = <any>{}
  const locales = <any>import.meta.glob("@/locales/*/*.json", {eager: true})

  for (const key in locales) {
    const matched = key.match(/\/([A-Za-z0-9-_]+)\/([A-Za-z0-9-_]+)\.json$/i)
    if (matched && matched.length === 3) {
      const locale = matched[1]
      const module = matched[2]
      if (!messages[locale]) messages[locale] = {}
      messages[locale][module] = locales[key].default
    }
  }

  return messages
}

export default createI18n({
  locale: localStorage.getItem("locale") || "ru",
  fallbackLocale: "ru",
  messages: loadLocaleMessages(),
  allowComposition: true,
  // legacy: false
})
