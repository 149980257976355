<script setup lang="ts">
import {onMounted, computed} from "vue"
import {useRouter} from "vue-router"
import {useHelpers} from "@/composables/useHelpers"
import {useDeviceSize} from "@/composables/useDeviceSize"
import {Swiper, SwiperSlide} from "swiper/vue"
import {Autoplay, Pagination} from "swiper/modules"
import {externalUrls} from "@/constants/urls"
import SliderArrow from "@/components/icons/SliderArrow.vue"
import SliderSmallArrow from "@/components/icons/SliderSmallArrow.vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import WhiteLogo from "@/components/icons/WhiteLogo.vue"
import AppleStoreDownloadIcon from "@/components/icons/socials/AppleStoreDownloadIcon.vue"
import GooglePlayMarketDownloadIcon from "@/components/icons/socials/GooglePlayMarketDownloadIcon.vue"

interface Slider {
  title1?: string
  title2?: string
  paragraph1?: string
  paragraph2?: string
  imageDesktop?: string
  imageTablet?: string
  imageMobile?: string
  title?: string
  subTitle?: string
  description?: string
  btnTitle?: string
  image?: string
  to?: string
}

interface Props {
  data: Slider[]
}

const props = defineProps<Props>()
const router = useRouter()
const {openLink, currentLocale} = useHelpers()
const {isDesktop, isTablet, isMobileDevice} = useDeviceSize()

const modules = [Autoplay, Pagination]

const paginationOptions = {
  el: ".swiper-pagination",
  clickable: true,
  renderBullet: (index, className) => {
    return `<span class="${className} custom-bullet"></span>`
  }
}

onMounted(() => {
  const swiperInstance = document.querySelector(".swiper").swiper

  const prevButton = document.querySelector(".button-prev")
  const nextButton = document.querySelector(".button-next")

  prevButton?.addEventListener("click", () => swiperInstance.slidePrev())
  nextButton?.addEventListener("click", () => swiperInstance.slideNext())
})

const getSlideImage = (slide, index) => {
  return computed(() => {
    let imagePath
    if (index === 0) {
      if (isDesktop.value) {
        imagePath = slide.imageDesktop
      } else if (isTablet.value) {
        imagePath = slide.imageTablet
      } else if (isMobileDevice.value) {
        imagePath = slide.imageMobile
      }
    } else {
      imagePath = slide.image
    }
    return imagePath
  })
}

const handleSlideClick = (index: number) => {
  if (index === 0) {
    openLink(externalUrls.superAppUrl, "_self")
  } else if (index === 1) {
    router.push("/pages/earn-money-with-dtp-app")
  }
}
</script>

<template>
  <div>
    <div class="slider container">
      <swiper
        :slides-per-view="1"
        :centered-slides="true"
        :space-between="50"
        :loop="true"
        :pagination="paginationOptions"
        :autoplay="{delay: 3000, disableOnInteraction: false}"
        :allow-touch-move="false"
        :modules="modules">
        <swiper-slide
          v-for="(value, index) in data"
          :key="index"
          @click="handleSlideClick(index)"
          :class="{'cursor-pointer': index === 0, slider__slide: true}"
          :style="{
            backgroundImage: `url(${getSlideImage(value, index).value})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }">
          <template v-if="index === 0">
            <div class="slider__slide-content">
              <div v-if="currentLocale === 'ru'" class="slider__slide-content-bank-cashback-titles">
                <p class="slider__slide-content-bank-cashback-title-1">{{ value.title1 }}</p>
                <p class="slider__slide-content-bank-cashback-title-2">{{ value.title2 }}</p>
              </div>
              <div v-if="currentLocale === 'kk'" class="slider__slide-content-bank-cashback-titles-not-ru">
                <p class="slider__slide-content-bank-cashback-title-2-not-ru">{{ value.title1 }}</p>
                <p class="slider__slide-content-bank-cashback-title-1-not-ru">{{ value.title2 }}</p>
              </div>
              <div v-if="currentLocale === 'en'" class="slider__slide-content-bank-cashback-titles-not-ru">
                <p class="slider__slide-content-bank-cashback-title-1-not-ru">{{ value.title1 }}</p>
                <p class="slider__slide-content-bank-cashback-title-2-not-ru">{{ value.title2 }}</p>
              </div>
              <p class="slider__slide-content-bank-cashback-paragraph-1">{{ value.paragraph1 }}</p>
              <p class="slider__slide-content-bank-cashback-paragraph-2">{{ value.paragraph2 }}</p>
            </div>
          </template>

          <template v-if="index === 1">
            <div class="slider__slide-dtp-money-content">
              <p class="slider__slide-dtp-money-license">{{ value.license }}</p>
              <WhiteLogo class="slider__slide-dtp-money-logo" height="61px" width="213px" />
              <div class="slider__slide-content-title-wrapper">
                <p
                  :class="
                    currentLocale === 'ru'
                      ? 'slider__slide-dtp-money-title'
                      : currentLocale === 'kk'
                        ? 'slider__slide-dtp-money-title-kk'
                        : 'slider__slide-dtp-money-title-en'
                  ">
                  {{ value.title }}
                </p>
                <div v-if="currentLocale !== 'en'" class="slider__slide-dtp-money-abbreviations">
                  <span>
                    {{ $t("form.formAbbreviations.tg") }}
                  </span>
                  <span>
                    {{ $t("form.formAbbreviations.monthStar") }}
                  </span>
                </div>
                <img
                  v-if="currentLocale === 'ru'"
                  class="slider__slide-dtp-money-or-more-ru"
                  src="/dtp-landing-front/images/or-more-ru.png"
                  alt="" />
                <img
                  v-if="currentLocale === 'kk'"
                  class="slider__slide-dtp-money-or-more-kk"
                  src="/dtp-landing-front/images/or-more-kk.png"
                  alt="" />
                <img
                  v-if="currentLocale === 'en'"
                  class="slider__slide-dtp-money-or-more-en"
                  src="/dtp-landing-front/images/or-more-en.png"
                  alt="" />
              </div>
              <p class="slider__slide-dtp-money-description">{{ value.description }}</p>
              <div class="slider__slide-dtp-money-stores">
                <AppleStoreDownloadIcon @click="openLink(externalUrls.dtpMobileAppAppStoreUrl, '_blank')"  />
                <GooglePlayMarketDownloadIcon @click="openLink(externalUrls.dtpMobileAppGoogleStoreUrl, '_blank')" />
              </div>
              <p
                :class="
                  currentLocale !== 'ru' ? 'slider__slide-dtp-money-remark-kk ' : 'slider__slide-dtp-money-remark'
                ">
                {{ value.remark }}
              </p>
              <img class="slider__slide-dtp-money-man-img" src="/dtp-landing-front/images/dtp-man.png" alt="" />
            </div>
          </template>

          <template v-else>
            <div class="slider__slide-content">
              <p class="slider__slide-content-title">{{ value.title }}</p>
              <p class="slider__slide-content-subtitle">{{ value.subTitle }}</p>
              <p class="slider__slide-content-description">{{ value.description }}</p>
            </div>
            <FFButton
              v-if="value.btnTitle"
              @click="openLink(value.to, '_self')"
              class="slider__slide-btn"
              :title="value.btnTitle"
              :uppercase="false"
              color="#4F9D3A"
              paddingx="17px"
              paddingy="30px"
              width="fit-content"
              widthMobile="280px" />
          </template>
        </swiper-slide>

        <template v-if="isDesktop">
          <div class="button-prev">
            <SliderArrow />
          </div>
          <div class="button-next">
            <SliderArrow />
          </div>
        </template>
        <template v-if="isTablet || isMobileDevice">
          <div class="button-prev">
            <SliderSmallArrow />
          </div>
          <div class="button-next">
            <SliderSmallArrow />
          </div>
        </template>
      </swiper>
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.slider {
  padding: 0 15px;
  @media (max-width: $xl) {
    position: relative;
  }
  .swiper {
    position: relative;
    max-width: 1100px;
    min-height: 400px;
    z-index: 0;

    .swiper-wrapper {
      .swiper-slide {
        width: 100%;
        max-width: 1100px;
        min-height: 400px;
        border-radius: 20px;

        .slider__slide-content {
          position: relative;
          padding: 82px 0 0 151px;

          @media (max-width: $xl) {
            padding: 58px 37px 0 81px;
          }

          @media (max-width: $lg) {
            padding: 39px 31px 0 29px;
          }

          //general
          .slider__slide-content-title {
            color: #ffffff;
            font-size: 50px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;

            @media (max-width: $lg) {
              font-size: 40px;
            }
          }
          .slider__slide-content-subtitle {
            color: #ffffff;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 10px;
            max-width: 704px;

            @media (max-width: $lg) {
              font-size: 18px;
            }
          }
          .slider__slide-content-description {
            color: #ffffff;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            max-width: 704px;
          }

          //bank
          .slider__slide-content-bank-cashback-titles {
            position: absolute;
            top: 115px;
            width: 390px;
            @media (max-width: $xxl) {
              left: 79px;
              top: 128px;
            }
            @media (max-width: $xl) {
              left: 59px;
              top: 123px;
            }
            @media (max-width: $lg) {
              width: 295px;
              top: 84px;
              left: 29px;
            }
            @media (max-width: $md) {
              width: 250px;
              top: 87px;
              left: 28px;
            }
            .slider__slide-content-bank-cashback-title-1 {
              color: #ffffff;
              font-size: 39px;
              font-style: normal;
              font-weight: 900;
              line-height: 50.05px;
              @media (max-width: $xxl) {
                line-height: 50.05px;
              }
              @media (max-width: $lg) {
                font-size: 31.472px;
                line-height: 30px;
              }
              @media (max-width: $md) {
                font-size: 25.472px;
              }
            }
            .slider__slide-content-bank-cashback-title-2 {
              background: linear-gradient(84deg, #1f8fce -2.18%, #3eef3b 97.83%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              font-size: 39px;
              font-style: normal;
              font-weight: 900;
              line-height: 50.05px;
              @media (max-width: $xxl) {
                line-height: 50.05px;
              }
              @media (max-width: $lg) {
                font-size: 31.472px;
                line-height: 30px;
              }
              @media (max-width: $md) {
                font-size: 25.472px;
              }
            }
          }
          .slider__slide-content-bank-cashback-titles-not-ru {
            position: absolute;
            top: 121px;
            width: 390px;
            @media (max-width: $xxl) {
              left: 79px;
              top: 121px;
            }
            @media (max-width: $xl) {
              left: 59px;
              top: 123px;
            }
            @media (max-width: $lg) {
              width: 277px;
              top: 86px;
              left: 29px;
            }
            @media (max-width: $md) {
              width: 250px;
              top: 86px;
              left: 26px;
            }
            .slider__slide-content-bank-cashback-title-1-not-ru {
              color: #ffffff;
              font-size: 34.608px;
              font-style: normal;
              font-weight: 900;
              line-height: 41.05px;

              @media (max-width: $lg) {
                font-size: 23.98px;
                line-height: 28.871px;
              }
              @media (max-width: $md) {
                line-height: 23px;
              }
            }
            .slider__slide-content-bank-cashback-title-2-not-ru {
              background: linear-gradient(84deg, #1f8fce -2.18%, #3eef3b 97.83%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              font-size: 34.608px;
              font-style: normal;
              font-weight: 900;
              line-height: 41.05px;

              @media (max-width: $lg) {
                font-size: 23.98px;
                line-height: 28.871px;
              }
              @media (max-width: $md) {
                line-height: 23px;
              }
            }
          }
          .slider__slide-content-bank-cashback-paragraph-1 {
            color: rgba(255, 255, 255, 0.8);
            font-size: 8.162px;
            font-style: normal;
            font-weight: 500;
            line-height: 140.2%;
            width: 186px;
            position: absolute;
            top: 314px;
            left: 310px;
            @media (max-width: $xxl) {
              left: 225px;
            }
            @media (max-width: $xl) {
              left: 219px;
            }
            @media (max-width: $lg) {
              left: 148px;
              top: 199px;
              color: rgba(6, 187, 165, 0.8);
              font-size: 6.179px;
              font-style: normal;
              font-weight: 400;
              line-height: 140.2%;
              width: 167px;
            }
            @media (max-width: $md) {
              left: 153px;
              top: 198px;
              color: rgba(6, 187, 165, 0.8);
              font-size: 6.179px;
              font-style: normal;
              font-weight: 400;
              line-height: 140.2%;
              width: 129px;
            }
          }
          .slider__slide-content-bank-cashback-paragraph-2 {
            color: rgba(255, 255, 255, 0.7);
            font-size: 7.676px;
            font-style: normal;
            font-weight: 500;
            line-height: 10.235px;
            width: 432px;
            position: absolute;
            right: 77px;
            top: 353px;
            @media (max-width: $xxl) {
              right: 20px;
            }
            @media (max-width: $xl) {
              width: 193px;
              top: 21px;
              font-size: 7px;
            }
            @media (max-width: $lg) {
              color: rgba(255, 255, 255, 0.7);
              font-size: 4px;
              font-style: normal;
              top: 33px;
              left: 150px;
              font-weight: 400;
              width: 160px;
              line-height: normal;
            }
            @media (max-width: 374px) {
              color: rgba(255, 255, 255, 0.7);
              font-size: 4px;
              font-style: normal;
              top: 34px;
              left: 115px;
              font-weight: 500;
              width: 142px;
              line-height: normal;
            }
          }
        }
        .slider__slide-dtp-money-content {
          //dtp earn money
          position: relative;
          padding: 27px 0 0 121px;

          @media (max-width: $xl) {
            padding: 28px 37px 0 82px;
          }

          @media (max-width: $lg) {
            padding: 11px 31px 0 27px;
          }

          .slider__slide-dtp-money-license {
            color: rgba(255, 255, 255, 0.5);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 18px;

            @include max-responsive(lg) {
              margin-bottom: 10px;
              margin-top: 5px;
            }
          }
          .slider__slide-dtp-money-logo {
            width: 141.96px;
            height: 41px;
          }
          .slider__slide-content-title-wrapper {
            position: relative;
            @include flex;
            margin: 20px 0;
            .slider__slide-dtp-money-title {
              font-size: 45px;
              font-style: normal;
              font-weight: 900;
              line-height: 40px;
              max-width: 492px;
              @include main-green-gradient;

              @include max-responsive(lg) {
                font-size: 22px;
                line-height: 23px;
                max-width: 240px;
              }
            }
            .slider__slide-dtp-money-title-kk {
              font-size: 45px;
              font-style: normal;
              font-weight: 900;
              line-height: 40px;
              max-width: 467px;
              @include main-green-gradient;

              @include max-responsive(lg) {
                font-size: 21px;
                line-height: 23px;
                max-width: 240px;
              }
            }
            .slider__slide-dtp-money-title-en {
              font-size: 45px;
              font-style: normal;
              font-weight: 900;
              line-height: 40px;
              max-width: 558px;
              @include main-green-gradient;

              @include max-responsive(lg) {
                font-size: 21px;
                line-height: 23px;
                max-width: 240px;
              }
            }
            .slider__slide-dtp-money-abbreviations {
              @include column;
              span {
                font-size: 18px;
                font-style: normal;
                font-weight: 900;
                @include main-green-gradient;
              }

              @include max-responsive(lg) {
                margin-top: -10px;
              }
            }
            .slider__slide-dtp-money-or-more-ru {
              position: absolute;
              top: -60px;
              right: 350px;
              transform: rotate(-11.528deg);
              width: 160px;
              height: 70px;

              @include max-responsive(xxl) {
                top: -61px;
                right: 235px;
              }

              @include max-responsive(xl) {
                top: -61px;
                right: -5px;
              }

              @include max-responsive(lg) {
                top: -47px;
                right: -28px;
                width: 95px;
                height: 40px;
                z-index: 1;
              }
            }
            .slider__slide-dtp-money-or-more-kk {
              position: absolute;
              top: -100px;
              right: 380px;
              transform: rotate(-11.528deg);
              width: 220px;
              height: 90px;

              @include max-responsive(xxl) {
                top: -95px;
                right: 180px;
              }

              @include max-responsive(xl) {
                top: -95px;
                right: -30px;
              }

              @include max-responsive(lg) {
                top: -60px;
                right: -35px;
                width: 128px;
                height: 51px;
                z-index: 1;
              }
            }
            .slider__slide-dtp-money-or-more-en {
              position: absolute;
              top: -60px;
              right: 350px;
              transform: rotate(-11.528deg);
              width: 100px;
              height: 70px;

              @include max-responsive(xxl) {
                top: -65px;
                right: 230px;
              }

              @include max-responsive(xl) {
                top: -61px;
                right: -5px;
              }

              @include max-responsive(lg) {
                top: -35px;
                right: 108px;
                width: 69px;
                height: 40px;
                z-index: 1;
              }
            }
          }
          .slider__slide-dtp-money-description {
            color: var(--color-white);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            max-width: 500px;

            @include max-responsive(lg) {
              font-size: 14px;
            }
          }
          .slider__slide-dtp-money-stores {
            @include flex;
            gap: 20px;
            margin-top: 10px;
            svg {
              width: 130px;
            }
          }
          .slider__slide-dtp-money-remark {
            color: rgba(255, 255, 255, 0.5);
            font-size: 12px;
            font-style: italic;
            font-weight: 500;
            line-height: normal;
            margin-top: 10px;
            max-width: 500px;

            @include max-responsive(xl) {
              position: absolute;
              right: 22px;
              bottom: 9px;
              max-width: 294px;
            }

            @include max-responsive(lg) {
              margin-top: unset;
              margin-left: unset;
              max-width: unset;
              max-width: 294px;
              bottom: -48px;
            }
          }
          .slider__slide-dtp-money-remark-kk {
            color: rgba(255, 255, 255, 0.5);
            font-size: 12px;
            font-style: italic;
            font-weight: 500;
            line-height: normal;
            margin-top: 10px;
            max-width: 500px;

            @include max-responsive(xl) {
              position: absolute;
              right: 22px;
              bottom: 9px;
              max-width: 294px;
            }

            @include max-responsive(lg) {
              margin-top: unset;
              margin-left: unset;
              max-width: unset;
              max-width: 294px;
              bottom: -32px;
            }
          }
          .slider__slide-dtp-money-man-img {
            position: absolute;
            top: 0;
            right: 95px;
            width: 330px;
            height: 450px;

            @include max-responsive(xxl) {
              opacity: 0.3;
              right: 15px;
            }

            @include max-responsive(xl) {
              display: none;
            }
          }
        }
        .slider__slide-btn {
          margin-left: 151px;
          margin-top: 50px;

          @media (max-width: $xl) {
            margin: 50px 0 0 81px;
          }
          @media (max-width: $lg) {
            margin: 20px 0 0 29px;
          }
          @media (max-width: $md) {
            width: 220px;
          }
        }
      }
    }
    .button-prev,
    .button-next {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      z-index: 1;
    }

    .button-prev {
      left: 50px;
      @media (max-width: $xxl) {
        left: 20px;
      }
      @media (max-width: $xl) {
        left: 71px;
        top: 348px;
      }
      @media (max-width: $lg) {
        left: 20px;
        top: 360px;
      }
    }
    .button-next {
      right: 50px;
      transform: translateY(-50%) rotate(180deg);
      @media (max-width: $xxl) {
        right: 20px;
      }
      @media (max-width: $xl) {
        top: 348px;
        right: 490px;
      }
      @media (max-width: $lg) {
        right: 210px;
        top: 360px;
      }
      @media (max-width: $md) {
        right: 220px;
      }
      @media (max-width: 360px) {
        right: 160px;
      }
    }
  }

  .swiper-pagination {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 38px;
    z-index: 0;

    @media (max-width: $xl) {
      position: absolute;
      margin-top: 0;
      left: 498px;
      top: 349px;
      width: fit-content;
      height: fit-content;
    }
    @media (max-width: $lg) {
      left: 160px;
      top: 357px;
    }
    @media (max-width: $md) {
      left: 195px;
    }
    @media (max-width: 360px) {
      left: 124px;
    }
  }
  .swiper-pagination :deep(.swiper-pagination-bullet):not(:last-of-type) {
    margin-right: 10px;
  }
  .swiper-pagination :deep(.swiper-pagination-bullet):not(.swiper-pagination-bullet-active) {
    background: #d9d9d9 !important;
  }
  .swiper-pagination :deep(.swiper-pagination-bullet-active) {
    background: #ffffff;
  }
}
</style>
