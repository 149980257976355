<script setup lang="ts">
import type {Component} from "vue"
import HProtectHowProgramWorkBlock from "@/components/HomeProtect/HProtectHowProgramWorks/UI/HProtectHowProgramWorkBlock.vue"
import GreenCake from "@/components/icons/greens/GreenCake.vue"
import GreenBuilding from "@/components/icons/greens/GreenBuilding.vue"
import GreenGeolocation from "@/components/icons/greens/GreenGeolocation.vue"
import GreenCalculator from "@/components/icons/greens/GreenCalculator.vue"

const blockIcons: Component[] = [GreenCake, GreenBuilding, GreenGeolocation, GreenCalculator]
</script>

<template>
  <section class="home-protect-how-program-works container">
    <p class="home-protect-how-program-works__title">{{ $t("app.homeProtectPage.howWorks.title") }}</p>
    <div class="home-protect-how-program-works__body">
      <div class="home-protect-how-program-works__blocks">
        <template v-for="i in 4" :key="i">
          <HProtectHowProgramWorkBlock
            :title="$t(`app.homeProtectPage.howProgramWorks.title-${i}`)"
            :description="$t(`app.homeProtectPage.howProgramWorks.description-${i}`)">
            <template #component>
              <component :is="blockIcons[i - 1]"></component>
            </template>
          </HProtectHowProgramWorkBlock>
        </template>
      </div>
      <div class="home-protect-how-program-works__earthquake-image"></div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.home-protect-how-program-works {
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-bottom: 600px;
  position: relative;
  z-index: 1;

  border-radius: 30px;
  background: rgba(74, 74, 74, 0.2);
  backdrop-filter: blur(8px);

  @media (max-width: $lg) {
    margin-bottom: 320px;
  }

  .home-protect-how-program-works__title {
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 40px;
    background: linear-gradient(95deg, #79b0e2 -3.54%, #1cd491 56.19%, #3fed6e 102.64%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .home-protect-how-program-works__body {
    display: flex;
    align-items: center;
    gap: 50px;

    @media (max-width: $lg) {
      flex-direction: column;
    }

    .home-protect-how-program-works__blocks {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 50px 30px;
    }
    .home-protect-how-program-works__earthquake-image {
      width: 1500px;
      height: 392px;
      border-radius: 20px;
      border: 5px solid #aaa;
      background: url(/home-protect-front/images/earthquake-result.png) center center / cover no-repeat;
      box-shadow: 0px 0px 150px 0px rgba(255, 255, 255, 0.2);

      @media (max-width: $xl) {
        height: 530px;
      }
      @media (max-width: $lg) {
        width: 100%;
      }
    }
  }
}
</style>
