<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="42" height="32" viewBox="0 0 400 121" fill="none">
    <path
      d="M133.081 58.3444V17.2314H158.279V20.2544C158.279 23.2774 155.713 25.847 152.696 25.847H143.19V34.4626H155.713V37.3345C155.713 40.5086 153.148 43.0782 149.98 43.0782H143.19V58.3444H133.081Z"
      fill="black"></path>
    <path
      d="M173.82 35.823H178.196C179.856 35.823 181.063 35.3695 181.968 34.4626C182.873 33.5557 183.326 32.3465 183.326 30.6838C183.326 28.4166 182.572 26.9051 180.912 26.3005C180.007 25.847 178.8 25.6959 177.14 25.6959H173.82V35.823V35.823ZM163.862 58.3444V17.2314H178.196C181.817 17.2314 184.533 17.5337 186.193 18.1383C188.607 19.0452 190.418 20.5567 191.776 22.5217C193.134 24.6378 193.737 27.0562 193.737 30.0792C193.737 32.3465 193.134 34.6137 192.078 36.7299C191.021 38.846 189.512 40.3575 187.551 41.4155V41.5667C188.004 42.0201 188.456 42.927 189.06 43.9851L197.057 58.3444H185.891L178.649 44.4385H173.971V58.3444H163.862Z"
      fill="black"></path>
    <path
      d="M201.584 58.3444V17.2314H227.235V25.847H211.693V33.2534H224.066V41.869H211.693V49.7288H228.14V58.3444H201.584Z"
      fill="black"></path>
    <path
      d="M234.477 58.3444V17.2314H260.279V25.847H244.586V33.2534H257.11V41.869H244.586V49.7288H261.033V58.3444H234.477Z"
      fill="black"></path>
    <path
      d="M277.48 49.7288H281.554C285.024 49.7288 287.891 48.6708 289.853 46.5547C291.814 44.4385 292.87 41.5667 292.87 37.6368C292.87 33.858 291.814 30.835 289.853 28.87C287.891 26.9051 285.175 25.847 281.705 25.847H277.631V49.7288H277.48ZM267.521 58.3444V17.2314H282.157C288.646 17.2314 293.776 19.0452 297.548 22.6728C301.32 26.3005 303.282 31.2884 303.282 37.6368C303.282 44.1362 301.32 49.1242 297.548 52.7518C293.776 56.3795 288.646 58.1933 282.157 58.1933H267.521V58.3444Z"
      fill="black"></path>
    <path
      d="M317.918 37.4855C317.918 40.962 318.974 43.985 321.087 46.2523C323.199 48.5195 325.764 49.7287 329.084 49.7287C332.252 49.7287 334.968 48.5195 337.081 46.2523C339.193 43.985 340.249 40.962 340.249 37.4855C340.249 34.1602 339.193 31.2883 337.081 29.0211C334.968 26.7538 332.403 25.6958 329.084 25.6958C325.915 25.6958 323.199 26.7538 321.087 29.0211C318.974 31.2883 317.918 34.1602 317.918 37.4855ZM307.507 37.4855C307.507 31.4395 309.468 26.4515 313.542 22.5216C317.616 18.5917 322.746 16.4756 328.933 16.4756C335.27 16.4756 340.4 18.4405 344.323 22.5216C348.397 26.6027 350.359 31.5906 350.359 37.4855C350.359 43.6827 348.397 48.6707 344.323 52.9029C340.249 56.984 335.119 59.1001 328.933 59.1001C322.595 59.1001 317.465 56.984 313.542 52.9029C309.468 48.8218 307.507 43.6827 307.507 37.4855Z"
      fill="black"></path>
    <path
      d="M355.188 58.3444L358.507 17.2314H369.371L375.406 34.6137L377.519 41.4155H377.67C378.424 38.6948 379.178 36.4276 379.782 34.6137L385.817 17.2314H396.681L400.001 58.3444H389.891L388.684 39.904C388.533 38.9971 388.533 37.9391 388.533 36.7299C388.533 35.5207 388.533 34.6137 388.533 34.0091V32.9511H388.382C387.477 35.823 386.723 38.0902 386.119 39.904L381.743 51.8449H373.143L368.767 39.904L366.504 32.9511H366.353C366.504 35.5207 366.504 37.7879 366.353 39.904L365.146 58.3444H355.188V58.3444Z"
      fill="black"></path>
    <path
      d="M52.0557 120.467C52.0557 120.467 0 105.05 0 29.1721V0H104.111V29.0209C104.111 102.329 52.0557 120.467 52.0557 120.467Z"
      fill="#4FB84E"></path>
    <path
      d="M82.0806 29.3236H40.1343V53.5077H82.0806V60.763C82.0806 73.0062 78.6102 82.831 60.0512 82.831H40.1343V114.724C47.075 118.956 52.0543 120.468 52.0543 120.468C52.0543 120.468 104.11 102.329 104.11 29.0213V7.10448C104.11 19.3477 98.6781 29.0213 82.0806 29.3236Z"
      fill="#055532"></path>
    <path
      d="M133.232 99.7603V70.8905H145.152C147.868 70.8905 150.131 71.7974 151.942 73.6112C153.602 75.425 154.507 77.8434 154.507 80.7153C154.507 83.5871 153.602 86.0055 151.942 87.9705C150.282 89.7843 148.019 90.8424 145.152 90.8424H140.324V99.7603H133.232ZM140.324 84.7963H143.794C145.001 84.7963 145.906 84.494 146.51 83.7383C147.113 82.9825 147.415 82.0756 147.415 80.8664C147.415 79.6572 147.113 78.7503 146.51 77.9946C145.906 77.2388 145.001 76.9365 143.945 76.9365H140.324V84.7963Z"
      fill="black"></path>
    <path
      d="M155.412 99.7603L165.22 70.8905H172.613L182.421 99.7603H175.178L173.368 93.7142H164.465L162.806 99.7603H155.412ZM166.125 87.9705H171.859L170.199 82.3779L168.992 77.39H168.841C168.388 79.5061 168.087 81.1687 167.634 82.3779L166.125 87.9705Z"
      fill="black"></path>
    <path
      d="M191.473 99.7603V87.9705L181.967 70.8905H189.964L193.284 77.8434L194.943 81.9245H195.094C195.698 80.413 196.151 79.0526 196.754 77.8434L199.923 70.8905H207.92L198.414 87.9705V99.7603H191.473Z"
      fill="black"></path>
  </svg>
</template>
