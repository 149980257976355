<script setup lang="ts">
import {ref, computed, watch} from "vue"
import {useRoute, useRouter} from "vue-router"
import {useHelpers} from "@/composables/useHelpers"
import {kaskoUrl, comfortUrl} from "@/constants/urls"
import FFCallCenterFull from "@/components/FFUI/FFCallCenterFull.vue"
import DarkBackground from "@/components/partials/DarkBackground.vue"

const router = useRouter()
const route = useRoute()

const {currentLocale, openLink} = useHelpers()

const productsMenu = ref<boolean>(false)
const insuranceMenu = ref<boolean>(false)
const aboutMenu = ref<boolean>(false)
const contactsMenu = ref<boolean>(false)

const closeMenu = () => {
  productsMenu.value = false
  insuranceMenu.value = false
  aboutMenu.value = false
  contactsMenu.value = false
}

const propertyInsurance = ref<boolean>(false)
const motorInsurance = ref<boolean>(false)
const liabilityInsurance = ref<boolean>(false)
const specializedInsurance = ref<boolean>(false)

const darkBg = computed(() => {
  return productsMenu.value || insuranceMenu.value || aboutMenu.value || contactsMenu.value
})

watch(
  () => route.path,
  () => {
    closeMenu()
  }
)

const waitOpenLink = (link: string, target?: string) => {
  closeMenu()
  setTimeout(() => {
    openLink(link, target)
  }, 200)
}

const routerPush = (path: string) => {
  closeMenu()
  router.push(path)
}
</script>

<template>
  <div class="ff-menu">
    <div class="nav-ul">
      <div class="nav-section">
        <div class="nav-btn" @click="productsMenu = true">
          {{ $t("app.productsServices") }}
        </div>
        <Transition>
          <div v-show="productsMenu" class="products-menu products-services">
            <div class="flex flex-col gap-[20px]">
              <p class="products-services__title">{{ $t("app.forPhysicalPersons") }}</p>
              <div class="products-services__list">
                <p @click="routerPush('/pages/ogpo')" class="alone">
                  {{ $t("app.requiredAutoInsuranceOgpo") }}
                </p>

                <p @click="waitOpenLink(kaskoUrl, '_self')" class="alone">
                  {{ $t("app.CASCO") }}
                </p>
                <p @click="routerPush('/pages/ogpo-plus')" class="alone">
                  {{ $t("app.ogpoPlus") }}
                </p>
                <p @click="routerPush('/pages/home-protect')" class="alone">Home Protect</p>
                <p @click="waitOpenLink(comfortUrl, '_self')" class="alone">
                  {{ $t("app.apartmentInsurance") }}
                </p>
                <p @click="routerPush('/pages/mst-remastered')" class="alone">
                  {{ $t("app.mst") }}
                </p>
              </div>
            </div>
            <div class="flex flex-col gap-[20px]">
              <p class="products-services__title" style="background: #72bf44">{{ $t("app.forLegalPersons") }}</p>
              <div class="products-services__list" style="gap: 20px">
                <div
                  @click="waitOpenLink(`https://ffins.kz/${currentLocale}/juridical-ogpo`, '_self')"
                  class="flex items-center justify-between cursor-pointer">
                  <p>{{ $t("app.requiredAutoInsuranceOgpo") }}</p>
                </div>
                <div
                  @click="propertyInsurance = !propertyInsurance"
                  class="flex items-center justify-between cursor-pointer">
                  <p>{{ $t("app.propertyCargoInsurance") }}</p>
                  <img
                    src="/mobile-menu-icons-front/arrow-black.svg"
                    :class="{'rotate-180': propertyInsurance}"
                    alt="arrow" />
                </div>
                <Transition>
                  <div v-show="propertyInsurance" class="mt-[-10px]">
                    <ul class="flex flex-col gap-[10px] ml-[22px]">
                      <li @click="waitOpenLink(`https://ffins.kz/${currentLocale}/strakhovanie-imuschestva`, '_self')">
                        {{ $t("app.propertyInsurance") }}
                      </li>
                      <li
                        @click="
                          waitOpenLink(
                            `https://ffins.kz/${currentLocale}/strakhovanie-imuschestva-i-finansovykh-ubytkov-v-rezultate-pereryva-v-proizvodst`,
                            '_self'
                          )
                        ">
                        {{ $t("app.propertyInsuranceNote") }}
                      </li>
                      <li
                        @click="
                          waitOpenLink(
                            `https://ffins.kz/${currentLocale}/strakhovanie-stroitelno-montadgnykh-riskov-smr`,
                            '_self'
                          )
                        ">
                        {{ $t("app.buildInsurance") }}
                      </li>
                      <li @click="waitOpenLink(`https://ffins.kz/${currentLocale}/strakhovanie-gruzov`, '_self')">
                        {{ $t("app.cargoInsurance") }}
                      </li>
                    </ul>
                  </div>
                </Transition>
                <div @click="motorInsurance = !motorInsurance" class="flex items-center justify-between cursor-pointer">
                  <p>{{ $t("app.motorInsurance") }}</p>
                  <img
                    src="/mobile-menu-icons-front/arrow-black.svg"
                    :class="{'rotate-180': motorInsurance}"
                    alt="arrow" />
                </div>
                <Transition>
                  <div v-show="motorInsurance" class="mt-[-10px]">
                    <ul class="flex flex-col gap-[10px] ml-[22px]">
                      <li
                        @click="
                          waitOpenLink(
                            `https://ffins.kz/${currentLocale}/strakhovanie-avtomobilnogo-transporta`,
                            '_self'
                          )
                        ">
                        {{ $t("app.autoInsurance") }}
                      </li>
                      <li
                        @click="
                          waitOpenLink(
                            `https://ffins.kz/${currentLocale}/strakhovanie-aviatransporta-i-otvetstvennosti-ego-vladeltsev`,
                            '_self'
                          )
                        ">
                        {{ $t("app.aviaInsurance") }}
                      </li>
                      <li
                        @click="
                          waitOpenLink(
                            `https://ffins.kz/${currentLocale}/strakhovanie-vodnogo-transporta-i-otvetstvennosti-ego-vladeltsev`,
                            '_self'
                          )
                        ">
                        {{ $t("app.seaInsurance") }}
                      </li>
                      <li
                        @click="
                          waitOpenLink(
                            `https://ffins.kz/${currentLocale}/strakhovanie-dgeleznodorodgnogo-transporta`,
                            '_self'
                          )
                        ">
                        {{ $t("app.railwayInsurance") }}
                      </li>
                    </ul>
                  </div>
                </Transition>
                <div
                  @click="liabilityInsurance = !liabilityInsurance"
                  class="flex items-center justify-between cursor-pointer">
                  <p>{{ $t("app.liabilityInsurance") }}</p>
                  <img
                    src="/mobile-menu-icons-front/arrow-black.svg"
                    :class="{'rotate-180': liabilityInsurance}"
                    alt="arrow" />
                </div>
                <Transition>
                  <div v-show="liabilityInsurance" class="mt-[-10px]">
                    <ul class="flex flex-col gap-[10px] ml-[22px]">
                      <li
                        @click="
                          waitOpenLink(
                            `https://ffins.kz/${currentLocale}/dobrovolnoe-strakhovanie-otvetstvennosti-vladeltsev-transportnogo-sredstva-dgpo-`,
                            '_self'
                          )
                        ">
                        {{ $t("app.optionalInsurance") }}
                      </li>
                      <li
                        @click="
                          waitOpenLink(
                            `https://ffins.kz/${currentLocale}/strakhovanie-professionalnoy-otvetstvennosti`,
                            '_self'
                          )
                        ">
                        {{ $t("app.proInsurance") }}
                      </li>
                      <li
                        @click="
                          waitOpenLink(
                            `https://ffins.kz/${currentLocale}/obyazatelnoe-strakhovanie-otvetstvennosti-vladeltsev-transportnogo-sredstva-ogpo`,
                            '_self'
                          )
                        ">
                        {{ $t("app.requiredInsurance") }}
                      </li>
                      <li
                        @click="waitOpenLink(`https://ffins.kz/${currentLocale}/strakhovanie-perevozchikov`, '_self')">
                        {{ $t("app.shippingInsurance") }}
                      </li>
                      <li
                        @click="
                          waitOpenLink(
                            `https://ffins.kz/${currentLocale}/strakhovanie-otvetstvennosti-arendatorov`,
                            '_self'
                          )
                        ">
                        {{ $t("app.rentInsurance") }}
                      </li>
                      <li
                        @click="waitOpenLink(`https://ffins.kz/${currentLocale}/ekologicheskoe-strakhovanie`, '_self')">
                        {{ $t("app.ecoInsurance") }}
                      </li>
                      <li
                        @click="
                          waitOpenLink(
                            `https://ffins.kz/${currentLocale}/obyazatelnoe-strakhovanie-gpo-vladeltsev-opasnykh-obektov`,
                            '_self'
                          )
                        ">
                        {{ $t("app.requiredGPOInsurance") }}
                      </li>
                      <li
                        @click="
                          waitOpenLink(
                            `https://ffins.kz/${currentLocale}/strakhovanie-neispolneniya-nenadledgaschego-ispolneniya-obyazatelstv-po-likvidat`,
                            '_self'
                          )
                        ">
                        {{ $t("app.liquidInsurance") }}
                      </li>
                    </ul>
                  </div>
                </Transition>
                <div
                  @click="specializedInsurance = !specializedInsurance"
                  class="flex items-start justify-between cursor-pointer">
                  <p class="max-w-[260px]">{{ $t("app.specialInsurance") }}</p>
                  <img
                    src="/mobile-menu-icons-front/arrow-black.svg"
                    :class="{'rotate-180': specializedInsurance}"
                    alt="arrow" />
                </div>
                <Transition>
                  <div v-show="specializedInsurance" class="mt-[-10px]">
                    <ul class="flex flex-col gap-[10px] ml-[22px]">
                      <li
                        @click="
                          waitOpenLink(`https://ffins.kz/${currentLocale}/strakhovanie-neftyanykh-operatsiy`, '_self')
                        ">
                        {{ $t("app.oilInsurance") }}
                      </li>
                      <li
                        @click="
                          waitOpenLink(`https://ffins.kz/${currentLocale}/meditsinskoe-strakhovanie-turistov`, '_self')
                        ">
                        {{ $t("app.medicalTouristsInsurance") }}
                      </li>
                    </ul>
                  </div>
                </Transition>
              </div>
            </div>
            <div class="flex flex-col gap-[20px]">
              <p class="products-services__title" style="background: #797979">{{ $t("app.services") }}</p>
              <div class="products-services__list">
                <p @click="waitOpenLink(`https://id.mkb.kz/#/esbd/checkInsurancePolicy`, '_self')" class="alone">
                  {{ $t("app.checkPolis") }}
                </p>
                <p @click="waitOpenLink(`https://dtp.kz`, '_self')" class="alone">{{ $t("app.dtpKz") }}</p>
                <p @click="router.push('/pages/earn-money-with-dtp-app')" class="alone">{{ $t("app.dtpApp") }}</p>
                <p @click="router.push('/pages/wear-vehicle')" class="alone">
                  {{ $t("app.depreciationCalculator") }}
                </p>
              </div>
            </div>
          </div>
        </Transition>
      </div>
      <div class="nav-section">
        <div class="nav-btn" @click="insuranceMenu = true">
          {{ $t("app.insuranceCase") }}
        </div>
        <Transition>
          <div v-show="insuranceMenu" class="products-menu insurance-menu">
            <div
              @click="waitOpenLink(`https://dtp.kz`, '_self')"
              class="products-menu__item"
              style="align-items: flex-start">
              <img src="/mobile-menu-icons-front/dtp.svg" alt="logo" />
              <div class="flex flex-col">
                <p>{{ $t("app.applyDtpkz") }}</p>
                <p class="sub">
                  {{ $t("app.ifDtp") }}
                </p>
              </div>
            </div>
          </div>
        </Transition>
      </div>
      <div class="nav-section">
        <div class="nav-btn" @click="aboutMenu = true">
          {{ $t("app.aboutUs") }}
        </div>
        <Transition>
          <div v-show="aboutMenu" class="products-menu about-menu">
            <div @click="router.push('/pages/about-company')" class="products-menu__item">
              <img src="/mobile-menu-icons-front/about.svg" alt="logo" />
              <p>{{ $t("app.aboutCompany") }}</p>
            </div>
            <div @click="router.push('/pages/our-team')" class="products-menu__item">
              <img src="/mobile-menu-icons-front/ourTeam.svg" alt="logo" />
              <p>{{ $t("app.ourTeamOne") }}</p>
            </div>
            <div @click="router.push('/pages/documents')" class="products-menu__item">
              <img src="/mobile-menu-icons-front/documents.svg" alt="logo" />
              <p>{{ $t("app.documents") }}</p>
            </div>
            <div @click="router.push('/pages/news')" class="products-menu__item">
              <img src="/mobile-menu-icons-front/news.svg" alt="logo" />
              <p>{{ $t("app.news") }}</p>
            </div>
          </div>
        </Transition>
      </div>
      <div class="nav-section">
        <div class="nav-btn" @click="router.push('/pages/contacts')">
          {{ $t("app.contacts") }}
        </div>
      </div>
      <!-- Write to chairmanlink desktop -->
      <div @click="router.push('/pages/chairman')" class="nav-btn__write-to-chairman">
        {{ $t("app.writeToChairman") }}
      </div>
    </div>

    <Transition>
      <DarkBackground :darkness="0.4" v-show="darkBg" @click="closeMenu" />
    </Transition>

    <FFCallCenterFull class="call-center" />
  </div>
</template>

<style scoped lang="scss">
.v-enter-active {
  transition: opacity 0.5s ease;
}

.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.ff-menu {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
  justify-content: space-between;
  padding: 0 14px 0 10px;
  z-index: 1;
}

.nav-ul {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.nav-btn__write-to-chairman {
  color: #fff;
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.5px;
  border-radius: 5px;
  border: 1px solid #ffffff;
  background: transparent;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all 0.3s ease;
  white-space: wrap;
  cursor: pointer;
  margin: 0 23px;
  height: max-content;
}

.nav-btn {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  border-radius: 10px;
  background: rgba(34, 34, 34, 0.2);
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  white-space: nowrap;
  cursor: pointer;
}

.nav-section {
  position: relative;
  display: inline-block;
}

.nav-btn:hover {
  background: rgba(34, 34, 34, 0.4);
  color: #4f9d3a;
}

.products-menu {
  position: absolute;
  top: 70px;
  left: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 30px 50px 30px 20px;
  border-radius: 0 20px 20px 20px;
  background: #fff;
  width: 400px;
  &__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 10px;
    transition: all 0.2s ease;
    width: fit-content;
    p {
      color: #6f6f6f;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.5px;
    }
    .sub {
      color: #8c8c8c;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  &__item:hover {
    p {
      color: #4f9d3a;
    }
    .sub {
      color: #2a5500;
    }
    transform: scale(1.04);
  }
}

.products-services {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 30px;
  &__title {
    border-radius: 4px;
    background: #f2994a;
    padding: 4px 0;
    width: 100%;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  &__list {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    gap: 10px;
    border-left: 1px solid #404040;
    p,
    li {
      color: var(--Gray-main, #404040);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.5px;
      cursor: pointer;
      transition: all 0.2s ease;
      width: fit-content;
    }
    div:hover {
      p {
        color: #4f9d3a;
      }
    }
    li {
      color: var(--Gray-secondary, #797979);
      letter-spacing: unset;
    }
    li:hover {
      color: #4f9d3a;
      transform: scale(1.04);
    }
    .alone:hover {
      transform: scale(1.04);
      color: #4f9d3a;
    }
  }
}

.insurance-menu {
  width: 447px;
}

.about-menu {
  width: 242px;
}

.contacts-menu {
  width: 372px;
}

ul {
  list-style-type: disc;
}
ol {
  list-style-type: decimal;
}

@media (max-width: 1526px) {
  .nav-btn__write-to-chairman {
    font-size: 12px;
    width: fit-content;
    height: fit-content;
    padding: 10px;
    line-height: 13px;
    text-align: center;
  }

  .nav-ul {
    gap: 1rem;
  }
}

@media (max-width: 1275px) {
  .products-menu {
    top: 62px;
  }

  .call-center {
    display: none;
  }
  .ff-menu {
    display: none;
  }
}
</style>
