export function useFormatters() {
  const phoneNumberFormatter = (value: string | null) => {
    if (value === null) {
      return null
    }
    const numberPart = value.replace(/\s+/g, "").replace(/[{()}]/g, "")
    return numberPart.substring(1)
  }

  const thousandSeparator = (value: number | null) => {
    if (value === null) {
      return 0
    }
    const parts = value.toString().split(" ")
    const numberPart = parts[0]
    const decimalPart = parts[1]
    const thousands = /\B(?=(\d{3})+(?!\d))/g
    return numberPart.replace(thousands, " ") + (decimalPart ? " " + decimalPart : " ")
  }

  const trimFileName = (n, len) => {
    var ext = n.substring(n.lastIndexOf(".") + 1, n.length).toLowerCase()
    var filename = n.replace("." + ext, "")
    if (filename.length <= len) {
      return n
    }
    filename = filename.substr(0, len) + (n.length > len ? "..." : "")
    return filename + "." + ext
  }

  const trimString = (str: string, n: number) => {
    return str.length > n ? str.slice(0, n - 1) + "..." : str
  }

  const dateFormatter = (date: string) => {
    //2024-04-26
    const localDate = new Date(date)
    return new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000).toISOString().split("T")[0]
  }

  const convertISOToDate = (input: string): string => {
    //28.05.2024
    const date = new Date(input)
    const day = String(date.getDate()).padStart(2, "0")
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const year = date.getFullYear()
    return `${day}.${month}.${year}`
  }

  const convertISOToDateTime = (input: string): string => {
    //28.05.2024 10:00
    const date = new Date(input)
    const day = String(date.getDate()).padStart(2, "0")
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const year = date.getFullYear()
    const hours = String(date.getHours()).padStart(2, "0")
    const minutes = String(date.getMinutes()).padStart(2, "0")

    return `${day}.${month}.${year} ${hours}:${minutes}`
  }

  const formatDateToRussian = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    };
    const formatter = new Intl.DateTimeFormat('ru-RU', options);
    return formatter.format(date);
  }

  const formatCardHash = (cardHash) => {
    const parts = cardHash.split('-');
    const lastPart = parts[parts.length - 1];
    return `**** ${lastPart}`;
  }
  
  return {
    phoneNumberFormatter,
    thousandSeparator,
    trimFileName,
    trimString,
    dateFormatter,
    convertISOToDate,
    convertISOToDateTime,
    formatDateToRussian,
    formatCardHash
  }
}
