<script setup lang="ts">
import {onBeforeUnmount} from "vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import {useHelpers} from "@/composables/useHelpers"

const {openLink} = useHelpers()

const paymentType = localStorage.getItem("paymentType")

const removePaymentTypeFromStorage = () => {
  localStorage.removeItem("paymentType")
}

onBeforeUnmount(() => {
  removePaymentTypeFromStorage()
})
</script>

<template>
  <div class="congratulations">
    <img src="/thankyou-front/images/thankyou.png" alt="photo" />
    <p class="congratulations__title">{{ $t("app.thankYou.title") }}</p>
    <p class="congratulations__title congratulations__subtitle">
      {{ paymentType === "kaspi" ? $t("app.thankYou.kaspiSubTitle") : $t("app.thankYou.subTitle") }}
    </p>
    <template v-if="paymentType !== 'kaspi'">
      <p class="congratulations__description">
      {{ $t("app.thankYou.description1") }} <br />
      {{ $t("app.thankYou.description2") }}
    </p>
    </template>
    <FFButton
      @click="openLink('https://ffins.kz/profile/policy', '_self')"
      class="congratulations__button"
      :title="$t('app.thankYou.login')"
      :uppercase="false"
      color="#4F9D3A"
      paddingx="15px"
      paddingy="30px"
      width="100%"
      border-pixel="1px solid #fff"
      background="#FFF" />
  </div>
</template>

<style scoped lang="scss">
.congratulations {
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  &__title {
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    background: var(--FF-gradient, linear-gradient(103deg, #bde0ff -2.91%, #1cd491 58.89%, #00ff45 106.95%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &__subtitle {
    font-size: 20px;
  }
  &__description {
    color: rgba(255, 255, 255, 0.7);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &__button {
    max-width: 300px;
  }
  &__button:hover {
    transform: scale(1.03);
  }
}

@media (max-width: $lg) {
  .congratulations {
    padding: 0;
    &__button:hover {
      transform: scale(1);
    }
  }
}
</style>
