<script setup lang="ts">
import {useRouter} from "vue-router"
import {useHelpers} from "@/composables/useHelpers"
import FFButton from "@/components/FFUI/FFButton.vue"

const {currentLocale} = useHelpers()
const router = useRouter()
</script>

<template>
  <div class="bg-image">
    <div class="container">
      <div class="fail-wrapper">
        <div class="fail">
          <img src="/fail-pay-front/images/fail-cancel.png" alt="" />
          <p class="fail__title">{{ $t("app.failPayPage.title") }}</p>
          <div class="fail__subtitle">
            <p>
              {{ $t("app.failPayPage.subtitle-1") }}
            </p>
            <a href="tel:5777">5777</a>
            <p>{{ $t("app.failPayPage.subtitle-2") }}</p>
          </div>

          <FFButton
            @click="router.push('/pages/personal-profile')"
            class="fail-message__button"
            :title="$t('app.thankYou.login')"
            :uppercase="false"
            color="#4F9D3A"
            paddingx="15px"
            paddingy="30px"
            width="300px"
            border-pixel="1px solid #fff"
            background="#FFF" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.bg-image {
  background: url("/fail-pay-front/images/fail-pay-bg.png") no-repeat top;
  height: 100%;
}
.fail-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 107px 0;

  .fail {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    img {
      width: 210px;
      height: 210px;
    }
    .fail__title {
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 900;
      line-height: 100%;
      color: #4fe8b0;
    }
    .fail__subtitle {
      display: inline;
      position: relative;
      text-align: center;
      color: #fff;
      width: fit-content;
      color: #4fe8b0;
      p {
        display: inline;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
      }
      a {
        padding: 0 4px;
        display: inline;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        cursor: pointer;
        text-decoration: underline;
        text-decoration-thickness: 1.5px;
      }
    }
  }
}

@media (max-width: $lg) {
  .thank-you {
    padding: 50px 0;
    gap: 50px;
  }
}
</style>
