<script setup lang="ts">
import {ref, computed} from "vue"
import {storeToRefs} from "pinia"
import {useOgpoStore} from "@/stores/OgpoStore"
import {usePaymentStore} from "@/stores/Payment"
import {useHelperStore} from "@/stores/HelperStore"
import {pushGtagEvent} from "@/utils/pushGtagEvent"
import ProductBankCashbackAmount from "@/components/shared/ProductBankCashbackAmount.vue"
import FreedomPayWhiteIcon from "@/components/icons/payment/FreedomPayWhiteIcon.vue"
import FfinPaymentsIcon from "@/components/icons/payment/FfinPaymentsIcon.vue"
import FFRadiobutton from "@/components/FFUI/FFRadiobutton.vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFCheckbox from "@/components/FFUI/FFCheckbox.vue"

const helperStore = useHelperStore()
const ogpoStore = useOgpoStore()
const {
  selectedPayment,
  showKaspiForm,
  paymentUrl,
  addOgpoPlus,
  calculationObject,
  calculateCashbackWithOgpoPlusOrWithout,
  calculateCashbackPriceWithOgpoOrWithout
} = storeToRefs(ogpoStore)
const paymentStore = usePaymentStore()
const {paymentMethodsWithSuperApp, paymentMethodsWithoutSuperApp} = storeToRefs(paymentStore)
const {isSheetVisible} = storeToRefs(helperStore)

const emit = defineEmits(["kaspiFormNext"])
const iAgree = ref<boolean>(false)

const handleKaspiFormNext = async () => {
  isSheetVisible.value = false
  if (selectedPayment.value === "KaspiPay") {
    showKaspiForm.value = true
  } else if (selectedPayment.value === "FreedomPay" || selectedPayment.value === "FreedomSuperApp") {
    if (calculationObject?.value?.isScoring) {
      pushGtagEvent("knopka_soglasie_step3")
    }
    pushGtagEvent("vibor_oplata_step3")
    await ogpoStore.finalPay()
  }
}

const paymentMethods = computed(() => {
  const methods = addOgpoPlus.value ? paymentMethodsWithoutSuperApp.value : paymentMethodsWithSuperApp.value

  return methods.map((method) => {
    if (calculationObject?.value?.isScoring && method.type === "FreedomPay") {
      return {
        ...method,
        name: "",
        subText: "",
        icon: FfinPaymentsIcon
      }
    }
    return method
  })
})
</script>

<template>
  <div class="payment-method-form">
    <template v-for="payment in paymentMethods">
      <div
        v-if="!(calculationObject?.isScoring && (payment.type === 'KaspiPay' || payment.type === 'FreedomSuperApp'))"
        :key="payment.id"
        @click="ogpoStore.handleCurrentMethod(payment.type)"
        class="payment-method"
        :class="{'payment-method--selected': selectedPayment === payment.type}">
        <FFRadiobutton id="bank" v-model="selectedPayment" name="payment" :value="payment.type" />
        <component :is="payment.icon" class="payment-method__icon" />
        <div class="payment-method__wrapper">
          <p>{{ payment.name }}</p>
          <p>{{ payment.subText }}</p>
        </div>
      </div>
    </template>
    <div v-if="!calculationObject?.isScoring">
      <ProductBankCashbackAmount
         productType="Ogpo"
        :isLoyaltyCalled="calculationObject?.isLoyaltyCalled"
        :isClientBank="calculationObject?.isClientBank"
        :cashbackBalance="calculateCashbackWithOgpoPlusOrWithout"
        :cashbackPercent="calculationObject?.cashbackPercent"
        :cashbackPrice="calculateCashbackPriceWithOgpoOrWithout"
        top="170px" />
    </div>
    <FFCheckbox
      v-if="calculationObject?.isScoring"
      v-model="iAgree"
      :title="$t('app.ogpoPage.agreement.iAgreeToComission')" />
  </div>
  <FFButton
    v-if="calculationObject?.isScoring"
    @click="handleKaspiFormNext"
    :title="$t('app.buttons.pay')"
    :uppercase="false"
    :disabled="selectedPayment === '' || !iAgree" />
  <FFButton
    v-else
    @click="handleKaspiFormNext"
    :title="$t('app.buttons.pay')"
    :uppercase="false"
    :disabled="selectedPayment === ''" />
</template>

<style lang="scss" scoped>
.payment-method-form {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 20px;
  margin: 20px 0 50px;
  .ff-checkbox {
    align-items: start;
  }
  .payment-method {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    background: rgba(226, 226, 226, 0.17);
    border: 1px solid transparent;
    cursor: pointer;

    &--selected {
      border: 1px solid rgba(255, 255, 255, 0.5);
      background: rgba(0, 0, 0, 0.25);
    }

    .payment-method__icon {
      margin-left: 13px;
    }

    .payment-method__freedom-icon {
      margin-left: auto;
    }

    .payment-method__wrapper {
      margin-left: 7px;
      p:first-of-type {
        color: #ffffff;
        text-align: start;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      p:last-of-type {
        color: rgba(255, 255, 255, 0.5);
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        max-width: 115px;
      }
    }
  }
}
</style>
