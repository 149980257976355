<script setup lang="ts">
import {useRouter} from "vue-router"
import {storeToRefs} from "pinia"
import {useNewsStore} from "@/stores/NewsStore"
import {useHelpers} from "@/composables/useHelpers"

const newsStore = useNewsStore()
const {news} = storeToRefs(newsStore)
const {currentLocale, openLink} = useHelpers()

const router = useRouter()

const handleSimilarNewsRedirect = (newsId: number) => {
  const response = newsStore.getNewsDetails(newsId)
  if (response) {
    router.push(`/pages/news/${String(newsId)}`)
  }
}
</script>

<template>
  <section class="news-recomendation container">
    <div class="news-recomendation__list">
      <div
        class="news-recomendation__list-item"
        @click="handleSimilarNewsRedirect(i.news_id)"
        v-for="(i, index) in news?.data"
        :key="index">
        <img :src="`https://ffins.kz${i.news_image}`" :alt="i.news_name_ru" />
        <p v-if="currentLocale === 'ru'" class="news-recomendation__list-item-title">
          {{ i.news_name_ru !== "" ? i.news_name_ru : i.news_name_ru }}
        </p>
        <p v-if="currentLocale === 'kk'" class="news-recomendation__list-item-title">
          {{ i.news_name_kz !== "" ? i.news_name_kz : i.news_name_ru }}
        </p>
        <p v-if="currentLocale === 'en'" class="news-recomendation__list-item-title">
          {{ i.news_name_en !== "" ? i.news_name_en : i.news_name_ru }}
        </p>

        <p v-if="currentLocale === 'ru'" class="news-recomendation__list-item-description">
          {{ i.news_desc_ru !== "" ? i.news_desc_ru : i.news_desc_kz }}
        </p>
        <p v-if="currentLocale === 'kk'" class="news-recomendation__list-item-description">
          {{ i.news_desc_kz !== "" ? i.news_desc_kz : i.news_desc_ru }}
        </p>
        <p v-if="currentLocale === 'en'" class="news-recomendation__list-item-description">
          {{ i.news_desc_en !== "" ? i.news_desc_en : i.news_desc_ru }}
        </p>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.news-recomendation {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding-bottom: 100px;

  @include max-responsive(xl) {
    position: relative;
    z-index: 1;
  }

  .news-recomendation__list {
    display: flex;
    gap: 30px;

    @include max-responsive(xl) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 30px;
      grid-row-gap: 30px;
    }

    @include max-responsive(lg) {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }

    .news-recomendation__list-item {
      display: flex;
      flex-direction: column;
      max-width: 255px;
      gap: 20px;
      cursor: pointer;
      transition: all 0.3s ease;

      @include min-responsive(lg) {
        &:hover {
          transform: scale(1.03);
        }
      }

      @include max-responsive(xxl) {
        max-width: 210px;
      }

      @include max-responsive(xl) {
        max-width: 330px;
      }

      img {
        border-radius: 10px;
        object-fit: cover;
        width: 255px;
        height: 267px;

        @include max-responsive(xl) {
          width: 100%;
        }
      }
      .news-recomendation__list-item-title {
        color: var(--color-black);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .news-recomendation__list-item-description {
        overflow: hidden;
        color: #606060;
        text-overflow: ellipsis;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .news-recomendation__list--item:hover {
      transform: scale(1.05);
    }
  }
}
</style>
