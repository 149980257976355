<script setup lang="ts">
import LocationIcon from "@/components/icons/LocationIcon.vue"
import WhatsAppIcon from "@/components/icons/WhatsAppIcon.vue"
import MailIcon from "@/components/icons/MailIcon.vue"
import PhoneIconFooter from "@/components/icons/PhoneIconFooter.vue"
import WhiteLogo from "@/components/icons/WhiteLogo.vue"
import {useRouter} from "vue-router"

const router = useRouter()

const goMainPage = () => {
  router.push("/")
  window.scrollTo({top: 0, left: 0, behavior: "smooth"})
}
</script>

<template>
  <ul class="contact-wrapper">
    <li class="logo pb-[6px]">
      <WhiteLogo :height="35" @click="goMainPage" />
    </li>
    <li>
      <a
        href="https://2gis.kz/almaty/firm/70000001025970567?m=76.938924%2C43.253072%2F16"
        target="_blank"
        class="contact-item"
        style="align-items: flex-start">
        <LocationIcon />
        <p class="contact-item__location">{{ $t("app.footer.address") }}</p>
      </a>
    </li>
    <li>
      <a href="tel:5777" class="contact-item">
        <PhoneIconFooter />
        <div class="contact-item__sub">
          <p>5777</p>
          <p>{{ $t("app.footer.phone") }}</p>
        </div>
      </a>
    </li>
    <li>
      <a href="mailto:info@ffins.kz" class="contact-item">
        <MailIcon />
        <p class="contact-item__email">info@ffins.kz</p>
      </a>
    </li>
  </ul>
</template>

<style scoped lang="scss">
.contact-wrapper {
  display: grid;
  gap: 24px;
}

.logo {
  display: none;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 10px;

  &__location,
  &__email {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.2px;
  }
  &__sub {
    display: flex;
    flex-direction: column;
    :nth-child(1) {
      color: #fff;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.2px;
    }
    :nth-child(2) {
      color: rgba(255, 255, 255, 0.5);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.2px;
    }
  }
}

@media (max-width: $xl) {
  .logo {
    display: block;
  }
}

@media (max-width: $lg) {
  .logo {
    margin: 0 auto;
  }
}
</style>
