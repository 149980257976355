<script setup lang="ts">
import {onBeforeMount, ref} from "vue"
import RemoveIcon from "@/components/icons/form/removeIcon.vue"
import AddIcon from "@/components/icons/form/addIcon.vue"
import {onClickOutside} from "@vueuse/core"

interface Props {
  title?: string
  modelValue: []
}
const emit = defineEmits(["update:modelValue", "update"])
const props = defineProps<Props>()
const areOptionsVisible = ref<boolean>(false)
const count = ref(0)
const categories = ref([
  {value: "UpTo1Year", count: 0, name: "До 1 года"},
  {value: "UpTo60Year", count: 0, name: "От 1 до 60 лет"},
  {value: "UpTo70Year", count: 0, name: "От 61 до 70 лет"},
  {value: "UpTo80Year", count: 0, name: "От 71 до 80 лет"}
])
const target = ref(null)

onClickOutside(target, () => (areOptionsVisible.value = false))
const getTotalPeople = () => {
  return categories.value.reduce((sum, category) => sum + category.count, 0)
}

onBeforeMount(() => {
  props.modelValue.forEach((value) => {
    const idx = categories.value.findIndex((category) => category.value === value)
    if (idx) {
      categories.value[idx].count++
    }
  })
  count.value = getTotalPeople()
})

const all = () => {
  const personList = []
  return categories.value.reduce((list, category) => {
    for (let i = 0; i < category.count; i++) {
      list.push(category.value)
    }
    return list
  }, personList)
}

const updateTotalPeople = () => {
  count.value = getTotalPeople()
  emit("update")
  emit("update:modelValue", all())
}

function toggleSelect(): void {
  areOptionsVisible.value = !areOptionsVisible.value
}

const addPersonToCategory = (categoryIndex) => {
  if (4 >= categories.value[categoryIndex].count && count.value <= 4) {
    categories.value[categoryIndex].count += 1
    updateTotalPeople()
  }
}

const removePerson = (categoryIndex) => {
  if (0 < categories.value[categoryIndex].count) {
    categories.value[categoryIndex].count -= 1
    updateTotalPeople()
  }
}
</script>

<template>
  <div class="ff-select" ref="target">
    <p @click="toggleSelect()">
      {{ $t('app.mstPage.form.calculationForm.quantity') }} {{ count }}
      <span class="ff-dropdown-icon"></span>
    </p>
    <div class="ff-options" v-if="areOptionsVisible">
      <p v-for="(option, index) in categories" :key="index">
        {{ $t(`app.mstPage.form.calculationForm.ageTravels.item-${index + 1}`) }}
        <span class="ff-select-control">
          <remove-icon @click="removePerson(index)" />
          {{ option.count }}
          <AddIcon @click="addPersonToCategory(index)" />
        </span>
      </p>
      <div class="p-3">
        <div class="ff-note">{{ $t("app.mstPage.form.calculationForm.euroInsuredCount") }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.ff-select {
  align-items: center;
  cursor: pointer;
  position: relative;
  width: 100%;
}

.ff-select p {
  height: max-content;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 8px 14px 9px;
}

.ff-options {
  z-index: 1;
  height: auto;
  overflow-y: auto;
  margin-top: 5px;
  position: absolute;
  width: 276px;
  left: -7px;
  border-radius: 10px;
  background: #fff;
  display: inline-flex;
  padding: 18px 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  transition: all 1s ease-out;
}

.ff-options p {
  display: inline-flex;
  justify-content: space-between;
  gap: 10px;
  cursor: pointer;
  width: 100%;
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 10px 20px;
  align-items: center;
}

.ff-options p:hover {
  border-radius: 10px;
  background-color: #e7e7e7;
}

.ff-selected {
  display: inline-flex;
  align-items: center;
  transition: 0.5s ease-out;
}

.ff-select-control {
  display: flex;
  align-items: center;
  gap: 12px;
}
.ff-note {
  padding: 10px 14px;
  align-items: flex-start;
  gap: 4px;
  border-radius: 10px;
  background: #f2994a;

  color: #232323;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
</style>
