<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
    <g clip-path="url(#clip0_2486_7439)">
      <path
        d="M43.75 1.5625C46.3344 1.5625 48.4375 3.66562 48.4375 6.25V43.75C48.4375 46.3344 46.3344 48.4375 43.75 48.4375H6.25C3.66562 48.4375 1.5625 46.3344 1.5625 43.75V6.25C1.5625 3.66562 3.66562 1.5625 6.25 1.5625H43.75ZM43.75 0H6.25C2.8125 0 0 2.8125 0 6.25V43.75C0 47.1875 2.8125 50 6.25 50H43.75C47.1875 50 50 47.1875 50 43.75V6.25C50 2.8125 47.1875 0 43.75 0Z"
        fill="white" />
      <path
        d="M35.7332 10.6875H14.2676C12.2473 10.6875 10.6895 12.2453 10.6895 14.2656V35.7313C10.6895 37.7516 12.2473 39.3094 14.2676 39.3094H35.7332C37.7535 39.3094 39.3113 37.7516 39.3113 35.7313V14.2656C39.3113 12.2453 37.7535 10.6875 35.7332 10.6875ZM25.0207 19.3766C28.1957 19.3766 30.7676 21.8687 30.7676 24.9453C30.7676 28.0187 28.1957 30.5141 25.0207 30.5141C21.8488 30.5141 19.2754 28.0187 19.2754 24.9453C19.277 21.8687 21.8504 19.3766 25.0207 19.3766ZM35.7332 34.8375C35.7332 35.4641 35.4645 35.7313 34.8395 35.7313H15.1613C14.5363 35.7313 14.2676 35.4641 14.2676 34.8375V22.3156L16.4973 22.8C16.2526 23.5953 16.1283 24.4226 16.1285 25.2547C16.1285 30.0109 20.1129 33.8719 25.0223 33.8719C29.9348 33.8719 33.916 30.0125 33.916 25.2547C33.916 24.4 33.7863 23.5781 33.5473 22.8L35.7348 22.3156V34.8375H35.7332ZM35.7332 18.7375C35.7334 18.8549 35.7104 18.9712 35.6656 19.0798C35.6207 19.1883 35.5549 19.2869 35.4719 19.3699C35.3889 19.453 35.2902 19.5188 35.1817 19.5636C35.0732 19.6085 34.9569 19.6315 34.8395 19.6312H31.2613C31.1439 19.6315 31.0276 19.6085 30.9191 19.5636C30.8105 19.5188 30.7119 19.453 30.6289 19.3699C30.5459 19.2869 30.48 19.1883 30.4352 19.0798C30.3904 18.9712 30.3674 18.8549 30.3676 18.7375V15.1594C30.3676 14.6641 30.7676 14.2656 31.2613 14.2656H34.8395C35.3348 14.2656 35.7332 14.6656 35.7332 15.1594V18.7375Z"
        fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_2486_7439">
        <rect width="50" height="50" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
