<script setup lang="ts">
import {ref} from "vue"
import {storeToRefs} from "pinia"
import {useTerminationAgreementStore} from "@/stores/TerminationAgreementStore"
import {useHelpers} from "@/composables/useHelpers"
import {useFormatters} from "@/utils/formatters"
import {
  whatsAppTerminationMessageRu,
  whatsAppTerminationMessageKk,
  whatsAppTerminationMessageEn
} from "@/constants/whatsAppMessages"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFCheckbox from "@/components/FFUI/FFCheckbox.vue"
import FFTooltip from "@/components/FFUI/FFTooltip.vue"

const emit = defineEmits(["payment", "next", "toggleTooltip"])

interface Props {
  showInsurancePremiumUnderNewContract?: boolean
  showAmountOfInsurancePremium?: boolean
  showPremiumSum?: boolean
  showDescription?: boolean
  showSumTooltip?: boolean
  tooltipText?: string
  premiumSum?: string
  premiumSumByNewContract?: string
  disabled?: boolean
  showFirstCheckboxSection?: boolean
  showSecondCheckboxSection?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showDescription: false,
  disabled: false,
  showAmountOfInsurancePremium: false,
  showFirstCheckboxSection: false,
  showSecondCheckboxSection: false,
  btnDisabled: false,
  showSumTooltip: false
})

const terminationAgreementStore = useTerminationAgreementStore()
const {
  terminationReason,
  terminationByGlobalId,
  isProductCodeOgpo,
  terminationInsSum,
  terminationOgpoPlusSum,
  terminationOgpoSum
} = storeToRefs(terminationAgreementStore)
const {openLink, currentLocale} = useHelpers()
const {thousandSeparator} = useFormatters()

const toggleTooltipState = ref<boolean>(false)
const toggleTooltipShow = () => {
  toggleTooltipState.value = !toggleTooltipState.value
}

const handleSubmitition = () => {
  if (!props.disabled) {
    emit("payment")
    emit("next")
  }
}
</script>

<template>
  <div class="payment">
    <template v-if="terminationInsSum !== null">
      <div class="payment__sum">
        <template v-if="Boolean(terminationByGlobalId?.globalIdOgpoPlus)">
          <div v-if="Boolean(terminationOgpoSum)" class="payment__sum--premium">
            <p>{{ $t("app.paymentPage.insuranceSumOgpo") }}:</p>
            <p>{{ `${thousandSeparator(terminationOgpoSum)} ${$t("form.formAbbreviations.tg")}` }}</p>
          </div>
          <div v-if="Boolean(terminationOgpoPlusSum)" class="payment__sum--premium">
            <p>{{ $t("app.paymentPage.insuranceSumOgpoPlus") }}:</p>
            <p>{{ `${thousandSeparator(terminationOgpoPlusSum)} ${$t("form.formAbbreviations.tg")}` }}</p>
          </div>
        </template>
        <template v-if="!isProductCodeOgpo">
          <div class="payment__sum--premium">
            <p>{{ $t("app.paymentPage.amountOfInsurancePremium") }}:</p>
            <p>{{ thousandSeparator(premiumSum) }}</p>
          </div>
        </template>

        <div class="payment__sum--total">
          <template v-if="showSumTooltip">
            <div>
              <p>{{ $t("app.paymentPage.sumToBeRefund") }}:</p>
              <img @click="toggleTooltipShow" src="/icons-front/info-black-icon.svg" alt="" />
              <div v-if="toggleTooltipState">
                <FFTooltip
                  @closeTooltip="toggleTooltipState = false"
                  :isBlack="true"
                  top="25px"
                  :description="tooltipText" />
              </div>
            </div>
            <p>{{ thousandSeparator(terminationInsSum) }}</p>
          </template>
          <template v-else>
            <p>{{ $t("app.paymentPage.sumToBeRefund") }}:</p>
            <p>{{ `${thousandSeparator(terminationInsSum)} ${$t("form.formAbbreviations.tg")}` }}</p>
          </template>
        </div>
      </div>
      <div class="payment__line"></div>
    </template>

    <FFButton
      @click="handleSubmitition"
      class="payment__button"
      :title="$t('app.buttons.continue')"
      :uppercase="false"
      color="white"
      paddingx="17px"
      paddingy="20px"
      width="100%"
      background="linear-gradient(132deg, #2F4D3B -0.69%, #162522 103.63%)"
      backgroundMobile="linear-gradient(132deg, #2F4D3B -0.69%, #162522 103.63%)"
      colorMobile="#ffffff"
      :disabled="disabled" />
    <p v-if="showDescription" class="payment__description">
      {{ $t("app.paymentPage.terminationRuleDescription") }}
    </p>
    <div
      class="payment__ask"
      @click="
        openLink(
          `https://api.whatsapp.com/send?phone=77788700014&text=${currentLocale === 'ru' ? whatsAppTerminationMessageRu : currentLocale === 'kk' ? whatsAppTerminationMessageKk : whatsAppTerminationMessageEn}`,
          '_blank'
        )
      ">
      <img src="/main-front/images/dashicons_whatsapp.svg" alt="icon" />
      <p>{{ $t("app.paymentPage.askQuestion") }}</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.payment {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 10px;
  background: #e2e2e2;
  padding: 20px;
  &__sum {
    display: flex;
    flex-direction: column;
    gap: 8px;
    z-index: 0;
    &--premium {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      opacity: 0.7;
      & > p {
        color: #2e2e2e;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      :nth-child(2) {
        white-space: nowrap;
      }
    }
    &--total {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      div {
        display: flex;
        align-items: center;
        gap: 5px;
        p {
          color: #2e2e2e;
          font-size: 18px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
          width: fit-content;
        }
      }
      & > p {
        color: #2e2e2e;
        font-size: 18px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        width: fit-content;
      }
      :nth-child(2) {
        white-space: nowrap;
      }
      :nth-child(1) {
        text-transform: uppercase;
      }
    }
  }
  .payment__agreement-checkbox {
    display: flex;
    align-items: center;
    padding: 10px;

    background-color: #ffffff;
    border-radius: 10px;

    p:nth-child(2) {
      color: #2e2e2e;
      font-size: 12px;
      font-style: normal;
      font-weight: 900;
      line-height: 16px;

      span {
        color: #4d52d4;
        font-size: 12px;
        font-style: normal;
        font-weight: 900;
        line-height: 16px;
        text-decoration-line: underline;
        text-underline-offset: 2px;
        cursor: pointer;
        margin-left: 4px;
      }
    }
  }
  &__line {
    width: 100%;
    border-bottom: 1px solid #2e2e2e;
    margin-top: -4px;
  }
  &__button:hover {
    transform: scale(1.02);
  }
  &__description {
    color: #2e2e2e;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &__ask {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #2e2e2e;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    margin: 0 auto;
    transition: all 0.3s ease;
    padding: 10px 0;
  }
  &__ask:hover {
    transform: scale(1.05);
  }
}

@media (max-width: $lg) {
  .payment {
    &__button:hover,
    &__ask:hover {
      transform: scale(1);
    }
  }
}
</style>
