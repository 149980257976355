<script setup lang="ts">
import {computed} from "vue"

interface Props {
  darkness?: number
  blur?: string
}

const props = withDefaults(defineProps<Props>(), {
  darkness: 0.6,
  blur: "8px"
})

const background = computed(() => {
  return `rgba(0, 0, 0, ${props.darkness})`
})
</script>

<template>
  <div class="dark-bg"></div>
</template>

<style scoped lang="scss">
.dark-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: v-bind(background);
  z-index: 1000;
  backdrop-filter: blur(v-bind(blur));
}
</style>
