<script setup lang="ts">
import {onMounted} from "vue"
import {storeToRefs} from "pinia"
import { usePersonalProfileStore } from "@/stores/PersonalProfileStore"
import {useTerminationSubscriptionAgreementStore} from "@/stores/TerminationSubscriptionAgreementStore"
import {useRoute} from "vue-router"
import Skeleton from "primevue/skeleton"

const terminationSubscriptionAgreementStore = useTerminationSubscriptionAgreementStore()
const {terminationBySubsRegNum, isProductCodeKaskoSubscription} =
  storeToRefs(terminationSubscriptionAgreementStore)
const personalProfileStore = usePersonalProfileStore()
const {currentPolicie} = storeToRefs(personalProfileStore)
const route = useRoute()
const dateTimeApplying = localStorage.getItem("date-time-applying")
</script>
<template>
  <div class="termination">
    <div class="termination__title">
      <p class="termination__title--main">{{ $t("app.terminationAgreementPage.mainTitle") }}</p>
      <p v-if="isProductCodeKaskoSubscription" class="termination__title--description">
        {{ $t("app.terminationAgreementPage.subTitleKasko") }}
      </p>
    </div>
    <div class="termination__info">
      <div class="termination__info--side">
        <p class="termination__info--side__title">{{ $t("app.terminationAgreementPage.dateTimeOfRequest") }}</p>
        <div class="termination__info--side__value">
          <p>{{ dateTimeApplying }}</p>
        </div>
      </div>
      <div class="termination__info--side">
        <p class="termination__info--side__title">{{ $t("app.terminationAgreementPage.insurer") }}</p>
        <p class="termination__info--side__value">
          <Skeleton v-if="!terminationBySubsRegNum?.subscriber.iin" width="10rem" height="1.3rem" />
          <p v-else class="reason__deadlines--side__value">{{ terminationBySubsRegNum?.subscriber.iin }}</p> <br />
          <Skeleton v-if="!terminationBySubsRegNum?.subscriber.fio" width="14rem" height="1.3rem" />
          <p v-else class="reason__deadlines--side__value">{{ terminationBySubsRegNum?.subscriber.fio }}</p>
        </p>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.termination {
  display: flex;
  flex-direction: column;
  &__title {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &--main {
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 900;
      line-height: 100%;
      background: var(--FF-gradient, linear-gradient(103deg, #bde0ff -2.91%, #1cd491 58.89%, #00ff45 106.95%));
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &--description {
      color: rgba(255, 255, 255, 0.7);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  &__info {
    margin-top: 50px;
    display: flex;
    gap: 20px;
    padding: 30px;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(15px);
    &--side {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
      &__title {
        color: #fff;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border-bottom: 1px solid #fff;
        padding-bottom: 10px;
      }
      &__value {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

@media (max-width: $lg) {
  .termination {
    &__title {
      &--main {
        font-size: 30px;
        max-width: 100%;
      }
    }
    &__info {
      flex-direction: column;
      padding: 16px;
      margin-top: 50px;
      gap: 39px;
    }
  }
}
</style>
