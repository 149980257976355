<script setup lang="ts">
import {onMounted, computed} from "vue"
import {storeToRefs} from "pinia"
import {vMaska} from "maska"
import {useI18n} from "vue-i18n"
import {useRecaptcha} from "vue3-recaptcha-v2"
import {useChairmanStore} from "@/stores/ChairmanStore"
import {useHelperStore} from "@/stores/HelperStore"
import {useValidation} from "@/composables/useValidation"
import {hotlineFillFormSchema} from "@/schemas/hotlineFillFormSchema"
import {onPhoneFocus} from "@/utils/inputEvents"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import FFFileInput from "@/components/FFUI/FFFileInput.vue"
import FFReCaptcha from "@/components/FFUI/FFReCaptcha.vue"
import Dropdown from "primevue/dropdown"

const {t} = useI18n()
const chairmanStore = useChairmanStore()
const helperStore = useHelperStore()
const {chairmanForm, themes, responseIsSent, loading} = storeToRefs(chairmanStore)
const {isRecaptchaVerified, widgetId} = storeToRefs(helperStore)
const {handleReset} = useRecaptcha()

onMounted(() => {
  chairmanStore.getThemes()
  chairmanForm.value.question = t("app.hotlinePage.fillForm.dearChairman")
})

const {isValid, validate} = useValidation(hotlineFillFormSchema, chairmanForm, {
  mode: "eager"
})

const handleSubmitForm = async () => {
  await validate()
  if (isValid.value && isRecaptchaVerified.value) {
    handleReset(widgetId.value)
    isRecaptchaVerified.value = false
    chairmanStore.handleSendOtp()
  }
}

const isHotlineFormFilled = computed(() => {
  return hotlineFillFormSchema.safeParse(chairmanForm.value).success && isRecaptchaVerified.value
})
</script>

<template>
  <section class="hotline-fill-wrapper container">
    <p class="hotline-fill-wrapper__title">{{ $t("app.hotlinePage.fillForm.title") }}</p>
    <p class="hotline-fill-wrapper__description">{{ $t("app.hotlinePage.fillForm.description") }}</p>
    <form @submit.prevent="() => console.log('submit')" class="hotline-fill-form">
      <div class="hotline-fill-form__inputs">
        <div class="hotline-fill-form__row hotline-fill-form__first-row">
          <FFFieldset :title="$t('form.inputTitles.fullName')">
            <FFInput v-model="chairmanForm.fullName" :required="true" />
          </FFFieldset>
          <FFFieldset :title="$t('form.inputTitles.phone')">
            <FFInput
              v-maska
              data-maska="+7 (###) ### ####"
              placeholder="+7 (___) ___ ____"
              id="phone"
              v-model="chairmanForm.phoneNumber"
              name="1"
              type="tel"
              @focus="onPhoneFocus"
              :required="true" />
          </FFFieldset>
        </div>
        <div class="hotline-fill-form__row hotline-fill-form__second-row">
          <FFFieldset :title="$t('form.inputTitles.email')">
            <FFInput v-model="chairmanForm.email" :required="true" />
          </FFFieldset>
          <FFFieldset :title="$t('form.inputTitles.theme')">
            <Dropdown
              v-model="chairmanForm.theme"
              :options="themes"
              optionLabel="value"
              :inputProps="{required: true}" />
          </FFFieldset>
        </div>
        <div class="hotline-fill-form__row hotline-fill-form__third-row">
          <FFFieldset :title="$t('form.inputTitles.yourQuestion')">
            <FFInput v-model="chairmanForm.question" :textarea="true" :required="true" />
          </FFFieldset>
        </div>
      </div>
      <div class="hotline-fill-form__buttons">
        <FFFileInput
          :title="$t('form.inputTitles.attachPhotoOrFile')"
          v-model="chairmanForm.file"
          componentAppearanceType="secondType"
          inputId="damage" />
        <FFButton
          @click="handleSubmitForm"
          :title="$t('app.buttons.askQuestion')"
          :uppercase="true"
          :disabled="!isHotlineFormFilled"
          type="submit"
          maxWidth="272px"
          maxWidthMobile="100%"
          background="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
          backgroundMobile="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
          color="#ffffff"
          colorMobile="#ffffff" />
      </div>
      <div class="recaptcha-wrapper">
        <FFReCaptcha />
      </div>
    </form>
    <div v-if="responseIsSent" class="hotline-fill-wrapper__responses">
      <p class="hotline-fill-wrapper__response">{{ $t("app.hotlinePage.fillForm.response-1") }}</p>
      <p class="hotline-fill-wrapper__response">{{ $t("app.hotlinePage.fillForm.response-2") }}</p>
    </div>
  </section>
</template>

<style scoped lang="scss">
.hotline-fill-wrapper {
  @include column;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.1);
  padding: 50px 20px;
  position: relative;
  z-index: 1;

  @include max-responsive(xl) {
    margin-top: 57px;
  }

  @include max-responsive(lg) {
    padding: 30px 20px;
  }
  .hotline-fill-wrapper__title {
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: 20px;
    @include main-green-gradient;
    text-align: center;
    margin-bottom: 30px;
  }
  .hotline-fill-wrapper__description {
    color: var(--color-white);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    max-width: 832px;
    margin: auto;
    text-align: center;
    @include center;
  }
  .hotline-fill-form {
    @include column;
    margin: auto;
    width: 558px;
    margin-top: 50px;
    margin-bottom: 50px;
    gap: 30px;

    @include max-responsive(lg) {
      width: 100%;
    }

    .hotline-fill-form__inputs {
      @include column;
      gap: 30px;
      .hotline-fill-form__row {
        @include between;
        gap: 30px;

        @include max-responsive(lg) {
          @include column;
        }
      }
    }

    .hotline-fill-form__buttons {
      @include between;
      align-items: center;
      gap: 20px;

      @include max-responsive(lg) {
        @include column;
      }

      .second-file-input {
        @include max-responsive(lg) {
          width: 100% !important;
        }
      }
    }
  }
  .hotline-fill-wrapper__responses {
    @include column;
    gap: 20px;
    max-width: 558px;
    padding: 20px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.2);
    margin: auto;
    .hotline-fill-wrapper__response {
      color: var(--color-white);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
</style>
