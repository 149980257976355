<script setup lang="ts">
import {unref} from "vue"
import {useHelpers} from "@/composables/useHelpers"
import {useFormatters} from "@/utils/formatters"
import type {IWearVehicleResponse} from "@/interfaces/Calculator/IWearVehicleResponse"

interface IProps {
  wearVehicleCalculateResult: IWearVehicleResponse | unknown
  actualMileage: number | null
}

const props = defineProps<IProps>()

const {openLink} = useHelpers()
const {thousandSeparator} = useFormatters()
</script>

<template>
  <section class="wear-vehicle-calculator-result">
    <template v-if="wearVehicleCalculateResult?.total_percent >= 75 && wearVehicleCalculateResult?.average_mileage === null">
      <div class="wear-vehicle-calculator-result__first-block">
        <p>{{ $t("app.calculatorPage.wearVehicle.depreciationAndAmortization") }}</p>
        <p>{{ wearVehicleCalculateResult?.total_percent }}%</p>
      </div>
      <div class="wear-vehicle-calculator-result__second-block">
        <div class="wear-vehicle-calculator-result__row">
          <p class="wear-vehicle-calculator-result__row-description">
            {{ $t("app.calculatorPage.wearVehicle.text-0") }}
          </p>
        </div>
      </div>
    </template>

    <template
      v-else-if="wearVehicleCalculateResult?.total_percent < 75 && wearVehicleCalculateResult.average_mileage === null">
      <div class="wear-vehicle-calculator-result__first-block">
        <p>{{ $t("app.calculatorPage.wearVehicle.depreciationAndAmortization") }}</p>
        <p>{{ wearVehicleCalculateResult?.total_percent }}%</p>
      </div>
    </template>

    <template
      v-else-if="
        wearVehicleCalculateResult?.total_percent < 75 && actualMileage < wearVehicleCalculateResult.subtracted
      ">
      <div class="wear-vehicle-calculator-result__first-block">
        <p>{{ $t("app.calculatorPage.wearVehicle.depreciationAndAmortization") }}</p>
        <p>{{ wearVehicleCalculateResult?.total_percent }}%</p>
      </div>
      <div class="wear-vehicle-calculator-result__second-block">
        <div class="wear-vehicle-calculator-result__row">
          <p class="wear-vehicle-calculator-result__row-title">
            {{ $t("app.calculatorPage.wearVehicle.averageAnnualMileage") }}
          </p>
          <p class="wear-vehicle-calculator-result__row-description">
            {{ thousandSeparator(wearVehicleCalculateResult?.average_mileage) }}{{ $t("form.formAbbreviations.km") }}
          </p>
        </div>
        <div class="wear-vehicle-calculator-result__row">
          <p class="wear-vehicle-calculator-result__row-title">
            {{ $t("app.calculatorPage.wearVehicle.deviationsInTheSmallerDirection") }}
          </p>
          <p class="wear-vehicle-calculator-result__row-description">
            {{ $t("app.calculatorPage.wearVehicle.text-1") }} {{ " " }}
            {{ thousandSeparator(wearVehicleCalculateResult?.percent_value) }} {{ $t("form.formAbbreviations.km") }}
          </p>
          <p class="wear-vehicle-calculator-result__row-description">
            {{ thousandSeparator(actualMileage) }} <
            {{ thousandSeparator(wearVehicleCalculateResult?.subtracted) }}
          </p>
        </div>
        <div class="wear-vehicle-calculator-result__row">
          <p class="wear-vehicle-calculator-result__row-title">{{ $t("app.calculatorPage.wearVehicle.note") }}</p>
          <p class="wear-vehicle-calculator-result__row-description">
            {{ thousandSeparator(wearVehicleCalculateResult?.average_mileage) }} -
            {{ thousandSeparator(wearVehicleCalculateResult?.percent_value) }} =
            {{ thousandSeparator(wearVehicleCalculateResult?.subtracted) }}
          </p>
          <p class="wear-vehicle-calculator-result__row-description">
            {{ $t("app.calculatorPage.wearVehicle.text-2") }} {{ wearVehicleCalculateResult.mileage_percent }}%
          </p>
        </div>
      </div>
    </template>

    <template
      v-else-if="
        wearVehicleCalculateResult.total_percent < 75 && actualMileage > wearVehicleCalculateResult.subtracted
      ">
      <div class="wear-vehicle-calculator-result__first-block">
        <p>{{ $t("app.calculatorPage.wearVehicle.depreciationAndAmortization") }}</p>
        <p>{{ wearVehicleCalculateResult?.total_percent }}%</p>
      </div>
      <div class="wear-vehicle-calculator-result__second-block">
        <div class="wear-vehicle-calculator-result__row">
          <p class="wear-vehicle-calculator-result__row-title">
            {{ $t("app.calculatorPage.wearVehicle.averageAnnualMileage") }}
          </p>
          <p class="wear-vehicle-calculator-result__row-description">
            {{ thousandSeparator(wearVehicleCalculateResult?.average_mileage) }}{{ $t("form.formAbbreviations.km") }}
          </p>
        </div>
        <div class="wear-vehicle-calculator-result__row">
          <p class="wear-vehicle-calculator-result__row-title">
            {{ $t("app.calculatorPage.wearVehicle.deviationsInTheSmallerDirection") }}
          </p>
          <p class="wear-vehicle-calculator-result__row-description">
            {{ $t("app.calculatorPage.wearVehicle.text-1") }} {{ " " }}
            {{ thousandSeparator(wearVehicleCalculateResult?.percent_value) }} {{ $t("form.formAbbreviations.km") }}
          </p>
          <p
            @click="openLink('https://ffins.kz/metodika_opredelenya_stoimosti_ts_v_ramkax_issledovanie.pdf', '_blank')"
            class="wear-vehicle-calculator-result__row-link-description">
            {{ $t("app.calculatorPage.wearVehicle.text-7") }}
          </p>
        </div>
      </div>
    </template>

    <template
      v-else-if="
        wearVehicleCalculateResult?.total_percent >= 75 && actualMileage < wearVehicleCalculateResult?.subtracted
      ">
      <div class="wear-vehicle-calculator-result__first-block">
        <p>{{ $t("app.calculatorPage.wearVehicle.depreciationAndAmortization") }}</p>
        <p>{{ wearVehicleCalculateResult?.total_percent }}%</p>
      </div>
      <div class="wear-vehicle-calculator-result__second-block">
        <div class="wear-vehicle-calculator-result__row">
          <p class="wear-vehicle-calculator-result__row-title">
            {{ $t("app.calculatorPage.wearVehicle.averageAnnualMileage") }}
          </p>
          <p class="wear-vehicle-calculator-result__row-description">
            {{ thousandSeparator(wearVehicleCalculateResult?.average_mileage) }}{{ $t("form.formAbbreviations.km") }}
          </p>
        </div>
        <div class="wear-vehicle-calculator-result__row">
          <p class="wear-vehicle-calculator-result__row-title">
            {{ $t("app.calculatorPage.wearVehicle.deviationsInTheSmallerDirection") }}
          </p>
          <p class="wear-vehicle-calculator-result__row-description">
            {{ $t("app.calculatorPage.wearVehicle.text-1") }} {{ " " }}
            {{ thousandSeparator(wearVehicleCalculateResult?.percent_value) }} {{ $t("form.formAbbreviations.km") }}
          </p>
          <p class="wear-vehicle-calculator-result__row-description">
            {{ thousandSeparator(actualMileage) }} <
            {{ thousandSeparator(wearVehicleCalculateResult?.subtracted) }}
          </p>
        </div>
        <div class="wear-vehicle-calculator-result__row">
          <p class="wear-vehicle-calculator-result__row-title">{{ $t("app.calculatorPage.wearVehicle.note") }}</p>
          <p class="wear-vehicle-calculator-result__row-description">
            {{ thousandSeparator(wearVehicleCalculateResult?.average_mileage) }} -
            {{ thousandSeparator(wearVehicleCalculateResult?.percent_value) }} =
            {{ thousandSeparator(wearVehicleCalculateResult?.subtracted) }}
          </p>
          <p class="wear-vehicle-calculator-result__row-description">
            {{ $t("app.calculatorPage.wearVehicle.text-2") }} {{ wearVehicleCalculateResult.mileage_percent }}%
          </p>
          <p class="wear-vehicle-calculator-result__row-description">
            {{ $t("app.calculatorPage.wearVehicle.text-3-1") }} {{ " " }}
            <span
              @click="
                openLink('https://ffins.kz/metodika_opredelenya_stoimosti_ts_v_ramkax_issledovanie.pdf', '_blank')
              "
              class="methodics">
              {{ $t("app.calculatorPage.wearVehicle.text-4") }} </span
            >,
            {{ $t("app.calculatorPage.wearVehicle.text-5-1") }}
          </p>
        </div>
      </div>
    </template>

    <template
      v-else-if="
        wearVehicleCalculateResult?.total_percent >= 75 && actualMileage > wearVehicleCalculateResult?.subtracted
      ">
      <div class="wear-vehicle-calculator-result__first-block">
        <p>{{ $t("app.calculatorPage.wearVehicle.depreciationAndAmortization") }}</p>
        <p>{{ wearVehicleCalculateResult?.total_percent }}%</p>
      </div>
      <div class="wear-vehicle-calculator-result__second-block">
        <div class="wear-vehicle-calculator-result__row">
          <p class="wear-vehicle-calculator-result__row-description">
            {{ $t("app.calculatorPage.wearVehicle.text-0") }} {{ " " }}
          </p>
        </div>
        <div class="wear-vehicle-calculator-result__row">
          <p class="wear-vehicle-calculator-result__row-description">
            {{ $t("app.calculatorPage.wearVehicle.text-3-2") }} {{ " " }}
            <span
              @click="
                openLink('https://ffins.kz/metodika_opredelenya_stoimosti_ts_v_ramkax_issledovanie.pdf', '_blank')
              "
              class="methodics">
              {{ $t("app.calculatorPage.wearVehicle.text-4") }} </span
            >,
            {{ $t("app.calculatorPage.wearVehicle.text-5-2") }}
            <span>
              {{ $t("app.calculatorPage.wearVehicle.text-6") }}
            </span>
          </p>
        </div>
      </div>
    </template>

    <template
      v-else-if="
        wearVehicleCalculateResult?.total_percent < 75 && actualMileage > wearVehicleCalculateResult?.subtracted
      ">
      <div class="wear-vehicle-calculator-result__first-block">
        <p>{{ $t("app.calculatorPage.wearVehicle.depreciationAndAmortization") }}</p>
        <p>{{ wearVehicleCalculateResult?.total_percent }}%</p>
      </div>
      <div class="wear-vehicle-calculator-result__second-block">
        <div class="wear-vehicle-calculator-result__row">
          <p class="wear-vehicle-calculator-result__row-title">
            {{ $t("app.calculatorPage.wearVehicle.averageAnnualMileage") }}
          </p>
          <p class="wear-vehicle-calculator-result__row-description">
            {{ thousandSeparator(wearVehicleCalculateResult?.average_mileage) }}{{ $t("form.formAbbreviations.km") }}
          </p>
        </div>
        <div class="wear-vehicle-calculator-result__row">
          <p class="wear-vehicle-calculator-result__row-title">
            {{ $t("app.calculatorPage.wearVehicle.deviationsInTheSmallerDirection") }}
          </p>
          <p class="wear-vehicle-calculator-result__row-description">
            {{ $t("app.calculatorPage.wearVehicle.text-1") }} {{ " " }}
            {{ thousandSeparator(wearVehicleCalculateResult?.percent_value) }} {{ $t("form.formAbbreviations.km") }}
          </p>
          <p class="wear-vehicle-calculator-result__row-description">
            {{ thousandSeparator(actualMileage) }} <
            {{ thousandSeparator(wearVehicleCalculateResult?.subtracted) }}
          </p>
        </div>
        <div class="wear-vehicle-calculator-result__row">
          <p class="wear-vehicle-calculator-result__row-title">{{ $t("app.calculatorPage.wearVehicle.note") }}</p>
          <p class="wear-vehicle-calculator-result__row-description">
            {{ thousandSeparator(wearVehicleCalculateResult?.average_mileage) }} -
            {{ thousandSeparator(wearVehicleCalculateResult?.percent_value) }} =
            {{ thousandSeparator(wearVehicleCalculateResult?.subtracted) }}
          </p>
          <p class="wear-vehicle-calculator-result__row-description">
            {{ $t("app.calculatorPage.wearVehicle.text-2") }} {{ wearVehicleCalculateResult.mileage_percent }}%
          </p>
          <p class="wear-vehicle-calculator-result__row-description">
            {{ $t("app.calculatorPage.wearVehicle.text-3-2") }} {{ " " }}
            <span
              @click="
                openLink('https://ffins.kz/metodika_opredelenya_stoimosti_ts_v_ramkax_issledovanie.pdf', '_blank')
              "
              class="methodics">
              {{ $t("app.calculatorPage.wearVehicle.text-4") }} </span
            >,
            {{ $t("app.calculatorPage.wearVehicle.text-5-2") }}
          </p>
        </div>
      </div>
    </template>

    <!-- <div class="wear-vehicle-calculator-result__first-block">
      <p>{{ $t("app.calculatorPage.wearVehicle.depreciationAndAmortization") }}</p>
      <p>{{ wearVehicleCalculateResult?.total_percent }}%</p>
    </div>
    <div class="wear-vehicle-calculator-result__second-block">
      <div class="wear-vehicle-calculator-result__row">
        <p class="wear-vehicle-calculator-result__row-title">
          {{ $t("app.calculatorPage.wearVehicle.averageAnnualMileage") }}
        </p>
        <p class="wear-vehicle-calculator-result__row-description">
          {{ thousandSeparator(wearVehicleCalculateResult?.average_mileage) }}{{ $t("form.formAbbreviations.km") }}
        </p>
      </div>
      <div class="wear-vehicle-calculator-result__row">
        <p class="wear-vehicle-calculator-result__row-title">
          {{ $t("app.calculatorPage.wearVehicle.deviationsInTheSmallerDirection") }}
        </p>
        <p class="wear-vehicle-calculator-result__row-description">
          {{ $t("app.calculatorPage.wearVehicle.text-1") }} {{ " " }}
          {{ thousandSeparator(wearVehicleCalculateResult?.percent_value) }} {{ $t("form.formAbbreviations.km") }}
        </p>
        <p class="wear-vehicle-calculator-result__row-description">
          {{ thousandSeparator(actualMileage) }} <
          {{ thousandSeparator(wearVehicleCalculateResult?.subtracted) }}
        </p>
      </div>
      <div class="wear-vehicle-calculator-result__row">
        <p class="wear-vehicle-calculator-result__row-title">{{ $t("app.calculatorPage.wearVehicle.note") }}</p>
        <p class="wear-vehicle-calculator-result__row-description">
          {{ $t("app.calculatorPage.wearVehicle.text-2") }} {{ wearVehicleCalculateResult.mileage_percent }}%
        </p>
        <p class="wear-vehicle-calculator-result__row-description">
          {{ $t("app.calculatorPage.wearVehicle.text-3") }} {{ " " }}
          <span
            @click="openLink('https://ffins.kz/metodika_opredelenya_stoimosti_ts_v_ramkax_issledovanie.pdf', '_blank')"
            class="methodics">
            {{ $t("app.calculatorPage.wearVehicle.text-4") }} </span
          >,
          {{ $t("app.calculatorPage.wearVehicle.text-5") }}
          <span>{{ $t("app.calculatorPage.wearVehicle.text-6") }}</span>
        </p>
      </div>
      <div
        v-if="wearVehicleCalculateResult?.average_mileage === null && wearVehicleCalculateResult?.total_percent > 75"
        class="wear-vehicle-calculator-result__row">
        <p class="wear-vehicle-calculator-result__row-description">
          {{ $t("app.calculatorPage.wearVehicle.text-0") }}
        </p>
        <br />
      </div>
    </div> -->
  </section>
</template>

<style scoped lang="scss">
.wear-vehicle-calculator-result {
  @include column;
  gap: 50px;
  max-width: 540px;

  .wear-vehicle-calculator-result__first-block {
    border-radius: 10px;
    background: #1f1f1f;
    padding: 24px 20px;

    @include between;

    p {
      color: var(--color-white);
      font-size: 18px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }

    .wear-vehicle-calculator-result__row {
      @include column;

      .wear-vehicle-calculator-result__row-title {
        color: rgba(255, 255, 255, 0.5);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 19.6px;
        margin-bottom: 8px;
      }
      .wear-vehicle-calculator-result__row-description {
        color: var(--color-white);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22.4px;
      }
    }
  }

  .wear-vehicle-calculator-result__second-block {
    border-radius: 10px;
    background: #1f1f1f;
    padding: 24px 20px;
    @include column;
    gap: 20px;

    p {
      color: var(--color-white);
      font-size: 18px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }

    .wear-vehicle-calculator-result__row {
      @include column;

      .wear-vehicle-calculator-result__row-title {
        color: rgba(255, 255, 255, 0.5);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 19.6px;
        margin-bottom: 8px;
      }
      .wear-vehicle-calculator-result__row-description {
        color: var(--color-white);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22.4px;

        .methodics {
          text-decoration: underline;
          cursor: pointer;
          font-weight: 500;

          &:hover {
            color: rgb(0, 255, 34);
          }
        }

        span {
          font-weight: 900;
        }
      }
      .wear-vehicle-calculator-result__row-link-description {
        color: var(--color-white);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22.4px;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          color: rgb(0, 255, 34);
        }
      }
    }
  }
}
</style>
