<script setup lang="ts"></script>

<template>
  <section class="dtp-freedom-insurance container">
    <p class="dtp-freedom-insurance__title">{{ $t("app.dtpLandingPage.about.title") }}</p>
    <div class="dtp-freedom-insurance__content">
      <div class="dtp-freedom-insurance__first-paragraph">{{ $t("app.dtpLandingPage.about.paragraph-1") }}</div>
      <img
        src="/dtp-landing-front/images/freedom-insurance-logo.png"
        class="dtp-freedom-insurance__logo"
        alt="freedom insurance logo" />
      <div class="dtp-freedom-insurance__second-paragraph">{{ $t("app.dtpLandingPage.about.paragraph-2") }}</div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.dtp-freedom-insurance {
  @include column;
  gap: 50px;
  margin-bottom: 150px;
  z-index: 1;
  position: relative;

  .dtp-freedom-insurance__title {
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    @include main-green-gradient;
  }

  .dtp-freedom-insurance__content {
    @include row;
    gap: 30px;

    @include max-responsive(xl) {
      @include column;
    }

    .dtp-freedom-insurance__first-paragraph {
      color: #c1c1c1;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22.4px;
      text-align: start;

      @include max-responsive(xl) {
        order: 2;
      }
    }
    .dtp-freedom-insurance__logo {
      width: 302px;
      height: 377px;
      flex-shrink: 0;
    }
    .dtp-freedom-insurance__second-paragraph {
      color: #c1c1c1;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22.4px;
      text-align: end;
      @include max-responsive(xl) {
        order: 2;
        text-align: start;
      }
    }
  }
}
</style>
