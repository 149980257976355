<script setup lang="ts">
import {ref, onMounted} from "vue"
import {useI18n} from "vue-i18n"
import {storeToRefs} from "pinia"
import {useGatewayFetcher} from "@/composables/useGatewayFetcher"
import {useAddDriverAutoStore} from "@/stores/AddDriverAutoStore"
import {useModalStore} from "@/stores/ModalStore"
import {useHelpers} from "@/composables/useHelpers"
import {constants} from "@/constants/kdp"
import FFButton from "@/components/FFUI/FFButton.vue"
import WhiteCheckIcon from "@/components/icons/button-states/WhiteCheckIcon.vue"
import WhiteCrossIcon from "@/components/icons/button-states/WhiteCrossIcon.vue"
import CloseIcon from "@/components/icons/CloseIcon.vue"

const {t} = useI18n()
const {postData} = useGatewayFetcher()
const addDriverAutoStore = useAddDriverAutoStore()
const modalStore = useModalStore()
const {identificationCardNotVerifiedUsers, agreementLoaderHide, intervalIds, allUsersAreVerificated} =
  storeToRefs(addDriverAutoStore)
const {currentLocale} = useHelpers()

const emit = defineEmits(["stepBack"])

const buttonStates = ref<Array<{id: number; status: string}>>([])
const timers = ref<number[]>([])

onMounted(async () => {
  if (identificationCardNotVerifiedUsers.value.length !== 0) {
    buttonStates.value = identificationCardNotVerifiedUsers.value.map(() => ({
      id: constants.DEFAULT,
      status: ""
    }))
    timers.value = new Array(identificationCardNotVerifiedUsers.value.length).fill(constants.DEFAULT)
  }
})

const handleIntervalIdentificationCardCheck = async (index: number) => {
  agreementLoaderHide.value = true
  const response = await addDriverAutoStore.sendIdentificationCardCode(index)
  const responseData = response?.data?.data

  if (responseData?.id === 1) {
    clearInterval(intervalIds.value[index])
    delete intervalIds.value[index]
    agreementLoaderHide.value = false
    buttonStates.value[index].id = constants.AGREED
    buttonStates.value[index].status = responseData?.status
    await addDriverAutoStore.setIdentificationCardNewClientData(index)
  } else if (responseData?.id === 2) {
    buttonStates.value[index].id = constants.PENDING
  } else if (responseData?.id === 3) {
    clearInterval(intervalIds.value[index])
    delete intervalIds.value[index]
    agreementLoaderHide.value = false
    buttonStates.value[index].id = constants.DENIED
    buttonStates.value[index].status = responseData?.status
  } else if (responseData?.id === 4) {
    clearInterval(intervalIds.value[index])
    delete intervalIds.value[index]
    agreementLoaderHide.value = false
    buttonStates.value[index].id = constants.ERROR
    buttonStates.value[index].status = responseData?.status
  }
}

const handleSendCode = async (index: number) => {
  buttonStates.value[index].id = constants.PENDING
  await handleIntervalIdentificationCardCheck(index)
  intervalIds.value[index] = setInterval(async () => {
    await handleIntervalIdentificationCardCheck(index)
  }, 5000)
}

const buttonTitle = (state: number, index: number) => {
  switch (state) {
    case constants.PENDING:
      if (currentLocale.value === "ru" || currentLocale.value === "en") {
        return `${t("app.buttons.sendCodeAgainThrough.text-1")} ${timers.value[index]} ${t("app.buttons.sendCodeAgainThrough.text-2")}`
      } else {
        return `${timers.value[index]} ${t("app.buttons.sendCodeAgainThrough.text-2")}`
      }
    case constants.DENIED:
      return t("app.buttons.sendCodeAgain")
    default:
      return t("app.buttons.sendCode")
  }
}

const closeKdp = () => {
  modalStore.closeModal()
  addDriverAutoStore.resetCalcStates()
  addDriverAutoStore.clearAllIntervals()
}
</script>

<template>
  <div class="agreement-form">
    <CloseIcon @click="closeKdp" class="agreement-form__close-icon" />
    <p class="agreement-form__title">{{ $t("app.ogpoPage.forms.agreementToGetAndProcessData") }}</p>
    <div class="agreement-form__content">
      <div class="agreement-form__content-first-part">
        <p>{{ $t("app.ogpoPage.forms.agreementToRefreshPersonalData") }}</p>
        <p>{{ $t("app.ogpoPage.forms.smsFrom1414RefreshPersonalData") }}</p>
      </div>
      <div class="agreement-form__content-second-part">
        <template v-if="Boolean(identificationCardNotVerifiedUsers.length)">
          <div
            v-for="(data, index) in identificationCardNotVerifiedUsers"
            :key="index"
            class="agreement-form__second-part-block">
            <p>{{ data.iin }}</p>
            <p class="!no-underline">{{ data.fio }}</p>
            <div v-if="buttonStates[index]?.id === 1" class="agreement-form__second-part-state-block">
              <p>{{ $t("app.buttons.confirmed") }}</p>
              <WhiteCheckIcon />
            </div>
            <div
              v-if="buttonStates[index]?.id === 2 || buttonStates[index]?.id === 4"
              class="agreement-form__second-part-state-block">
              <p>{{ $t("app.buttons.waitingConfirmation") }}</p>
              <img class="loading-icon" src="/main-front/images/loading-gif.gif" height="20" width="20" alt="loading" />
            </div>
            <div v-if="buttonStates[index]?.id === 3" class="agreement-form__second-part-state-block">
              <p>{{ buttonStates[index]?.status }}</p>
              <WhiteCrossIcon />
            </div>
            <FFButton
              v-if="buttonStates[index]?.id !== 1 && buttonStates[index]?.id !== 2"
              @click="handleSendCode(index)"
              :disabled="buttonStates[index]?.id === 2 ? true : false"
              :title="buttonTitle(buttonStates[index]?.id, index)"
              width="100%"
              :uppercase="false" />
            <p @click="addDriverAutoStore.openAddDriverKdpAgreementPdf(data.fio, data.iin)">
              {{ $t("app.ogpoPage.forms.downloadConsent") }}
            </p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.agreement-form {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 30px 20px;
  margin: 0 20px;
  max-width: 320px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);
  .agreement-form__close-icon {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
  }
  .agreement-form__title {
    color: #ffffff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
  }
  .agreement-form__content {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;
    margin-top: 20px;

    .agreement-form__content-first-part {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 26px;
      p:first-of-type {
        color: #ffffff;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      p:last-of-type {
        color: #ffffff;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
    }

    .agreement-form__content-second-part {
      display: flex;
      flex-direction: column;
      gap: 26px;
      width: 100%;

      .agreement-form__second-part-block {
        padding-top: 26px;
        border-top: 1px solid rgba(255, 255, 255, 0.5);
        margin-bottom: 10px;
        .agreement-form__second-part-state-block {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 14px 0;
          p {
            color: #ffffff;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
            text-decoration: unset !important;
          }
        }
        p {
          color: rgba(255, 255, 255, 0.5);
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
        }
        p:nth-child(2) {
          margin-top: 3px;
        }
        p:last-of-type {
          text-decoration: underline;
          cursor: pointer;
        }
        .ff-button-wrapper {
          margin: 14px 0;
          width: 100%;
        }
      }
    }
  }
  .agreement-form__buttons {
    display: flex;
    width: 100%;
    gap: 10px;
    margin-top: 30px;
  }
}
</style>
