<script setup lang="ts">
import {ref, onUnmounted} from "vue"
import {storeToRefs} from "pinia"
import {useKaskoStore} from "@/stores/KaskoStore"
import {useHelpers} from "@/composables/useHelpers"
import {useFormatters} from "@/utils/formatters"
import ProductBankCashbackAmount from "@/components/shared/ProductBankCashbackAmount.vue"
import FFSlider from "@/components/FFUI/FFSlider.vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFYesNoCheckbox from "@/components/FFUI/FFYesNoCheckbox.vue"

const kaskoStore = useKaskoStore()
const {
  currentStep,
  currentTariffName,
  amountOfCoverage,
  currentTariff,
  payMonthly,
  insSum,
  sliderStep,
  sliderSteps,
  kaskoTariffs
} = storeToRefs(kaskoStore)
const {currentLocale} = useHelpers()
const {thousandSeparator} = useFormatters()

const handleNextStep = async () => {
  const response1 = await kaskoStore.getCashbackData()
  let response2
  if(payMonthly.value) {
    response2 = await kaskoStore.getClientCardData() 
  }

  if((!payMonthly.value && response1) || (payMonthly.value && response1 && response2)) {
    currentStep.value = 3
  } else {
    return
  }
}
</script>

<template>
  <div class="tariff">
    <div class="tariff__wrapper">
      <div class="tariff__header">
        <p class="tariff__kasko">{{ $t("app.kaskoPage.forms.tariffs.kasko") }}</p>
        <p class="tariff__current-tariff">
          {{
            currentTariff.kasko_type === "KASKO_EXPRESS"
              ? "EXPRESS"
              : currentTariff.kasko_type === "KASKO_CLASSIC"
                ? "CLASSIC"
                : "VIP"
          }}
        </p>
      </div>
      <div class="tariff__content">
        <div class="tariff__content-payments-row">
          <p class="tariff__content-paragraph">{{ $t("app.kaskoPage.forms.tariffs.payments") }}:</p>
          <p v-if="currentLocale === 'kk'" class="tariff__content-paragraph">
            {{ currentTariff.ins_sum && thousandSeparator(currentTariff.ins_sum) }}
            {{ $t("app.kaskoPage.forms.tariffs.till") }}
            {{ $t("app.kaskoPage.forms.tariffs.tg") }}
          </p>
          <p v-else class="tariff__content-paragraph">
            {{ $t("app.kaskoPage.forms.tariffs.till") }}
            {{ currentTariff.ins_sum && thousandSeparator(currentTariff.ins_sum) }}
            {{ $t("app.kaskoPage.forms.tariffs.tg") }}
          </p>
        </div>
        <div class="tariff__content-payments-row">
          <p class="tariff__content-paragraph">{{ $t("app.kaskoPage.forms.tariffs.cover") }}:</p>
          <p
            v-show="sliderStep !== 3 && sliderStep !== 4"
            @click="kaskoStore.handleOpenRiskModal('EXPRESS')"
            class="tariff__content-paragraph underline">
            1 {{ $t("app.kaskoPage.forms.tariffs.risk") }}
          </p>
          <p
            v-show="sliderStep === 3"
            @click="kaskoStore.handleOpenRiskModal('CLASSIC')"
            class="tariff__content-paragraph underline">
            11 {{ $t("app.kaskoPage.forms.tariffs.riskss") }}
          </p>
          <p
            v-show="sliderStep === 4"
            @click="kaskoStore.handleOpenRiskModal('VIP')"
            class="tariff__content-paragraph underline">
            16 {{ $t("app.kaskoPage.forms.tariffs.riskss") }}
          </p>
        </div>
        <div class="tariff__content-payments-row">
          <p class="tariff__content-paragraph">{{ $t("app.kaskoPage.forms.tariffs.cost") }}:</p>
          <div>
            <p>
              {{
                payMonthly
                  ? thousandSeparator(currentTariff.subscriptionPremium)
                  : thousandSeparator(currentTariff.premium)
              }}
              {{ payMonthly ? $t("form.formAbbreviations.inMonth") : $t("form.formAbbreviations.tg") }}
            </p>
          </div>
        </div>
      </div>
      <p class="tariff__slider-helper-text">{{ $t("app.kaskoPage.forms.tariffs.moveSlider") }}</p>
      <FFSlider
        :title="$t('app.kaskoPage.forms.tariffs.cost')"
        titleUppercase="capitalize"
        :tg="$t('app.kaskoPage.forms.tariffs.tg')"
        :sliderMin="0"
        :sliderStep="0"
        :sliderMax="Object.entries(kaskoTariffs).length === 5 ? 4 : Object.entries(kaskoTariffs).length === 4 ? 3 : 2"
        :amount="currentTariff.premium"
        :sliderValue="sliderStep"
        :inputFunc="kaskoStore.handleAmountOfCoverageValChange"
        inputRangeBackground="linear-gradient(to right, #444444, #ffffff)" />
      <ul v-show="sliderStep < 3" class="tariff__list">
        <li class="tariff__list-item">{{ $t("app.kaskoPage.forms.tariffs.whatIncluded.text-1") }}</li>
        <li class="tariff__list-item">{{ $t("app.kaskoPage.forms.tariffs.whatIncluded.text-2") }}</li>
        <li class="tariff__list-item">{{ $t("app.kaskoPage.forms.tariffs.whatIncluded.text-3") }}</li>
        <li class="tariff__list-item">{{ $t("app.kaskoPage.forms.tariffs.whatIncluded.text-4") }}</li>
        <li class="tariff__list-item">{{ $t("app.kaskoPage.forms.tariffs.whatIncluded.text-5") }}</li>
        <li class="tariff__list-item">{{ $t("app.kaskoPage.forms.tariffs.whatIncluded.text-6") }}</li>
      </ul>
      <ul v-show="sliderStep === 3" class="tariff__list">
        <li class="tariff__list-item">{{ $t("app.kaskoPage.forms.tariffs.whatIncludedClassic.text-1") }}</li>
        <li class="tariff__list-item">{{ $t("app.kaskoPage.forms.tariffs.whatIncludedClassic.text-2") }}</li>
        <li class="tariff__list-item">{{ $t("app.kaskoPage.forms.tariffs.whatIncludedClassic.text-3") }}</li>
        <li class="tariff__list-item">{{ $t("app.kaskoPage.forms.tariffs.whatIncludedClassic.text-4") }}</li>
        <li class="tariff__list-item">{{ $t("app.kaskoPage.forms.tariffs.whatIncludedClassic.text-5") }}</li>
        <li class="tariff__list-item">{{ $t("app.kaskoPage.forms.tariffs.whatIncludedClassic.text-6") }}</li>
      </ul>
      <ul v-show="sliderStep === 4" class="tariff__list">
        <li class="tariff__list-item">{{ $t("app.kaskoPage.forms.tariffs.whatIncludedVip.text-1") }}</li>
        <li class="tariff__list-item">{{ $t("app.kaskoPage.forms.tariffs.whatIncludedVip.text-2") }}</li>
        <li class="tariff__list-item">{{ $t("app.kaskoPage.forms.tariffs.whatIncludedVip.text-3") }}</li>
        <li class="tariff__list-item">{{ $t("app.kaskoPage.forms.tariffs.whatIncludedVip.text-4") }}</li>
        <li class="tariff__list-item">{{ $t("app.kaskoPage.forms.tariffs.whatIncludedVip.text-5") }}</li>
        <li class="tariff__list-item">{{ $t("app.kaskoPage.forms.tariffs.whatIncludedVip.text-6") }}</li>
        <li class="tariff__list-item">{{ $t("app.kaskoPage.forms.tariffs.whatIncludedVip.text-7") }}</li>
      </ul>
      <div class="tariff__price-monthly-row">
        <p class="tariff__price-title">
          {{ $t("app.kaskoPage.forms.tariffs.payMonthly") }}
        </p>
        <FFYesNoCheckbox
          :value="payMonthly"
          :toggleCheckbox="() => kaskoStore.togglePayMonthlyCheckbox('payMonthly')"
          :isYesNoNeeded="false"
          backgroundColor="#9B9B9B"
          yesBackgroundColor="#4FAF3B" />
      </div>
      <ProductBankCashbackAmount
        productType="Kasko"
        :isLoyaltyCalled="currentTariff?.cashBackInfo.isLoyaltyCalled"
        :isClientBank="currentTariff?.cashBackInfo.isClientBank"
        :cashbackPercent="
          currentTariff?.cashBackInfo.isClientBank
            ? currentTariff?.cashBackInfo.cashbackUpToPercent
            : currentTariff?.cashBackInfo.cashbackPercent
        "
        :currentStep="currentStep" />
      <FFButton @click="handleNextStep" :title="$t('app.buttons.arrange')" :uppercase="false" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.tariff {
  display: flex;
  flex-direction: column;

  .tariff__wrapper {
    padding: 20px 16px;
    border-radius: 10px;
    background-color: #1f1f1f;
    margin-top: 24px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .tariff__header {
      .tariff__kasko {
        color: #ffffff;
        font-size: 14px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
      }
      .tariff__current-tariff {
        font-size: 40px;
        font-style: normal;
        font-weight: 900;
        line-height: 31px;

        background: linear-gradient(95deg, #79b0e2 -3.54%, #1cd491 56.19%, #3fed6e 102.64%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .tariff__slider-helper-text {
      color: #fdfdfd;
      text-align: center;
      font-size: 15px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      opacity: 0.7;
    }

    .tariff__content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
      .tariff__content-payments-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .tariff__content-paragraph {
          color: #ffffff;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          cursor: pointer;
        }
        div {
          border-radius: 5px;
          background-color: #72bf44;
          p {
            padding: 5px 10px;
            color: #1f1f1f;
            font-size: 14px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
          }
        }
      }
    }
    .tariff__list {
      padding-bottom: 40px;
      border-bottom: 2px solid rgba($color: #979797, $alpha: 1);
      .tariff__list-item {
        position: relative;
        color: rgba(255, 255, 255, 0.5);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-left: 13px;

        &:not(:last-of-type) {
          margin-bottom: 5px;
        }

        &::before {
          content: "•";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
        }
      }
    }
    .tariff__price-monthly-row {
      position: relative;
      z-index: 1;
      @include between;
      .tariff__price-title {
        color: var(--color-white);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}
</style>
