<script setup lang="ts">
import {useHelpers} from "@/composables/useHelpers"
import FFButton from "@/components/FFUI/FFButton.vue"
import HProtectHowWorkBlock from "@/components/HomeProtect/HProtectHowWorks/UI/HProtectHowWorkBlock.vue"
import ListWithTick from "@/components/icons/ListWithTick.vue"

const {scrollToTop} = useHelpers()
</script>

<template>
  <section class="home-protect-how-works container">
    <p class="home-protect-how-works__title">{{ $t("app.homeProtectPage.howWorks.title") }}</p>
    <div class="home-protect-how-works__blocks">
      <HProtectHowWorkBlock
        v-for="(number, index) in 4"
        :key="index"
        :index="index"
        :icon="ListWithTick"
        :title="$t(`app.homeProtectPage.howWorks.title-${number}`)"
        :description="$t(`app.homeProtectPage.howWorks.description-${number}`)" />
    </div>
    <div class="home-protect-how-works__remarks">
      <p>{{ $t(`app.homeProtectPage.howWorks.remark-1`) }}</p>
      <p>{{ $t(`app.homeProtectPage.howWorks.remark-2`) }}</p>
    </div>
    <div class="home-protect-how-works__button-wrapper">
      <FFButton
        @click="scrollToTop"
        :title="$t('app.buttons.calculateCost')"
        maxWidth="325px"
        maxWidthMobile="100%"
        background="#2BC48A"
        backgroundMobile="#2BC48A"
        color="#ffffff"
        colorMobile="#ffffff"
        :uppercase="false"
        :uppercaseMobile="false" />
    </div>
  </section>
</template>

<style scoped lang="scss">
.home-protect-how-works {
  margin-top: 58px;
  margin-bottom: 150px;
  position: relative;
  z-index: 0;
  @media(max-width: $lg) {
    margin-top: 0;
  }
  .home-protect-how-works__title {
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 40px;
    background: linear-gradient(95deg, #79b0e2 -3.54%, #1cd491 56.19%, #3fed6e 102.64%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .home-protect-how-works__blocks {
    display: grid;
    gap: 30px;
    width: 100%;
    margin-top: 30px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "block1 block2"
      "block3 block4";

    @media (max-width: $lg) {
      grid-template-columns: 1fr;
      grid-template-areas:
        "block1"
        "block2"
        "block3"
        "block4";
    }
  }

  .home-protect-how-works__blocks > *:nth-child(1) {
    grid-area: block1;
  }
  .home-protect-how-works__blocks > *:nth-child(2) {
    grid-area: block2;
  }
  .home-protect-how-works__blocks > *:nth-child(3) {
    grid-area: block3;
  }
  .home-protect-how-works__blocks > *:nth-child(4) {
    grid-area: block4;
  }

  .home-protect-how-works__remarks {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 20px;
    margin: 30px 0;

    p {
      color: #ffffff;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      opacity: 0.5;
    }
  }

  .home-protect-how-works__button-wrapper {
    display: flex;
    justify-content: center;
  }
}
</style>
