<script setup lang="ts">
import {useHelpers} from "@/composables/useHelpers"
import FFButton from "@/components/FFUI/FFButton.vue"

const {scrollToTop} = useHelpers()
</script>

<template>
  <section class="comfort-subscription">
    <div class="comfort-subscription__left-part">
      <h2 class="comfort-subscripton__title">{{ $t("app.comfortPage.peaceInsurance-title") }}</h2>
      <div class="comfort-subscription__paragraphs">
        <p>
          {{ $t("app.comfortPage.peaceInsurance-description-1") }}
        </p>
        <p>
          {{ $t("app.comfortPage.peaceInsurance-description-2") }}
        </p>
        <p>{{ $t("app.comfortPage.peaceInsurance-description-3") }}</p>
      </div>
      <div class="comfort-subscription__btns">
        <FFButton @click="scrollToTop" width="190px" size="16px" weight="700" :title="$t('app.buttons.arrange')" />
      </div>
    </div>
    <div class="comfort-subscription__right-part">
      <img src="/comfort-front/images/first-glass-apartment.png" alt="first glass apartment" />
    </div>
  </section>
  <div class="first-glass-apartment-mobile">
    <img src="/comfort-front/images/first-glass-apartment-mobile.png" alt="first glass apartment mobile" />
  </div>
</template>

<style scoped lang="scss">
.comfort-subscription {
  border-radius: 30px;
  background: rgba(74, 74, 74, 0.2);
  backdrop-filter: blur(8px);
  padding: 50px 40px;
  margin-bottom: 150px;

  .comfort-subscription__left-part {
    max-width: 506px;

    .comfort-subscripton__title {
      font-size: 30px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      text-transform: uppercase;

      background: linear-gradient(95deg, #79b0e2 -3.54%, #1cd491 56.19%, #3fed6e 102.64%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .comfort-subscription__paragraphs {
      margin: 30px 0;

      p {
        color: #d6d6d6;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
      }

      p:nth-child(2) {
        margin: 16px 0;
      }
    }

    .comfort-subscription__btns {
      display: flex;

      .ff-button-wrapper {
        &:last-child:hover {
          color: #000000;
          transition: 0.3s;
        }
      }

      .ff-button-wrapper:hover {
        scale: 1.05;
        transition: scale 0.3s ease;
      }
    }
  }

  .comfort-subscription__right-part {
    position: relative;

    img {
      position: absolute;
      right: -49px;
      bottom: -118px;

      width: 571px;
      height: 535px;
    }
  }
}

.first-glass-apartment-mobile {
  display: none;
}

@media (max-width: $xxl) {
  .comfort-subscription {
    .comfort-subscription__right-part {
      display: block;
      position: relative;

      img {
        position: absolute;
        top: -335px;
        right: -32px;
        z-index: -1;
        width: 365px;
        height: 348px;
      }
    }
  }
}

@media (max-width: $xl) {
  .comfort-subscription {
    margin-bottom: 100px;
    .comfort-subscription__right-part {
      display: none;
    }
  }
  .first-glass-apartment-mobile {
    display: block;
    position: relative;

    img {
      position: absolute;
      top: -767px;
      right: 151px;
      z-index: -1;
      width: 365px;
      height: 348px;
    }
  }
}

@media (max-width: $lg) {
  .comfort-subscription {
    padding: 39px 20px;
    margin: 0px 0 100px 0;

    .comfort-subscription__left-part {
      .comfort-subscripton__title {
        text-align: center;
        font-size: 30px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
      }
      .comfort-subscription__paragraphs {
        p {
          color: #d6d6d6;
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 130%;
          opacity: 0.6;
        }
      }
      .comfort-subscription__btns {
        display: flex;
        flex-direction: column;
        padding: 0 20px;

        .ff-button-wrapper {
          &:first-of-type {
            margin-bottom: 30px;
          }
          &:last-child {
            margin-left: 0;
          }
          &:last-child:hover {
            color: #ffffff;
            transition: 0.3s;
          }
        }
      }
    }

    .comfort-subscription__right-part {
      display: none;
    }
  }
  .first-glass-apartment-mobile {
    display: block;
    position: relative;

    img {
      position: absolute;
      top: -823px;
      left: 38px;
      right: 0;
      z-index: -1;
      width: 265px;
      height: 248px;
    }
  }
}
</style>
