<script setup lang="ts">
import {storeToRefs} from "pinia"
import {useMSTRemasteredStore} from "@/stores/MSTRemastered"
import {useModalStore} from "@/stores/ModalStore"
import {useDeviceSize} from "@/composables/useDeviceSize"
import {useI18n} from "vue-i18n"
import FFForm from "@/components/FFUI/FFForm.vue"
import ModalHeader from "@/components/FFUI/ModalHeader.vue"
import MSTAuthorizeForm from "@/components/MSTRemastered/Forms/MSTAuthorizeForm.vue"
import MSTAgreementForm from "@/components/MSTRemastered/Forms/MSTAgreementForm.vue"
import MSTInsuredAgreementForm from "@/components/MSTRemastered/Forms/MSTInsuredAgreementForm.vue"
import MSTCalculateForm from "@/components/MSTRemastered/Forms/MSTCalculateForm.vue"
import MSTPersonalDataForm from "@/components/MSTRemastered/Forms/MSTPersonalDataForm.vue"
import MSTPaymentMethodForm from "@/components/MSTRemastered/Forms/MSTPaymentMethodForm.vue"
import MSTKaspiPaymentForm from "@/components/MSTRemastered/Forms/MSTKaspiPaymentForm.vue"
import SuperAppQrBlock from "@/components/shared/SuperAppQrBlock.vue"
import CloseIcon from "@/components/icons/socials/CloseIcon.vue"

const modalStore = useModalStore()
const mstStore = useMSTRemasteredStore()
const {
  currentStep,
  needKdp,
  allUsersAreVerificated,
  verificationUsersData,
  calculationResponse,
  insureds,
  showKaspiForm,
  showSuperAppBlock,
  cantScanQr,
  agreementLoaderHide,
  isAllInsuredsVerified,
  currentForm,
  paymentUrl
} = storeToRefs(mstStore)
const {isDesktop, isMobileDevice} = useDeviceSize()

const {t} = useI18n()

const handleCloseModal = () => {
  modalStore.closeModal()
  mstStore.resetStep()
  agreementLoaderHide.value = false
}

const handleStepBack = () => {
  if (currentStep.value === 4 && showKaspiForm.value && !showSuperAppBlock.value) {
    showKaspiForm.value = false
  } else if (currentStep.value === 4 && !showKaspiForm.value && showSuperAppBlock.value && !cantScanQr.value) {
    showSuperAppBlock.value = false
  } else if (currentStep.value === 4 && !showKaspiForm.value && showSuperAppBlock.value && cantScanQr.value) {
    cantScanQr.value = false
  } else if (currentStep.value === 3 && !isAllInsuredsVerified.value && needKdp.value) {
    currentForm.value = currentForm.value
    currentStep.value = 3
    mstStore.handleKdp()
    mstStore.clearInsuredIin()
  } else if (currentStep.value === 2 && needKdp.value && isMobileDevice.value) {
    mstStore.handleStepBack()
    agreementLoaderHide.value = false
    mstStore.clearAllIntervals()
    insureds.value = []
  } else if (currentStep.value === 2 && needKdp.value && isDesktop.value) {
    handleCloseModal()
    mstStore.clearAllIntervals()
  } else if (currentStep.value === 2 && !needKdp.value && isDesktop.value) {
    modalStore.closeModal()
    mstStore.handleStepBack()
    mstStore.clearAllIntervals()
  } else if (currentStep.value === 1 && isDesktop.value) {
    currentStep.value = 1
    modalStore.closeModal()
    mstStore.clearAllIntervals()
  } else if (currentStep.value === 1 && !isDesktop.value) {
    currentStep.value = 1
    mstStore.clearAllIntervals()
  } else {
    mstStore.handleStepBack()
  }
}

const handleStepNextOutInsuredKdp = () => {
  currentForm.value = currentForm.value
  currentStep.value = 3
  mstStore.handleKdp()
  mstStore.fillInsured()
}

const handleProblemsWithScan = (value: boolean) => {
  cantScanQr.value = value
}
</script>

<template>
  <FFForm>
    <CloseIcon v-if="currentStep === 1" @click="modalStore.closeModal" class="ff-form-close" />
    <ModalHeader
      v-if="
        ((currentStep === 2 || currentStep === 3 || currentStep === 4) &&
          allUsersAreVerificated &&
          isAllInsuredsVerified &&
          needKdp) ||
        ((currentStep === 2 || currentStep === 3 || currentStep === 4) && !needKdp && !isAllInsuredsVerified)
      "
      :title="currentStep === 4 ? t('form.formHeaders.paymentMethod') : t('form.formHeaders.specifyTheData')"
      @stepBack="handleStepBack"
      :currentStep="currentStep"
      :totalSteps="4" />
    <MSTAuthorizeForm v-if="currentStep === 1" />
    <MSTAgreementForm
      v-if="currentStep === 2 && needKdp && !allUsersAreVerificated"
      @handleKdp="mstStore.handleKdp"
      @stepBack="handleStepBack" />
    <MSTCalculateForm
      v-if="(currentStep === 2 && needKdp && allUsersAreVerificated) || (currentStep === 2 && !needKdp)" />
    <MSTPersonalDataForm
      v-if="(currentStep === 3 && needKdp && isAllInsuredsVerified) || (currentStep === 3 && !needKdp)" />
    <MSTInsuredAgreementForm
      v-if="currentStep === 3 && needKdp && !isAllInsuredsVerified"
      @handleKdp="handleStepNextOutInsuredKdp"
      @stepBack="handleStepBack" />
    <MSTPaymentMethodForm v-if="currentStep === 4 && !showKaspiForm && !showSuperAppBlock" />
    <MSTKaspiPaymentForm v-if="currentStep === 4 && showKaspiForm" />
    <SuperAppQrBlock
      v-if="currentStep === 4 && showSuperAppBlock && !showKaspiForm"
      :url="paymentUrl"
      @cantScan="handleProblemsWithScan"
      :cantScanQr="cantScanQr"
      :orderId="calculationResponse?.orderId"
      level="Q">
      <template #super-app-qr-block__title-first-row>
        {{ $t("app.ogpoPage.forms.superAppQrCode-1") }}
      </template>
      <template #super-app-qr-block__title-second-row>
        {{ $t("app.ogpoPage.forms.superAppQrCode-2") }}
      </template>
    </SuperAppQrBlock>
  </FFForm>
</template>

<style scoped lang="scss">
.ff-form {
  @include max-responsive(lg) {
    overflow-y: unset;
    overflow-x: unset;
    max-height: unset;
  }
  .ff-form-close {
    position: absolute;
    cursor: pointer;
    top: 25px;
    left: 28px;
  }
}
</style>
