<script setup>
import {watchEffect} from "vue"
import {useHelperStore} from "@/stores/HelperStore"
import {useI18n} from "vue-i18n"
import MSTBanner from "@/components/MST/MSTBanner.vue"
import MSTGuideLine from "@/components/MST/MSTGuideLine.vue"
import MSTHero from "@/components/MST/MSTHero.vue"
import MSTProduct from "@/components/MST/MSTProduct.vue"
import MSTInsuranceCase from "@/components/MST/MSTInsuranceCase.vue"

const helperStore = useHelperStore()
const {t} = useI18n()

watchEffect(() => {
  helperStore.pageTitle = t("app.headerPageTitles.mstPage")
})
</script>

<template>
  <MSTHero />
  <MSTProduct />
  <MSTGuideLine />
  <MSTBanner />
  <MSTInsuranceCase />
</template>

<style scoped></style>
