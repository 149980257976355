<script setup>

</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_25747_39725)">
      <path d="M19.875 5H4.125C3.82663 5 3.54048 5.12292 3.3295 5.34171C3.11853 5.5605 3 5.85725 3 6.16667V17.8333C3 18.1428 3.11853 18.4395 3.3295 18.6583C3.54048 18.8771 3.82663 19 4.125 19H19.875C20.1734 19 20.4595 18.8771 20.6705 18.6583C20.8815 18.4395 21 18.1428 21 17.8333V6.16667C21 5.85725 20.8815 5.5605 20.6705 5.34171C20.4595 5.12292 20.1734 5 19.875 5ZM19.0087 17.8333H5.05875L8.99625 13.61L8.18625 12.7992L4.125 17.1567V7.05333L11.1169 14.2692C11.3277 14.4865 11.6128 14.6084 11.91 14.6084C12.2072 14.6084 12.4923 14.4865 12.7031 14.2692L19.875 6.8725V17.0808L15.735 12.7875L14.9419 13.61L19.0087 17.8333ZM4.86187 6.16667H18.9637L11.91 13.4408L4.86187 6.16667Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_25747_39725">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped>

</style>