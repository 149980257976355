<script setup lang="ts">
import {ref, computed} from "vue"
import {vMaska} from "maska"
import {useI18n} from "vue-i18n"
import {useToast} from "vue-toastification"
import {useModalStore} from "@/stores/ModalStore"
import {useGatewayFetcher} from "@/composables/useGatewayFetcher"
import {useHelpers} from "@/composables/useHelpers"
import {onPhoneFocus} from "@/utils/inputEvents"
import {useFormatters} from "@/utils/formatters"
import {contactsFormSchema} from "@/schemas"
import {throttle} from "lodash-es"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import FFOtp from "@/components/FFUI/FFOtp.vue"
import Modal from "@/components/FFUI/Modal.vue"
import Loader from "@/components/FFUI/Loader.vue"

const {openModal, closeModal} = useModalStore()
const {loading, postData} = useGatewayFetcher()
const {t} = useI18n()
const {currentLocale} = useHelpers()
const {phoneNumberFormatter} = useFormatters()
const toast = useToast()

const formData = ref({
  fullName: "",
  phone: "",
  email: "",
  comment: ""
})

const isContactsFormComplete = computed(() => {
  const validation = contactsFormSchema.safeParse(formData.value)
  return validation.success
})

//OTP
const otpCode = ref<string | number>()
const showOtp = ref<boolean>(false)
const codeIsSent = ref<boolean>(false)
const sentOtpResponse = ref<{message: string; status: boolean}>()
const timer = ref<number>(0)

const handleOtpFormOpen = async () => {
  const otpProps = {
    codeIsSent: codeIsSent,
    buttonTitle: buttonTitle,

    sendOtp: handleSendOtp,
    checkOtp: checkOtpCode,
    closeOtp: closeOtpForm
  }
  openModal({
    component: FFOtp,
    props: otpProps
  })
}

const buttonTitle = computed(() => {
  if (checkOtpResponse.value?.status) {
    return t("app.buttons.sendCode")
  } else if (timer.value > 0) {
    const minutes = Math.floor(timer.value / 60)
    const seconds = timer.value % 60
    return `${t("app.buttons.sendCodeAgainThrough.text-1")} ${minutes}:${seconds.toString().padStart(2, "0")}`
  }
  return t("app.buttons.sendCode")
})

const startTimer = () => {
  timer.value = 90
  const interval = setInterval(() => {
    timer.value--
    if (timer.value <= 0) {
      clearInterval(interval)
      codeIsSent.value = false
    }
  }, 1000)
}

const handleSendOtp = throttle(async () => {
  const config = {
    headers: {
      "Accept-Language": currentLocale.value
    }
  }
  try {
    const response = await postData(
      "/auth/send-otp",
      {
        phone: phoneNumberFormatter(formData.value.phone)
      },
      config
    )
    if (response?.status === 200) {
      handleOtpFormOpen()
      startTimer()
      codeIsSent.value = true
      showOtp.value = true
      sentOtpResponse.value = response?.data?.data
    } else {
      otpCode.value = ""
      codeIsSent.value = false
      showOtp.value = false
    }
  } catch (error) {
    console.error(error)
  }
}, 3000)

const checkOtpResponse = ref()
const responseIsSent = ref<boolean>(false)
const checkOtpCode = throttle(async (code: string | number) => {
  const config = {
    headers: {
      "Accept-Language": currentLocale.value
    }
  }
  try {
    const response = await postData(
      "/company/contacts/create-request",
      {
        phone: phoneNumberFormatter(formData.value.phone),
        username: formData.value.fullName,
        email: formData.value.email,
        comment: formData.value.comment,
        otpCode: code
      },
      config
    )
    if (response?.status === 201) {
      toast.success(t("app.toasterMessages.weWillContactWithYouLater"))
      closeOtpForm()
      formData.value = {
        fullName: "",
        phone: "",
        email: "",
        comment: ""
      }
    } else {
      return
    }
  } catch (error) {
    console.error(error)
  }
}, 3000)

const closeOtpForm = () => {
  showOtp.value = false
  codeIsSent.value = false
  timer.value = 0
  closeModal()
}
</script>

<template>
  <section class="write-us-form container">
    <p class="write-us-form__title">{{ $t("app.contactsPage.writeUs") }}</p>
    <div class="write-us-form__form">
      <div class="write-us-form__form-wrapper">
        <div class="write-us-form__inputs">
          <FFFieldset :title="$t('form.inputTitles.fullName')">
            <FFInput v-model="formData.fullName" id="fullName" name="fullName" :required="true" inputmode="text" />
          </FFFieldset>
          <FFFieldset :title="$t('form.inputTitles.phone')">
            <FFInput
              v-maska
              data-maska="+7 (###) ### ####"
              placeholder="+7 (___) ___ ____"
              v-model="formData.phone"
              id="phone"
              name="phone"
              :required="true"
              inputmode="text"
              type="tel"
              @focus="onPhoneFocus" />
          </FFFieldset>
          <FFFieldset :title="$t('form.inputTitles.email')">
            <FFInput v-model="formData.email" id="email" name="email" :required="true" inputmode="text" />
          </FFFieldset>
        </div>
        <div class="write-us-form__comment">
          <FFFieldset :title="$t('form.inputTitles.commentary')">
            <FFInput v-model="formData.comment" :textarea="true" />
          </FFFieldset>
          <FFButton
            @click="handleSendOtp"
            :title="$t('app.buttons.send')"
            :uppercase="false"
            paddingx="17px"
            paddingy="20px"
            width="100%"
            background=" linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
            backgroundMobile=" linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
            color="var(--color-white)"
            colorMobile="var(--color-white)"
            :disabled="!isContactsFormComplete" />
        </div>
      </div>
    </div>
  </section>
  <Modal style="z-index: 1039" :isOpen="loading">
    <Loader />
  </Modal>
</template>

<style scoped lang="scss">
.write-us-form {
  @include column;
  gap: 30px;
  padding: 50px 30px;
  border-radius: 20px;
  background: rgba(74, 74, 74, 0.2);
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  .write-us-form__title {
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: 20px;
    @include main-green-gradient;
  }
  .write-us-form__form {
    .write-us-form__form-wrapper {
      @include flex;
      gap: 50px;

      @include max-responsive(xl) {
        @include column;
        gap: 30px;
      }

      .write-us-form__inputs {
        @include column;
        gap: 30px;

        @include max-responsive(xl) {
          max-width: 100%;
        }

        .input-fieldset {
          width: 260px;

          @include max-responsive(xl) {
            width: 100%;
          }
        }
      }

      & > .input-fieldset {
        textarea {
          height: 100%;

          @include max-responsive(xl) {
            height: 189px;
            width: 100%;
          }
        }
      }

      .write-us-form__comment {
        width: 500px;

        @include max-responsive(xl) {
          width: 100%;
        }

        .ff-button-wrapper {
          height: fit-content;
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
