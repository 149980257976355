<script setup lang="ts"></script>

<template>
  <div class="add">
    <div class="add__title">
      <p class="add__title--main">{{ $t("app.addDriverAuto.mainTitle") }}</p>
      <p class="add__title--description">
        {{ $t("app.addDriverAuto.subTitle-1") }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.add {
  display: flex;
  flex-direction: column;
  .add__title {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .add__title--main {
      text-align: center;
      font-size: 30px;
      font-style: normal;
      font-weight: 900;
      line-height: 100%;
      background: linear-gradient(103deg, #bde0ff -2.91%, #1cd491 58.89%, #00ff45 106.95%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .add__title--description {
      color: rgba(255, 255, 255, 0.7);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
</style>
