<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
    <path
      d="M11.0893 21C16.6615 21 21.1787 16.5228 21.1787 11C21.1787 5.47715 16.6615 1 11.0893 1C5.51715 1 1 5.47715 1 11C1 16.5228 5.51715 21 11.0893 21Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path d="M11.0898 7V15" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M7.05469 11H15.1262" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>
