import zod from "zod"

const currentYear = new Date().getFullYear()

export const vehicleMileageSchema = zod.object({
  category: zod.object({
    name: zod.string().nonempty()
  }),
  yearOfRelease: zod
    .string()
    .nonempty()
    .refine((val) => {
      const year = parseInt(val, 10)
      return year >= 1900 && year <= currentYear
    })
})
