<script setup lang="ts">
import {reactive, computed} from "vue"
import {storeToRefs} from "pinia"
import {useI18n} from "vue-i18n"
import {useComfortStore} from "@/stores/ComfortStore"
import {useFormatters} from "@/utils/formatters"
import {useHelpers} from "@/composables/useHelpers"
import FFSlider from "@/components/FFUI/FFSlider.vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFYesNoCheckbox from "@/components/FFUI/FFYesNoCheckbox.vue"
import FFTooltip from "@/components/FFUI/FFTooltip.vue"

interface ITooltips {
  interiorDecoration: boolean
  property: boolean
  paymentToNeighbors: boolean
  franchise: boolean
}

const emit = defineEmits(["nextStep"])

const comfortStore = useComfortStore()
const {currentSumOfCoverage, forRent, payMonthly, sliderStep} = storeToRefs(comfortStore)
const {thousandSeparator} = useFormatters()
const {currentLocale} = useHelpers()
const {t} = useI18n()

const tooltipsTypes = reactive<ITooltips>({
  interiorDecoration: false,
  property: false,
  paymentToNeighbors: false,
  franchise: false
})

const handleMouseOver = (type: keyof ITooltips) => {
  tooltipsTypes[type] = true
}

const handleMouseLeave = (type: keyof ITooltips) => {
  tooltipsTypes[type] = false
}

const handleTooltipClose = (type: keyof ITooltips) => {
  tooltipsTypes[type] = false
}

const handleSubscription = () => {
  emit("nextStep")
}

const getPremium = computed(() => {
  return forRent.value
    ? (payMonthly.value
      ? `${thousandSeparator(currentSumOfCoverage.value.toRentPremium)} ${t("form.formAbbreviations.tg")}`
      : `${thousandSeparator(currentSumOfCoverage.value.toRentPremium)} ${t("form.formAbbreviations.tg")}`)
    : (payMonthly.value ? `${thousandSeparator(currentSumOfCoverage.value.premium)} ${t("form.formAbbreviations.tg")}` : `${thousandSeparator(currentSumOfCoverage.value.premium)} ${t("form.formAbbreviations.tg")}`)
})
</script>

<template>
  <p class="comfort-price__helper-text">{{ $t("app.comfortPage.forms.price.priceHelperText") }}</p>
  <FFSlider
    :title="$t('app.comfortPage.forms.price.insuranceAmount')"
    :tg="$t('form.formAbbreviations.tg')"
    :sliderMin="0"
    :sliderStep="0"
    :sliderMax="5"
    :amount="thousandSeparator(currentSumOfCoverage.coverageAmount)"
    :sliderValue="sliderStep"
    :needHeader="true"
    :inputFunc="comfortStore.handleAmountOfCoverageValChange" />
  <p class="comfort-price__coverage-tariff-text">{{ $t("app.comfortPage.forms.price.coverBasedOnTariff") }}</p>
  <hr />
  <div class="comfort-price__coverage-tariff-wrapper">
    <div class="comfort-price__coverage-tariff-row">
      <p @mouseover="handleMouseOver('interiorDecoration')" @mouseleave="handleMouseLeave('interiorDecoration')">
        {{ $t("app.comfortPage.forms.price.interiorDecorationTitle") }}:
      </p>
      <p v-if="currentLocale === 'kk'">
        {{
          `${thousandSeparator(currentSumOfCoverage.upToInteriorDecorationAmount)} ${t("form.formAbbreviations.tg")} ${t("form.formAbbreviations.till")}`
        }}
      </p>
      <p v-else>
        {{
          `${t("form.formAbbreviations.till")} ${thousandSeparator(currentSumOfCoverage.upToInteriorDecorationAmount)} ${t("form.formAbbreviations.tg")}`
        }}
      </p>
      <FFTooltip
        @closeTooltip="handleTooltipClose('interiorDecoration')"
        v-show="tooltipsTypes.interiorDecoration"
        :title="$t('app.comfortPage.forms.price.interiorDecorationTitle')"
        :description="$t('app.comfortPage.forms.price.interiorDecorationDescription')"
        fontSize="14px"
        top="17px"
        left="0px" />
    </div>
    <div class="comfort-price__coverage-tariff-row">
      <p @mouseover="handleMouseOver('property')" @mouseleave="handleMouseLeave('property')">
        {{ $t("app.comfortPage.forms.price.propertyTitle") }}:
      </p>
      <p v-if="currentLocale === 'kk'">
        {{
          `${thousandSeparator(currentSumOfCoverage.upToPledge)} ${t("form.formAbbreviations.tg")} ${t("form.formAbbreviations.till")}`
        }}
      </p>
      <p v-else>
        {{
          `${t("form.formAbbreviations.till")} ${thousandSeparator(currentSumOfCoverage.upToPledge)} ${t("form.formAbbreviations.tg")}`
        }}
      </p>
      <FFTooltip
        @closeTooltip="handleTooltipClose('property')"
        v-show="tooltipsTypes.property"
        :title="$t('app.comfortPage.forms.price.propertyTitle')"
        :description="$t('app.comfortPage.forms.price.propertyDescription')"
        fontSize="14px"
        top="16px"
        left="0px" />
    </div>
    <div class="comfort-price__coverage-tariff-row">
      <p @mouseover="handleMouseOver('paymentToNeighbors')" @mouseleave="handleMouseLeave('paymentToNeighbors')">
        {{ $t("app.comfortPage.forms.price.paymentToNeighborsTitle") }}:
      </p>
      <p v-if="currentLocale === 'kk'">
        {{
          `${thousandSeparator(currentSumOfCoverage.payoutToNeighbors)} ${t("form.formAbbreviations.tg")} ${t("form.formAbbreviations.till")}`
        }}
      </p>
      <p v-else>
        {{
          `${t("form.formAbbreviations.till")} ${thousandSeparator(currentSumOfCoverage.payoutToNeighbors)} ${t("form.formAbbreviations.tg")}`
        }}
      </p>
      <FFTooltip
        @closeTooltip="handleTooltipClose('paymentToNeighbors')"
        v-show="tooltipsTypes.paymentToNeighbors"
        :title="$t('app.comfortPage.forms.price.paymentToNeighborsTitle')"
        :description="$t('app.comfortPage.forms.price.paymentToNeighborsDescription')"
        fontSize="14px"
        top="16px"
        left="0px" />
      <FFTooltip
        @closeTooltip="handleTooltipClose('franchise')"
        v-show="tooltipsTypes.franchise"
        :title="$t('app.comfortPage.forms.price.franchise')"
        :description="$t('app.comfortPage.forms.price.franchiseDescription')"
        paddingXY="20px"
        fontSize="14px"
        top="53px"
        left="0px" />
    </div>
  </div>
  <hr />
  <p v-if="currentLocale !== 'kk'" class="comfort-price__franchise-total-sum">
    <span
      @mouseover="handleMouseOver('franchise')"
      @mouseleave="handleMouseLeave('franchise')"
      class="underline cursor-pointer"
      >{{ $t("app.comfortPage.forms.price.franchise-1") }}</span
    >
    {{ $t("app.comfortPage.forms.price.franchise-2") }}
    {{ thousandSeparator(currentSumOfCoverage.franchise) }}
    {{ $t("form.formAbbreviations.tg") }}
  </p>
  <p v-else class="comfort-price__franchise-total-sum">
    {{ $t("app.comfortPage.forms.price.franchise-1") }}
    <span
      @mouseover="handleMouseOver('franchise')"
      @mouseleave="handleMouseLeave('franchise')"
      class="underline cursor-pointer"
      >{{ $t("app.comfortPage.forms.price.franchise-2") }}</span
    >
    {{ thousandSeparator(currentSumOfCoverage.franchise) }}
    {{ $t("app.comfortPage.forms.price.franchise-3") }}
  </p>
  <div class="comfort-price__for-rent-wrapper">
    <p>{{ $t("app.comfortPage.forms.price.forRent") }}</p>
    <FFYesNoCheckbox :value="forRent" :toggleCheckbox="() => comfortStore.toggleCheckbox('forRent')" />
  </div>
  <div class="comfort-price__price-summary">
    <div class="comfort-price__price-summary-wrapper">
      <div class="comfort-price__price-summary-row">
        <p v-if="currentLocale === 'ru'">{{ $t("app.comfortPage.forms.price.policy") }} Freedom Comfort:</p>
        <p v-else>Freedom Comfort {{ $t("app.comfortPage.forms.price.policy") }}:</p>
        <p>
          {{
            forRent
              ? `${thousandSeparator(currentSumOfCoverage.toRentPremium)} ${$t("form.formAbbreviations.tg")}`
              : `${thousandSeparator(currentSumOfCoverage.premium)} ${$t("form.formAbbreviations.tg")}`
          }}
        </p>
      </div>
      <div class="comfort-price__price-summary-row">
        <p>{{ $t("app.comfortPage.forms.price.toPay") }}:</p>
        <p>
          {{
            forRent
              ? `${thousandSeparator(currentSumOfCoverage.toRentPremium)} ${$t("form.formAbbreviations.tg")}`
              : `${thousandSeparator(currentSumOfCoverage.premium)} ${$t("form.formAbbreviations.tg")}`
          }}
        </p>
      </div>
    </div>
    <hr />
    <div class="relative">
      <div class="comfort-price__price-monthly-row">
        <p class="comfort-price__price-summary-title">
          {{ $t("app.comfortPage.forms.price.payMonthly-1") }} <br />
          {{ $t("app.comfortPage.forms.price.payMonthly-2") }}
        </p>
        <FFYesNoCheckbox
          :value="payMonthly"
          :toggleCheckbox="() => comfortStore.toggleCheckbox('payMonthly')"
          :isYesNoNeeded="false"
          backgroundColor="#9B9B9B"
          yesBackgroundColor="#0A84FF" />
      </div>
      <div class="comfort-price__buttons">
        <FFButton
          @click="handleSubscription"
          :title="$t('app.buttons.pay')"
          background="#ffffff"
          color="#337000"
          size="16px"
          weight="700"
          :uppercase="false" />
      </div>

      <div class="comfort-price__dark-bg"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.comfort-price__helper-text {
  color: #fdfdfd;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.7;
  margin: 20px 0 30px;
}
.comfort-price__coverage-tariff-text {
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 30px;
}
hr {
  margin: 13px 0;
  height: 1px;
  border-top: 1px solid #c5c5c5;
}
.comfort-price__coverage-tariff-wrapper {
  display: flex;
  flex-direction: column;

  .comfort-price__coverage-tariff-row {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }

    p:first-of-type {
      color: #ffffff;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      text-decoration-line: underline;
      cursor: pointer;
    }
    p:last-of-type {
      color: #ffffff;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }
}
.comfort-price__price-monthly-row {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.comfort-price__franchise-total-sum {
  color: #ffffff;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  opacity: 0.5;
}
.comfort-price__for-rent-wrapper {
  padding: 15px;
  border-radius: 10px;
  background: rgba(217, 217, 217, 0.13);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0 40px;

  p {
    color: #ffffff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.comfort-price__price-summary {
  position: relative;
  width: 100%;

  .comfort-price__price-summary-title {
    color: #ffffff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  hr {
    position: relative;
    z-index: 1;
    margin: 13px 0;
    height: 1px;
    border-top: 1px solid #cbcbcb;
    opacity: 0.42;
  }
  .comfort-price__price-summary-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;

    .comfort-price__price-summary-row {
      display: flex;
      justify-content: space-between;

      &:first-of-type {
        margin-bottom: 11px;
        p {
          color: #ffffff;
          opacity: 0.8;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
      &:last-of-type {
        p:first-of-type {
          color: #ffffff;
          font-size: 18px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
          text-transform: uppercase;
        }
        p:last-of-type {
          color: #ffffff;
          font-size: 18px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
        }
      }
    }
  }
  .comfort-price__dark-bg {
    background-color: rgba(0, 0, 0, 0.5);
    height: calc(100% + 180px);
    width: calc(100% + 60px);
    position: absolute;
    top: -94px;
    left: -10%;
    right: 0;
    bottom: 0;
  }
}
.comfort-price__buttons {
  position: relative;
  z-index: 1;
  button:last-of-type {
    margin-top: 20px;
  }
}
.comfort-price__ask {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: #2e2e2e;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  margin: 0 auto;
  transition: all 0.3s ease;
  padding: 10px 0;
  margin-top: 30px;
  p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.comfort-price__ask:hover {
  scale: 1.1;
}
</style>
