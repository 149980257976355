<script setup lang="ts">
import {useI18n} from "vue-i18n"
import FFButton from "@/components/FFUI/FFButton.vue"
import PhoneIconFooter from "@/components/icons/PhoneIconFooter.vue"

const {t} = useI18n()
</script>

<template>
  <section class="kasko-any-questions">
    <div class="kasko-any-questions__content">
      <div class="kasko-any-questions__texts">
        <p>{{ $t("app.kaskoPage.anyquestions.mainTitle") }}</p>
        <p>{{ $t("app.kaskoPage.anyquestions.description") }}</p>
      </div>
      <div class="kasko-any-questions__call">
        <div class="kasko-any-questions__contact-item">
          <PhoneIconFooter />
          <div class="contact-item__sub">
            <p>5777</p>
            <p>{{ $t("app.footer.phone") }}</p>
          </div>
        </div>
        <a href="tel:5777"
          ><FFButton
            :title="t('app.buttons.callBack')"
            background="transparent"
            backgroundMobile="transparent"
            borderPixel="1px solid #ffffff"
            borderPixelMobile="1px solid #ffffff"
            color="#ffffff"
            colorMobile="#ffffff"
            :uppercase="false"
        /></a>
      </div>
    </div>
  </section>
  <div class="kasko-any-questions-images">
    <img
      class="kasko-any-questions__sms-ask-image"
      src="/ogpo-front/images/sms-ask.png"
      data-src="/ogpo-front/images/sms-ask.png"
      alt=""
      draggable="false" />
  </div>
</template>

<style scoped lang="scss">
.kasko-any-questions {
  position: relative;
  z-index: 1;
  padding: 55px 60px 55px 150px;
  margin-bottom: 185px;

  border-radius: 30px;
  background: rgba(74, 74, 74, 0.2);
  backdrop-filter: blur(16px);
  border: 1px solid rgba(255, 255, 255, 0.1);

  .kasko-any-questions__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 203px;
    .kasko-any-questions__texts {
      p:first-of-type {
        font-size: 40px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;

        background: linear-gradient(95deg, #79b0e2 -3.54%, #1cd491 56.19%, #3fed6e 102.64%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      p:last-of-type {
        color: #ffffff;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        opacity: 0.8;
        margin-top: 20px;
      }
    }

    .kasko-any-questions__call {
      height: 100%;
      border-left: 1px solid rgba(255, 255, 255, 0.1);
      padding-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .kasko-any-questions__contact-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        margin-bottom: 24px;
        .contact-item__sub {
          display: flex;
          flex-direction: column;

          p:first-of-type {
            color: #fff;
            font-size: 24px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            letter-spacing: 0.2px;
          }
          p:last-of-type {
            color: rgba(255, 255, 255, 0.5);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.2px;
          }
        }
      }
    }
  }
}
.kasko-any-questions-images {
  position: relative;
  .kasko-any-questions__sms-ask-image {
    position: absolute;
    top: -309px;
    left: 34px;
    width: 304px;
    height: 235px;
    z-index: 0;
  }
}

@media (max-width: $xxl) {
  .kasko-any-questions {
    padding: 30px;

    .kasko-any-questions__content {
      display: flex;
      justify-content: unset;

      .kasko-any-questions__texts {
        margin-right: 30px;
      }
    }
  }

  .kasko-any-questions-images {
    .kasko-any-questions__sms-ask-image {
      position: absolute;
      top: -261px;
      left: -30px;
      width: 250px;
      height: 185px;
      z-index: 0;
    }
  }
}

@media (max-width: $xl) {
  .kasko-any-questions {
    padding: 30px;
    margin-bottom: 100px;

    .kasko-any-questions__content {
      display: flex;
      justify-content: unset;

      .kasko-any-questions__call {
        a {
          .ff-button-wrapper {
            width: 234px;
          }
        }
      }
    }
  }

  .kasko-any-questions-images {
    .kasko-any-questions__sms-ask-image {
      position: absolute;
      top: -261px;
      left: -10px;
      width: 250px;
      height: 185px;
      z-index: 0;
    }
  }
}

@media (max-width: $lg) {
  .kasko-any-questions {
    padding: 30px;

    .kasko-any-questions__content {
      display: flex;
      justify-content: unset;
      flex-direction: column;
      height: fit-content;

      .kasko-any-questions__texts {
        margin-right: 0;
        padding-bottom: 30px;
        p:first-of-type {
          font-size: 30px;
        }
        p:last-of-type {
          font-size: 16px;
        }
      }

      .kasko-any-questions__call {
        width: 100%;
        padding-left: 0;
        padding-top: 30px;
        border-left: 0;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
      }
    }
  }

  .kasko-any-questions-images {
    display: none;
  }
}
</style>
