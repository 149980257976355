<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="40" viewBox="0 0 36 40" fill="none">
    <path
      d="M12.9747 4.04993C14.8331 3.49684 16.808 3 17.9898 3C19.1717 3 21.1466 3.49684 23.005 4.04993C24.9063 4.6124 26.8229 5.27798 27.95 5.68108C28.4212 5.85142 28.8389 6.1638 29.1553 6.58234C29.4716 7.00088 29.6736 7.5086 29.7382 8.04718C30.759 16.441 28.3902 22.6619 25.516 26.7773C24.2973 28.5377 22.844 30.088 21.2048 31.3763C20.638 31.8222 20.0375 32.2142 19.4098 32.5481C18.9302 32.7956 18.4146 32.9981 17.9898 32.9981C17.5651 32.9981 17.0512 32.7956 16.5699 32.5481C15.9422 32.2142 15.3416 31.8222 14.7749 31.3763C13.1357 30.088 11.6825 28.5377 10.4637 26.7773C7.58952 22.6619 5.22067 16.441 6.24152 8.04718C6.30607 7.5086 6.50811 7.00088 6.82442 6.58234C7.14074 6.1638 7.5585 5.85142 8.02972 5.68108C9.6658 5.09389 11.3146 4.55001 12.9747 4.04993Z"
      fill="url(#paint0_linear_2949_6345)" />
    <g filter="url(#filter0_bi_2949_6345)">
      <path
        d="M29.1914 10.5234H6.75023C5.47485 10.5234 4.25171 11.0361 3.34988 11.9487C2.44805 12.8612 1.94141 14.0989 1.94141 15.3895V31.6097C1.94141 32.9003 2.44805 34.138 3.34988 35.0506C4.25171 35.9631 5.47485 36.4758 6.75023 36.4758H29.1914C30.4668 36.4758 31.6899 35.9631 32.5918 35.0506C33.4936 34.138 34.0002 32.9003 34.0002 31.6097V15.3895C34.0002 14.0989 33.4936 12.8612 32.5918 11.9487C31.6899 11.0361 30.4668 10.5234 29.1914 10.5234ZM21.45 14.5505C23.1137 14.5505 23.9456 14.5505 24.2857 14.8614C24.5802 15.1305 24.7198 15.5294 24.6575 15.9233C24.5856 16.3785 23.9354 16.8974 22.635 17.9352L18.88 20.932C18.845 20.9599 18.8275 20.9738 18.7937 20.9972C18.7748 21.0103 18.7205 21.0441 18.7006 21.0553C18.6646 21.0755 18.6599 21.0778 18.6504 21.0822C18.4382 21.1828 18.2063 21.2352 17.9708 21.2352C17.7354 21.2352 17.5034 21.1828 17.2912 21.0822C17.2817 21.0778 17.277 21.0755 17.2411 21.0553C17.2211 21.0441 17.1669 21.0103 17.148 20.9972C17.1141 20.9738 17.0966 20.9599 17.0616 20.932L13.3066 17.9352C12.0062 16.8974 11.3561 16.3785 11.2841 15.9233C11.2218 15.5294 11.3615 15.1305 11.6559 14.8614C11.9961 14.5505 12.8279 14.5505 14.4917 14.5505H21.45Z"
        fill="url(#paint1_radial_2949_6345)"
        fill-opacity="0.8" />
    </g>
    <path
      d="M17.5044 23.1416C17.7788 23.3528 18.1611 23.3528 18.4356 23.1416L28.0914 15.7085C28.6689 15.264 28.3545 14.3403 27.6258 14.3403H8.31411C7.58541 14.3403 7.27107 15.264 7.84849 15.7085L17.5044 23.1416Z"
      fill="white" />
    <defs>
      <filter
        id="filter0_bi_2949_6345"
        x="-1.027"
        y="7.55503"
        width="37.9954"
        height="31.8899"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.4842" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2949_6345" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2949_6345" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha" />
        <feOffset dx="0.270104" dy="0.135052" />
        <feGaussianBlur stdDeviation="0.675261" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0" />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_2949_6345" />
      </filter>
      <linearGradient
        id="paint0_linear_2949_6345"
        x1="29.9797"
        y1="3"
        x2="0.985069"
        y2="20.04"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#60F392" />
        <stop offset="1" stop-color="#17572C" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_2949_6345"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(40.4131 2.00241) rotate(131.982) scale(39.9764 41.7833)">
        <stop stop-color="white" stop-opacity="0.700689" />
        <stop offset="1" stop-color="#B3EBDA" stop-opacity="0.254405" />
      </radialGradient>
    </defs>
  </svg>
</template>
