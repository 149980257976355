<script setup lang="ts">
import {useRouter} from "vue-router"

const router = useRouter()
</script>

<template>
  <section class="our-team-hero">
    <h1 class="our-team-hero__title">{{ $t("app.ourTeam.title") }}</h1>
    <div class="our-team-hero__breadcrumbs">
      <p @click="router.push('/')">{{ $t("app.ourTeam.main") }}</p>
      /
      <span>{{ $t("app.ourTeam.title") }}</span>
    </div>
  </section>
</template>

<style scoped lang="scss">
.our-team-hero {
  top: 0;
  background: url("/our-team-front/images/our-team-hero.png") no-repeat center;
  background-size: cover;
  height: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 150px 0;

  @include max-responsive(lg) {
    border-radius: 0 0 50px 0;
  }

  .our-team-hero__title {
    color: #ffffff;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;

    @include max-responsive(xl) {
      font-size: 30px;
    }
  }
  .our-team-hero__breadcrumbs {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #a1a1a1;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    
    @include max-responsive(xl) {
      font-size: 14px;
    }
    
    p {
      cursor: pointer;
      transition: all 0.3s ease;
    }
    p:hover {
      color: #ffffff;
    }
    span {
      cursor: pointer;
      color: #ffffff;
    }
  }
}
</style>
