<script setup lang="ts">
import {computed, onMounted, ref, watch} from "vue"

import type {CalculationForm} from "@/interfaces/MST/CalculationForm"
import VueDatePicker from "@vuepic/vue-datepicker"
import "@vuepic/vue-datepicker/dist/main.css"
import {useHelperStore} from "@/stores/HelperStore"

import "@formatjs/intl-datetimeformat/polyfill-force"
import "@formatjs/intl-datetimeformat/locale-data/kk"
import "@formatjs/intl-datetimeformat/locale-data/ru"
import "@formatjs/intl-datetimeformat/locale-data/en"
import "@formatjs/intl-datetimeformat/add-all-tz"

import {useFetcher} from "@/composables/useFetcher"
import {useMSTStore} from "@/stores/MSTStore"
import {useToast} from "vue-toastification"
import {useHelpers} from "@/composables/useHelpers"
import {useI18n} from "vue-i18n"

import WhoWillFly from "@/components/MST/Form/WhoWillFly.vue"
import FFSelect from "@/components/FFUI/FFSelect.vue"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import ProgramBadge from "@/components/MST/Form/ProgramBadge.vue"
import Loader from "@/components/FFUI/Loader.vue"
import Modal from "@/components/FFUI/Modal.vue"
import FFCheckbox from "@/components/FFUI/FFCheckbox.vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import FFTooltip from "@/components/FFUI/FFTooltip.vue"
import QuestionIcon from "@/components/icons/QuestionIcon.vue"

interface Props {
  data: CalculationForm
}

const props = defineProps<Props>()

const emit = defineEmits(["success", "update:calculateForm"])

const {postData, loading} = useFetcher()
const {currentLocale} = useHelpers()
const mstStore = useMSTStore()
const toast = useToast()
const {t} = useI18n()
const helpersStore = useHelperStore()

onMounted(() => {
  calculatedAmount.value = mstStore.calculatedAmount
})

const calculatedAmount = ref<boolean>(null)
const calculationReceived = ref<boolean>(false)
const activeLifestyle = ref<boolean>(false)
const lang = localStorage.getItem("locale")

const onCalculateResolve = (form: any) => {
  return {
    requestId: form.id,
    dateStart: form.dateStart,
    dateStop: form.dateStop,
    aimTravel: form.aimTravel.value,
    amount: form.amount,
    ageTravels: form.ageTravels,
    countries: form.countries,
    sportId: form.sportId.id ?? 0,
    insuranceSumId: form.insuranceSumId,
    activeTravel: form.activeTravel,
    manyTravel: form.manyTravel
  }
}

const resetCalculation = () => {
  calculationReceived.value = false
}

const calculationBtnIsClicked = ref<boolean>(false)
const onCalculate = () => {
  resetCalculation()
  const data = onCalculateResolve(mstStore.calculateForm)
  if (mstStore.calculateForm.countries.length === 0) {
    toast.clear()
    toast.error(t("app.toasterMessages.mstPage.chooseArriveCountry"))
    return
  }
  if (Object.keys(mstStore.calculateForm.amount).length === 0) {
    toast.clear()
    toast.error(t("app.toasterMessages.mstPage.chooseInsuranceSum"))
    return
  }
  postData("/core/mst/calculate", data)
    .then((res) => {
      if (res && res.status == 200) {
        calculationBtnIsClicked.value = true
        mstStore.updateCalculatedAmount(res.data)
        calculatedAmount.value = res.data
        calculationReceived.value = true
      }
    })
    .catch((error) => {
      calculationBtnIsClicked.value = false
      console.log(error)
    })
}

const updateAmountList = () => {
  mstStore.updateAmountList([])
  postData("/core/mst/amounts", {destinations: mstStore.calculateForm.countries})
    .then((res) => {
      mstStore.updateAmountList(res.data)
    })
    .catch((err) => {
      console.error(err)
    })
}

const setAmount = () => {
  mstStore.calculateForm.insuranceSumId = mstStore.calculateForm.amount.id
}

const toPay = () => {
  if (calculationReceived.value) {
    emit("success", mstStore.calculatedAmount)
  }
}

const getDaysCount = computed(() => {
  if (
    JSON.stringify(mstStore.calculateForm.dateStart) === "{}" ||
    JSON.stringify(mstStore.calculateForm.dateStop) === "{}"
  ) {
    return 0
  }

  // Convert both dates to UTC to avoid issues with daylight saving time
  const startUtc = Date.UTC(
    mstStore.calculateForm.dateStart.getFullYear(),
    mstStore.calculateForm.dateStart.getMonth(),
    mstStore.calculateForm.dateStart.getDate()
  )
  const stopUtc = Date.UTC(
    mstStore.calculateForm.dateStop.getFullYear(),
    mstStore.calculateForm.dateStop.getMonth(),
    mstStore.calculateForm.dateStop.getDate()
  )

  // Calculate the time difference in milliseconds
  const timeDifference = stopUtc - startUtc

  // Convert the time difference to days
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

  return daysDifference + 1
})

const handleMouseOver = () => {
  activeLifestyle.value = true
}
const handleMouseLeave = () => {
  activeLifestyle.value = false
}
const activeLifestyleDescriptions = [
  "app.mstPage.form.calculationForm.activeTypeSportTooltip.text-1",
  "app.mstPage.form.calculationForm.activeTypeSportTooltip.text-2",
  "app.mstPage.form.calculationForm.activeTypeSportTooltip.text-3",
  "app.mstPage.form.calculationForm.activeTypeSportTooltip.text-4",
  "app.mstPage.form.calculationForm.activeTypeSportTooltip.text-5",
  "app.mstPage.form.calculationForm.activeTypeSportTooltip.text-6"
]

watch(
  () => mstStore.calculateForm.countries.slice(),
  (newCountries, oldCountries) => {
    if (newCountries.length < oldCountries.length || newCountries.length > oldCountries.length) {
      mstStore.calculateForm.amount = {}
    }
  }
)

watch(
  () => mstStore.calculateForm.dateStart,
  () => {
    mstStore.calculateForm.dateStop = {}
  }
)
watch(
  () => mstStore.calculateForm.activeTravel,
  (newValue, oldValue) => {
    if (calculationBtnIsClicked.value && newValue === true && oldValue === false) {
      onCalculate()
    }
    if (calculationBtnIsClicked.value && newValue === false && oldValue === true) {
      onCalculate()
    }
  }
)
</script>

<template>
  <Modal style="z-index: 100" :is-open="loading">
    <Loader />
  </Modal>
  <div class="calculation-form__row">
    <FFFieldset :title="$t('app.mstPage.form.calculationForm.travelAim')">
      <FFSelect
        :multiple="false"
        :options="data.goals[currentLocale]"
        :title="$t('app.mstPage.form.calculationForm.placeholders.choose')"
        @update="resetCalculation"
        v-model="mstStore.calculateForm.aimTravel" />
    </FFFieldset>
  </div>

  <div v-if="mstStore.calculateForm.aimTravel.value === 'Tourism'" class="calculation-form__checkbox-row">
    <FFCheckbox
      class="active-type-of-sport"
      :title="$t('app.mstPage.form.calculationForm.activeTypeSport')"
      v-model="mstStore.calculateForm.activeTravel"
      :locked="false" />
    <QuestionIcon
      @click="handleMouseOver('activeLifestyle')"
      @mouseover="handleMouseOver('activeLifestyle')"
      @mouseleave="handleMouseLeave('activeLifestyle')"
      class="calculation-form__question-icon" />
  </div>
  <FFTooltip
    v-show="activeLifestyle"
    class="calculation-form__activelifestyle-tooltip"
    :descriptions="activeLifestyleDescriptions"
    top="190px"
    left="5px" />

  <div v-if="mstStore.calculateForm.aimTravel.value === 'Sport'" class="calculation-form__row">
    <FFFieldset :title="$t('app.mstPage.form.calculationForm.sportType')">
      <FFSelect
        :multiple="false"
        :search-enable="true"
        :options="data.sports[currentLocale]"
        :title="$t('app.mstPage.form.calculationForm.placeholders.choose')"
        v-model="mstStore.calculateForm.sportId"
        @update="resetCalculation" />
    </FFFieldset>
  </div>

  <div class="calculation-form__row">
    <FFFieldset :title="$t('app.mstPage.form.calculationForm.finalCountry')">
      <FFSelect
        :multiple="true"
        :max-count="3"
        :search-enable="true"
        :options="data.destinations[currentLocale]"
        :title="$t('app.mstPage.form.calculationForm.placeholders.choose')"
        @update="
          () => {
            resetCalculation()
            updateAmountList()
          }
        "
        v-model="mstStore.calculateForm.countries" />
    </FFFieldset>
  </div>

  <div class="mst-datepicker calculation-form__row">
    <FFFieldset :title="$t('app.mstPage.form.calculationForm.beginDate')">
      <VueDatePicker
        class="mr-[2px]"
        @date-update="resetCalculation"
        v-model="mstStore.calculateForm.dateStart"
        :enable-time-picker="false"
        auto-apply
        format="dd.MM.yyyy"
        :locale="lang"
        :min-date="new Date(new Date().setDate(new Date().getDate() + 1))"
        :max-date="new Date(new Date().setDate(new Date().getDate() + 1) + 89 * 24 * 60 * 60 * 1000)"
        :start-date="new Date(new Date().setDate(new Date().getDate() + 2))">
        <template #clear-icon="{clear}"></template>
      </VueDatePicker>
    </FFFieldset>
    <FFFieldset :title="$t('app.mstPage.form.calculationForm.endDate')">
      <VueDatePicker
        @date-update="resetCalculation"
        v-model="mstStore.calculateForm.dateStop"
        :enable-time-picker="false"
        auto-apply
        format="dd.MM.yyyy"
        :locale="lang"
        :min-date="new Date(new Date(mstStore.calculateForm.dateStart).getTime() + 24 * 60 * 60 * 1000)"
        :max-date="new Date(new Date(mstStore.calculateForm.dateStart).getTime() + 364 * 24 * 60 * 60 * 1000)"
        :start-date="
          new Date(
            JSON.stringify(mstStore.calculateForm.dateStart) === '{}'
              ? Date.now() + 1000 * 60 * 60 * 24
              : new Date(mstStore.calculateForm.dateStart).getTime() + 24 * 60 * 60 * 1000
          )
        "
        :disabled="JSON.stringify(mstStore.calculateForm.dateStart) === '{}'">
        <template #clear-icon="{clear}"></template>
      </VueDatePicker>
    </FFFieldset>
  </div>

  <div class="mst-days-count">{{ $t("app.mstPage.form.calculationForm.dayQuantity") }}: {{ getDaysCount }}</div>

  <div class="calculation-form__row">
    <FFFieldset :title="$t('app.mstPage.form.calculationForm.whoWillFly')">
      <WhoWillFly
        v-model="mstStore.calculateForm.ageTravels"
        @update="resetCalculation" />
    </FFFieldset>
  </div>

  <div class="calculation-form__row">
    <FFFieldset :title="$t('app.mstPage.form.calculationForm.insuranceAmout')">
      <FFSelect
        :key="JSON.stringify(mstStore.calculateForm.amount)"
        :multiple="false"
        :options="mstStore.amountList"
        :title="$t('app.mstPage.form.calculationForm.placeholders.chooseAmount')"
        v-model="mstStore.calculateForm.amount"
        @update="
          () => {
            resetCalculation()
            setAmount()
          }
        " />
    </FFFieldset>
  </div>

  <FFButton
    :title="$t('app.mstPage.form.calculationForm.solveCost')"
    v-if="!calculationReceived"
    @click="onCalculate" />

  <ProgramBadge
    v-if="calculationReceived && calculatedAmount !== null"
    :program="mstStore.calculateForm.aimTravel.value"
    :price="calculatedAmount.premium"
    :currency-name="calculatedAmount.currencyName"
    :currency-value="calculatedAmount.currencyValue" />

  <FFButton :title="$t('app.buttons.next')" v-if="calculationReceived" @click="toPay"></FFButton>
</template>

<style scoped>
.calculation-form__row {
  margin-bottom: 20px;
}

.calculation-form__checkbox-row {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}
.calculation-form__question-icon {
  position: absolute;
  left: 180px;
}

.mst-days-count {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.2);

  color: #d7d7d7;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.mst-datepicker {
  gap: 2px;
  display: flex;
}
</style>
