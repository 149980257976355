<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="112" height="115" viewBox="0 0 112 115" fill="none">
    <g opacity="0.2" clip-path="url(#clip0_1385_25275)">
      <path
        d="M48.6915 3.12656C52.7293 -1.04219 59.2733 -1.04219 63.3111 3.12656L108.667 49.9603C112.704 54.1291 112.704 60.8781 108.667 65.0397L63.3111 111.873C59.2733 116.042 52.7363 116.042 48.7054 111.873L3.33594 65.0469C2.37566 64.0575 1.61382 62.8823 1.09401 61.5885C0.574202 60.2947 0.306641 58.9078 0.306641 57.5072C0.306641 56.1065 0.574202 54.7196 1.09401 53.4259C1.61382 52.1321 2.37566 50.9569 3.33594 49.9675L48.6915 3.12656ZM58.4379 8.15781C57.7917 7.49067 56.9152 7.11588 56.0013 7.11588C55.0874 7.11588 54.211 7.49067 53.5647 8.15781L8.20218 54.9844C7.55599 55.6516 7.19298 56.5565 7.19298 57.5C7.19298 58.4435 7.55599 59.3484 8.20218 60.0156L53.5647 106.849C54.211 107.517 55.0874 107.891 56.0013 107.891C56.9152 107.891 57.7917 107.517 58.4379 106.849L103.8 60.0156C104.447 59.3484 104.81 58.4435 104.81 57.5C104.81 56.5565 104.447 55.6516 103.8 54.9844L58.4379 8.15062V8.15781Z"
        fill="white" />
      <path
        d="M49.0547 79.0623C49.0547 78.1184 49.2348 77.1838 49.5846 76.3118C49.9345 75.4397 50.4473 74.6474 51.0937 73.98C51.7402 73.3126 52.5076 72.7831 53.3523 72.4219C54.1969 72.0607 55.1022 71.8748 56.0164 71.8748C56.9306 71.8748 57.8359 72.0607 58.6805 72.4219C59.5252 72.7831 60.2926 73.3126 60.9391 73.98C61.5855 74.6474 62.0983 75.4397 62.4482 76.3118C62.798 77.1838 62.9781 78.1184 62.9781 79.0623C62.9781 80.9686 62.2447 82.7967 60.9391 84.1446C59.6335 85.4926 57.8628 86.2498 56.0164 86.2498C54.17 86.2498 52.3993 85.4926 51.0937 84.1446C49.7882 82.7967 49.0547 80.9686 49.0547 79.0623ZM49.7369 35.9014C49.6442 34.9946 49.7372 34.0778 50.0098 33.2106C50.2825 32.3433 50.7287 31.5449 51.3195 30.8672C51.9103 30.1894 52.6325 29.6475 53.4393 29.2765C54.246 28.9056 55.1193 28.7139 56.0025 28.7139C56.8856 28.7139 57.7589 28.9056 58.5657 29.2765C59.3725 29.6475 60.0947 30.1894 60.6855 30.8672C61.2763 31.5449 61.7225 32.3433 61.9951 33.2106C62.2678 34.0778 62.3607 34.9946 62.268 35.9014L59.8314 61.1079C59.7496 62.0982 59.3107 63.0206 58.6017 63.6928C57.8927 64.365 56.9651 64.738 56.0025 64.738C55.0399 64.738 54.1123 64.365 53.4033 63.6928C52.6943 63.0206 52.2554 62.0982 52.1735 61.1079L49.7369 35.9014Z"
        fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_1385_25275">
        <rect width="111.387" height="115" fill="white" transform="translate(0.306641)" />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped lang="scss">
svg {
  opacity: 0.2;
}
</style>
