<script setup lang="ts"></script>

<template>
  <section class="kasko-how-works">
    <p class="kasko-how-works__title">{{ $t("app.kaskoPage.howWorks.title") }}</p>
    <div class="kasko-how-works__list">
      <div class="kasko-how-works__item">
        <img src="/kasko-front/icons/green-flexible-pricing.svg" alt="green flexible pricing icon" />
        <p class="kasko-how-works__item-title">{{ $t("app.kaskoPage.howWorks.title-1") }}</p>
        <p class="kasko-how-works__item-paragraph">{{ $t("app.kaskoPage.howWorks.description-1") }}</p>
      </div>

      <div class="kasko-how-works__item">
        <img src="/kasko-front/icons/green-convenient-payment.svg" alt="green convenient payment icon" />
        <p class="kasko-how-works__item-title">{{ $t("app.kaskoPage.howWorks.title-2") }}</p>
        <p class="kasko-how-works__item-paragraph">{{ $t("app.kaskoPage.howWorks.description-2") }}</p>
      </div>

      <div class="kasko-how-works__item">
        <img src="/kasko-front/icons/green-no-gai.svg" alt="green no gai icon" />
        <p class="kasko-how-works__item-title">{{ $t("app.kaskoPage.howWorks.title-3") }}</p>
        <p class="kasko-how-works__item-paragraph">{{ $t("app.kaskoPage.howWorks.description-3") }}</p>
      </div>

      <div class="kasko-how-works__item">
        <img src="/kasko-front/icons/green-additional-options.svg" alt="green additional options icon" />
        <p class="kasko-how-works__item-title">{{ $t("app.kaskoPage.howWorks.title-4") }}</p>
        <p class="kasko-how-works__item-paragraph">{{ $t("app.kaskoPage.howWorks.description-4") }}</p>
      </div>

      <div class="kasko-how-works__item">
        <img src="/kasko-front/icons/green-fast-withdraw.svg" alt="green fast withdraw icon" />
        <p class="kasko-how-works__item-title">{{ $t("app.kaskoPage.howWorks.title-5") }}</p>
        <p class="kasko-how-works__item-paragraph">{{ $t("app.kaskoPage.howWorks.description-5") }}</p>
      </div>

      <div class="kasko-how-works__item">
        <img src="/kasko-front/icons/green-cto.svg" alt="green cto icon" />
        <p class="kasko-how-works__item-title">{{ $t("app.kaskoPage.howWorks.title-6") }}</p>
        <p class="kasko-how-works__item-paragraph">{{ $t("app.kaskoPage.howWorks.description-6") }}</p>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.kasko-how-works {
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  background: rgba(74, 74, 74, 0.4);
  backdrop-filter: blur(7.5px);
  padding: 50px 30px;
  gap: 50px;
  margin: 150px 0;
  .kasko-how-works__title {
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;

    background: linear-gradient(95deg, #79b0e2 -3.54%, #1cd491 56.19%, #3fed6e 102.64%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .kasko-how-works__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px 20px;
    .kasko-how-works__item {
      max-width: 330px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      img {
        width: 40px;
        height: 40px;
      }
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        margin-bottom: 30px;
      }

      .kasko-how-works__item-title {
        color: #ffffff;
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-transform: uppercase;
      }
      .kasko-how-works__item-paragraph {
        color: #cecece;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
    }
  }
}

@media (max-width: $lg) {
  .kasko-how-works {
    margin: 50px 0;
    padding: 50px 0 50px 30px;
    .kasko-how-works__title {
      font-size: 30px;
      width: 230px;
    }
    .kasko-how-works__list {
      display: flex;
      overflow-x: auto;

      .kasko-how-works__item {
        width: 88.888%;
        flex-shrink: 0;
      }
    }
  }
}
</style>
