<script setup lang="ts">
import {ref} from "vue"
import {useRouter} from "vue-router"
import {useHelpers} from "@/composables/useHelpers"
import type {IProductCard} from "@/interfaces/Main/IProductCard"
import {comfortUrl, kaskoUrl} from "@/constants/urls"

const router = useRouter()
const {openLink} = useHelpers()

interface Props {
  data: IProductCard[]
}

const props = defineProps<Props>()
</script>

<template>
  <div>
    <div class="cards container">
      <template v-for="(i, index) in props.data" :key="index">
        <div
          class="cards__card"
          :class="{
            'backdrop-filter': i?.backdropFilter,
            'set-border': i?.backdropFilter
          }"
          :style="{background: i?.background}"
          @click="router.push(i.to)">
          <div class="flex flex-col">
            <p v-if="i.title" class="cards__card--title" :class="{'gradient-text': i?.gradientTitle}">
              {{ $t(i.title) }}
            </p>
            <p v-if="i.subTitle" class="cards__card--subtitle">{{ $t(i.subTitle) }}</p>
          </div>
          <img v-show="i.image" :src="i.image" alt="card" />
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.cards {
  display: grid;
  grid-column-gap: 10px;
  // grid-template-columns: repeat(5, 1fr);
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  position: relative;
  z-index: 0;

  @media (max-width: $xxl) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto;

    // & > :nth-child(4),
    // & > :nth-child(5) {
    //   grid-column: span 1.5;
    // }
  }

  @media (max-width: $lg) {
    grid-template-columns: repeat(2, 1fr);

    // & > :nth-child(5) {
    //   grid-column: span 2;
    // }
  }

  .skeleton-card {
    width: 270px;
    height: 118px;
    border-radius: 10px;
  }
  &__card {
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    max-height: 118px;
    cursor: pointer;
    &--title {
      font-size: 18px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
      color: #fff;
      width: fit-content;
      max-width: 200px;
      z-index: 10;
    }
    &--subtitle {
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      padding-left: 20px;
      color: #abb0a9;
      z-index: 10;
      max-width: 174px;
    }
    img {
      object-fit: cover;
      margin-left: -115px;
    }
  }
  &__card:hover {
    box-shadow: 0 0 5px #fff;
  }
  .backdrop-filter {
    backdrop-filter: blur(8px);
  }
  .set-border {
    border: 1px solid #3f4c3b;
  }
  .gradient-text {
    background: var(--FF-gradient, linear-gradient(103deg, #bde0ff -2.91%, #1cd491 58.89%, #00ff45 106.95%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@media (max-width: $xxl) {
  .cards {
    .skeleton-card {
      width: 225px;
    }
  }
}

@media (max-width: $xl) {
  .cards {
    position: relative;
    z-index: 1;
    .skeleton-card {
      width: 165px;
    }

    &__card {
      &--title {
        font-size: 14px;
        font-weight: 900;
      }
    }
  }
}

@media (max-width: $lg) {
  .cards {
    grid-template-columns: repeat(2, 1fr);

    .skeleton-card {
      width: 166.5px;
    }
  }
}

@media (max-width: $md) {
  .cards {
    &__card {
      &--title {
        word-break: break-all;
      }
    }
    .skeleton-card {
      width: 134px;
    }
  }
}
</style>
