<script setup lang="ts">
import {useI18n} from "vue-i18n"
import {useDeviceSize} from "@/composables/useDeviceSize"
import {useModalStore} from "@/stores/ModalStore"
import FFForm from "@/components/FFUI/FFForm.vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import OgpoCalculateForm from "./Forms/OgpoPlusCalculateForm.vue"
import OgpoPlusForms from "@/components/OgpoPlus/OgpoPlusForms.vue"

const modalStore = useModalStore()

const {isDesktop} = useDeviceSize()

const handleOpenFormModal = () => {
  modalStore.openModal({
    component: OgpoPlusForms
  })
}

const {t} = useI18n()
</script>

<template>
  <section class="ogpo-plus-hero">
    <div class="ogpo-plus-hero__left-part">
      <h1 class="ogpo-plus-hero__title">{{ $t("app.ogpoPlusPage.mainTitle") }}</h1>
      <p class="ogpo-plus-hero__subtitle">{{ $t("app.ogpoPlusPage.subTitle") }}</p>
      <p class="ogpo-plus-hero__description">{{ $t("app.ogpoPlusPage.description") }}</p>
      <div class="ogpo-plus-hero__advantages">
        <div class="ogpo-plus-hero__advantage-box">
          <img src="/kasko-front/icons/green-timer.svg" alt="green timer icon" />
          <div class="ogpo-plus-hero__advantage-box-texts">
            <p>{{ $t("app.ogpoPlusPage.advantages.title-1") }}</p>
            <p>{{ $t("app.ogpoPlusPage.advantages.description-1") }}</p>
          </div>
        </div>
        <div class="ogpo-plus-hero__advantage-box">
          <img src="/kasko-front/icons/green-calendar.svg" alt="green calendar icon" />
          <div class="ogpo-plus-hero__advantage-box-texts">
            <p>{{ $t("app.ogpoPlusPage.advantages.title-2") }}</p>
            <p>{{ $t("app.ogpoPlusPage.advantages.description-2") }}</p>
          </div>
        </div>
        <div class="ogpo-plus-hero__advantage-box">
          <img src="/kasko-front/icons/green-shield.svg" alt="green shield icon" />
          <div class="ogpo-plus-hero__advantage-box-texts">
            <p>{{ $t("app.ogpoPlusPage.advantages.title-3") }}</p>
            <p>{{ $t("app.ogpoPlusPage.advantages.description-3") }}</p>
          </div>
        </div>
      </div>
      <FFButton
        v-if="!isDesktop"
        @click="handleOpenFormModal"
        :title="t('app.buttons.findOutCostInsurance')"
        maxWidth="320px"
        :uppercase="false"
        background="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
        color="#ffffff"
        backgroundMobile="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
        colorMobile="#ffffff" />
    </div>
    <div class="ogpo-plus-hero__right-part">
      <FFForm>
        <OgpoCalculateForm />
      </FFForm>
    </div>
  </section>
</template>

<style scoped lang="scss">
.ogpo-plus-hero {
  position: relative;
  padding: 82px 52px 61px;
  border-radius: 30px;
  background: rgba(74, 74, 74, 0.2);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);

  @include min-responsive(xxlWide) {
    margin: 197px 0 263px;
  }

  @include max-responsive(xxlWide) {
    margin: 152px 0 113px;
  }

  @include max-responsive(xxl) {
    margin: 162px 0 103px;
  }

  @include max-responsive(xl) {
    padding: 0;
    background: none;
    border: 0;
  }

  @include max-responsive(768px) {
    background: none;
    backdrop-filter: unset;
    border: 0;
  }

  @include max-responsive(lg) {
    margin: 100px 0;
  }

  .ogpo-plus-hero__left-part {
    max-width: 650px;
    width: 100%;

    @include max-responsive(xxl) {
      max-width: 540px;
    }

    .ogpo-plus-hero__title {
      font-size: 60px;
      font-style: normal;
      font-weight: 900;
      line-height: 60px;
      background: linear-gradient(96deg, #79b0e2 -3.55%, #1cd491 19.9%, #3fed6e 38.14%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @include max-responsive(lg) {
        font-size: 30px;
        line-height: 100%;
      }
    }
    .ogpo-plus-hero__subtitle {
      color: #ffffff;
      font-size: 30px;
      font-style: normal;
      font-weight: 900;
      line-height: 30px;
      margin: 10px 0;

      @include max-responsive(lg) {
        font-size: 18px;
        line-height: normal;
        margin-bottom: 30px;
      }
    }
    .ogpo-plus-hero__description {
      color: #ffffff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .ogpo-plus-hero__advantages {
      display: flex;
      margin-top: 60px;

      @include max-responsive(xl) {
        margin-bottom: 50px;
      }

      @include max-responsive(lg) {
        @include column;
        margin-top: 30px;
        gap: 20px;
      }

      .ogpo-plus-hero__advantage-box {
        display: inline-flex;
        align-items: start;

        @include max-responsive(lg) {
          display: inline-flex;
          align-items: start;
        }

        img {
          margin-right: 10px;
        }
        .ogpo-plus-hero__advantage-box-texts {
          p:nth-child(1) {
            color: #ffffff;
            font-size: 18px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;

            @include max-responsive(lg) {
              color: #ffffff;
              font-size: 18px;
              font-style: normal;
              font-weight: 900;
              line-height: normal;
            }
          }
          p:nth-child(2) {
            color: #ffffff;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
          }
        }
      }
      .ogpo-plus-hero__advantage-box:nth-child(1) {
        padding-right: 16px;

        @include max-responsive(lg) {
          padding-right: 0;
        }
      }
      .ogpo-plus-hero__advantage-box:nth-child(2) {
        padding: 0 16px;
        border-left: 1px solid rgba(255, 255, 255, 0.2);
        border-right: 1px solid rgba(255, 255, 255, 0.2);

        @include max-responsive(lg) {
          padding: 0;
          border: none;
        }
      }
      .ogpo-plus-hero__advantage-box:nth-child(3) {
        padding-left: 16px;

        @include max-responsive(lg) {
          padding-left: 0;
        }
      }
    }
  }

  .ogpo-plus-hero__right-part {
    position: relative;

    @include max-responsive(xl) {
      display: none;
    }

    .ff-form {
      position: absolute;
      right: -53px;
      top: -420px;
      padding: 30px 8px 15px 15px;

      @include max-responsive(xxl) {
        top: -477px;
      }
    }
  }
}
</style>
