<script setup lang="ts">
import {ref, computed} from "vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFButton from "@/components/FFUI/FFButton.vue"
import {useFormatters} from "@/utils/formatters"
import {useFetcher} from "@/composables/useFetcher"
import {useHelpers} from "@/composables/useHelpers"
import {debounce} from "lodash-es"
import Loader from "@/components/FFUI/Loader.vue"
import Modal from "@/components/FFUI/Modal.vue"
import {vMaska} from "maska"
import {useAuthStore} from "@/stores/AuthStore"
import {useToast} from "vue-toastification"
import {useI18n} from "vue-i18n"
import {onPhoneFocus} from "@/utils/inputEvents"

const {postData, loading, error} = useFetcher()
const emit = defineEmits(["success", "fail"])

const authStore = useAuthStore()
const toast = useToast()
const {t} = useI18n()
const {phoneNumberFormatter} = useFormatters()
const {toUpperCase, openLink} = useHelpers()

const clientNumIsNotApproved = ref<boolean>(false)
let isGetConfirmationCode = ref<boolean>(false)
let timerCountdown = ref<number>(30)

const pdfDocument = ref<any>()

let isLenConfCodeIsThree = computed(() => authStore.credentials.confirmationCode.length !== 3)

const startCountdown = () => {
  const countdownInterval = setInterval(() => {
    if (timerCountdown.value > 0) {
      timerCountdown.value--
    } else {
      clearInterval(countdownInterval)
    }
  }, 1000)
}

const getCode = async (): Promise<any> => {
  timerCountdown.value = 30
  try {
    const phoneAndIINData = {
      phone: phoneNumberFormatter(authStore.credentials.phone),
      iin: authStore.credentials.identifier
    }

    const response = await postData("/core/mst/send/sms", phoneAndIINData)

    if (response && response.data?.status === true) {
      isGetConfirmationCode.value = true
      startCountdown()
    }
  } catch (error) {
    console.error(error)
  }
}

const sendCode = async (): Promise<any> => {
  try {
    const phoneAndIINAndCodeData = {
      phone: phoneNumberFormatter(authStore.credentials.phone),
      iin: authStore.credentials.identifier,
      code: toUpperCase(authStore.credentials.confirmationCode)
    }

    const response = await postData("/core/mst/confirm/sms", phoneAndIINAndCodeData)

    if (response && response.data?.status === true) {
      clientNumIsNotApproved.value = false
      isGetConfirmationCode.value = false
    }
  } catch (error) {
    console.error(error)
  }
}

const debouncedSendCode = debounce(sendCode, 1000)

const check = async () => {
  if (authStore.credentials.phone.length != 17) {
    toast.clear()
    toast.error(t("app.toasterMessages.mstPage.phoneNumberMustFilled"))
    return
  }
  try {
    const formattedPhone = <string>phoneNumberFormatter(authStore.credentials.phone)
    const content = await postData(
      "/core/mst/check/phone",
      {
        identifier: authStore.credentials.identifier,
        phone: formattedPhone
      },
      {timeout: 60000}
    )
    if (content.data["status_code"] === 2) {
      clientNumIsNotApproved.value = true
      toast.clear()
      toast.error(content.data["errors"])
      return
    }
    if ([3, 5].includes(content.data["status_code"])) {
      clientNumIsNotApproved.value = false
      toast.clear()
      toast.error(content.data["errors"])
      return
    }
    const authorizeContent = await postData(
      "/core/mst/authorize",
      {
        identifier: authStore.credentials.identifier,
        phone: formattedPhone
      },
      {timeout: 60000}
    )

    emit("success", authorizeContent.data)
  } catch (error) {
    console.error(error)
  }
}

const openDataConsentPdf = async () => {
  try {
    const response = await postData("/core/mst/pdf/data-consent", {iin: authStore.credentials.identifier})

    if (response.data.data) {
      const binaryData = atob(response.data.data)

      const arrayBuffer = new Uint8Array(binaryData.length)
      for (let i = 0; i < binaryData.length; i++) {
        arrayBuffer[i] = binaryData.charCodeAt(i)
      }

      const blob = new Blob([arrayBuffer], {type: "application/pdf"})

      const pdfUrl = URL.createObjectURL(blob)

      openLink(pdfUrl, "_blank")
    }
  } catch (error) {
    console.error(error)
  }
}
</script>

<template>
  <Modal style="z-index: 100" :is-open="loading">
    <Loader />
  </Modal>
  <template v-if="clientNumIsNotApproved">
    <h2 class="ff-form-title">{{ $t("app.mstPage.form.approvalForm.approvalFormTitle") }}</h2>
    <span class="rule rule-1">{{ $t("app.mstPage.form.approvalForm.firstDesc") }}</span>
    <div class="login-form__row">
      <FFFieldset :title="$t('app.mstPage.form.approvalForm.phone')">
        <FFInput
          v-maska
          data-maska="+7 (###) ### ####"
          placeholder="+7 (___) ___ ____"
          id="phonenumber"
          v-model="authStore.credentials.phone"
          :locked="isGetConfirmationCode"
          name="phone"
          type="tel" />
      </FFFieldset>
    </div>
    <div class="login-form__row">
      <FFFieldset :title="$t('app.mstPage.form.approvalForm.iin')">
        <FFInput
          v-maska
          data-maska="############"
          id="identifier"
          v-model="authStore.credentials.identifier"
          :locked="isGetConfirmationCode"
          name="identifier"
          placeholder=""
          type="tel" />
      </FFFieldset>
    </div>
    <div>
      <span v-if="timerCountdown === 0" @click="getCode" class="timer-countdown">
        {{ $t("app.mstPage.form.approvalForm.sendCodeAgain") }}
      </span>
      <span v-if="isGetConfirmationCode && timerCountdown !== 0" class="timer-countdown">
        {{ $t("app.mstPage.form.approvalForm.sendCodeAgain") }} 00:{{ timerCountdown }}
      </span>
      <div v-if="isGetConfirmationCode" class="approve-code">
        <div class="sms-sent">
          {{ $t("app.mstPage.form.approvalForm.codeSentToNumber") }}: {{ authStore.credentials.phone }}
        </div>
        <div class="login-form__row">
          <FFFieldset :title="$t('app.mstPage.form.approvalForm.approvalCode')">
            <FFInput
              v-maska
              data-maska="***"
              id="confirmationCode"
              v-model="authStore.credentials.confirmationCode"
              name="confirmationCode"
              placeholder=""
              type="text"
              :isUppercase="true" />
          </FFFieldset>
        </div>
      </div>
    </div>
    <FFButton v-if="!isGetConfirmationCode" :title="$t('app.mstPage.form.approvalForm.getCode')" @click="getCode" />
    <FFButton
      v-else
      :title="$t('app.mstPage.form.approvalForm.sendCode')"
      @click="debouncedSendCode"
      :disabled="isLenConfCodeIsThree" />

    <span class="rule rule-2">
      {{ $t("app.mstPage.form.approvalForm.secondDesc1") }}
      <strong @click="openDataConsentPdf" class="underline">{{
        $t("app.mstPage.form.approvalForm.secondDesc2")
      }}</strong>
      {{ $t("app.mstPage.form.approvalForm.secondDesc3") }}
    </span>
  </template>
  <template v-if="!clientNumIsNotApproved">
    <h2 class="ff-form-title">{{ $t("app.mstPage.form.formHeaderTitle") }}</h2>
    <div class="login-form__row">
      <FFFieldset :title="$t('app.mstPage.form.loginForm.phone')">
        <FFInput
          v-maska
          data-maska="+7 (###) ### ####"
          placeholder="+7 (___) ___ ____"
          id="phonenumber"
          v-model="authStore.credentials.phone"
          name="phone"
          type="tel" />
      </FFFieldset>
    </div>
    <div class="login-form__row">
      <FFFieldset :title="$t('app.mstPage.form.loginForm.iin')">
        <FFInput
          v-maska
          data-maska="############"
          id="identifier"
          v-model="authStore.credentials.identifier"
          name="identifier"
          placeholder=""
          type="text"
          inputmode="numeric" />
      </FFFieldset>
    </div>
    <FFButton :title="$t('app.mstPage.form.loginForm.next')" @click="check" />
  </template>
</template>

<style lang="scss" scoped>
.ff-form-title {
  color: white;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  text-wrap: wrap;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.login-form__row {
  margin-bottom: 26px;
}
.rule {
  font-size: 14px;
  color: #ffffff;
}
.rule-1 {
  margin-bottom: 20px;
}
.rule-2 {
  margin-top: 20px;
}
.sms-sent {
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 11px;
}
.timer-countdown {
  font-size: 14px;
  color: #ffffff;
}

.approve-code {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 15px;
  border-radius: 10px;
  margin: 20px 0;
}
</style>
