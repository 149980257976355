<script setup lang="ts">
import {ref} from "vue"
import GreenLogoIcon from "@/components/icons/greens/GreenLogoIcon.vue"
import MSTForms from "@/components/MST/MSTForms.vue"
import FFButton from "../FFUI/FFButton.vue"

const isModal = ref<boolean>(false)
const currentStep = ref<number>(0)

const onCalculateMobile = () => {
  toggleModal()
  currentStep.value++
}

const toggleModal = () => {
  isModal.value = !isModal.value
}

const handleStepBack = () => {
  currentStep.value--
}

const forward = () => {
  currentStep.value++
}

const reset = () => {
  toggleModal()
  currentStep.value = 0
}

const stepBackToCalcForm = () => {
  currentStep.value = 2
}
</script>

<template>
  <section class="mst-hero">
    <div class="mst-hero__bag-bg"></div>
    <div class="container mx-auto grid lg:grid-cols-3 relative md-max:pt-[334px]">
      <div class="lg:col-span-2 lg:mr-[20px]">
        <div class="mst-hero-badge">{{ $t("app.mstPage.fullyOnline") }}</div>
        <div class="mst-hero-title">{{ $t("app.mstPage.medInsuranceTouristsAbroadTitle") }}</div>
        <div class="mst-hero-description">{{ $t("app.mstPage.fastlyOrganizeMedHelp") }}</div>
        <div @click="onCalculateMobile" class="mst-hero-calculate-pricing">
          <FFButton backgroundMobile="#448723" colorMobile="#ffffff" :title="$t('app.mstPage.getPrice')" />
        </div>
        <div class="mst-hero-purpose grid gap-4 md:grid-cols-2">
          <div class="col-auto grid gap-[20px]">
            <div class="mst-hero-purpose-wrapper" v-for="item in [1, 2, 3]" :key="item">
              <GreenLogoIcon />
              <div class="mst-hero-purpose-text">
                <div class="mst-hero-purpose-title">
                  {{ $t("app.mstPage.purpose-" + item) }}
                </div>
                <div class="mst-hero-purpose-description">
                  {{ $t("app.mstPage.purpose_note-" + item) }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-auto grid gap-[20px]">
            <div class="mst-hero-purpose-wrapper" v-for="item in [4, 5, 6]">
              <GreenLogoIcon />
              <div class="mst-hero-purpose-text">
                <div class="mst-hero-purpose-title">
                  {{ $t("app.mstPage.purpose-" + item) }}
                </div>
                <div class="mst-hero-purpose-description">
                  {{ $t("app.mstPage.purpose_note-" + item) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lg:col-span-1 grid justify-end content-center">
        <MSTForms
          @stepBackToCalcForm="stepBackToCalcForm"
          @click="onCalculateMobile"
          :isModal="isModal"
          :currentStep="currentStep"
          :handleStepBack="handleStepBack"
          :forward="forward"
          :toggleModal="toggleModal"
          :reset="reset" />
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.mst-hero {
  position: relative;
  background-color: black;
  background-image: url("/mst-front/images/mst-hero.png");
  background-repeat: no-repeat;
  background-position: center;

  padding: 215px 0;
}

.mst-hero__bag-bg {
  display: none;
}

.mst-hero-badge {
  width: fit-content;
  padding: 4px 12.5px;
  justify-content: center;
  align-items: center;

  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 11.048px;
  text-transform: uppercase;

  border-radius: 2.053px;
  background: #f2994a;
}

.mst-hero-title {
  font-size: 50px;
  font-style: normal;
  font-weight: 900;
  line-height: 55px;

  background: linear-gradient(95deg, #79b0e2 -3.54%, #1cd491 56.19%, #3fed6e 102.64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  padding-top: 10px;
  padding-bottom: 50px;
}

.mst-hero-description {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 50px;
}

.mst-hero-calculate-pricing {
  display: none;
}

.mst-hero-purpose-wrapper {
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: 50px;
  gap: 10px;
}

.mst-hero-purpose-title {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  text-transform: uppercase;
}

.mst-hero-purpose-description {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.6;
}

@media (max-width: $lg) {
  .mst-hero {
    padding: 30px 0 0;
    background-image: none;
  }

  .mst-hero__bag-bg {
    display: block;
    background-image: url("/mst-front/images/mst-hero-mobile.png");
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: 0;
  }

  .mst-hero-title {
    font-size: 30px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;

    padding-bottom: 30px;
  }

  .mst-hero-description {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 300px;
  }

  .mst-hero-purpose-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }

  .mst-hero-purpose-description {
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
}

@media (max-width: 1024px) {
  .mst-hero-calculate-pricing {
    display: block;
    margin-bottom: 50px;
  }
}

@media (max-width: $xl) {
  .mst-hero-description {
    max-width: 100%;
  }
}
</style>
