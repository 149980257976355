<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_29049_68165)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.636 7.27282C13.636 8.23724 13.2529 9.16216 12.5709 9.84411C11.889 10.5261 10.9641 10.9092 9.99963 10.9092C9.03521 10.9092 8.11029 10.5261 7.42834 9.84411C6.7464 9.16216 6.36328 8.23724 6.36328 7.27282C6.36328 6.3084 6.7464 5.38348 7.42834 4.70154C8.11029 4.01959 9.03521 3.63647 9.99963 3.63647C10.9641 3.63647 11.889 4.01959 12.5709 4.70154C13.2529 5.38348 13.636 6.3084 13.636 7.27282ZM11.8178 7.27282C11.8178 7.75503 11.6262 8.21749 11.2853 8.55847C10.9443 8.89944 10.4818 9.091 9.99963 9.091C9.51742 9.091 9.05496 8.89944 8.71399 8.55847C8.37301 8.21749 8.18146 7.75503 8.18146 7.27282C8.18146 6.79061 8.37301 6.32815 8.71399 5.98718C9.05496 5.64621 9.51742 5.45465 9.99963 5.45465C10.4818 5.45465 10.9443 5.64621 11.2853 5.98718C11.6262 6.32815 11.8178 6.79061 11.8178 7.27282Z"
        fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.99996 0C4.47725 0 0 4.47725 0 9.99996C0 15.5227 4.47725 19.9999 9.99996 19.9999C15.5227 19.9999 19.9999 15.5227 19.9999 9.99996C19.9999 4.47725 15.5227 0 9.99996 0ZM1.81817 9.99996C1.81817 11.9 2.46635 13.649 3.55271 15.0381C4.31565 14.0362 5.29989 13.2243 6.42855 12.6657C7.55721 12.1071 8.79973 11.817 10.059 11.8181C11.3021 11.817 12.529 12.0995 13.6462 12.6443C14.7635 13.1891 15.7417 13.9817 16.5063 14.9618C17.294 13.9287 17.8243 12.7229 18.0535 11.4441C18.2826 10.1654 18.204 8.85045 17.824 7.60814C17.4441 6.36582 16.7738 5.23184 15.8686 4.30002C14.9634 3.36819 13.8493 2.66532 12.6185 2.24954C11.3877 1.83377 10.0756 1.71706 8.79075 1.90906C7.5059 2.10106 6.28524 2.59625 5.22977 3.35367C4.1743 4.11109 3.31436 5.10895 2.72111 6.2647C2.12785 7.42044 1.81834 8.70084 1.81817 9.99996ZM9.99996 18.1817C8.12174 18.1846 6.30022 17.5384 4.84362 16.3527C5.42991 15.5133 6.21028 14.828 7.11833 14.3551C8.02637 13.8822 9.03522 13.6356 10.059 13.6363C11.0701 13.6355 12.0668 13.8759 12.9662 14.3376C13.8657 14.7993 14.6421 15.4689 15.2308 16.2908C13.7629 17.5151 11.9114 18.1844 9.99996 18.1817Z"
        fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_29049_68165">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
