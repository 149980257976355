<script setup lang="ts">
import {onUnmounted} from "vue"
import {useAddDriverAutoStore} from "@/stores/AddDriverAutoStore"

const addDriverAutoStore = useAddDriverAutoStore()

onUnmounted(() => {
  localStorage.removeItem("date-time-applying")
})

const dateAndTimeApplying = localStorage.getItem("date-time-applying")
</script>

<template>
  <div class="add-statement">
    <p class="add-statement__title">{{ $t("app.addDriverAuto.subTitle-2") }}</p>
    <p class="add-statement__subtitle">
      {{ $t("app.addDriverAuto.subTitle-3") }}
    </p>

    <div class="add-statement__date-request">
      <p>{{ $t("app.addDriverAuto.dateTimeOfRequest") }}</p>
      <p>{{ dateAndTimeApplying }}</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.add-statement {
  display: flex;
  flex-direction: column;
  padding: 30px;
  margin-top: 50px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);

  .add-statement__title {
    color: #ffffff;
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }

  .add-statement__subtitle {
    color: #ffffff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 4px 0 20px;
  }

  .add-statement__date-request {
    display: flex;
    justify-content: space-between;
    p:first-of-type {
      color: #ffffff;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    p:last-of-type {
      color: rgba(255, 255, 255, 0.5);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}

@media (max-width: $lg) {
  .add-statement {
    padding: 30px 16px;
  }
}
</style>
