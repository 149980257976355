<script setup lang="ts">
import {onMounted, onUnmounted} from "vue"
import {useRouter, useRoute} from "vue-router"
import {storeToRefs} from "pinia"
import {useAddDriverAutoStore} from "@/stores/AddDriverAutoStore"
import {usePersonalProfileStore} from "@/stores/PersonalProfileStore"
import {useModalStore} from "@/stores/ModalStore"
import {useHelpers} from "@/composables/useHelpers"
import {useI18n} from "vue-i18n"
import {useFormatters} from "@/utils/formatters"
import AddTitle from "@/components/AddDriverAuto/AddTitle.vue"
import AddStatement from "@/components/AddDriverAuto/AddStatement.vue"
import AddDriver from "@/components/AddDriverAuto/AddDriver.vue"
import AddAuto from "@/components/AddDriverAuto/AddAuto.vue"
import AddNewAgreement from "@/components/AddDriverAuto/AddNewAgreement.vue"
import AddReason from "@/components/AddDriverAuto/AddReason.vue"
import AddTotalPayment from "@/components/AddDriverAuto/AddTotalPayment.vue"
import Modal from "@/components/FFUI/Modal.vue"
import Loader from "@/components/FFUI/Loader.vue"
import AddPaymentMethods from "@/components/AddDriverAuto/AddPaymentMethods.vue"
import AddWelcomeModal from "@/components/AddDriverAuto/AddWelcomeModal.vue"

const modalStore = useModalStore()
const addDriverAutoStore = useAddDriverAutoStore()
const {
  iin,
  autoNumber,
  currentStep,
  loading,
  isBadScoring,
  isPersonsLengthEqualToVehicles,
  isDriverAutoSumCalculated,
  pushedPersons,
  pushedVehicles,
  initialPersonsState,
  initialVehiclesState,
  isPrivilegeUnchanged,
  calculationResult,
  iAgreeState,
  paymentUrl,
  agreementLoaderHide,
  persons
} = storeToRefs(addDriverAutoStore)
const personalProfileStore = usePersonalProfileStore()
const route = useRoute()
const {thousandSeparator} = useFormatters()

onMounted(async () => {
  modalStore.openModal({
    component: AddWelcomeModal
  })
  currentStep.value = 1
  const globalIdFromParams = route.params.id
  const productCodeFromParams = route.params.product
  if (globalIdFromParams) {
    await addDriverAutoStore.getAddDriverAutoDataByGlobalId(
      globalIdFromParams as string,
      productCodeFromParams as string
    )
  }
})

onUnmounted(() => {
  iin.value = ""
  autoNumber.value = ""
  pushedPersons.value = []
  pushedVehicles.value = []
  initialPersonsState.value = []
  initialVehiclesState.value = []
  isDriverAutoSumCalculated.value = false
  iAgreeState.value = false
  modalStore.closeModal()
})
</script>
<template>
  <template v-if="agreementLoaderHide"> </template>
  <template v-else>
    <Modal style="z-index: 103" :isOpen="loading">
      <Loader />
    </Modal>
  </template>
  <div class="bg-image">
    <div class="container">
      <div class="add-page">
        <template v-if="currentStep === 1">
          <AddTitle />
          <AddStatement />
          <AddDriver />
          <AddAuto />
          <AddReason />
          <AddNewAgreement />
          <AddTotalPayment
            @payment="addDriverAutoStore.handleAddDriverAutoPayment"
            :showPremiumSum="isDriverAutoSumCalculated"
            :showDescription="true"
            :showSumTooltip="isBadScoring ? true : false"
            :tooltipText="$t('app.addDriverAuto.badScoringTooltipText')"
            :iAgreeState="iAgreeState"
            :disabled="!isDriverAutoSumCalculated ? isPersonsLengthEqualToVehicles : !iAgreeState" />
        </template>

        <template v-if="currentStep === 2">
          <AddPaymentMethods />
        </template>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.bg-image {
  background: url("/thankyou-front/images/thankyou-bg.png") no-repeat top;
}
.add-page {
  padding: 107px 0;
  max-width: 690px;
  margin: 0 auto;
}

@media (max-width: $lg) {
  .add-page {
    padding: 50px 0;
  }
}
</style>
