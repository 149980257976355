<script lang="ts" setup>
import {vMaska} from "maska"
import {useRouter} from "vue-router"
import {useMSTStore} from "@/stores/MSTStore"
import FFFieldset from "@/components/FFUI/FFFieldset.vue"
import FFInput from "@/components/FFUI/FFInput.vue"
import FFButton from "@/components/FFUI/FFButton.vue"

interface IProps {
  createOrderInKaspi: () => Promise<any>
  orderNumber: string
}

const props = defineProps<IProps>()

const mstStore = useMSTStore()
const router = useRouter()

const handleOrderInKaspi = async () => {
  try {
    const response = await props.createOrderInKaspi()

    if (response && response.status === 200) {
      localStorage.setItem("paymentType", "kaspi")
      if (localStorage.getItem("paymentType") === "kaspi") {
        router.replace("thank-you")
      }
    }
  } catch (error) {
    console.error(error)
  }
}
</script>

<template>
  <FFFieldset :title="$t('app.mstPage.form.paymentForm.linkedPhoneNumberToKaspi')">
    <FFInput
      v-maska
      data-maska="+7 (###) ### ####"
      id="phone"
      v-model="mstStore.kaspiPayPhoneNumber"
      name="phone"
      placeholder="+7 (___) ___ ____"
      type="tel" />
  </FFFieldset>
  <FFButton
    @click="handleOrderInKaspi"
    :title="$t('app.mstPage.form.paymentForm.sendBillToKaspi')"
    :uppercase="false" />
</template>

<style scoped lang="scss">
.input-fieldset {
  margin-bottom: 20px;
}
</style>
