<script setup lang="ts"></script>

<template>
  <div class="mission container">
    <div class="mission__texts">
      <p class="mission__texts--title">{{ $t("app.aboutCompanyPage.companyMission.title") }}</p>
      <p class="mission__texts--quote">
        {{ $t("app.aboutCompanyPage.companyMission.quote") }}
      </p>
      <p class="mission__texts--description">
        {{ $t("app.aboutCompanyPage.companyMission.description") }}
      </p>
    </div>
    <img src="/about-company-front/images/ffins-book.png" alt="ffins-book" />
  </div>
</template>

<style scoped lang="scss">
.mission {
  padding: 30px;
  border-radius: 20px;
  border: 1px solid #262626;
  background: rgba(74, 74, 74, 0.2);
  backdrop-filter: blur(16px);
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 100px;
  margin-bottom: 100px;

  @include max-responsive(xl) {
    gap: 66px;
  }

  @include max-responsive(lg) {
    @include column;
    text-align: center;
    gap: 30px;
    padding: 50px 20px 20px 20px;
  }

  .mission__texts {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .mission__texts--title {
      font-size: 30px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      text-transform: uppercase;
      @include main-green-gradient;
    }
    .mission__texts--quote {
      color: var(--color-white);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .mission__texts--description {
      color: #a9a9a9;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
</style>
