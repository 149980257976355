<script setup lang="ts">
import {ref} from "vue"
import type {IUser} from "@/interfaces/Auth/IUser"
 
const currentUser = ref<IUser | null>(null)
 
const storedUser = localStorage.getItem("current-user")
if (storedUser) {
  currentUser.value = JSON.parse(storedUser)
}
</script>
<template>
  <div v-if="storedUser" class="info container">
    <p class="info__name">{{ currentUser?.data.fullName }}</p>
  </div>
</template>
 
<style scoped lang="scss">
.info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 100px;
  &__name {
    color: #fff;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: fit-content;
  }
  &__bonus {
    width: fit-content;
    padding: 10px;
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 4px;
    background: var(--Gray-main, #404040);
  }
}

@media (max-width: $lg) {
  .info {
    padding-top: 50px;
  }
}
</style>
