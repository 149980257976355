<script setup lang="ts">
import {useRouter} from "vue-router"
import {useHelpers} from "@/composables/useHelpers"
import {useFormatters} from "@/utils/formatters"
import {INewsDetail} from "@/interfaces/News/INewsDetail"

interface IProps {
  newsDetail: INewsDetail
}

defineProps<IProps>()

const router = useRouter()
const {currentLocale} = useHelpers()
const {formatDateToRussian} = useFormatters()
</script>

<template>
  <section class="news-details-post container">
    <div class="news-details__breadcrumbs">
      <p @click="router.push('/')">{{ $t("app.ourTeam.main") }}</p>
      /
      <p @click="router.push('/pages/news')">{{ $t("app.news") }}</p>
      /
      <p>{{ newsDetail?.news_name_ru }}</p>
    </div>
    <img class="news-details-post__poster" :src="newsDetail.news_image" loading="lazy" alt="" />
    <div class="news-details-post__category">{{ newsDetail.news_type }}</div>
    <p class="news-details-post__title">
      {{ currentLocale === "kk" ? newsDetail.news_name_kz : newsDetail.news_name_ru }}
    </p>
    <p class="news-details-post__subtitle">
      {{
        currentLocale === "ru"
          ? newsDetail.news_desc_ru
          : currentLocale === "kk"
            ? newsDetail.news_desc_kz
            : newsDetail.news_desc_en
      }}
    </p>
    <div v-html="currentLocale === 'kk' ? newsDetail.news_text_kz : newsDetail.news_text_ru"></div>
    <p class="news-details-post__date">{{ formatDateToRussian(newsDetail.news_date) }}</p>
  </section>
</template>

<style scoped lang="scss">
.news-details-post {
  .news-details__breadcrumbs {
    padding-top: 50px;
    display: flex;
    align-items: center;
    gap: 5px;
    color: #a1a1a1;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    @include max-responsive(lg) {
      display: none;
    }
    p {
      cursor: pointer;
      transition: all 0.3s ease;
    }
    p:hover {
      color: #555555;
      cursor: pointer;
    }
  }
  .news-details-post__poster {
    height: 447px;
    display: block;
    margin: auto;
    margin-bottom: 20px;
    padding-top: 10px;

    @include max-responsive(lg) {
      object-fit: contain;
      height: unset;
    }
  }
  .news-details-post__category {
    display: inline-flex;
    padding: 4px 10px;
    border-radius: 5px;
    background: var(--color-orange);
    color: var(--color-black);
    text-shadow: 4px 4px 27px rgba(0, 0, 0, 0.25);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
  }
  .news-details-post__title {
    color: var(--color-black);
    text-shadow: 4px 4px 27px rgba(0, 0, 0, 0.25);
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-bottom: 10px;
  }
  .news-details-post__subtitle {
    color: #606060;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
  }
  .news-details-post__date {
    margin-top: 20px;
    padding-bottom: 50px;
    color: #606060;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  ::v-deep(.MsoNormal) {
    p {
      line-height: 1.42857143;
    }
  }
}
</style>
