<script setup lang="ts">
import FFButton from "@/components/FFUI/FFButton.vue"
import {useHelpers} from "@/composables/useHelpers"

const {openLink} = useHelpers()
</script>

<template>
  <section class="ogpo-guarantee">
    <div class="ogpo-guarantee__card">
      <div class="bg-[#F2994A] rounded-[5px] text-[16px] text-white w-fit px-[10px] py-[8px] leading-3">DTP.kz</div>
      <p class="ogpo-guarantee__card--title">{{ $t("main.guarantee.online") }}</p>
      <p class="ogpo-guarantee__card--subtitle">{{ $t("main.guarantee.insuranceThroughFiveDays") }}</p>
      <p class="ogpo-guarantee__card--description">{{ $t("main.guarantee.crash") }}</p>
      <FFButton
        @click="openLink(`https://dtp.kz`, '_self')"
        class="ogpo-guarantee__card--btn"
        :title="$t('main.guarantee.more')"
        :uppercase="true"
        color="#ffffff"
        colorMobile="#ffffff"
        background="transparent"
        backgroundMobile="transparent"
        borderpixel="1px solid #ffffff"
        borderpixelMobile="1px solid #ffffff"
        paddingx="17px"
        paddingy="30px"
        width="100%" />
    </div>
    <img class="guarantee-xxl" src="/main-front/images/guarantee-xxl.png" alt="guarantee" draggable="false" />
    <img class="guarantee-xl" src="/main-front/images/guarantee-xl.png" alt="guarantee" draggable="false" />
  </section>
</template>

<style scoped lang="scss">
.ogpo-guarantee {
  display: flex;
  gap: 30px;
  margin: 100px 0 186px;
  position: relative;
  z-index: 1;

  @include max-responsive(xxl) {
    margin: 100px 0;
  }

  @include max-responsive(xl) {
    @include column;
  }

  @include max-responsive(lg) {
    margin: 150px 0;
  }

  @include max-responsive(md) {
    margin: 100px 0;
  }

  .ogpo-guarantee__card {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 30px;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(74, 74, 74, 0.4);
    backdrop-filter: blur(8px);
    .ogpo-guarantee__card--title {
      font-size: 40px;
      font-style: normal;
      font-weight: 900;
      line-height: 40.4px;
      @include main-green-gradient;
      margin-top: 14px;
      max-width: 438px;

      @include max-responsive(lg) {
        margin-bottom: 0;
        font-size: 30px;
        line-height: 101%;
      }
    }
    .ogpo-guarantee__card--subtitle {
      font-size: 16px;
      font-weight: 900;
      line-height: 19.2px;
      color: #ffffff;
      margin: 14px 0 35px 0;
    }
    .ogpo-guarantee__card--description {
      color: #a9a9a9;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      max-width: 645px;
      white-space: pre-wrap;
    }
    .ogpo-guarantee__card--btn {
      margin-top: auto;
      border: 1px solid rgba(255, 255, 255, 0.7);
      max-width: 250px;

      @include max-responsive(xxl) {
        margin-top: 15px;
      }

      @include max-responsive(xl) {
        margin-top: 30px;
      }

      @include max-responsive(lg) {
        max-width: inherit;
      }
    }
  }
}

.guarantee-xxl {
  @include max-responsive(xl) {
    display: none;
  }
}

.guarantee-xl {
  display: none;

  @include max-responsive(xl) {
    @include flex;
  }

  @include max-responsive(lg) {
    display: none;
  }
}

.guarantee-lg {
  display: none;

  @include max-responsive(lg) {
    @include flex;
  }

  @include max-responsive(md) {
    display: none;
  }
}

.guarantee-md {
  display: none;

  @include max-responsive(md) {
    @include flex;
  }
}
</style>
