<script setup lang="ts">
import {useI18n} from "vue-i18n"
import {useDeviceSize} from "@/composables/useDeviceSize"
import {useModalStore} from "@/stores/ModalStore"
import KaskoForms from "@/components/Kasko/KaskoForms.vue"
import KaskoCalculateForm from "@/components/Kasko/Forms/KaskoCalculateForm.vue"
import FFForm from "@/components/FFUI/FFForm.vue"
import FFButton from "@/components/FFUI/FFButton.vue"

const modalStore = useModalStore()

const {isDesktop} = useDeviceSize()

const handleOpenFormModal = () => {
  modalStore.openModal({
    component: KaskoForms
  })
}

const {t} = useI18n()
</script>

<template>
  <section class="kasko-hero">
    <div class="kasko-hero__left-part">
      <div class="kasko-hero__boxes">
        <div class="kasko-hero__box">{{ $t("app.kaskoPage.kasko") }}</div>
        <div class="kasko-hero__box">{{ $t("app.kaskoPage.withoutInspectionOfTheCar") }}</div>
      </div>
      <h1 class="kasko-hero__title">{{ $t("app.kaskoPage.mainTitle") }}</h1>
      <p class="kasko-hero__subtitle">{{ $t("app.kaskoPage.subTitle") }}</p>
      <p class="kasko-hero__description">{{ $t("app.kaskoPage.description") }}</p>
      <div class="kasko-hero__advantages">
        <div class="kasko-hero__advantage-box">
          <img src="/kasko-front/icons/green-timer.svg" alt="green timer icon" />
          <div class="kasko-hero__advantage-box-texts">
            <p>{{ $t("app.kaskoPage.advantages.title-1") }}</p>
            <p>{{ $t("app.kaskoPage.advantages.description-1") }}</p>
          </div>
        </div>
        <div class="kasko-hero__advantage-box">
          <img src="/kasko-front/icons/green-calendar.svg" alt="green calendar icon" />
          <div class="kasko-hero__advantage-box-texts">
            <p>{{ $t("app.kaskoPage.advantages.title-2") }}</p>
            <p>{{ $t("app.kaskoPage.advantages.description-2") }}</p>
          </div>
        </div>
        <div class="kasko-hero__advantage-box">
          <img src="/kasko-front/icons/green-shield.svg" alt="green shield icon" />
          <div class="kasko-hero__advantage-box-texts">
            <p>{{ $t("app.kaskoPage.advantages.title-3") }}</p>
            <p>{{ $t("app.kaskoPage.advantages.description-3") }}</p>
          </div>
        </div>
      </div>
      <FFButton
        v-if="!isDesktop"
        @click="handleOpenFormModal"
        :title="t('app.buttons.findOutCostInsurance')"
        maxWidth="320px"
        :uppercase="false"
        background="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
        color="#ffffff"
        backgroundMobile="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(132deg, #8DD979 -0.7%, #2A5704 103.63%)"
        colorMobile="#ffffff" />
    </div>
    <div class="kasko-hero__right-part">
      <FFForm>
        <KaskoCalculateForm />
      </FFForm>
    </div>
  </section>
</template>

<style scoped lang="scss">
.kasko-hero {
  display: flex;
  position: relative;
  gap: 20px;
  z-index: 1;
  padding: 58px 20px 58px 52px;
  border-radius: 30px;
  background: rgba(74, 74, 74, 0.2);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);

  .kasko-hero__left-part {
    .kasko-hero__boxes {
      display: flex;
      gap: 10px;
      
      .kasko-hero__box {
        display: inline-block;
        color: #ffffff;
        text-transform: uppercase;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 11.048px;
        text-transform: uppercase;

        border-radius: 2.053px;
        background: #f2994a;
        padding: 5px 10px;
      }
    }
    .kasko-hero__title {
      font-size: 60px;
      font-style: normal;
      font-weight: 900;
      line-height: 60px;
      max-width: 753px;
      background: linear-gradient(95deg, #79b0e2 -3.54%, #1cd491 56.19%, #3fed6e 102.64%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      margin: 20px 0 10px;
    }
    .kasko-hero__subtitle {
      color: #ffffff;
      font-size: 30px;
      font-style: normal;
      font-weight: 900;
      line-height: 30px;
      margin-bottom: 50px;
    }
    .kasko-hero__description {
      color: #ffffff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .kasko-hero__advantages {
      display: flex;
      margin-top: 60px;
      .kasko-hero__advantage-box {
        display: inline-flex;
        align-items: start;
        img {
          margin-right: 10px;
        }
        .kasko-hero__advantage-box-texts {
          p:nth-child(1) {
            color: #ffffff;
            font-size: 18px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
          }
          p:nth-child(2) {
            color: #ffffff;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
          }
        }
      }
      .kasko-hero__advantage-box:nth-child(1) {
        padding-right: 16px;
      }
      .kasko-hero__advantage-box:nth-child(2) {
        padding: 0 16px;
        border-left: 1px solid rgba(255, 255, 255, 0.2);
        border-right: 1px solid rgba(255, 255, 255, 0.2);
      }
      .kasko-hero__advantage-box:nth-child(3) {
        padding-left: 16px;
      }
    }
  }

  .kasko-hero__right-part {
    position: relative;
  }
}

@media (min-width: $xxlWide) {
  .kasko-hero {
    margin: 150px 0;
  }
}

@media (max-width: $xxlWide) {
  .kasko-hero {
    margin: 152px 0 113px;
  }
}

@media (max-width: $wide) {
  .kasko-hero {
    margin: 152px 0 113px;
  }
}

@media (max-width: $xxl) {
  .kasko-hero {
    margin: 162px 0 103px;

    .kasko-hero__left-part {
      .kasko-hero__advantages {
        .kasko-hero__advantage-box {
          img {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@media (max-width: $xl) {
  .kasko-hero {
    border: 0;
    padding: 30px;

    .kasko-hero__left-part {
      .kasko-hero__advantages {
        margin-bottom: 50px;
        .kasko-hero__advantage-box {
          img {
            margin-right: 10px;
          }
        }
      }
    }

    .kasko-hero__right-part {
      display: none;
    }
  }
}

@media (max-width: $lg) {
  .kasko-hero {
    margin: 50px 0 50px;
    padding: 50px 20px;
    .kasko-hero__left-part {
      .kasko-hero__title {
        font-size: 30px;
        font-style: normal;
        font-weight: 900;
        line-height: 100%;
      }
      .kasko-hero__subtitle {
        font-size: 18px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        margin-bottom: 30px;
      }

      .kasko-hero__advantages {
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;
        gap: 20px;

        .kasko-hero__advantage-box {
          img {
            margin-right: 10px;
          }

          padding: 0 !important;
          border: 0 !important;
        }
      }
    }
  }
}
</style>
