<script setup lang="ts">
import {useFormatters} from "@/utils/formatters"

interface Props {
  program: string
  premium: number
  currencyName: string
  currencyValue: number
}

defineProps<Props>()

const {thousandSeparator} = useFormatters()

const getBadgeTitle = (program: string) => {
  switch (program) {
    case "Tourism":
      return "Travel"
    case "Job":
      return "Work for hire"
    case "Study":
      return "Education"
    case "Sport":
      return "Sport"
    case "Business":
      return "Business"
  }
}
</script>

<template>
  <div class="program-badge">
    <div class="program-badge__top">
      <div class="program-badge__name">{{ getBadgeTitle(program) }}</div>
      <div class="program-badge__price">
        <div class="program-badge__price-title">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_27088_38442)">
              <path
                d="M6 0.375C2.925 0.375 0.375 2.925 0.375 6C0.375 9.075 2.925 11.625 6 11.625C9.075 11.625 11.625 9.075 11.625 6C11.625 2.925 9.075 0.375 6 0.375ZM5.775 9.15C5.325 9.15 4.95 8.775 4.95 8.325C4.95 7.875 5.25 7.5 5.775 7.5C6.225 7.5 6.6 7.875 6.6 8.325C6.6 8.775 6.225 9.15 5.775 9.15ZM6.825 5.775C6.525 6.15 6.375 6.525 6.375 6.9V7.05H5.175V6.825C5.175 6.375 5.325 5.925 5.7 5.4C6 5.1 6.225 4.8 6.225 4.5C6.225 4.2 6 3.975 5.55 3.975C5.25 3.975 4.95 4.05 4.725 4.2L4.35 3.225C4.65 3 5.25 2.85 5.85 2.85C7.05 2.85 7.575 3.525 7.575 4.275C7.65 4.95 7.2 5.4 6.825 5.775Z"
                fill="white" />
            </g>
            <defs>
              <clipPath id="clip0_27088_38442">
                <rect width="12" height="12" fill="white" />
              </clipPath>
            </defs>
          </svg>
          {{ $t("app.mstPage.form.calculationForm.cost") }}
        </div>
        <div class="program-badge__note">
          <div class="program-badge__note-title">{{ $t("app.mstPage.form.calculationForm.usdToKzt") }}</div>
          <div class="program-badge__note-value">
            <p>{{ currencyName }}/KZT</p>
            <p>{{ currencyValue }}</p>
          </div>
        </div>
        <div class="program-badge__price-value">
          {{ thousandSeparator(premium) }} {{ $t("form.formAbbreviations.tg") }}.
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.program-badge {
  @include flex;
  width: 100%;
  padding: 18px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.2);

  .program-badge__top {
    @include flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .program-badge__name {
    color: var(--color-white);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    max-width: 91px;
  }

  .program-badge__price {
    display: flex;
    height: 44px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .program-badge__price-title {
    display: flex;
    gap: 5px;
    color: #d7d7d7;
    text-align: right;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .program-badge__price-value {
    color: var(--color-white);
    text-align: right;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-wrap: nowrap;
  }
  .program-badge__note {
    display: none;
    border-radius: 10px;
    background: #fff;
    position: absolute;
    width: 270px;
    padding: 18px 20px;
    flex-direction: column;
    align-items: flex-start;
    top: 40px;
    right: -22px;
  }
  .program-badge__note-title {
    color: #666;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .program-badge__note-value {
    @include flex;
    justify-content: space-between;
    width: 100%;
    p {
      color: #666;
      font-size: 16px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
  }

  .program-badge__price:hover {
    .program-badge__note {
      @include flex;
    }
  }
}
</style>
