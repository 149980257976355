<script setup lang="ts">
import {onMounted, onUnmounted} from "vue"
import {storeToRefs} from "pinia"
import {useRouter} from "vue-router"
import {useTerminationSubscriptionAgreementStore} from "@/stores/TerminationSubscriptionAgreementStore"
import {useModalStore} from "@/stores/ModalStore"
import TerminationSubscriptionTitle from "@/components/TerminationSubscriptionAgreement/TerminationSubscriptionTitle.vue"
import TerminationSubscriptionReason from "@/components/TerminationSubscriptionAgreement/TerminationSubscriptionReason.vue"
import TerminationSubscriptionTotalPayment from "@/components/TerminationSubscriptionAgreement/TerminationSubscriptionTotalPayment.vue"
import Modal from "@/components/FFUI/Modal.vue"
import Loader from "@/components/FFUI/Loader.vue"

const terminationSubscriptionAgreementStore = useTerminationSubscriptionAgreementStore()
const modalStore = useModalStore()
const {
  loading,
  terminationReason,
  terminationByGlobalId,
  calculationTerminationResponse,
  checkOtpResponse,
  terminationInsSum,
  terminationOgpoPlusSum,
  withoutRefund
} = storeToRefs(terminationSubscriptionAgreementStore)
const router = useRouter()

const handleTotalPayment = async () => {
  terminationSubscriptionAgreementStore.handleOtpFormOpen()
}

onUnmounted(() => {
  terminationByGlobalId.value = null
  calculationTerminationResponse.value = null
  terminationInsSum.value = null

  terminationSubscriptionAgreementStore.resetTerminationReason()
})
</script>
<template>
  <Modal style="z-index: 103" :isOpen="loading">
    <Loader />
  </Modal>
  <div class="bg-image">
    <div class="container">
      <div class="add-page">
        <TerminationSubscriptionTitle />
        <TerminationSubscriptionReason />
        <TerminationSubscriptionTotalPayment
          @payment="handleTotalPayment"
          show-description
          :showPremiumSum="Boolean(terminationReason)"
          :showAmountOfInsurancePremium="true"
          :premium-sum="`${String(terminationByGlobalId?.ins_premium)} ${$t('form.formAbbreviations.tg')}`"
          :disabled="!terminationReason" />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.bg-image {
  background: url("/thankyou-front/images/thankyou-bg.png") no-repeat top;
}
.add-page {
  padding: 107px 0;
  max-width: 690px;
  margin: 0 auto;
}

@media (max-width: $lg) {
  .add-page {
    padding: 50px 0;
  }
}
</style>
