<script setup lang="ts">
import {useHelpers} from "@/composables/useHelpers"
import FFCheckbox from "@/components/FFUI/FFCheckbox.vue"

interface IProps {
  openQuestionnairePdfFn: () => Promise<void>
  openRules: () => void
  product?: string
  showQuestionnaire?: boolean
}

withDefaults(defineProps<IProps>(), {
  showQuestionnaire: true
})
const modelValue = defineModel<boolean>()

const {currentLocale} = useHelpers()
</script>

<template>
  <FFCheckbox v-if="currentLocale === 'kk'" v-model="modelValue">
    <template #title>
      {{ $t("form.checkboxTitles.iAgree.iAgree-1") }}
      <template v-if="product === 'ogpoPlus'">{{ $t("form.checkboxTitles.iAgree.ogpoPlus") }}</template
      >{{ " " }}
      <span @click="openQuestionnairePdfFn" :class="{ link: showQuestionnaire }">
        {{ $t("form.checkboxTitles.iAgree.iAgree-2") }}</span
      >
      {{ $t("form.checkboxTitles.iAgree.iAgree-3") }}
      <span @click="openRules" class="link">
        {{ $t("form.checkboxTitles.iAgree.iAgree-4") }}
      </span>
      {{ $t("form.checkboxTitles.iAgree.iAgree-5") }}
    </template>
  </FFCheckbox>
  <FFCheckbox v-else v-model="modelValue">
    <template #title>
      {{ $t("form.checkboxTitles.iAgree.iAgree-1") }}
      <span @click="openQuestionnairePdfFn" :class="{ link: showQuestionnaire }">
        {{ $t("form.checkboxTitles.iAgree.iAgree-2") }}
      </span>
      {{ $t("form.checkboxTitles.iAgree.iAgree-3") }}
      <span @click="openRules" class="link"
        >{{ $t("form.checkboxTitles.iAgree.iAgree-4") }} {{ $t("form.checkboxTitles.iAgree.iAgree-5") }}</span
      >{{' '}}
      <template v-if="product === 'ogpoPlus'">{{ $t("form.checkboxTitles.iAgree.ogpoPlus") }}</template>
    </template>
  </FFCheckbox>
</template>

<style scoped lang="scss">
.link {
  text-decoration: underline;
  cursor: pointer;
  font-weight: 700;
}
span {
  font-weight: 700;
}
</style>
