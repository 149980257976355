<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
    <path
      d="M20.4032 34.901L11.8813 26.9971L20.4032 19.0933L22.2185 21.046L17.2379 25.6648L41.5843 25.6648L41.5843 28.3295L17.2379 28.3295L22.2185 32.9483L20.4032 34.901Z"
      fill="white" />
  </svg>
</template>

<style scoped>
svg {
  cursor: pointer;
}
svg:hover path {
  fill: #4f9d3a;
}
</style>
