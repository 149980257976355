<script setup lang="ts">
import CongratulationsMessage from "@/components/ThankYou/CongratulationsMessage.vue"
import LeaveFeedback from "@/components/ThankYou/LeaveFeedback.vue"

const paymentType = localStorage.getItem("paymentType")
</script>

<template>
  <div class="bg-image">
    <div class="container">
      <div class="thank-you">
        <CongratulationsMessage />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.bg-image {
  background: url("/thankyou-front/images/thankyou-bg.png") no-repeat top;
}
.thank-you {
  display: flex;
  flex-direction: column;
  gap: 107px;
  padding: 107px 0;
}

@media (max-width: $lg) {
  .thank-you {
    padding: 50px 0;
    gap: 50px;
  }
}
</style>
